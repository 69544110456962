// libs
import React from 'react';

// UI components
import Icon from '@material-ui/core/Icon';

const Pagination = ({
    currentPage,
    pageLimit,
    totalCount,
    onChange,
    className,
}) => {
    return (
        <div className="flex flex-space-between">
            <data>Total: {totalCount.toLocaleString()}</data>
            <div className={`flex flex-center-y ${className}`}>
                <Icon
                    className="pointer"
                    fontSize="large"
                    onClick={() => {
                        if (currentPage - 1 !== 0) {
                            onChange(currentPage - 1);
                        }
                    }}
                >
                    chevron_left
                </Icon>
                <div className="ml5 mr5">
                    {currentPage} /{' '}
                    {Math.ceil(totalCount / pageLimit).toLocaleString()}
                </div>
                <Icon
                    className="pointer"
                    fontSize="large"
                    onClick={() => {
                        if (
                            currentPage + 1 <=
                            Math.ceil(totalCount / pageLimit)
                        ) {
                            onChange(currentPage + 1);
                        }
                    }}
                >
                    chevron_right
                </Icon>
            </div>
        </div>
    );
};

export default Pagination;
