export default function getHighestKey(objects) {
    let maxObject = objects[0];

    for (let i = 1; i < objects.length; i++) {
        const currentObject = objects[i];
        if (currentObject.key > maxObject.key) {
            maxObject = currentObject;
        }
    }

    return maxObject.key;
}
