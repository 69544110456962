import axios from 'axios';
import { throwError } from '../../actions/error/index';

export function getGeolocation(query) {
    return async function(dispatch) {
        try {
            const response = await axios.get(`/api/v1/locations/geolocation/?${query}`);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}


export function getCounty(query) {
    return async function(dispatch) {
        try {
            const response = await axios.get(`/api/v1/locations/county/?${query}`);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getServiceArea(query) {
    return async function(dispatch) {
        try {
            const response = await axios.get(`/api/v1/counties?${query}`);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}