// libs
import React, { Component } from 'react';

// UI components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Navbar from '../components/Navbar';

// helpers
import navigate from '../helpers/navigate';
import logInfo from '../helpers/logInfo';

// styling
import '../css/approved.css';

class Success extends Component {
    state = {};

    componentDidMount() {
        const urlParams = Object.fromEntries(
            new URLSearchParams(window.location.search),
        );
        const message = `Your ${
            urlParams.changeOrder ? 'change order' : 'quote'
        } has been approved, great job. Click the button below to go to your account.`;
        this.setState({
            message: message,
        });

        logInfo('Approved page loaded');
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="approved-section1 pt75">
                    <div className="mt25">
                        <Grid container spacing={2}>
                            <Grid item md={2} sm={2}></Grid>
                            <Grid item md={8} sm={8} xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 16,
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <h3
                                        className="text-purpleB90"
                                        style={{ marginTop: 32 }}
                                    >
                                        Success!
                                    </h3>
                                    <p className="m0">
                                        Your quote has been approved, great job.
                                        Click the button below to go to your
                                        account.
                                    </p>
                                    <button
                                        className="btn3 small flex flex-center-y"
                                        onClick={() =>
                                            navigate('/account', true)
                                        }
                                    >
                                        <span style={{ marginRight: 8 }}>
                                            Continue to my account
                                        </span>
                                        <Icon>arrow_forward</Icon>
                                    </button>
                                </div>
                            </Grid>
                            <Grid item md={2} sm={2}></Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default Success;
