// libraries
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';
import Favicon from 'react-favicon';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { datadogLogs } from '@datadog/browser-logs';

// UI components
import Account from './screens/Account';
import Activate from './screens/Activate';
import Apply from './screens/Apply';
import Approve from './screens/Approve';
import Approved from './screens/Approved';
import Auth from './hocs/Auth';
import Careers from './screens/Careers';
import ContactUs from './screens/ContactUs';
import CropRotation from './screens/CropRotation';
import Dashboard from './screens/Dashboard';
import DesktopNav from './components/DesktopNav';
import Footer from './components/Footer';
import getAuthToken from './formatting/getAuthToken';
import Home from './screens/Home';
import Invitation from './screens/Invitation';
import Invited from './screens/Invited';
import Login from './screens/Login';
import MobileNav from './components/MobileNav';
import PlantSelection from './screens/PlantSelection';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ProductsAndServices from './screens/ProductsAndServices';
import QRCode from './screens/QRCode';
import Referral from './screens/Referral';
import ResetPassword from './screens/ResetPassword';
import Reschedule from './screens/Reschedule';
import Reviews from './screens/Reviews';
import SetPassword from './screens/SetPassword';
import TermsOfService from './screens/TermsOfService';
import DirectDeposit from './screens/DirectDeposit';
import MaintenanceReport from './screens/MaintenanceReport';
import Products from './screens/Products';
import Gallery from './screens/Gallery';
import InitialPlantingReport from './screens/InitialPlantingReport';
import MembershipDetails from './screens/MembershipDetails';
import NewPassword from './screens/NewPassword';
import SoilAnalysisReport from './screens/SoilAnalysisReport';
import MaintenanceResults from './screens/MaintenanceResults';
import Billing from './screens/Billing';

// helpers
import getEnv from './helpers/getEnv';

// vars
import {
    DEVELOPMENT,
    STAGING,
    TEST_STRIPE_CLIENT_KEY,
    LIVE_STRIPE_CLIENT_KEY,
    GOOGLE_OAUTH_CLIENT_ID,
    DATADOG_CLIENT_TOKEN,
} from './config/index';

// styles
import './css/global.css';
import './css/responsive.css';
import './index.css';

const currentEnv = getEnv();

const key =
    currentEnv === DEVELOPMENT || currentEnv === STAGING
        ? TEST_STRIPE_CLIENT_KEY
        : LIVE_STRIPE_CLIENT_KEY;
window.Stripe.setPublishableKey(key);

datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: currentEnv === 'production',
    sessionSampleRate: 100,
});

const App = () => {
    const authToken = getAuthToken();
    let renderContent = true;

    const authRoutes = [
        '/account',
        '/dashboard',
        '/approve',
        '/approved',
        '/crop-rotation',
        '/plant-selection',
        '/reviews',
        '/direct-deposit',
        '/maintenance-report',
        '/products',
        '/initial-planting-report',
        '/soil-analysis',
        '/maintenance-results',
        '/billing',
    ];

    if (
        authRoutes.find(
            (route) => route === window.location.pathname && !authToken,
        )
    ) {
        renderContent = false;
    }

    const renderNavbar = () => {
        if (renderContent) {
            const pathname = window.location.pathname;
            const authRoutes = ['/account', '/dashboard'];

            const hiddenNavbarRoutes = [
                '/activate',
                '/approved',
                '/approve',
                '/apply',
                '/crop-rotation',
                '/invitation',
                '/invited',
                '/plant-selection',
                '/qr-code',
                '/referral',
                '/reset-password',
                '/reschedule',
                '/reviews',
                '/set-password',
                '/direct-deposit',
                '/maintenance-report',
                '/products',
                '/initial-planting-report',
                '/new-password',
                '/soil-analysis-report',
                '/maintenance-results',
                '/billing',
            ];

            if (!hiddenNavbarRoutes.find((route) => route === pathname)) {
                if (
                    authToken &&
                    authRoutes.find((route) => route === pathname)
                ) {
                    return <></>;
                } else {
                    return (
                        <div
                            style={{ backgroundColor: 'var(--purpleB' }}
                            className="hero-header-content text-center"
                        >
                            <div className="flex flex-center pt5 pb5">
                                <a
                                    style={{
                                        color: 'var(--white)',
                                        fontSize: 'var(--h7)',
                                        fontWeight: 'bold',
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="tel:(415) 231-3183"
                                >
                                    Call Us Now!{' '}
                                    <span role="img" aria-label="american flag">
                                        🇺🇸
                                    </span>{' '}
                                    (415) 231-3183
                                </a>
                            </div>
                            <MobileNav />
                            <DesktopNav />
                        </div>
                    );
                }
            }
        } else {
            return <></>;
        }
    };

    const renderFooter = () => {
        if (renderContent) {
            const pathname = window.location.pathname;
            const hiddenFooterRoutes = [
                '/apply',
                '/approved',
                '/approve',
                '/contact-us',
                '/crop-rotation',
                '/invitation',
                '/invited',
                '/plant-selection',
                '/qr-code',
                '/referral',
                '/reset-password',
                '/reschedule',
                '/reviews',
                '/set-password',
                '/direct-deposit',
                '/maintenance-report',
                '/products',
                '/initial-planting-report',
                '/new-password',
                '/login',
                '/activate',
                '/soil-analysis-report',
                '/maintenance-results',
                '/billing',
            ];

            if (!hiddenFooterRoutes.find((route) => route === pathname)) {
                if (!authToken) return <Footer />;
            }
        } else {
            return <></>;
        }
    };

    // route config
    const Index = ({ pathname }) => {
        switch (pathname) {
            case '/':
                document.title =
                    'Yarden - Garden Design, Installation, and Maintenance';
                return <Home />;
            case '/login':
                document.title = 'Yarden - Login';
                return <Login />;
            case '/careers':
                document.title = 'Yarden - Careers';
                return <Careers />;
            case '/privacy-policy':
                document.title = 'Yarden - Privacy Policy';
                return <PrivacyPolicy />;
            case '/terms-of-service':
                document.title = 'Yarden - Terms of Service';
                return <TermsOfService />;
            case '/reset-password':
                document.title = 'Yarden - Reset Password';
                return <ResetPassword />;
            case '/account':
                document.title = 'Yarden - Account';
                return <Auth authenticate={Account} />;
            case '/activate':
                document.title = 'Yarden - Activate';
                return <Activate />;
            case '/products-and-services':
                document.title = 'Yarden - Products and Services';
                return <ProductsAndServices />;
            case '/reschedule':
                document.title = 'Yarden - Reschedule';
                return <Reschedule />;
            case '/dashboard':
                document.title = 'Yarden - Dashboard';
                return <Auth authenticate={Dashboard} />;
            case '/approve':
                document.title = 'Yarden - Approve';
                return <Auth authenticate={Approve} />;
            case '/approved':
                document.title = 'Yarden - Approved';
                return <Auth authenticate={Approved} />;
            case '/referral':
                document.title = 'Yarden - Referral';
                return <Referral />;
            case '/qr-code':
                document.title = 'Yarden - QR Code';
                return <QRCode />;
            case '/contact-us':
                document.title = 'Yarden - Contact Us';
                return <ContactUs />;
            case '/set-password':
                document.title = 'Yarden - Set Password';
                return <SetPassword />;
            case '/invitation':
                document.title = 'Yarden - Invitation';
                return <Invitation />;
            case '/invited':
                document.title = 'Yarden - Invited';
                return <Invited />;
            case '/crop-rotation':
                document.title = 'Yarden - Crop Rotation';
                return <Auth authenticate={CropRotation} />;
            case '/plant-selection':
                document.title = 'Yarden - Plant Selection';
                return <Auth authenticate={PlantSelection} />;
            case '/apply':
                document.title = 'Yarden - Apply';
                return <Apply />;
            case '/reviews':
                document.title = 'Yarden - Reviews';
                return <Auth authenticate={Reviews} />;
            case '/direct-deposit':
                document.title = 'Yarden - Direct Deposit';
                return <Auth authenticate={DirectDeposit} />;
            case '/maintenance-report':
                document.title = 'Yarden - Maintenance Report';
                return <Auth authenticate={MaintenanceReport} />;
            case '/products':
                document.title = 'Yarden - Products';
                return <Auth authenticate={Products} />;
            case '/gallery':
                document.title = 'Yarden - Gallery';
                return <Gallery />;
            case '/initial-planting-report':
                document.title = 'Yarden - Initial Planting Report';
                return <Auth authenticate={InitialPlantingReport} />;
            case '/membership-details':
                document.title = 'Yarden - Membership Details';
                return <MembershipDetails />;
            case '/new-password':
                document.title = 'Yarden - New Password';
                return <NewPassword />;
            case '/soil-analysis-report':
                document.title = 'Yarden - Soil Analysis Report';
                return <Auth authenticate={SoilAnalysisReport} />;
            case '/maintenance-results':
                document.title = 'Yarden - Maintenance Results';
                return <Auth authenticate={MaintenanceResults} />;
            case '/billing':
                document.title = 'Yarden - Billing';
                return <Auth authenticate={Billing} />;
            default:
                document.title =
                    'Yarden - Garden Design, Installation, and Maintenance';
                return <Home />;
        }
    };

    // auto log-in user
    const payload = authToken
        ? JSON.parse(window.atob(authToken.split('.')[1]))
        : null;
    let pathname;
    if (payload && window.location.pathname === '/') {
        if (payload.type === 'customer') {
            window.location.pathname = '/account';
            pathname = '/account';
        } else if (payload.type !== 'customer') {
            window.location.pathname = '/dashboard';
            pathname = '/dashboard';
        } else {
            pathname = window.location.pathname;
        }
    } else {
        pathname = window.location.pathname;
    }

    const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );

    // setup direct deposit (vendors only)
    if (window.location.pathname === '/' && urlParams.code) {
        window.location.pathname = `/direct-deposit`;
        pathname = `/direct-deposit`;
    }

    pathname = window.location.pathname;

    // global data config
    const createStoreWithMiddleware = applyMiddleware(
        ReduxPromise,
        ReduxThunk,
    )(createStore);
    const store = createStoreWithMiddleware(reducers);

    return (
        <div className="app-root">
            <Favicon url="https://yarden-garden.s3.us-west-1.amazonaws.com/mobile/yarden_mark_bg-01.ico" />
            <Provider store={store}>
                <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                    <footer>{renderFooter()}</footer>
                    <main>
                        <Index pathname={pathname} />
                    </main>
                    <header>{renderNavbar()}</header>
                </GoogleOAuthProvider>
            </Provider>
        </div>
    );
};

render(<App />, document.getElementById('root'));
