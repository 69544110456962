// libraries
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import QRCode from 'qrcode';

// UI components
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';
import Alert from '../components/Alert.js';
import LoaderModal from '../components/LoaderModal.js';

// actions
import { getUsers } from '../actions/users/index';
import { createUser, updateUser } from '../actions/user/index';
import { createOrder } from '../actions/order/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { toggleLoading } from '../actions/loading/index';
import { throwError } from '../actions/error/index';
import { createAccount } from '../actions/account/index';
import { sendAlert } from '../actions/alert/index';
import {
    getGeolocation,
    getCounty,
    getServiceArea,
} from '../actions/location/index';

// vars
import vars from '../vars/index';
import { PARTNER, GARDENER } from '../vars/types.js';

// formatting
import { formatNumber } from '../formatting/phone';
import capitalize from '../formatting/capitalize';

const styles = {
    phoneLabel: {
        margin: '10px 0px 8px 0px',
    },
    orderDateLabel: {
        margin: '10px 0px 10px 0px',
    },
    createOrderButtonContainer: {
        paddingTop: '15px',
        paddingBottom: '25px',
        float: 'right',
    },
    adminMaskedInput: {
        marginTop: '0px',
        marginBottom: '4px',
    },
    closeButton: {
        backgroundColor: '#F7F7F7',
        color: '#2F3033',
    },
};

const initialState = {
    step1: true,
    orderType: 'yard assessment',
    orderDate: '',
    orderDescription: vars.orderDescriptions.yardAssessment,
    userState: 'ca',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhoneNumber: '',
    userAddress: '',
    userUnit: '',
    userCity: '',
    userZipCode: '',
    orderTime: '09',
    selectedVendor: 'Select Vendor',
    vendors: [],
    searchQuery: '',
};

class NewOrder extends Component {
    state = initialState;

    async componentDidMount() {
        this.props.toggleLoading(true);

        // get users
        await this.props.getUsers();
        const vendors = this.props.users.data.filter(
            (user) =>
                (user.type === PARTNER && user.active_vendor) ||
                (user.type === GARDENER && user.active_vendor),
        );

        this.setState({
            vendors,
        });

        this.props.toggleLoading(false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async next(step) {
        if (step === 1) {
            // validate input fields
            if (
                !this.state.userFirstName ||
                !this.state.userLastName ||
                !this.state.userEmail ||
                !this.state.userPhoneNumber ||
                !this.state.userAddress ||
                !this.state.userCity ||
                !this.state.userZipCode ||
                !this.state.orderDate ||
                !this.state.orderTime ||
                !this.state.orderDescription ||
                !this.state.selectedVendor ||
                this.state.selectedVendor === 'Select Vendor'
            ) {
                return window.alert('Please complete all required fields');
            }

            this.props.toggleLoading(true);

            if (this.state.orderType === 'yard assessment') {
                // lookup user email to avoid duplicate accounts
                const users = await this.props.getUsers(
                    `email=${this.state.userEmail}`,
                    true,
                );

                // if user account already exists, render warning
                if (users.data.length > 0)
                    return window.alert('Account already exists');
            }

            // get geolocation
            const geo = await this.props.getGeolocation(
                `address=${this.state.userAddress.trim()}&city=${this.state.userCity.trim()}&state=${this.state.userState.trim()}`,
            );
            if (geo.data.error)
                return window.alert('Invalid address, please try again');
            const geolocation = {
                lat: geo.data[0].lat,
                lon: geo.data[0].lon,
                boundingbox: geo.data[0].boundingbox,
            };

            // get county
            const cty = await this.props.getCounty(
                `address=${this.state.userAddress.trim()}&city=${this.state.userCity.trim()}&state=${this.state.userState.trim()}`,
            );

            const propertyInfo = JSON.parse(cty.data);
            if (propertyInfo.ErrorCode > 0 || !propertyInfo.County) {
                return window.alert('Invalid address, please try again');
            }

            const county = propertyInfo.County;

            // get service area
            const serviceArea = await this.props.getServiceArea(
                `name=${county.toLowerCase()}`,
            );
            if (serviceArea.data.length < 1)
                return window.alert('Out of service area');

            // update UI
            this.setState(
                {
                    userFirstName: this.state.userFirstName.trim(),
                    userLastName: this.state.userLastName.trim(),
                    userEmail: this.state.userEmail.trim(),
                    userPhoneNumber: this.state.userPhoneNumber,
                    userAddress: this.state.userAddress.trim(),
                    userCity: this.state.userCity.trim(),
                    userZipCode: this.state.userZipCode.toString(),
                    orderDescription: this.state.orderDescription.trim(),
                    userCounty: county,
                    userGeolocation: geolocation,
                },
                () => {
                    this.setState({
                        step1: false,
                        step2: true,
                    });

                    this.props.toggleLoading(false);
                    window.scrollTo(0, 0);
                },
            );
        }

        if (step === 2) {
            this.props.toggleLoading(true);
            let userId = this.state.userId;
            const phoneNumber = this.state.userPhoneNumber.replace(/\D/g, '');

            if (this.state.orderType === 'yard assessment') {
                // Generate a random password
                const password =
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15);

                let newUser = {
                    user: {
                        type: 'customer',
                        first_name: this.state.userFirstName,
                        last_name: this.state.userLastName,
                        email: this.state.userEmail,
                        phone_number: phoneNumber,
                        address: this.state.userAddress,
                        city: this.state.userCity,
                        state: this.state.userState,
                        zip_code: this.state.userZipCode,
                        county: this.state.userCounty,
                        geolocation: this.state.userGeolocation,
                        garden_info: {},
                    },
                    password: password,
                };

                // if unit, set unit
                if (this.state.userUnit)
                    newUser.user.unit = this.state.userUnit.trim();

                // create user
                const user = await this.props.createUser(newUser, true);

                // set user id
                userId = user.data.user._id;

                // set qr code
                const qrCode = await QRCode.toDataURL(
                    `${window.location.origin}/referral?refId=${user.data.user._id}`,
                );

                // update user with referral QR code
                await this.props.updateUser(`userId=${userId}`, {
                    qrCode: qrCode,
                });

                // create account for user
                await this.props.createAccount({
                    user: userId,
                });
            }

            const newOrder = {
                customer: userId,
                vendor: this.state.selectedVendor,
                type: this.state.orderType,
                date: this.state.orderDate,
                time: this.state.orderTime,
                description: this.state.orderDescription,
                point_of_sale: window.location.pathname,
            };

            // create new order
            const order = await this.props.createOrder(newOrder);

            const address = `${this.state.userAddress.trim()}${
                this.state.userUnit ? ' #' + this.state.userUnit.trim() : ''
            }, ${this.state.userCity.trim()} ${this.state.userState} ${
                this.state.userZipCode
            }`;

            if (this.state.orderType === 'crop rotation') {
                const cropRotationUrl = `${window.location.origin}/crop-rotation`;
                const email = {
                    email: this.state.userEmail,
                    subject: `Yarden - You asked, we listened!`,
                    label: 'Crop Rotation',
                    body:
                        '<p style="margin-bottom: 25px">Greetings from Yarden!</p>' +
                        '<p style="margin-bottom: 15px">We understand that our garden club members lead busy lives and seek to streamline the seasonal plant selection process. Beginning in Spring 2024, we will kindly ask you to create a permanent plant list for both warm and cold seasons. This eliminates the need for you to manually choose your garden plants with each seasonal change, as was previously required.</p>' +
                        `<a href="${cropRotationUrl}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px; text-align: center;"><b>Select Plants</b></a>`,
                };

                // send email to customer
                await this.props.sendEmail(email);

                const sms = {
                    from: '8888289287',
                    to: phoneNumber,
                    body: `Greetings from Yarden! We understand that our garden club members lead busy lives and seek to streamline the seasonal plant selection process. Beginning in Spring 2024, we will kindly ask you to create a permanent plant list for both warm and cold seasons. This eliminates the need for you to manually choose your garden plants with each seasonal change, as was previously required. Please build your plant list here: ${cropRotationUrl}`,
                };

                // send sms notification to customer
                await this.props.sendSms(sms);
            } else if (this.state.orderType === 'yard assessment') {
                let orderConfirmation = {
                    email: this.state.userEmail.trim(),
                    subject: `Yarden Booking Confirmation and Details`,
                    label: 'Booking Confirmation',
                    body:
                        '<p style="margin-bottom: 25px;">Hello <b>' +
                        this.state.userFirstName.trim() +
                        '</b>,</p>' +
                        '<p>Thank you for scheduling your consultation with Yarden. We are thrilled that you have chosen us to assist you in your gardening journey. Here are the details of your appointment:</p>' +
                        '<div style="margin: 25px 0px;">' +
                        '<p style="margin-bottom: 0px"><b>Date</b></p>' +
                        '<p style="margin-bottom: 15px"><em>' +
                        moment(order.data.date).format('MM-DD-YYYY') +
                        '</em></p>' +
                        '<p style="margin-bottom: 0px"><b>Time</b></p>' +
                        '<p style="margin-bottom: 0px"><em>' +
                        moment(order.data.time, `HH:mm:ss`).format(`h:mm A`) +
                        '</em></p>' +
                        '</div>' +
                        '<p>One of our garden specialists will be there to walk you through our unique process of designing and creating a sustainable garden that aligns with your vision. If for any reason you need to adjust your appointment date or time, please let us know at least 24 hours in advance. You can do so by sending an email to info@yardengarden.com or giving us a call at (415) 231-3183.</p>' +
                        '<p>We look forward to partnering with you on this exciting journey!</p>',
                };

                // send customer confirmation email
                await this.props.sendEmail(orderConfirmation);

                const sms = {
                    from: '8888289287',
                    to: phoneNumber,
                    body: `Greetings from Yarden! Your appointment is all set. 📅 We've booked it for ${moment(
                        order.data.date,
                    ).format('MM-DD-YYYY')} @ ${moment(
                        order.data.time,
                        `HH:mm:ss`,
                    ).format(`h:mm A`)}. See you then!`,
                };

                // send sms notification to customer
                await this.props.sendSms(sms);

                // send alert to HQ
                await this.props.sendAlert({
                    channel: 'yard-assessments',
                    text: `*Appointment Booked!* \n${capitalize(
                        this.state.userFirstName.trim(),
                    )} ${capitalize(
                        this.state.userLastName.trim(),
                    )}\n${address}\n${moment(order.data.date).format(
                        'MM-DD-YYYY',
                    )} @ ${moment(order.data.time, `HH:mm:ss`).format(
                        `h:mm A`,
                    )}`,
                });
            }

            this.setState({
                step2: false,
                step3: true,
            });

            this.props.toggleLoading(false);
            window.scrollTo(0, 0);
        }
    }

    search(e) {
        if (e.target.value !== '')
            this.props.getUsers(`type=customer&search=${e.target.value}`);
        if (e.target.value === '') this.props.getUsers(`type=customer`);

        this.setState({ searchQuery: e.target.value });
    }

    selectUser(user) {
        let userInfo = {
            selectedUser: true,
            userFirstName: user.first_name,
            userLastName: user.last_name,
            userEmail: user.email,
            userPhoneNumber: user.phone_number,
            userAddress: user.address,
            userCity: user.city,
            userZipCode: user.zip_code,
            userId: user._id,
            customerId: user.payment_info
                ? user.payment_info.customer_id
                : null,
        };

        if (user.unit) userInfo.userUnit = user.unit;
        if (user.garden_info) userInfo.gardenInfo = user.garden_info;
        this.setState(userInfo);
    }

    render() {
        const {
            step1,
            step2,
            step3,
            orderType,
            userFirstName,
            userLastName,
            userEmail,
            userPhoneNumber,
            userAddress,
            userUnit,
            userCity,
            userState,
            userZipCode,
            orderDate,
            orderTime,
            orderDescription,
            selectedUser,
            alertIsOpen,
            vendors,
            searchQuery,
        } = this.state;

        const { user, users, loading, error, onClose } = this.props;

        return (
            <div>
                {/* modals */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                <LoaderModal isOpen={loading.isLoading} />

                {/* step 1 */}
                <div className={step1 ? null : 'hidden'}>
                    <div>
                        <div>
                            <label>Order Type*</label>
                        </div>
                        <select
                            name="orderType"
                            disabled={loading.isLoading}
                            value={orderType}
                            onChange={(e) => {
                                this.setValue(e);
                                let orderDescription = '';
                                if (e.target.value === 'yard assessment')
                                    orderDescription =
                                        vars.orderDescriptions.yardAssessment;
                                if (e.target.value === 'crop rotation')
                                    orderDescription =
                                        vars.orderDescriptions.cropRotation;
                                this.setState({ orderDescription });
                            }}
                        >
                            <option value={'yard assessment'}>
                                Yard Assessment
                            </option>
                            <option value={'crop rotation'}>
                                Crop Rotation
                            </option>
                        </select>
                    </div>
                    <div
                        className={
                            selectedUser || orderType === 'yard assessment'
                                ? 'hidden'
                                : null
                        }
                    >
                        <label>Member</label>
                        <input
                            placeholder="Search Customers"
                            type="text"
                            name="searchQuery"
                            value={searchQuery}
                            onChange={(e) => this.search(e)}
                        />
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Member</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!users.data
                                            ? null
                                            : users.data.map((user, index) => (
                                                  <TableRow
                                                      key={index}
                                                      onClick={() =>
                                                          this.selectUser(user)
                                                      }
                                                      style={{
                                                          cursor: 'pointer',
                                                      }}
                                                  >
                                                      <TableCell>
                                                          {user.first_name}{' '}
                                                          {user.last_name}
                                                      </TableCell>
                                                  </TableRow>
                                              ))}
                                    </TableBody>
                                </Table>
                                {users.data && users.data.length < 1 && (
                                    <p
                                        style={{
                                            paddingTop: '25px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        No Users
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        className={
                            orderType === 'crop rotation' && !selectedUser
                                ? 'hidden'
                                : null
                        }
                    >
                        <div
                            className={
                                user.data.type !== 'admin' ? 'hidden' : null
                            }
                        >
                            <div>
                                <label>Vendor*</label>
                            </div>
                            <select
                                name="selectedVendor"
                                disabled={loading.isLoading}
                                value={this.state.selectedVendor}
                                onChange={(e) => this.setValue(e)}
                            >
                                <option value={'Select Vendor'}>
                                    Select Vendor
                                </option>
                                {vendors.map((vendor, index) => (
                                    <option
                                        key={index}
                                        value={vendor._id}
                                    >{`${vendor.first_name} ${vendor.last_name}`}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>First Name*</label>
                            <input
                                type="text"
                                name="userFirstName"
                                placeholder="John"
                                value={userFirstName}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <label>Last Name*</label>
                            <input
                                type="text"
                                name="userLastName"
                                placeholder="Doe"
                                value={userLastName}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <label>Email*</label>
                            <input
                                type="email"
                                name="userEmail"
                                placeholder="john@yardengarden.com"
                                value={userEmail}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <label style={styles.phoneLabel}>
                                Phone Number*
                            </label>
                            <input
                                type="text"
                                name="userPhoneNumber"
                                placeholder="(123) 456-7890"
                                disabled={this.props.loading.isLoading}
                                value={formatNumber(this.state.userPhoneNumber)}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <label>Street Address*</label>
                            <input
                                type="text"
                                name="userAddress"
                                placeholder="1234 Yarden Ave"
                                value={userAddress}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <label>Unit# (if applicable)</label>
                            <input
                                type="text"
                                name="userUnit"
                                placeholder="123"
                                value={userUnit}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <label>City*</label>
                            <input
                                type="text"
                                name="userCity"
                                placeholder="San Francisco"
                                value={userCity}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div>
                            <div>
                                <label>State*</label>
                            </div>
                            <select
                                value={'ca'}
                                name="userState"
                                onChange={(e) => this.setValue(e)}
                            >
                                <option value="ca">CA</option>
                            </select>
                        </div>
                        <div>
                            <label>Zip Code*</label>
                            <input
                                type="number"
                                name="userZipCode"
                                placeholder="12345"
                                value={userZipCode}
                                disabled={loading.isLoading}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <span>
                            <div>
                                <div>
                                    <label style={styles.onboardingFormLabel}>
                                        Time*
                                    </label>
                                </div>
                                <select
                                    name="orderTime"
                                    disabled={loading.isLoading}
                                    value={this.state.orderTime}
                                    onChange={(e) => this.setValue(e)}
                                >
                                    <option value={'09'}>9:00am</option>
                                    <option value={'0930'}>9:30am</option>
                                    <option value={'10'}>10:00am</option>
                                    <option value={'1030'}>10:30am</option>
                                    <option value={'11'}>11:00am</option>
                                    <option value={'1130'}>11:30am</option>
                                    <option value={'12'}>12:00pm</option>
                                    <option value={'1230'}>12:30pm</option>
                                    <option value={'13'}>1:00pm</option>
                                    <option value={'1330'}>1:30pm</option>
                                    <option value={'14'}>2:00pm</option>
                                    <option value={'1430'}>2:30pm</option>
                                    <option value={'15'}>3:00pm</option>
                                    <option value={'1530'}>3:30pm</option>
                                    <option value={'16'}>4:00pm</option>
                                    <option value={'1630'}>4:30pm</option>
                                    <option value={'17'}>5:00pm</option>
                                </select>
                            </div>
                        </span>
                        <div>
                            <div className="mb5">
                                <label style={styles.orderDateLabel}>
                                    Date*
                                </label>
                            </div>
                            <div className="date-container curved mt5">
                                <input
                                    name="orderDate"
                                    type="date"
                                    value={
                                        orderDate
                                            ? moment(orderDate).format(
                                                  'YYYY-MM-DD',
                                              )
                                            : ''
                                    }
                                    min={moment().format('YYYY-MM-DD')}
                                    onChange={(e) =>
                                        this.setState({
                                            orderDate: moment(
                                                e.target.value,
                                            ).startOf('day'),
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div className="mt5 mb5">
                                <label style={styles.orderDateLabel}>
                                    Description*
                                </label>
                            </div>
                            <textarea
                                type="text"
                                name="orderDescription"
                                disabled={loading.isLoading}
                                value={orderDescription}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                        <div style={styles.createOrderButtonContainer}>
                            <button
                                className="flex flex-center-y btn3 small"
                                disabled={loading.isLoading}
                                onClick={() => this.next(1)}
                            >
                                <span style={{ marginRight: 8 }}>Next</span>{' '}
                                <Icon>arrow_forward</Icon>
                            </button>
                        </div>
                    </div>
                </div>

                {/* step 2 */}
                <div className={step2 ? null : 'hidden'}>
                    <div className="data-detail">
                        <label>Order Type</label>
                        <p>{orderType}</p>
                    </div>
                    <div className="data-detail">
                        <label>Date</label>
                        <p>{moment(orderDate).format('MM/DD/YYYY')}</p>
                    </div>
                    <div className="data-detail">
                        <label>Time</label>
                        <p>{moment(orderTime, `HH:mm:ss`).format(`h:mm A`)}</p>
                    </div>
                    <div className="data-detail">
                        <label>Member</label>
                        <p>
                            {`${userFirstName} ${userLastName}`}
                            <br />
                            {`${userAddress}${
                                userUnit ? ` #${userUnit}` : ''
                            }, ${userCity} ${userState} ${userZipCode}`}
                            <br />
                            <span className="lowercase">{userEmail}</span>
                            <br />
                            {userPhoneNumber}
                        </p>
                    </div>
                    <div className="data-detail">
                        <label>Description (only visible to gardener)</label>
                        <p className="regularcase">{orderDescription}</p>
                    </div>
                    <div className="flex flex-space-between mt15">
                        <button
                            className="btn3 small mr15 flex flex-center-y"
                            disabled={loading.isLoading}
                            onClick={() =>
                                this.setState({ step1: true, step2: false })
                            }
                        >
                            <Icon>arrow_back</Icon>
                            <span style={{ marginLeft: 8 }}>Back</span>
                        </button>
                        <button
                            className="purple small flex flex-center-y"
                            disabled={loading.isLoading}
                            onClick={() => this.next(2)}
                        >
                            <Icon>done</Icon>
                            <span style={{ marginLeft: 8 }}>Finish</span>
                        </button>
                    </div>
                </div>

                {/* step 3 */}
                <div className={step3 ? null : 'hidden'}>
                    <div>
                        <p className="mt25">
                            Your work order has been successfully created!
                        </p>
                    </div>
                    <button className="btn3 small" onClick={() => onClose()}>
                        Close
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUsers,
            createUser,
            updateUser,
            sendEmail,
            sendSms,
            createOrder,
            toggleLoading,
            throwError,
            getGeolocation,
            getCounty,
            getServiceArea,
            createAccount,
            sendAlert,
        },
        dispatch,
    );
}

NewOrder = connect(mapStateToProps, mapDispatchToProps)(NewOrder);

export default NewOrder;
