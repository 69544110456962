import axios from 'axios'
import { throwError } from '../../actions/error/index';

export function sendSms(sms) {
    return async function(dispatch) {
        try {
            if(window.location.origin !== 'http://localhost:3000') axios.post('/api/v1/sms', sms);
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}