// libs
import Rollbar from 'rollbar';

// actions
import { sendAlert } from '../alert/index';

// helpers
import getEnv from '../../helpers/getEnv';
import capitalize from '../../helpers/capitalize';

// types
import { ERROR, LOADING } from './types';

const currentEnv = getEnv();

const rollbarConfig = {
    accessToken: 'bf206af85d9d45e4b8f35126fdac1d7b',
    captureUncaught: false,
    captureUnhandledRejections: false,
    environment: currentEnv,
};

const rollbar = new Rollbar(rollbarConfig);

export function throwError(err, message) {
    return async function (dispatch, useState) {
        const currentState = useState();
        const { user } = currentState;
        if (user && user.data) {
            rollbar.error(err, {
                email: user.data.email,
            });
            dispatch(
                sendAlert({
                    channel: 'alerts',
                    text: `*New Error!* \nUSER\n${capitalize(
                        user.data.first_name,
                    )} ${capitalize(user.data.last_name)}\n\nURL\n"${
                        window.location.pathname
                    }"\n\nDEBUG INFO\nhttps://app.rollbar.com/a/yardengarden/fix/items`,
                }),
            );
        }

        dispatch({ type: ERROR, payload: message });
        dispatch({ type: LOADING, payload: false });

        window.alert(message);
    };
}
