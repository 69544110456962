import React from "react";

function InstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      data-name="brand instagram"
      viewBox="0 0 14 13.997"
    >
      <path
        fill="#309"
        d="M6.927 35.235a3.589 3.589 0 103.589 3.589 3.583 3.583 0 00-3.589-3.589zm0 5.922a2.333 2.333 0 112.333-2.334 2.337 2.337 0 01-2.333 2.334zm4.573-6.069a.837.837 0 11-.837-.837.835.835 0 01.837.837zm2.377.85A4.142 4.142 0 0012.745 33a4.169 4.169 0 00-2.933-1.131c-1.156-.066-4.619-.066-5.775 0A4.163 4.163 0 001.1 33a4.156 4.156 0 00-1.126 2.934c-.066 1.156-.066 4.619 0 5.775A4.142 4.142 0 001.1 44.642a4.175 4.175 0 002.933 1.131c1.156.066 4.619.066 5.775 0a4.142 4.142 0 002.933-1.131 4.169 4.169 0 001.131-2.933c.069-1.155.069-4.616.004-5.771zm-1.493 7.012a2.362 2.362 0 01-1.331 1.331 15.425 15.425 0 01-4.126.281A15.546 15.546 0 012.8 44.28a2.362 2.362 0 01-1.33-1.331 15.426 15.426 0 01-.281-4.126A15.546 15.546 0 011.47 34.7a2.362 2.362 0 011.33-1.333 15.426 15.426 0 014.126-.281 15.546 15.546 0 014.126.281 2.362 2.362 0 011.331 1.333 15.426 15.426 0 01.281 4.126 15.417 15.417 0 01-.281 4.123z"
        data-name="brand instagram"
        transform="translate(.075 -31.825)"
      ></path>
    </svg>
  );
}

export default InstagramIcon;