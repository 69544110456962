// libs
import React, { Component } from 'react';

// vars
import { APPOINTMENT_URL } from '../vars/constants';

// styles
import '../css/footer.css';

class Footer extends Component {
    state = {};

    render() {
        return (
            <span>
                {/* footer start */}
                <div
                    className="bg-purpleB90"
                    style={{ gridGap: 'var(--unit5)' }}
                >
                    <div className="footer-container">
                        <div>
                            <div>
                                <b className="footer-header text-brown2">
                                    COMPANY
                                </b>
                            </div>
                            <div>
                                <a
                                    href="https://www.youtube.com/watch?v=4rqe7nu4Lk0"
                                    className="footer-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Testimonials
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://officialyarden.medium.com/"
                                    className="footer-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Blog
                                </a>
                            </div>
                            <div>
                                <a href="/careers" className="footer-link">
                                    Careers
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <b className="footer-header text-brown2">
                                    SERVICES
                                </b>
                            </div>
                            <div>
                                <a
                                    href={APPOINTMENT_URL}
                                    className="footer-link"
                                >
                                    Schedule Appointment
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <b className="footer-header text-brown2">
                                    ACCOUNT
                                </b>
                            </div>
                            <div>
                                <a
                                    className="footer-link pointer"
                                    href="/login"
                                >
                                    Sign In
                                </a>
                            </div>
                            <div>
                                <a
                                    href="/reset-password"
                                    className="footer-link"
                                >
                                    Reset Password
                                </a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <b className="footer-header text-brown2">
                                    SOCIAL
                                </b>
                            </div>
                            <div>
                                <a
                                    href="https://www.facebook.com/yardengardeninc"
                                    className="footer-link"
                                >
                                    Facebook
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.instagram.com/official_yarden"
                                    className="footer-link"
                                >
                                    Instagram
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://twitter.com/official_yarden"
                                    className="footer-link"
                                >
                                    Twitter
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.linkedin.com/company/12903995"
                                    className="footer-link"
                                >
                                    LinkedIn
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="copyright flex-center">
                        <small>
                            <span style={{ color: 'var(--white)' }}>
                                © {new Date().getFullYear()} Yarden Garden Inc
                            </span>{' '}
                            <span style={{ color: 'var(--white)' }}>·</span>{' '}
                            <a href="/terms-of-service" className="footer-link">
                                Terms
                            </a>{' '}
                            <span style={{ color: 'var(--white)' }}>·</span>{' '}
                            <a href="/privacy-policy" className="footer-link">
                                Privacy
                            </a>
                        </small>
                    </div>
                </div>
                {/* footer end */}
            </span>
        );
    }
}

export default Footer;
