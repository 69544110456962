// libs
import moment from 'moment';
import Holidays from 'date-holidays';

// vars
import { FULL_PLAN_RATE, GARDEN_BOOTCAMP_FEE } from './constants';

const fall = [1, 8, 9, 10, 11, 12];
const month = parseInt(moment().format('M'));
const season = fall.includes(month) ? 'fall' : 'spring';
const usHolidays = new Holidays('US');
let holidays = [];
const currentHolidays = usHolidays.getHolidays(new Date().getFullYear());
currentHolidays.forEach((holiday) =>
    holidays.push(moment(holiday.date).format('MM/DD')),
);

let vars = {
    orderDescriptions: {
        yardAssessment:
            'Meet with customer at the date/time listed. Take measurements of garden area. If the customer would like a new garden, give them a verbal quote for installation (see pricing page). Otherwise, if the customer would like to revive their old garden, give them a verbal quote for a revive service (see pricing page). Take 2 pictures: 1 of the garden area, and 1 of the water source. Upload results to complete the order.',
        installation: 'Garden installation',
        revive: 'Garden revive',
        cropRotation: 'Garden crop rotation',
        fullPlan:
            'Provide garden maintenance for this garden once a week. Follow the maintenance guidelines listed in your Yardener handbook. Clean up any materials or debris before leaving the garden site. Take 2 pictures: 1 of the garden, and 1 of any vegetables or herbs that were harvested during your visit (if no harvest just take 1 picture of the garden). Upload results to complete the order.',
        assistedPlan:
            'Provide garden maintenance for this garden once every 2 weeks. Follow the maintenance guidelines listed in your Yardener handbook. Clean up any materials or debris before leaving the garden site. Take 2 pictures: 1 of the garden, and 1 of any vegetables or herbs that were harvested during your visit (if no harvest just take 1 picture of the garden). Upload results to complete the order.',
        initialPlanting:
            'Plant the garden using the list provided. Take 2 pictures: 1 before the planting, and 1 after the planting. Upload results to complete the order.',
        soilAnalysis:
            'Inspect each garden bed and determine the amount of soil needed to refill to the top. Also, check amendment levels and determine if the soil needs to be amended as well.',
        soilRefill:
            'Refill the garden soil and amendments to ensure proper growth environment for plants.',
    },
    products: {
        gardenBootcamp: {
            title: '4 Week Garden Bootcamp',
            description:
                'An online course that turns beginners into confident gardeners. This bootcamp provides the knowledge and techniques needed to grow and maintain a thriving garden, all with expert guidance and support.',
            image: 'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-37.jpeg',
            price: GARDEN_BOOTCAMP_FEE,
            details: [
                {
                    title: 'Beginner-Friendly',
                    description:
                        'Perfect for those new to gardening, with easy-to-follow lessons.',
                },
                {
                    title: 'Expert Instruction',
                    description:
                        'Learn from experienced gardeners and horticulturists.',
                },
                {
                    title: 'Flexible Schedule',
                    description:
                        'Access lessons at your own pace, anytime, anywhere.',
                },
                {
                    title: 'Certificate of Completion',
                    description:
                        'Earn a certificate to showcase your gardening skills.',
                },
                {
                    title: 'Lifetime Access',
                    description:
                        'Revisit lessons and materials whenever you need a refresher.',
                },
            ],
        },
        fullPlan: {
            title: 'Full Service Maintenance',
            description:
                'A complete gardening service to keep your garden thriving year-round. Our team manages everything from weeding, pruning, and fertilizing to seasonal planting. Enjoy a beautiful garden without the effort—leave it all to us.',
            image: 'https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/gardener-woman.jpg',
            price: FULL_PLAN_RATE,
            details: [
                {
                    title: 'Weekly Visits',
                    description:
                        'Routine care like weeding, pruning, and watering.',
                },
                {
                    title: 'Guaranteed Harvest',
                    description:
                        'We ensure a successful harvest, managing every step from planting to picking.',
                },
                {
                    title: 'Pest Management (no pesticides)',
                    description: 'Eco-friendly pest control.',
                },
                {
                    title: 'Plant Replacement',
                    description:
                        'If a plant doesn’t thrive, we’ll replace it to keep your garden looking its best.',
                },
                {
                    title: 'Priority Support',
                    description:
                        'Fast, dedicated help whenever you need it, ensuring your garden stays in top shape.',
                },
            ],
        },
    },
    orderImages: {
        yardAssessment:
            'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/yard-assessment.jpg',
        installation:
            'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/installation.jpg',
        maintenancePlan:
            'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/maintenance-plan.png',
        misc: 'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/logo-wood.jpg',
    },
    season: season,
    accessories: {
        fivePotGarden: {
            id: 1,
            title: '5 Pot Garden',
            description: [
                {
                    label: 'Grow Bags',
                    text: 'Five (30 gallon) eco-friendly fabric pots (black)',
                },
                {
                    label: 'Soil',
                    text: 'Compost, loam, nitrogen, mulch, worm castings, oyster shell and more',
                },
                {
                    label: 'Drip Irrigation',
                    text: 'Automated drip irrigation system (run off of Hose Bib (Spigot) or current irrigation system)',
                },
                { label: 'Plants', text: 'Starters and seed mix' },
            ],
            image: 'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/potted-plant-garden.jpg',
            price: 575,
            sqft: 20,
            cf: 8,
            height: 2,
            width: 2,
            length: 2,
        },
        gardenCage: {
            id: 2,
            title: 'Garden Cage',
            description: [
                {
                    label: 'Garden Cage',
                    text: `Round 2' x 2' x 6' cage, galvanized steel`,
                },
            ],
            image: 'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/cage.jpeg',
            price: 40,
            height: 4,
            width: 2,
            length: 2,
        },
        trellis: {
            id: 3,
            title: 'Trellis',
            description: [
                {
                    label: 'Trellis',
                    text: `4' x 7' section, galvanized steel`,
                },
            ],
            image: 'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/trellis.jpg',
            price: 50,
            height: 0,
            width: 4,
            length: 7,
        },
        coverBox: {
            id: 4,
            title: 'Cover Box',
            description: [
                {
                    label: 'Cover Box',
                    text: `4' x 8' x 36" wood frame with side doors for access`,
                },
            ],
            image: 'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/cover-box.jpg',
            price: 625,
            height: 3,
            width: 4,
            length: 8,
        },
        cloche: {
            id: 5,
            title: 'Cloche',
            description: [
                {
                    label: 'Cloche',
                    text: `PVC pipe combined with lightweight fabric cloth cover`,
                },
            ],
            image: 'https://yarden-garden.s3-us-west-1.amazonaws.com/static+assets/cloche.jpeg',
            price: 150,
            height: 2,
            width: 4,
            length: 8,
        },
    },
    holidays: holidays,
    pricing: {
        defaultSqft: 32,
        installation: {
            initial: {
                twelve: 1100,
                eighteen: 1350,
                twentyFour: 1600,
                thirtySix: 1850,
            },
            additional: {
                twelve: 400,
                eighteen: 500,
                twentyFour: 600,
                thirtySix: 700,
            },
            sqFtLabor: {
                twelve: 5,
                eighteen: 7,
                twentyFour: 9,
                thirtySix: 11,
            },
            irrigationLabor: 1 * 35,
        },
        revive: {
            twelve: 18.5,
            eighteen: 23.5,
            twentyFour: 28.5,
            thirtySix: 33.5,
            irrigationLabor: 1 * 35,
        },
        delivery: {
            lumber: 80,
            soil: 100,
        },
        fullPlan: 299,
        assistedPlan: 199,
        labor: 35,
    },
    tax: {
        ca: 0.1,
    },
    fees: {
        maintenance: 0.4,
        misc: 0.1,
        payment_processing: 0.03,
        loan_interest: 0.72,
    },
    payouts: {
        installation: {
            // per bed
            twelve: 150,
            eighteen: 200,
            twentyFour: 250,
            thirtySix: 300,
        },
        revive: {
            // per sq ft
            twelve: 2.5,
            eighteen: 3,
            twentyFour: 3.5,
            thirtySix: 4,
        },
        cropRotation: 1, // per sq ft
        maintenance: 75 - 75 * 0.4, // per visit
        yardAssessment: 25, // per visit
        accessories: {
            // per accessory
            fivePotGarden: 150,
            gardenCage: 25,
            trellis: 25,
            coverBox: 300,
            cloche: 75,
        },
        initialPlanting: 1, // per sq ft
    },
    referral_programs: {
        customer_referral: 'customer referral',
    },
};

export default vars;
