// libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher';

// UI components
import Icon from '@material-ui/core/Icon';
import Filepicker from '../components/UI/Filepicker';
import Alert from '../components/Alert';
import LoaderModal from '../components/LoaderModal';

// helpers
import capitalize from '../helpers/capitalize';
import { formatNumber } from '../formatting/phone';
import logInfo from '../helpers/logInfo';

// types
import { GARDENER, PARTNER } from '../vars/types';

// styles
import '../css/apply.css';

let Apply = ({
    createApplication,
    getImage,
    updateImage,
    getGeolocation,
    getCounty,
    getServiceArea,
    sendAlert,
}) => {
    const [activeStep, setActiveStep] = useState(1);
    const [role, setRole] = useState(GARDENER);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userUnit, setUserUnit] = useState('');
    const [userCity, setUserCity] = useState('');
    const [userState] = useState('ca');
    const [userZipCode, setUserZipCode] = useState('');
    const [userGeolocation, setUserGeolocation] = useState('');
    const [userCounty, setUserCounty] = useState('');
    const [onboardingAgreement, setOnboardingAgreement] = useState(false);
    const [profilePicture, setProfilePicture] = useState(null);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const [applicationSubmitted, setApplicationSubmitted] = useState(false);

    useEffect(() => {
        logInfo('Apply page loaded');
    }, []);

    function renderWarning(message) {
        setAlertIsOpen(true);
        setLoadingModalIsOpen(false);
        return setAlertMessage(message);
    }

    async function next() {
        if (
            activeStep === 2 &&
            (!userFirstName ||
                !userLastName ||
                !userEmail ||
                !userPhoneNumber ||
                !onboardingAgreement)
        ) {
            return renderWarning('Please complete all required fields');
        } else if (activeStep === 3) {
            if (!userAddress || !userCity || !userZipCode) {
                return renderWarning('Please complete all required fields');
            } else {
                setLoadingModalIsOpen(true);

                const geo = await getGeolocation(
                    `address=${userAddress}&city=${userCity}`,
                );

                if (geo.data.error) {
                    return renderWarning('Invalid address, please try again');
                }

                const geolocation = {
                    lat: geo.data[0].lat,
                    lon: geo.data[0].lon,
                    boundingbox: geo.data[0].boundingbox,
                };

                const county = await getCounty(
                    `address=${userAddress.trim()}&city=${userCity.trim()}&state=${userState.trim()}`,
                );

                const propertyInfo = JSON.parse(county.data);
                if (propertyInfo.ErrorCode > 0 || !propertyInfo.County) {
                    return renderWarning('Invalid address, please try again');
                }

                const serviceArea = await getServiceArea(
                    `name=${propertyInfo.County.toLowerCase()}`,
                );

                if (serviceArea.data.length < 1) {
                    return renderWarning(
                        'Your address is out of our service area',
                    );
                }

                setUserGeolocation(geolocation);
                setUserCounty(propertyInfo.County);
                setLoadingModalIsOpen(false);
            }
        } else if (activeStep === 4 && !profilePicture) {
            return renderWarning('Please upload a profile image');
        }

        setActiveStep(activeStep + 1);
    }

    function back() {
        if (activeStep === 1) {
            window.location.pathname = '/careers';
        } else {
            setActiveStep(activeStep - 1);
        }
    }

    async function finish() {
        setLoadingModalIsOpen(true);

        const hostedImg = await getImage(
            profilePicture.name,
            profilePicture.type,
        );
        const profileImage = hostedImg.data.data.returnData.url;
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': profilePicture.type,
            },
        };

        await updateImage(signedRequest, profilePicture, options);

        const phoneNumber = userPhoneNumber.replace(/\D/g, '');
        const application = {
            role: role,
            first_name: userFirstName.trim(),
            last_name: userLastName.trim(),
            email: userEmail.trim(),
            phone_number: phoneNumber,
            address: userAddress.trim(),
            city: userCity.trim(),
            state: userState,
            zip_code: userZipCode.trim(),
            county: userCounty.trim(),
            geolocation: userGeolocation,
            profile_image: profileImage,
        };

        await createApplication(application);

        const alert = {
            channel: 'job-applications',
            text: `*New Job Application!* \nAPPLICANT NAME\n${capitalize(
                userFirstName,
            )} ${capitalize(
                userLastName,
            )}\n\nCITY / STATE\n${userCity}, ${userState}\n\nPROFILE IMAGE\n${profileImage}`,
        };

        await sendAlert(alert);

        setApplicationSubmitted(true);

        setLoadingModalIsOpen(false);
    }

    function renderStep() {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            case 5:
                return renderStep5();
            default:
                return renderStep1();
        }
    }

    function renderProgress() {
        return (
            <div className="text-center mb25">
                <h6>Step {activeStep} of 5</h6>
            </div>
        );
    }

    function renderStep1() {
        return (
            <div>
                <div>
                    <h3>Role</h3>
                    <div className="mt15">
                        <div>
                            <div className="radio-header">
                                <div className="flex-center-y">
                                    <input
                                        type="radio"
                                        id="gardener"
                                        name="role"
                                        value={GARDENER}
                                        checked={role === GARDENER}
                                        onChange={(e) =>
                                            setRole(e.target.value)
                                        }
                                    />
                                    <h5 className="ml4">Gardener</h5>
                                </div>
                            </div>
                            <p className="role-description mt10">
                                A Gardener is responsible for routine garden
                                maintenance
                            </p>
                        </div>
                        <div>
                            <div className="radio-header">
                                <div className="flex-center-y">
                                    <input
                                        type="radio"
                                        id="partner"
                                        name="role"
                                        value={PARTNER}
                                        checked={role === PARTNER}
                                        onChange={(e) =>
                                            setRole(e.target.value)
                                        }
                                    />
                                    <h5 className="ml4">Partner</h5>
                                </div>
                            </div>
                            <p className="role-description mt10">
                                A Partner handles the installation of garden
                                beds and other projects
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderStep2() {
        return (
            <div>
                <h3>Contact Info</h3>
                <form
                    style={{
                        display: 'flex',
                        gap: 'var(--unit4)',
                        flexDirection: 'column',
                    }}
                >
                    <div className="name-inputs-container">
                        <div>
                            <label style={{ marginTop: '10px' }}>
                                First Name*
                            </label>
                            <input
                                required
                                margin="dense"
                                type="text"
                                name="userFirstName"
                                placeholder="John"
                                value={userFirstName}
                                onChange={(e) =>
                                    setUserFirstName(e.target.value)
                                }
                            />
                        </div>
                        <div>
                            <label>Last Name*</label>
                            <input
                                required
                                margin="dense"
                                type="text"
                                name="userLastName"
                                placeholder="Doe"
                                value={userLastName}
                                onChange={(e) =>
                                    setUserLastName(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <label style={{ marginTop: '10px' }}>Email*</label>
                        <input
                            required
                            margin="dense"
                            type="email"
                            name="userEmail"
                            placeholder="john@yardengarden.com"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label style={{ marginTop: '10px' }}>
                            Phone Number*
                        </label>
                        <input
                            required
                            margin="dense"
                            name="userPhoneNumber"
                            placeholder="(123) 456-7890"
                            value={formatNumber(userPhoneNumber)}
                            onChange={(e) => setUserPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="flex-center-y mt4 mb4">
                        <input
                            style={{ marginRight: 'var(--unit3)' }}
                            type="checkbox"
                            checked={onboardingAgreement}
                            onChange={() =>
                                setOnboardingAgreement(!onboardingAgreement)
                            }
                        />
                        <label>
                            I agree to the{' '}
                            <a
                                href="/terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                                href="/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                            *
                        </label>
                    </div>
                </form>
            </div>
        );
    }

    function renderStep3() {
        return (
            <div>
                <h3>Address</h3>
                <form>
                    <div>
                        <div className="street-address-inputs-container">
                            <div style={{ flexGrow: 2 }}>
                                <label>Street Address*</label>
                                <input
                                    type="text"
                                    name="userAddress"
                                    placeholder="1234 Yarden Ave"
                                    value={userAddress}
                                    onChange={(e) =>
                                        setUserAddress(e.target.value)
                                    }
                                />
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <label className="mt10">
                                    Unit# (if applicable)
                                </label>
                                <input
                                    required
                                    type="text"
                                    name="userUnit"
                                    placeholder="123"
                                    value={userUnit}
                                    onChange={(e) =>
                                        setUserUnit(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="city-state-zip-inputs">
                            <div style={{ flexGrow: 2 }}>
                                <label className="mt10">City*</label>
                                <input
                                    required
                                    type="text"
                                    name="userCity"
                                    placeholder="San Francisco"
                                    value={userCity}
                                    onChange={(e) =>
                                        setUserCity(e.target.value)
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: '9px',
                                    flexGrow: 1,
                                }}
                            >
                                <label>State*</label>
                                <select
                                    style={{
                                        marginTop: '12px',
                                        width: '100%',
                                        minWidth:
                                            'calc(var(--unit6) + var(--unit5)',
                                    }}
                                >
                                    <option>CA</option>
                                </select>
                            </div>
                            <div
                                style={{ flexGrow: 1, minWidth: 'var(--unit7' }}
                            >
                                <label className="mt10">Zip Code*</label>
                                <input
                                    required
                                    type="text"
                                    name="userZipCode"
                                    placeholder="94706"
                                    value={userZipCode}
                                    onChange={(e) =>
                                        setUserZipCode(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    function renderStep4() {
        return (
            <div>
                <h3>Profile Picture</h3>
                <small className="mt10">
                    Please upload a clear photo of your face so our garden club
                    members can identify you
                </small>
                <div className="mt10">
                    <Filepicker
                        max={1}
                        onChange={(files) => setProfilePicture(files[0])}
                        onReset={() => setProfilePicture('')}
                    />
                </div>
            </div>
        );
    }

    function renderStep5() {
        return (
            <div>
                <h3>Application Summary</h3>
                <div>
                    <div>
                        <label>Role</label>
                        <div>{capitalize(role)}</div>
                    </div>
                    <div>
                        <label>Name</label>
                        <div>
                            {capitalize(`${userFirstName} ${userLastName}`)}
                        </div>
                    </div>
                    <div>
                        <label>Email</label>
                        <div>{userEmail}</div>
                    </div>
                    <div>
                        <label>Phone Number</label>
                        <div>{formatNumber(userPhoneNumber)}</div>
                    </div>
                    <div>
                        <label>Address</label>
                        <div>{`${userAddress}${
                            userUnit ? ` #${userUnit}` : ''
                        }, ${userCity} CA ${userZipCode}`}</div>
                    </div>
                </div>
                <small className="mt25">
                    Once your application is submitted, Yarden will contact you
                    within 5 business days for a preliminary phone interview.
                </small>
            </div>
        );
    }

    function renderNavigationButtons() {
        return (
            <div className="flex flex-center-y flex-space-between mt15">
                <button className="small btn3" onClick={() => back()}>
                    <div className="flex flex-center-y">
                        <Icon>arrow_back</Icon>
                        <div style={{ marginLeft: 8 }}>Back</div>
                    </div>
                </button>
                {activeStep === 5 ? (
                    <button className="small purple" onClick={() => finish()}>
                        <div className="flex flex-center-y">
                            <Icon>done</Icon>
                            <div style={{ marginLeft: 8 }}>Finish</div>
                        </div>
                    </button>
                ) : (
                    <button className="small btn3" onClick={() => next()}>
                        <div className="flex flex-center-y">
                            <div style={{ marginRight: 8 }}>Next</div>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                )}
            </div>
        );
    }

    function renderSuccessMessage() {
        return (
            <div className="p50">
                <div className="flex flex-center" style={{ height: 100 }}>
                    <span style={{ fontSize: 35, color: 'var(--purpleB)' }}>
                        ٩( ᐛ )و
                    </span>
                </div>
                <p className="text-center">
                    Success! Your application has been submitted. Our team will
                    review it and get back to you soon.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => (window.location.pathname = '/')}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Home</span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div id="application-form">
            {/* modals */}
            <Alert
                isOpen={alertIsOpen}
                message={alertMessage}
                onClose={() => setAlertIsOpen(false)}
            />

            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* main content */}
            <div className="p25">
                <div className="mb25">
                    <h4 className="text-purpleB90">Application</h4>
                </div>
                <div className="mb25 bg-green0">
                    <hr />
                </div>
                <div className="step-content">
                    {applicationSubmitted ? (
                        <div>{renderSuccessMessage()}</div>
                    ) : (
                        <div>
                            <div>{renderProgress()}</div>
                            <div>{renderStep()}</div>
                            <div>{renderNavigationButtons()}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Apply = connect(null, mapDispatchToProps)(Apply);

export default Apply;
