import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createAccount(account) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/accounts`, account, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getAccount(userId) {
    return async function(dispatch) {
        try {
            const response = await axios.get(`/api/v1/accounts/${userId}`);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateAccount(userId, account) {
    return async function(dispatch) {
        try {
            await axios.put(`/api/v1/accounts/${userId}`, account, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}