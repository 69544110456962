// libraries
import React from 'react';

// UI components
import Divider from '@material-ui/core/Divider';
import ArrowForward from '@material-ui/icons/ArrowForward';

const CropRotation = () => {
    return (
        <div className="p50">
            <div className="flex flex-center-y flex-space-between mb25">
                <h4 className="text-purpleB90">Crop Rotation</h4>
                <div
                    className="pointer"
                    onClick={() => (window.location.pathname = '/account')}
                >
                    Account
                </div>
            </div>
            <div className="mb50 bg-green0">
                <Divider />
            </div>
            <div className="flex flex-center mb15">
                <div>
                    <h3 className="mb0 ml5 mr5">
                        <b>You asked.</b>
                    </h3>
                    <p className="mb0 ml5 mr5 float-right">
                        <b>We listened.</b>
                    </p>
                </div>
            </div>
            <div>
                <p className="text-center">
                    We understand that our garden club members lead busy lives
                    and seek to streamline the seasonal plant selection process.
                    Beginning in Spring 2024, we will kindly ask you to create a
                    permanent plant list for both warm and cold seasons. This
                    eliminates the need for you to manually choose your garden
                    plants with each seasonal change, as was previously
                    required.
                </p>
                <p className="text-center">
                    If you have any questions about this change, please reach
                    out to{' '}
                    <a href="mailto: info@yardengarden.com">
                        info@yardengarden.com
                    </a>
                </p>
            </div>
            <div className="float-right">
                <button
                    className="small btn3"
                    onClick={() =>
                        (window.location.pathname = `/plant-selection`)
                    }
                >
                    <div className="flex flex-center-y">
                        <span style={{ marginRight: 8 }}>Next</span>
                        <ArrowForward />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default CropRotation;
