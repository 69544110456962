import axios from 'axios';
import { GET_REFERRALS } from './types';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createReferral(referral) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/referrals`, referral, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getReferral(refereeId) {
    return async function(dispatch) {        
        try {
            const response = await axios.get(`/api/v1/referrals/${refereeId}`, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getReferrals(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/referrals${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_REFERRALS, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateReferral(id, referral) {
    return async function(dispatch) {
        try {
            const updatedReferral = await axios.put(`/api/v1/referrals/${id}`, referral, {headers: {authorization: getAuthToken()}});
            return updatedReferral;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}