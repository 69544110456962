import { UPDATE_CART } from './../actions/cart/types';
import vars from '../vars/index';

let initialState = {
    qty: getCartQty(),
    items: getCartItems()
}

export default function(state = initialState, action) {
    switch(action.type){
        case UPDATE_CART:
            return {
                qty: getCartQty(),
                items: getCartItems()
            }
        default:
            return state;
    }
}

function getCartQty() {
    let total = 0;
    for(let key in localStorage) {
        if(parseInt(key) > 0) {
            total += parseInt(localStorage[key]);
        }
    }

    return total;
}

function getCartItems() {
    let items = [];
    let products = [];
    for(let key in vars.accessories) {
        products.push(vars.accessories[key])
    }

    for(let key in localStorage) {
        if(parseInt(key) > 0) {
            let item = products.find((product) => product.id === parseInt(key));
            item.qty = parseInt(localStorage[key]);
            item.subtotal = (item.qty * item.price);
            if(!items.find((i) => i.id === item.id)) {
                items.push(item);
            }
        }
    }

    return items;
}