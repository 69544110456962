// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Icon from '@material-ui/core/Icon';

// helpers
import isHarvestDate from '../helpers/isHarvestDate';

// vars
import { WEEKLY, BI_WEEKLY, CUSTOMER } from '../vars/types';

// styles
import '../css/harvest-schedule.css';

let HarvestSchedule = ({
    getHarvestSchedule,
    updateHarvestSchedule,
    pendingMaintenanceOrder,
    user,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [harvestSchedule, setHarvestSchedule] = useState(null);
    const [harvestCadence, setHarvestCadence] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [days, setDays] = useState([]);

    useEffect(() => {
        const initData = async () => {
            setIsLoading(true);
            if (
                pendingMaintenanceOrder &&
                pendingMaintenanceOrder.customer.payment_info &&
                pendingMaintenanceOrder.customer.payment_info.plan_id
            ) {
                const schedule = await getHarvestSchedule(
                    pendingMaintenanceOrder.customer._id,
                );
                setHarvestSchedule(schedule.data);
            }

            setIsLoading(false);
        };

        initData();
    }, []);

    useEffect(() => {
        generateCalendar(currentDate);
        if (!harvestCadence && harvestSchedule) {
            setHarvestCadence(harvestSchedule.cadence);
        }
    }, [currentDate, harvestSchedule]);

    const generateCalendar = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1).getDay();
        const lastDay = new Date(year, month + 1, 0).getDate();
        const daysArray = [];
        for (let i = 0; i < firstDay; i++) {
            daysArray.push({ date: '', isHarvest: false });
        }

        for (let day = 1; day <= lastDay; day++) {
            daysArray.push({
                date: day,
                isHarvest:
                    isHarvestDate(
                        new Date(year, month, day),
                        harvestSchedule,
                    ) &&
                    new Date(year, month, day).getTime() / 1000 >=
                        new Date(pendingMaintenanceOrder.date).getTime() / 1000,
            });
        }

        setDays(daysArray);
    };

    const getMonthName = (monthIndex) => {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        return monthNames[monthIndex];
    };

    const prevMonth = () => {
        setCurrentDate(
            new Date(currentDate.setMonth(currentDate.getMonth() - 1)),
        );
    };

    const nextMonth = () => {
        setCurrentDate(
            new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
        );
    };

    const changeHarvestCadence = async (cadence) => {
        const updatedHarvestSchedule = await updateHarvestSchedule(
            harvestSchedule._id,
            { cadence },
        );
        setHarvestSchedule(updatedHarvestSchedule.data);
        setHarvestCadence(cadence);
    };

    const renderCalendar = () => {
        return (
            <div className="calendar">
                <div className="flex flex-center-y flex-space-between mt15 mb15">
                    <div className="p10" onClick={prevMonth}>
                        <Icon>chevron_left</Icon>
                    </div>
                    <h5>
                        {getMonthName(currentDate.getMonth())}{' '}
                        {currentDate.getFullYear()}
                    </h5>
                    <div className="p10" onClick={nextMonth}>
                        <Icon>chevron_right</Icon>
                    </div>
                </div>
                <div className="weekdays">
                    <div>Sun</div>
                    <div>Mon</div>
                    <div>Tue</div>
                    <div>Wed</div>
                    <div>Thu</div>
                    <div>Fri</div>
                    <div>Sat</div>
                </div>
                <div className="weekdays-mobile">
                    <div>S</div>
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                </div>
                <div className="days">
                    {days.map((day, index) => (
                        <div
                            key={index}
                            className={`day ${day.isHarvest ? 'harvest' : ''}`}
                        >
                            {day.date}
                        </div>
                    ))}
                </div>
                {user.data.type === CUSTOMER && (
                    <div className="mt25">
                        <h5 className="mb25">I want my garden harvested...</h5>
                        <div className="radio-header">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={harvestCadence === WEEKLY}
                                    onChange={() =>
                                        changeHarvestCadence(WEEKLY)
                                    }
                                />
                                <h5 className="ml4">Weekly</h5>
                            </div>
                        </div>
                        <div className="radio-header mt15">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={harvestCadence === BI_WEEKLY}
                                    onChange={() =>
                                        changeHarvestCadence(BI_WEEKLY)
                                    }
                                />
                                <h5 className="ml4">Bi-Weekly</h5>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    if (isLoading) {
        return <div className="flex flex-center p25">Loading...</div>;
    } else {
        if (!harvestSchedule) {
            return (
                <div className="p25 flex flex-center">
                    No harvest schedule found. Your harvest schedule will appear
                    once your maintenance plan has been activated.
                </div>
            );
        } else {
            return renderCalendar();
        }
    }
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

HarvestSchedule = connect(mapStateToProps, mapDispatchToProps)(HarvestSchedule);

export default HarvestSchedule;
