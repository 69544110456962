// libs
import React, { useState } from 'react';
import { connect } from 'react-redux';

// UI components
import Tabs from '../components/UI/Tabs.js';
import StarCheckbox from '../components/UI/StarCheckbox.js';

// vars
import { SPRING, FALL } from '../vars/types';
import { MAX_FAVORITES_LIMIT_PER_SEASON } from '../vars/constants';

let PlantFavorites = (props) => {
    const { springPlants, fallPlants, onChange } = props;
    const [currentSpringSelection, setCurrentSpringSelection] =
        useState(springPlants);
    const [currentFallSelection, setCurrentFallSelection] =
        useState(fallPlants);
    const [plantSeason, setPlantSeason] = useState(SPRING);
    const currentSelection =
        plantSeason === SPRING ? currentSpringSelection : currentFallSelection;
    const selected = currentSelection.filter((p) => p.selected);

    const validatePlantSelectionSize = (plants) => {
        return plants <= MAX_FAVORITES_LIMIT_PER_SEASON;
    };

    const updatePlantSelection = (plant) => {
        let isMaxSizeLimit = false;
        let plantsToUpdate = [...currentSelection];
        plantsToUpdate.forEach((plantToUpdate) => {
            if (plantToUpdate.plant._id === plant._id) {
                if (plantToUpdate.selected) {
                    plantToUpdate.selected = false;
                } else {
                    if (validatePlantSelectionSize(selected.length + 1)) {
                        plantToUpdate.selected = true;
                    } else {
                        isMaxSizeLimit = true;
                    }
                }
            }
        });

        if (isMaxSizeLimit) {
            return window.alert(
                `You cannot select more than ${MAX_FAVORITES_LIMIT_PER_SEASON} favorites for ${plantSeason}`,
            );
        } else {
            if (plantSeason === SPRING) {
                setCurrentSpringSelection(plantsToUpdate);
                onChange(plantsToUpdate, SPRING);
            }

            if (plantSeason === FALL) {
                setCurrentFallSelection(plantsToUpdate);
                onChange(plantsToUpdate, FALL);
            }
        }
    };

    const renderTabs = () => {
        const totalSpringPlantsSelected = currentSpringSelection
            ? currentSpringSelection.reduce((total, current) => {
                  if (current.selected) {
                      return total + 1;
                  }
                  return total;
              }, 0)
            : 0;

        const totalFallPlantsSelected = currentFallSelection
            ? currentFallSelection.reduce((total, current) => {
                  if (current.selected) {
                      return total + 1;
                  }
                  return total;
              }, 0)
            : 0;

        const plantSeasonTabs = [
            {
                title: `Spring (${totalSpringPlantsSelected})`,
                icon: '',
                content: 'spring',
                value: SPRING,
            },
            {
                title: `Fall (${totalFallPlantsSelected})`,
                icon: '',
                content: 'fall',
                value: FALL,
            },
        ];

        return (
            <div>
                <Tabs
                    className="plant-selection-tabs"
                    outlined={true}
                    data={plantSeasonTabs}
                    defaultValue={SPRING}
                    onChange={(item) => {
                        const value = item.value;
                        if (value === 'spring') {
                            setPlantSeason(SPRING);
                        }
                        if (value === 'fall') {
                            setPlantSeason(FALL);
                        }
                    }}
                />
            </div>
        );
    };

    const renderSeasonLimitMessage = () => {
        return (
            <div>
                <p className="text-small">
                    * You can select up to {MAX_FAVORITES_LIMIT_PER_SEASON}{' '}
                    spring plant varieties and {MAX_FAVORITES_LIMIT_PER_SEASON}{' '}
                    fall plant varieties
                </p>
            </div>
        );
    };

    const renderPlantFavorites = () => {
        const list = currentSelection.map((plantData, index) => {
            return (
                <div key={index}>
                    <div
                        className="flex flex-center-y flex-space-between"
                        style={{
                            paddingVertical: 'var(--unit4)',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                borderRightColor: '#ddd',
                                borderRightWidth: 1,
                                width: '100%',
                            }}
                        >
                            <div className="flex flex-center-y">
                                <StarCheckbox
                                    checked={plantData.selected}
                                    onChange={() => {
                                        updatePlantSelection(plantData.plant);
                                    }}
                                />
                                <img
                                    style={{
                                        height: 'var(--unit5)',
                                        width: 'var(--unit5)',
                                        marginRight: 'var(--unit3)',
                                        marginLeft: 'var(--unit3)',
                                    }}
                                    src={plantData.plant.image}
                                    alt="plant"
                                />
                                <p className="capitalize text-small m0">
                                    {plantData.plant.name}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return list;
    };

    return (
        <div>
            <div className="mb15">{renderTabs()}</div>
            {renderSeasonLimitMessage()}
            <div
                className="overflow-y-scroll curved p15 mb25 bg-gray1 plant-selection-grid"
                style={{
                    maxHeight: 400,
                    border: `1px solid var(--gray2)`,
                }}
            >
                {renderPlantFavorites()}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

PlantFavorites = connect(mapStateToProps, null)(PlantFavorites);

export default PlantFavorites;
