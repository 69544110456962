// libraries
import React, { Component } from 'react';

// UI components
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';

// helpers
import logInfo from '../helpers/logInfo';

// styling
import '../css/home.css';

class Invited extends Component {
    state = {};

    componentDidMount() {
        logInfo('Invited page loaded');
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={10} sm={8} md={8} lg={8} xl={8}>
                        <div>
                            <h2
                                style={{ marginTop: 16, marginBottom: 16 }}
                                className="text-purpleB90"
                            >
                                Invite Accepted!
                            </h2>
                            <div className="mb25 bg-green0">
                                <Divider />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={4} md={4} lg={4} xl={4}></Grid>
                    <Grid item xs={10} sm={4} md={4} lg={4} xl={4}>
                        <h5 className="text-center mb15 mt50">Next Steps</h5>
                        <ol>
                            <li>1. Download the Yarden App</li>
                            <li>
                                2. Log in using the email you were invited from
                            </li>
                            <li>
                                3. That's it! You now have access to their
                                account
                            </li>
                        </ol>
                        <div className="flex flex-center">
                            <button
                                className="flex flex-center"
                                onClick={() =>
                                    (window.location.href =
                                        'https://apps.apple.com/us/app/yarden/id1626672979')
                                }
                            >
                                <Icon>apple</Icon>{' '}
                                <span style={{ marginLeft: 8 }}>
                                    Download App
                                </span>
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={4} md={4} lg={4} xl={4}></Grid>
                </Grid>
            </div>
        );
    }
}

export default Invited;
