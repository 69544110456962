import axios from 'axios';
import { GET_TASKS } from './types';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createTask(task) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/tasks`, task, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}


export function getTasks(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/tasks${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_TASKS, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getTask(id) {
    return async function(dispatch) {
        try {
            const response = await axios.get(`/api/v1/tasks/${id}`, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateTask(id, task) {
    return async function(dispatch) {
        try {
            await axios.put(`/api/v1/tasks/${id}`, task, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}