// HowItWorks.js

// libraries
import React from'react';

// styles
import '../css/how-it-works.css';

// images
const appStore = 'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/appstore.png';
const consultation = 'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/assessment.png';
const harvest = 'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/harvest.png';
const installation = 'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/installation.png';
const maintenance = 'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/maintenance.png';
const plantSelection = 'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/plantselection.png';



const content = [
    {
        img: consultation,
        header: '1. Consultation',
        alt: 'Two people shaking hands',
        text: 'Begin with a personal touch. Our garden experts visit your home to design a garden that suits your space and lifestyle perfectly.'
    },
    {
        img: appStore,
        header: '2. Download the App',
        alt: 'A picture of the Yarden app on the App Store',
        text: 'Get Yarden at your fingertips. Our app makes garden management a breeze, from scheduling to daily tips. 5 stars on the app store!'
    },
    {
        img: plantSelection,
        header: '3. Select Your Plants',
        alt: 'A picture of the Plant Selection screen in the Yarden mobile app',
        text: 'Choose from a variety of organic vegetables, fruits, and herbs tailored to your taste and our expert recommendations.'
    },
    {
        img: installation,
        header: '4. Garden Installation',
        alt: 'handshake',
        text: 'Watch as your garden takes root. Our team handles the build with precision, preparing for a bountiful season.'
    },
    {
        img: maintenance,
        header: '5. Maintenance',
        alt: 'handshake',
        text: 'Regular visits from our gardeners keep your plants healthy and thriving, ensuring a lush, productive garden.'
    },
    {
        img: harvest,
        header: '6. Harvest',
        alt: 'handshake',
        text: 'Enjoy the fruits of our labor. Harvest fresh, home-grown produce right from your backyard, ready for your table.'
    },
]
const HowItWorks = () => {
    return (
        <section className="how-it-works">
            <div style={{position: 'relative'}}>
                <div className="how-it-works-sticky">
                    <label>Get a clear picture.</label>
                    <h2>How It Works</h2>
                    <p>Understanding your garden's specific needs and challenges is key. That's why we provide a complete assessment service. This helps us get a clear picture of your gardening space and your needs.</p>
                </div>
            </div>
            <div className="auto-grid-medium">
                {content.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className="flex-center"><img style={{height: 'var(--unit8)'}} src={item.img} alt={item.alt} /></div>
                            <h3>{item.header}</h3>
                            <small>{item.text}</small>
                        </div>
                    )
                })}
            </div>
        </section>
    );
}

export default HowItWorks;

