import { GET_BIDS } from '../actions/bid/types';

let initialState = {}

export default function( state = initialState, action){
    switch(action.type){
      case GET_BIDS :
        return {...action.payload}
      default :
        return state;
    }
}