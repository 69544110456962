// libs
import React from 'react';

// styles
import '../css/sidenav.css';

const SideNav = ({ userInitials, userFirstName, content, onNavigate }) => {
    return (
        <aside id="customer-dashboard-sidebar">
            <div id="sidebar-header">
                <h6 className="avatar uppercase">{userInitials}</h6>
                <h5 className="user-name">{userFirstName}</h5>
            </div>
            <ul id="customer-dashboard-sidenav">
                <div>
                    {content.map((item, index) => (
                        <li
                            className={`
                                sidenav-item 
                                pointer 
                                ${item.disabled ? 'disabled' : ''}
                            `}
                            key={index}
                            onClick={() => onNavigate(item.title)}
                        >
                            <span className="sidenav-icon">{item.icon}</span>
                            <span className="link-title">{item.title}</span>
                        </li>
                    ))}
                </div>
                <li
                    className="sidenav-item pointer"
                    style={{ justifySelf: 'end' }}
                    onClick={() => {
                        onNavigate('settings');
                    }}
                >
                    <span>{'⚙️'}</span>
                    <span className="link-title">Settings</span>
                </li>
            </ul>
        </aside>
    );
};

export default SideNav;
