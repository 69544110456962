// libs
import React, { useEffect } from 'react';

// UI components
import ContactForm from '../components/ContactForm';

// helpers
import logInfo from '../helpers/logInfo';

const ContactUs = () => {
    useEffect(() => {
        logInfo('Contact Us page loaded');
    }, []);

    return (
        <div>
            <ContactForm />
        </div>
    );
};

export default ContactUs;
