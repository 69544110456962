// libs
import React from "react";

// styling
import "../css/YardenTable.css";

const Table = ({ columns, data, onSelect }) => {
  if (data.length > 0) {
    return (
      <div className="table-wrapper">
        <table>
          <tbody>
            {data.map((row, i) => {
              return (
                <tr key={i} onClick={() => onSelect(row.originalData)} className="pointer">
                  {columns.map((column, j) => {
                    const cell = row.tableData[column.accessor];
                    return (
                      <td key={j}>
                        <data>{cell}</data>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  <h6>{column.Header}</h6>
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
    );
  } else {
    return (
      <div className="table-wrapper">
        <table>
          <tbody>
            <tr>
              <td>
                <data>No Data</data>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  <h6>{column.Header}</h6>
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
    );
  }
};

export default Table;
