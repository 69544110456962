// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// UI components
import Icon from '@material-ui/core/Icon';
import LoaderModal from '../components/LoaderModal.js';
import PasswordStrengthIndicator from '../components/PasswordStrengthIndicator';
import Navbar from '../components/Navbar.js';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher';

// helpers
import capitalize from '../helpers/capitalize.js';
import validatePassword from '../helpers/validatePassword.js';
import getEnv from '../helpers/getEnv';
import logInfo from '../helpers/logInfo';

// vars
import {
    PRODUCTION,
    TEST_STRIPE_CONNECT_CLIENT_KEY,
    LIVE_STRIPE_CONNECT_CLIENT_KEY,
} from '../config/index';

// styling
import '../css/home.css';

const currentEnv = getEnv();

const clientKey =
    currentEnv === PRODUCTION
        ? LIVE_STRIPE_CONNECT_CLIENT_KEY
        : TEST_STRIPE_CONNECT_CLIENT_KEY;
const redirect =
    currentEnv === PRODUCTION
        ? 'https%3A%2F%2Fwww.yardengarden.com'
        : 'http%3A%2F%2Flocalhost:3000';

let Activate = ({
    getApplication,
    updateApplication,
    createUser,
    sendEmail,
    sendAlert,
}) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const [application, setApplication] = useState(null);
    const [invalidURL, setInvalidURL] = useState(false);
    const [accountActivated, setAccountActivated] = useState(false);

    useEffect(() => {
        const initData = async () => {
            const urlParams = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );

            if (urlParams._id) {
                const application = await getApplication(`${urlParams._id}`);
                setApplication(application.data);
                if (application.data.vendor) {
                    setAccountActivated(true);
                }

                logInfo('Activate page loaded', {
                    application: application.data,
                });
            } else {
                setInvalidURL(true);
            }
        };

        initData();
    }, []);

    async function activateAccount() {
        const validPassword = validatePassword(password);
        const validConfirmPassword = validatePassword(confirmPassword);
        if (!validPassword || !validConfirmPassword) {
            return window.alert('Password does not meet requirements');
        } else if (password !== confirmPassword) {
            return window.alert(
                "Password and confirm password fields don't match",
            );
        } else {
            setLoadingModalIsOpen(true);
            const vendorPassword = password;

            setTimeout(async () => {
                let newUser = {
                    user: {
                        type: application.role,
                        first_name: application.first_name,
                        last_name: application.last_name,
                        email: application.email,
                        phone_number: application.phone_number,
                        address: application.address,
                        city: application.city,
                        state: application.state,
                        zip_code: application.zip_code,
                        county: application.county,
                        geolocation: application.geolocation,
                        profile_image: application.profile_image,
                    },
                    password: vendorPassword,
                };

                if (application.unit) newUser.user.unit = application.unit;

                const data = await createUser(newUser);

                await updateApplication(application._id, {
                    applicationVendor: data.data.user._id,
                });

                const directDepositUrl = `https://connect.stripe.com/express/oauth/authorize?client_id=${clientKey}&state=${data.data.user._id}&redirect_uri=${redirect}`;
                const email = {
                    email: application.email,
                    subject: 'Yarden - (ACTION REQUIRED) Direct Deposit',
                    label: 'Direct Deposit',
                    body:
                        `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                        `<p style="margin-bottom: 15px">To complete your account activation, please click the button below - which will redirect you to Stripe to setup your payout info.</p>` +
                        '<div style="border-top: 1px solid #DDDDDD;">' +
                        '<div style="margin-top: 25px; text-align: center;">' +
                        `<a href="${directDepositUrl}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>Setup Direct Deposit</b></a>` +
                        '</div>' +
                        '</div>',
                };

                await sendEmail(email);

                const alert = {
                    channel: 'account-activation',
                    text: `*New Account Activated!* \nNAME\n${capitalize(
                        application.first_name,
                    )} ${capitalize(application.last_name)}\n\nROLE\n${
                        application.role
                    }\n\nCITY / STATE\n${application.city}, ${
                        application.state
                    }\n\nPROFILE IMAGE\n${application.profile_image}`,
                };

                await sendAlert(alert);

                setAccountActivated(true);
                setLoadingModalIsOpen(false);
            }, 1000);
        }
    }

    function renderInvalidURL() {
        return (
            <div className="p50">
                <p className="text-center">
                    Invalid URL, please contact support to continue.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => (window.location.pathname = '/')}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Go Home</span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    function renderView() {
        if (application && !accountActivated) {
            return (
                <div className="flex flex-center">
                    <div className="mt25">
                        <h4 className="mb15">Activate Account</h4>
                        <div className="mb25">
                            <small>
                                Hello{' '}
                                <b>{capitalize(application.first_name)}</b>,
                                please set a password for your account
                            </small>
                        </div>
                        <PasswordStrengthIndicator password={password} />
                        <label className="mt10">Password *</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="******"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="mt10">Confirm Password *</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="******"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="mt10">
                            <button
                                className="purple small flex flex-center full-width"
                                onClick={() => activateAccount()}
                            >
                                <Icon>done</Icon>
                                <span style={{ marginLeft: 8 }}>
                                    Save & Continue
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else if (accountActivated) {
            return (
                <div className="mt25 flex flex-center">
                    <div>
                        <h4 className="mb15">Almost done!</h4>
                        <small>
                            Next, check your email inbox for a link to finish
                            your account activation
                        </small>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* main content */}
            <Navbar />
            {invalidURL ? (
                renderInvalidURL()
            ) : (
                <div className="pt75 pr25 pl25 pb15">{renderView()}</div>
            )}
        </div>
    );
};

Activate = connect(null, mapDispatchToProps)(Activate);

export default Activate;
