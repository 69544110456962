// libs
import React from 'react';

// styles
import '../css/tabs.css';

const NavTabs = ({ data, className }) => {
    return (
        <ul className={`tabs ${className}`}>
            {data.map((item, index) => (
                <li
                    key={index}
                    className={`tab pointer ${item.active && 'active'} ${item.disabled && 'disabled'}`}
                    onClick={() => item.onClick()}
                >
                    {item.icon}
                    <span className="tab-link-text">{item.title}</span>
                </li>
            ))}
        </ul>
    );
};

export default NavTabs;
