import { GET_PLANT_COMMON_TYPES } from '../actions/plantCommonType/types';

let initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PLANT_COMMON_TYPES:
            return action.payload;
        default:
            return state;
    }
}
