// libs
import { datadogLogs } from '@datadog/browser-logs';

// helpers
import getEnv from './getEnv';

const currentEnv = getEnv();

export default function logInfo(label, metrics = {}) {
    if (currentEnv === 'production') {
        datadogLogs.logger.info(label, {
            ...metrics,
            env: getEnv(),
            url: window.location.href,
        });
    }
}
