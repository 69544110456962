// libs
import React, { useState, useEffect } from 'react';

// UI components
import Icon from '@material-ui/core/Icon';

// helpers
import logInfo from '../helpers/logInfo';

// vars
import { APPOINTMENT_URL } from '../vars/constants';

// styles
import '../css/gallery.css';

const images = [
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-1.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-2.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-3.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-4.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-5.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-6.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-7.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-8.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-9.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-10.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-11.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-12.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-13.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-14.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-15.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-16.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-17.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-18.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-19.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-20.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-21.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-22.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-23.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-24.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-25.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-26.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-27.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-28.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-29.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-30.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-31.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-32.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-33.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-34.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-35.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-36.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-37.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-38.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-39.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-40.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-41.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-42.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-43.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-44.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-45.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-46.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-47.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-48.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-49.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-50.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-51.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-52.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-53.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-54.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-55.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-56.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-57.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-58.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-59.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-60.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-61.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-62.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-63.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-64.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-65.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-66.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-67.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-68.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-69.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-70.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-71.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-72.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-73.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-74.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-75.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-76.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-77.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-78.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-79.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-80.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-81.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-82.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-83.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-84.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-85.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-86.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-87.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-88.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-89.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-90.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-91.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-92.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-93.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-94.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-95.jpeg',
    'https://yarden-garden.s3.us-west-1.amazonaws.com/gallery-page-images/gallery-96.jpeg',
];

const Gallery = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        logInfo('Gallery page loaded');
        updateRows();
        window.addEventListener('resize', updateRows);
        return () => window.removeEventListener('resize', updateRows);
    }, []);

    function handleImageClick(index) {
        setSelectedImageIndex(index);
    }

    function handleCloseModal() {
        setSelectedImageIndex(null);
    }

    function handleNextImage() {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }

    function handlePrevImage() {
        setSelectedImageIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length,
        );
    }

    function updateRows() {
        const imagesPerRow = window.innerWidth < 960 ? 1 : 3;
        const newRows = [];
        for (let i = 0; i < images.length; i += imagesPerRow) {
            newRows.push(images.slice(i, i + imagesPerRow));
        }
        setRows(newRows);
    }

    return (
        <div>
            <h1 className="text-center mt25 mb25">Gallery</h1>
            <div className="gallery-container">
                {rows.map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                        {row.map((image, index) => {
                            const actualIndex =
                                rowIndex * (window.innerWidth < 960 ? 1 : 3) +
                                index;
                            return (
                                <div className="section" key={actualIndex}>
                                    <div
                                        className="img-container"
                                        onClick={() =>
                                            handleImageClick(actualIndex)
                                        }
                                    >
                                        <img
                                            src={image}
                                            alt={`${actualIndex + 1}`}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
            <div className="p50 flex flex-center-x">
                <div className="text-center">
                    <h4>Schedule Consultation</h4>
                    <p>Talk to an expert gardener about your project.</p>
                    <div className="flex flex-center">
                        <button
                            onClick={() =>
                                (window.location.href = APPOINTMENT_URL)
                            }
                        >
                            <Icon>calendar_month</Icon>
                            <span style={{ marginLeft: 8 }}>Schedule Call</span>
                        </button>
                    </div>
                </div>
            </div>
            {selectedImageIndex !== null && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <img
                            src={images[selectedImageIndex]}
                            alt={`${selectedImageIndex + 1}`}
                        />
                        <div className="controls">
                            <button onClick={handlePrevImage}>Back</button>
                            <button onClick={handleNextImage}>Next</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;
