// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

// actions
import { updateTask } from '../actions/task/index';

class TaskDetails extends Component {
    async setTaskStatus(status) {
        if (window.confirm('Are you sure?')) {
            await this.props.updateTask(this.props.selectedTask._id, {
                status,
            });
            this.props.onSetStatus();
        }
    }

    render() {
        const { selectedTask, user } = this.props;

        return (
            <div>
                {/* task details */}
                <div>
                    <div className="flex flex-space-between full mb15">
                        <div />
                        <div
                            className={
                                selectedTask.status === 'pending'
                                    ? 'flex'
                                    : 'hidden'
                            }
                        >
                            <div className="mr10">
                                <button
                                    className="purple small"
                                    onClick={() =>
                                        this.setTaskStatus('complete')
                                    }
                                >
                                    <span className="flex flex-center-y">
                                        <Icon>done</Icon>
                                        <span style={{ marginLeft: 8 }}>
                                            Finish
                                        </span>
                                    </span>
                                </button>
                            </div>
                            <button
                                className="btn3 small"
                                onClick={() => this.setTaskStatus('cancelled')}
                            >
                                <span className="flex flex-center-y">
                                    <Icon>delete_outlined</Icon>
                                    <span style={{ marginLeft: 8 }}>
                                        Delete
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="data-detail">
                        <label>Member</label>
                        <p>
                            {`${selectedTask.customer.first_name} ${selectedTask.customer.last_name}`}
                            <br />
                            {selectedTask.customer.address}
                            {selectedTask.customer.unit
                                ? ` #${selectedTask.customer.unit}`
                                : ''}
                            , {selectedTask.customer.city}{' '}
                            {selectedTask.customer.state}{' '}
                            {selectedTask.customer.zip_code}
                            <br />
                            <span
                                className={
                                    user.data.type !== 'admin' ? 'hidden' : null
                                }
                            >
                                {selectedTask.email}
                            </span>
                            {selectedTask.phone_number}
                        </p>
                    </div>
                    <div className={`data-detail`}>
                        <label>Date</label>
                        <p>{moment(selectedTask.date).format('MM/DD/YYYY')}</p>
                    </div>
                    <div className="data-detail">
                        <label>Time</label>
                        <p>
                            {moment(selectedTask.time, `HH:mm:ss`).format(
                                `h:mm A`,
                            )}
                        </p>
                    </div>
                    <div className="data-detail">
                        <label>Title</label>
                        <p>{selectedTask.title}</p>
                    </div>
                    <div className="data-detail">
                        <label>Description</label>
                        <p>{selectedTask.description}</p>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateTask,
        },
        dispatch,
    );
}

TaskDetails = connect(mapStateToProps, mapDispatchToProps)(TaskDetails);

export default TaskDetails;
