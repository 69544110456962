export default function validatePassword(password) {
    const passwordIsLongEnough = password.length > 5;
    const passwordContainsUppercaseCharacter = /[A-Z]/.test(password);
    const passwordContainsLowercaseCharacter = /[a-z]/.test(password);
    const passwordContainsAtLeastOneNumberOrSpecialCharacter =
        /[0-9!@#$%^&*]/.test(password);

    const valid =
        passwordIsLongEnough &&
        passwordContainsUppercaseCharacter &&
        passwordContainsLowercaseCharacter &&
        passwordContainsAtLeastOneNumberOrSpecialCharacter;

    return valid;
}
