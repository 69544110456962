import { ORDER_PENDING, ORDER_COMPLETE } from './../actions/status/types';

let initialState = {
    type: 'pending',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ORDER_PENDING:
            return {
                type: 'pending',
            };
        case ORDER_COMPLETE:
            return {
                type: 'complete',
            };
        default:
            return state;
    }
}
