export function setQty(originalValue, newValue) {
    var value;
    if(newValue.length < 1) {
        value = '';
    } else {
        value = parseFloat(newValue);
        if(isNaN(newValue)) return originalValue;
    }

    return value;
}