import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_BIDS } from './types';

export function createBid(bid) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/bids`, bid, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getBids(query, override) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/bids${q}`, {
                headers: { authorization: getAuthToken() },
            });
            if (!override) dispatch({ type: GET_BIDS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getBid(id) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/bids/${id}`, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function checkBids(query) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.head(`/api/v1/bids${q}`, {
                headers: { authorization: getAuthToken() },
            });

            if (response.status === 200) {
                return true;
            } else if (response.status === 204) {
                return false;
            }
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function updateBid(id, bid) {
    return async function (dispatch) {
        try {
            await axios.put(`/api/v1/bids/${id}`, bid, {
                headers: { authorization: getAuthToken() },
            });
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
