// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// UI components
import Navbar from '../components/Navbar';
import PaymentMethod from '../components/PaymentMethod';
import GeneralMessage from '../components/GeneralMessage';
import Link from '../components/UI/Link';

// helpers
import logInfo from '../helpers/logInfo';

// vars
import {
    DISPLAY_PAYMENT_METHOD_INFO,
    CHANGE_PAYMENT_METHOD,
} from '../screens/Account';

let Billing = ({ user }) => {
    const [paymentMethodAction, setPaymentMethodAction] = useState(
        DISPLAY_PAYMENT_METHOD_INFO,
    );
    const [paymentMethodWasUpdated, setPaymentMethodWasUpdated] =
        useState(false);

    useEffect(() => {
        logInfo('Billing page loaded', { user: user.data });
    }, []);

    return (
        <div>
            {/* page header */}
            <Navbar />

            {/* page view */}
            <div className="pt75 pb25 pl25 pr25">
                {!paymentMethodWasUpdated && (
                    <div>
                        <div className="text-center mb25 mt25">
                            <h6>Billing</h6>
                        </div>
                        <div>
                            <div
                                id="customer-billing-info"
                                className="settings-card"
                            >
                                <div className="flex-space-between flex-center">
                                    <h4>Payment Method</h4>
                                    {paymentMethodAction ===
                                        DISPLAY_PAYMENT_METHOD_INFO &&
                                        user.data.payment_info && (
                                            <Link
                                                onClick={() =>
                                                    setPaymentMethodAction(
                                                        CHANGE_PAYMENT_METHOD,
                                                    )
                                                }
                                            >
                                                Edit
                                            </Link>
                                        )}
                                </div>
                                <hr className="full-width" />
                                <PaymentMethod
                                    action={paymentMethodAction}
                                    onCancel={() =>
                                        setPaymentMethodAction(
                                            DISPLAY_PAYMENT_METHOD_INFO,
                                        )
                                    }
                                    onUpdate={() =>
                                        setPaymentMethodWasUpdated(true)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}

                {paymentMethodWasUpdated && (
                    <GeneralMessage
                        message="Your payment method has been updated"
                        isSuccess={true}
                        buttonText="Continue to account"
                        buttonAction={() =>
                            (window.location.pathname = '/account')
                        }
                    />
                )}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

Billing = connect(mapStateToProps, null)(Billing);

export default Billing;
