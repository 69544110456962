import { NAVIGATE } from '../actions/navigation/types';

let initialState = {
    target: 'orders'
}

export default function( state = initialState, action){
    switch(action.type){
        case NAVIGATE:
            return {
                target: action.payload
            }
        default :
            return state;
    }
}