// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';

// actions
import { createTask, getTasks } from '../actions/task/index';
import { getOrders } from '../actions/order/index';
import { getUsers } from '../actions/users/index';

class TaskModal extends Component {
    state = {
        customers: [],
        selectedCustomer: '',
        taskDate: moment().startOf('day').format('YYYY-MM-DD'),
        taskTime: '00',
        taskTitle: '',
        taskDescription: '',
        isLoadingCustomers: false,
        isSaving: false,
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.props.isOpen) {
                this.setState({ isLoadingCustomers: true });
                let customers = [];
                if (this.props.user.data.type !== 'admin') {
                    const orders = await this.props.getOrders(
                        `vendor=${this.props.user.data._id}`,
                        true,
                    );
                    orders.data.list.forEach((order) => {
                        const exists = customers.find(
                            (customer) => customer._id === order.customer._id,
                        );
                        if (!exists) {
                            customers.push(order.customer);
                        }
                    });
                } else {
                    const users = await this.props.getUsers('type=customer');
                    customers = users.data;
                }

                this.setState({
                    customers,
                    isLoadingCustomers: false,
                });
            }
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    createTask() {
        this.setState({ isSaving: true });
        setTimeout(async () => {
            const selectedCustomer = this.state.customers.find(
                (c) => c._id === this.state.selectedCustomer._id,
            );
            const task = {
                title: this.state.taskTitle,
                description: this.state.taskDescription,
                time: this.state.taskTime,
                date: this.state.taskDate,
                owner: this.props.user.data._id,
                customer: selectedCustomer._id,
            };

            await this.props.createTask(task);
            await this.props.getTasks(`status=${this.props.taskStatus.type}`);
            this.setState({
                customer: null,
                taskDate: moment().startOf('day'),
                taskTime: '',
                taskTitle: '',
                taskDescription: '',
            });

            this.props.onClose();
            this.setState({ isSaving: false });
        }, 1000);
    }

    render() {
        const {
            taskDate,
            taskTime,
            taskTitle,
            taskDescription,
            customers,
            selectedCustomer,
            isLoadingCustomers,
            isSaving,
        } = this.state;

        const { isOpen = false, onClose } = this.props;

        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={isSaving ? null : () => onClose()}
                >
                    <DialogContent>
                        <h5>{isSaving ? 'Saving...' : 'New Reminder'}</h5>
                        <label>Member*</label>
                        <div>
                            <select
                                value={selectedCustomer._id}
                                onChange={async (e) => {
                                    const selectedOption = customers.find(
                                        (c) => c._id === e.target.value,
                                    );
                                    this.setState({
                                        selectedCustomer: selectedOption,
                                    });
                                }}
                            >
                                <option>
                                    {isLoadingCustomers
                                        ? 'Loading...'
                                        : 'Select a customer'}
                                </option>
                                {customers &&
                                    customers.map((c, index) => (
                                        <option value={c._id} key={index}>
                                            {c.first_name} {c.last_name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <label>Time*</label>
                        <select
                            name="taskTime"
                            disabled={isSaving}
                            value={taskTime}
                            onChange={(e) => this.setValue(e)}
                        >
                            <option value={'00'}>12:00am</option>
                            <option value={'01'}>1:00am</option>
                            <option value={'02'}>2:00am</option>
                            <option value={'03'}>3:00am</option>
                            <option value={'04'}>4:00am</option>
                            <option value={'05'}>5:00am</option>
                            <option value={'06'}>6:00am</option>
                            <option value={'07'}>7:00am</option>
                            <option value={'08'}>8:00am</option>
                            <option value={'09'}>9:00am</option>
                            <option value={'10'}>10:00am</option>
                            <option value={'11'}>11:00am</option>
                            <option value={'12'}>12:00pm</option>
                            <option value={'13'}>1:00pm</option>
                            <option value={'14'}>2:00pm</option>
                            <option value={'15'}>3:00pm</option>
                            <option value={'16'}>4:00pm</option>
                            <option value={'17'}>5:00pm</option>
                            <option value={'18'}>6:00pm</option>
                            <option value={'19'}>7:00pm</option>
                            <option value={'20'}>8:00pm</option>
                            <option value={'21'}>9:00pm</option>
                            <option value={'22'}>10:00pm</option>
                            <option value={'23'}>11:00pm</option>
                        </select>
                        <label>Date*</label>
                        <div className="date-container curved mt5">
                            <input
                                type="date"
                                name="taskDate"
                                value={moment(taskDate).format('YYYY-MM-DD')}
                                onChange={(e) => {
                                    this.setState({
                                        taskDate: moment(
                                            e.target.value,
                                        ).startOf('day'),
                                    });
                                }}
                            />
                        </div>
                        <label>Title*</label>
                        <input
                            margin="dense"
                            name="taskTitle"
                            onChange={(e) => this.setValue(e)}
                        />
                        <label>Description*</label>
                        <textarea
                            margin="dense"
                            name="taskDescription"
                            onChange={(e) => this.setValue(e)}
                        />
                        <div className="pt15 pb15 float-right">
                            <div className="flex">
                                <button
                                    className="btn3 small flex flex-center-y mr10"
                                    disabled={isSaving}
                                    onClick={() => onClose()}
                                >
                                    <Icon>clear</Icon>
                                    <span style={{ marginLeft: 8 }}>
                                        Cancel
                                    </span>
                                </button>
                                <button
                                    className="purple small flex flex-center-y"
                                    disabled={
                                        isSaving ||
                                        !selectedCustomer ||
                                        !taskDate ||
                                        !taskTime ||
                                        !taskTitle ||
                                        !taskDescription
                                    }
                                    onClick={() => this.createTask()}
                                >
                                    <Icon>save</Icon>{' '}
                                    <span style={{ marginLeft: 8 }}>Save</span>
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        taskStatus: state.taskStatus,
        loading: state.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createTask,
            getTasks,
            getOrders,
            getUsers,
        },
        dispatch,
    );
}

TaskModal = connect(mapStateToProps, mapDispatchToProps)(TaskModal);

export default TaskModal;
