// libs
import React from 'react';

// vars
import { FULL_PLAN, GARDEN_BOOTCAMP } from '../vars/types';
import vars from '../vars/index';

// styling
import '../css/pricing.css';

const Pricing = ({ onSelect }) => {
    const { gardenBootcamp, fullPlan } = vars.products;
    return (
        <div>
            <div className="pricing-container">
                <h3 className="mb50 text-center">
                    Master Your Gardening Skills or Let Us Do the Work.
                </h3>
                <div className="offerings">
                    <div className="p10">
                        <img
                            src={gardenBootcamp.image}
                            className="offering-image"
                            alt="vegetable garden beds"
                        />
                        <h4 className="mb10 mt10">{gardenBootcamp.title}</h4>
                        <p className="m0">${gardenBootcamp.price.toFixed(2)}</p>
                        <small className="free-trial-badge">
                            E-learning course
                        </small>
                        <button
                            onClick={() => onSelect(GARDEN_BOOTCAMP)}
                            className="mt10"
                        >
                            Select Garden Bootcamp
                        </button>
                        <small className="mb10 mt10">
                            {gardenBootcamp.description}
                        </small>
                        <ul>
                            {gardenBootcamp.details.map((detail, index) => (
                                <li key={index}>
                                    <small>
                                        <b>{detail.title}</b>:{' '}
                                        {detail.description}
                                    </small>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="p10">
                        <img
                            src={fullPlan.image}
                            className="offering-image"
                            alt="gardener"
                        />
                        <h4 className="mb10 mt10">{fullPlan.title}</h4>
                        <p className="m0">
                            ${fullPlan.price.toFixed(2)} / Month
                        </p>
                        <small className="free-trial-badge">
                            1st month FREE!
                        </small>
                        <button
                            onClick={() => onSelect(FULL_PLAN)}
                            className="mt10"
                        >
                            Select Maintenance Plan
                        </button>
                        <small className="mb10 mt10">
                            {fullPlan.description}
                        </small>
                        <ul>
                            {fullPlan.details.map((detail, index) => (
                                <li key={index}>
                                    <small>
                                        <b>{detail.title}</b>:{' '}
                                        {detail.description}
                                    </small>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
