import { GET_SUBSCRIPTION } from './../actions/subscription/types';

let initialState = {}

export default function( state = initialState, action){
    switch(action.type){
        case GET_SUBSCRIPTION:
            return {...action.payload}
        default:
            return state;
    }
}