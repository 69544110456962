// libs
import React, { useState } from 'react';

// components
import ImageModal from './ImageModal';

// helpers
import getQuotePricing from '../helpers/getQuotePricing';
import delimit from '../formatting/delimit.js';

// vars
import { BID_PENDING_APPROVAL } from '../vars/types';

const CustomerBidDetails = ({ bid, onBack }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const {
        laborTotal,
        materialsTotal,
        deliveryTotal,
        rentalTotal,
        disposalTotal,
        firstPayment,
        secondPayment,
    } = getQuotePricing(bid);

    const preTaxTotal = firstPayment.preTaxTotal + secondPayment.preTaxTotal;
    const salesTax = firstPayment.salesTax + secondPayment.salesTax;
    const paymentProcessingFee =
        firstPayment.paymentProcessingFee + secondPayment.paymentProcessingFee;
    const totalSale = firstPayment.total + secondPayment.total;

    function handleImgClick(image) {
        setSelectedImage(image);
    }

    function closeModal() {
        setSelectedImage(null);
    }

    function handleGoToCheckout() {
        window.location.href = `${window.location.origin}/approve?bid=${bid._id}`;
    }

    return (
        <div className="pt4">
            <div className="flex-space-between">
                <button className="btn3 small mb4" onClick={onBack}>
                    Back
                </button>
                {bid.status === BID_PENDING_APPROVAL && (
                    <button className="small mb4" onClick={handleGoToCheckout}>
                        Go to Checkout
                    </button>
                )}
            </div>
            <div className="customer-bid-details">
                <div className="flex-space-between">
                    <div>
                        <label>Title</label>
                        <h4>{bid.title}</h4>
                    </div>
                    <div>
                        <label>Status</label>
                        <small>
                            <data>{bid.status}</data>
                        </small>
                    </div>
                </div>

                <hr style={{ width: '100%' }} />

                <div className="auto-grid-medium">
                    <div>
                        <label>Member</label>
                        <small>
                            {bid.customer.first_name} {bid.customer.last_name}
                            <br />
                            {bid.customer.address}
                            <br />
                            {bid.customer.city},&nbsp;
                            <span className="uppercase">
                                {bid.customer.state}
                            </span>
                            &nbsp;
                            {bid.customer.zip_code}
                        </small>
                    </div>

                    <div>
                        <label>Date Created</label>
                        <small>
                            {new Date(bid.dt_created).toLocaleDateString(
                                'en-US',
                                {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                },
                            )}
                        </small>
                    </div>

                    <div>
                        <label>Estimated Start Date</label>
                        <small>
                            {new Date(
                                bid.estimated_start_dt,
                            ).toLocaleDateString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </small>
                    </div>
                </div>
                <div className="auto-grid-large">
                    <div>
                        <label>Description</label>
                        <p style={{ textTransform: 'none' }}>
                            {bid.description}
                        </p>
                    </div>
                    <div>
                        <label>Attachments</label>
                        <div
                            style={{
                                display: 'flex',
                                gap: 'var(--unit4)',
                                flexWrap: 'wrap',
                            }}
                        >
                            {bid.attachments.map((attachment, index) => (
                                <img
                                    key={index}
                                    className="attachment-thumbnail"
                                    src={attachment.url}
                                    alt={attachment.filename}
                                    onClick={() => handleImgClick(attachment)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="table-wrapper mb4 mt4 quote-details-table">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <small>
                                        {bid.line_items.labor.name
                                            ? bid.line_items.labor.name
                                            : 'Hourly'}
                                    </small>
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    <data>{bid.line_items.labor.qty}</data>
                                </td>
                                <td>
                                    <data>
                                        $
                                        {delimit(
                                            bid.line_items.labor.price.toFixed(
                                                2,
                                            ),
                                        )}
                                    </data>
                                </td>
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th style={{ maxWidth: '24ch' }}>Labor</th>
                                <th></th>
                                <th>Quantity</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Labor Total</th>
                                <td></td>
                                <td></td>
                                <td>
                                    <data style={{ fontWeight: 'bold' }}>
                                        ${delimit(laborTotal.toFixed(2))}
                                    </data>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                {bid.line_items.rentals && (
                    <div className="table-wrapper mb4 mt4 quote-details-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <small>
                                            {bid.line_items.rentals.name}
                                        </small>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <data>
                                            $
                                            {delimit(
                                                bid.line_items.rentals.price.toFixed(
                                                    2,
                                                ),
                                            )}
                                        </data>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Rentals</th>
                                    <th></th>
                                    <th></th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Rentals Total</th>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <data style={{ fontWeight: 'bold' }}>
                                            ${delimit(rentalTotal.toFixed(2))}
                                        </data>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
                {bid.line_items.disposal && (
                    <div className="table-wrapper mb4 mt4 quote-details-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <small>
                                            {bid.line_items.disposal.name}
                                        </small>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <data>
                                            $
                                            {delimit(
                                                bid.line_items.disposal.price.toFixed(
                                                    2,
                                                ),
                                            )}
                                        </data>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Disposal</th>
                                    <th></th>
                                    <th></th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Disposal Total</th>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <data style={{ fontWeight: 'bold' }}>
                                            ${delimit(disposalTotal.toFixed(2))}
                                        </data>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
                {bid.line_items.delivery && (
                    <div className="table-wrapper mb4 mt4 quote-details-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <small>
                                            {bid.line_items.delivery.name}
                                        </small>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <data>
                                            $
                                            {delimit(
                                                bid.line_items.delivery.price.toFixed(
                                                    2,
                                                ),
                                            )}
                                        </data>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Delivery</th>
                                    <th></th>
                                    <th></th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Delivery Total</th>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <data style={{ fontWeight: 'bold' }}>
                                            ${delimit(deliveryTotal.toFixed(2))}
                                        </data>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
                {bid.line_items.materials && (
                    <div className="table-wrapper mb4 mt4 quote-details-table">
                        <table>
                            <tbody>
                                {bid.line_items.materials.map(
                                    (material, index) => (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    maxWidth: '24ch',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                <small
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {material.name}
                                                </small>
                                            </td>
                                            <td>
                                                <a href={material.url}>Link</a>
                                            </td>
                                            <td>
                                                <data>{material.qty}</data>
                                            </td>
                                            <td>
                                                <data>
                                                    $
                                                    {delimit(
                                                        material.price.toFixed(
                                                            2,
                                                        ),
                                                    )}
                                                </data>
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                            <thead>
                                <tr>
                                    <th style={{ maxWidth: '24ch' }}>
                                        Materials
                                    </th>
                                    <th>Link</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Materials Total</th>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <data style={{ fontWeight: 'bold' }}>
                                            $
                                            {delimit(materialsTotal.toFixed(2))}
                                        </data>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
                <div>
                    <label>Totals</label>
                    <div className="total-container">
                        <div className="flex-space-between flex-center-y">
                            <small>Subtotal</small>
                            <div>
                                <data>${delimit(preTaxTotal.toFixed(2))}</data>
                            </div>
                        </div>

                        <div className="flex-space-between flex-center-y">
                            <small>Sales Tax</small>
                            <div>
                                <data>${delimit(salesTax.toFixed(2))}</data>
                            </div>
                        </div>

                        <div className="flex-space-between flex-center-y">
                            <small>Processing Fee</small>
                            <div>
                                <data>
                                    ${delimit(paymentProcessingFee.toFixed(2))}
                                </data>
                            </div>
                        </div>

                        <div className="flex-space-between flex-center-y">
                            <small>Total</small>
                            <div>
                                <data>${delimit(totalSale.toFixed(2))}</data>
                            </div>
                        </div>
                    </div>
                </div>
                {bid.status === BID_PENDING_APPROVAL && (
                    <div className="flex-end">
                        <button
                            className="small mb4"
                            onClick={handleGoToCheckout}
                        >
                            Go to Checkout
                        </button>
                    </div>
                )}

                {/* Modal for displaying images */}
                <ImageModal
                    isOpen={!!selectedImage}
                    onClose={closeModal}
                    image={selectedImage}
                />
            </div>
        </div>
    );
};

export default CustomerBidDetails;
