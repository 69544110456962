// libs
import axios from 'axios';

// actions
import { throwError } from '../../actions/error/index';

// helpers
import getEnv from '../../helpers/getEnv';

// vars
import { DEVELOPMENT } from '../../config/index';

const currentEnv = getEnv();

export async function sendEmail(info) {
    return async function (dispatch) {
        // if email is to Yarden HQ and in env is development, don't send email
        if (
            info.email === 'info@yardengarden.com' &&
            currentEnv === DEVELOPMENT
        )
            return;

        try {
            const email = {
                to: info.email,
                subject: info.subject,
                bcc:
                    currentEnv === DEVELOPMENT ? null : 'info@yardengarden.com',
                html:
                    '<table style="margin: 0 auto; width="600px" cellspacing="0" cellpadding="0" border="0">' +
                    '<tr>' +
                    '<td>' +
                    '<img src="https://yarden-garden.s3.us-west-1.amazonaws.com/mobile/yarden_horizontal_lockup_bg-01.png" style="width: 150px;" />' +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                    '<tr>' +
                    '<td>' +
                    info.body +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                    '<tr>' +
                    '<td style="padding-top: 15px;">' +
                    '<a href="https://www.facebook.com/yardengardeninc"><img src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/fb.png" style="width: 25px; margin-right: 5px;" /></a>' +
                    '<a href="https://www.instagram.com/official_yarden"><img src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/insta.png" style="width: 25px;" /></a>' +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '<table style="margin: 0 auto;" width="200px" cellspacing="0" cellpadding="0" border="0">' +
                    '<tr>' +
                    '<td style="padding-top: 15px; text-align: center;">' +
                    '<span><b>Download on the App Store</b></span>' +
                    '<a href="https://apps.apple.com/us/app/yarden/id1626672979"><img src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/app-store-download.png" style="margin-top: 5px;" /></a>' +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '<table style="margin: 0 auto;" width="200px" cellspacing="0" cellpadding="0" border="0">' +
                    '<tr>' +
                    '<td style="padding-top: 15px; text-align: center;">' +
                    '<p>Leave us a review & get 1 FREE month of gardening service!</p>' +
                    '<a style="color: black;" href="https://www.yardengarden.com/reviews">☆☆☆☆☆</a>' +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                    '<tr>' +
                    '<td style="padding-top: 15px;">' +
                    '<span style="color:#adc5aa; font-size: 10px;">This email was sent from a notification-only address that cannot accept incoming email. Please do not reply to this message. For support, please contact (888) 828-9287</span>' +
                    '</td>' +
                    '</tr>' +
                    '</table>',
            };

            axios.post('/api/v1/emails', email);
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
