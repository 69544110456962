import { BI_WEEKLY } from '../vars/types';

export default function isHarvestDate(date, harvestSchedule) {
    if (!harvestSchedule) return false;
    const today = new Date();
    const startDate = new Date(harvestSchedule.start_date);
    const cadence = harvestSchedule.cadence === BI_WEEKLY ? 14 : 7;

    if (date < today) {
        return false;
    }

    for (
        let d = new Date(startDate);
        d <= date;
        d.setDate(d.getDate() + cadence)
    ) {
        if (d.toDateString() === date.toDateString()) {
            return true;
        }
    }

    return false;
}
