import axios from 'axios';
import { throwError } from '../../actions/error/index';
import { sendEmail } from '../../actions/email/index';
import { sendSms } from '../../actions/sms/index';
import getAuthToken from '../../formatting/getAuthToken';


export function createCustomer(customer) {
    return async function(dispatch) {
        try {
            const response = await axios.post(`/api/v1/cards/customer`, customer);
            return response;
        }
        
        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function createCard(card) {
    return async function(dispatch) {
        try {
            const response = await axios.post(`/api/v1/cards/source`, card, {headers: {authorization: getAuthToken()}});
            return response;
        }
        
        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function deleteCard() {
    return async function(dispatch) {
        try {
            const response = await axios.delete(`/api/v1/cards/source`, {headers: {authorization: getAuthToken()}});
            return response;
        }
        
        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function chargeCard(payment, user) {
    return async function(dispatch, getState) {
        try {
            const response = await axios.post(`/api/v1/payments`, payment, {headers: {authorization: getAuthToken()}});
            return response;
        }
        
        catch(error) {

            // set current user
            const currentState = getState();

            // set email
            const email = {
                email: user.email,
                bcc: (window.location.origin === 'http://localhost:3000') ? null : 'info@yardengarden.com',
                subject: 'Yarden - Your payment was declined',
                label: 'Payment Declined',
                body: (
                    '<p>Hello <b>' + user.first_name + '</b>,</p>' +
                    `<p>Your recent payment of $${(payment.amount / 100).toFixed(2)} was declined by your bank.</p>` +
                    `<p>This can happen sometimes as a fraud prevention mechanism set up by your bank.</p>` +
                    `<p>Please contact your financial institution to resolve this issue.</p>` +
                    `<p>If you have any questions, please do not hesitate to contact us!</p>`
                )
            }

            // send email
            await dispatch(sendEmail(email));

            // set sms
            const sms = {
                from: '8888289287',
                to: user.phone_number,
                body: `Greetings from Yarden! Your recent payment of $${(payment.amount / 100).toFixed(2)} was declined by your bank. Please contact your financial institution to resolve this issue.`
            }
    
            // send sms notification
            await dispatch(sendSms(sms));

            // if user is customer {...}
            if(currentState.user.data.type === 'customer') {

                // throw error
                dispatch(throwError(error, error.response.data));
                throw new Error();
            }
        }
    }
}


