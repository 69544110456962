// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// UI components
import Icon from '@material-ui/core/Icon';

// helpers
import getSeason from '../helpers/getSeason';

// vars
import { MAX_FAVORITES_LIMIT_PER_SEASON } from '../vars/constants';

// styles
import '../css/customer-plant-favorites.css';

let CustomerPlantFavorites = (props) => {
    const { plants, onChange } = props;
    const [currentSelection, setCurrentSelection] = useState(plants);
    const selected = currentSelection.filter((p) => p.selected);
    const plantSeason = getSeason();

    useEffect(() => {
        setCurrentSelection(plants);
    }, [plants]);

    const validatePlantSelectionSize = (plants) => {
        return plants <= MAX_FAVORITES_LIMIT_PER_SEASON;
    };

    const updatePlantSelection = (plant) => {
        let isMaxSizeLimit = false;
        let plantsToUpdate = [...currentSelection];
        plantsToUpdate.forEach((plantToUpdate) => {
            if (plantToUpdate.plant._id === plant._id) {
                if (plantToUpdate.selected) {
                    plantToUpdate.selected = false;
                } else {
                    if (validatePlantSelectionSize(selected.length + 1)) {
                        plantToUpdate.selected = true;
                    } else {
                        isMaxSizeLimit = true;
                    }
                }
            }
        });

        if (isMaxSizeLimit) {
            return window.alert(
                `You cannot select more than ${MAX_FAVORITES_LIMIT_PER_SEASON} favorites for ${plantSeason}`,
            );
        } else {
            setCurrentSelection(plantsToUpdate);
            onChange(plantsToUpdate);
        }
    };

    const renderSeasonLimitMessage = () => {
        return (
            <div className='limit-message'>
                <p className="text-small" style={{ margin: 0, maxWidth: 'var(--unit9)' }}>
                    * You can select up to {MAX_FAVORITES_LIMIT_PER_SEASON}{' '}
                    spring plant varieties and {MAX_FAVORITES_LIMIT_PER_SEASON}{' '}
                    fall plant varieties. While we cannot guarantee their
                    availability at all times, your gardener will make every
                    effort to prioritize these selections in your garden space.
                </p>
                <data className="selection-indicator text-small">
                    {selected.length}/{MAX_FAVORITES_LIMIT_PER_SEASON} selected
                </data>
            </div>
        );
    };

    const renderPlantFavorites = () => {
        const list = currentSelection.map((plantData, index) => {
            return (
                <div
                    className={`card ${plantData.selected ? 'checked' : ''}`} 
                    onClick={() => updatePlantSelection(plantData.plant)}
                    key={index}
                >
                    <span style={{color: 'var(--purpleB)', display: 'flex', alignItems: 'center'}}>
                        {plantData.selected ? (
                            <Icon>star</Icon>
                        ) : (
                            <Icon>star_border</Icon>
                        )}
                    </span>
                    <div className="flex-center-y">
                        <img
                            style={{
                                height: 'var(--unit5)',
                                width: 'var(--unit5)',
                            }}
                            src={plantData.plant.image}
                            alt="plant"
                        />
                        &nbsp;
                        <p className="capitalize text-small m0">
                            {plantData.plant.name}
                        </p>
                    </div>
                </div>
            );
        });

        return list;
    };

    return (
        <div>
            {renderSeasonLimitMessage()}
            <div className="plant-selection-grid">{renderPlantFavorites()}</div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

CustomerPlantFavorites = connect(mapStateToProps, null)(CustomerPlantFavorites);

export default CustomerPlantFavorites;
