// libs
import React from 'react';

// UI components
import Icon from '@material-ui/core/Icon';

// styles
import '../css/google-reviews.css';

const reviews = [
    {
        author: 'Krista W.',
        text: "We've been staring at our neighbor's garden for a year now, humbly accepting their wonderful surplus of veggies and fruits, and finally decided to jump in with Yarden ourselves. The process was so easy! They came, discussed our options and ideas, and goals, and then mapped our spaces and came back to us with a plan in days. Installation was easy, taking only two days, with complete irrigation. It's been two months and we're already harvesting. The team comes biweekly, and is so friendly, considerate and knowledgeable. We've enjoyed every minute of this so far and are looking forward to what we can grow together.",
    },
    {
        author: 'Ryan S.',
        text: 'Was on the fence with a decision to hire a professional gardener for our vegetable garden, so glad we said yes. We consistently get amazing veggies right from the backyard, delicious and super convenient/inexpensive.',
    },
    {
        author: 'Abel Z.',
        text: "Yarden is the best. I have landscaping, mow and blow guys, but they don't know how to grow food. Yarden knows how to grow food, and we are eating healthier than ever! Thanks for showing us the difference between guys to grow food and guys that don't!",
    },
];

const GoogleReviews = () => {
    return (
        <div className="reviews-section">
            <h3 className="mt50 text-center">Our Google Reviews</h3>
            <div className="reviews-container p50">
                {reviews.map((review, index) => (
                    <div className="review flex-1 card p15" key={index}>
                        <p>{review.author}</p>
                        <h3>
                            <Icon>star</Icon>
                            <Icon>star</Icon>
                            <Icon>star</Icon>
                            <Icon>star</Icon>
                            <Icon>star</Icon>
                        </h3>
                        <small>{review.text}</small>
                    </div>
                ))}
            </div>
            <div className="flex flex-center">
                <button
                    className="small btn3"
                    onClick={() =>
                        (window.location.href =
                            'https://www.google.com/maps/place/Yarden+Garden,+Inc./@39.6610454,-125.4807831,6z/data=!4m12!1m2!2m1!1syarden+garden!3m8!1s0x808f7fbbbf661127:0xb36921cf9a1793d0!8m2!3d37.878638!4d-122.4203375!9m1!1b1!15sCg15YXJkZW4gZ2FyZGVuWg8iDXlhcmRlbiBnYXJkZW6SAQhnYXJkZW5lcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VObE5scFBWbmxCUlJBQuABAA!16s%2Fg%2F11tcvh69qv')
                    }
                >
                    <div className="flex flex-center-y">
                        <span style={{ marginRight: 8 }}>
                            View More Reviews
                        </span>
                        <Icon>arrow_forward</Icon>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default GoogleReviews;
