// libs
import axios from 'axios';

// actions
import { throwError } from '../../actions/error/index';

// helpers
import getAuthToken from '../../formatting/getAuthToken';

export function createPlantActivity(plantActivity) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/plant-activities`,
                plantActivity,
                { headers: { authorization: getAuthToken() } },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
