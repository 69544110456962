import React, { Component } from 'react';

class StepIndicatorV2 extends Component {

    render() {

        const {
            currentStep,
            maxSteps
        } = this.props;

        return (
            <div className="flex">
                <label>{currentStep} out of {maxSteps} steps</label>
            </div>
        )

    }
}

export default StepIndicatorV2;