// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// actions
import { authenticateUser } from '../actions/user/index';
import getAuthToken from '../formatting/getAuthToken';

// UI components
import LoginModal from '../components/LoginModal.js';

class Auth extends Component {

    state = {}

    componentDidMount() {
        const authToken = getAuthToken();
        if(authToken) {
            this.props.authenticateUser();
        } else {
            this.setState({loginModalIsOpen: true});
        }
    }

    render() {
        const { loginModalIsOpen } = this.state;
        const { user } = this.props;
        const AuthenticatedComponent = this.props.authenticate;

        if(user.data) {
            if(window.location.pathname === '/dashboard') {
                if(
                    user.data.type !== 'admin' && 
                    user.data.type !== 'gardener' && 
                    user.data.type !== 'partner'
                ) {
                    return <h4>Not Authorized</h4>
                }
            }

            if(window.location.pathname === '/account') {
                if(user.data.type !== 'customer') {
                    return <h4>Not Authorized</h4>
                }
            }
        }

        return (
            <span>
                {/* modals */}
                <LoginModal 
                    isOpen={loginModalIsOpen} 
                    onClose={() => this.setState({loginModalIsOpen: false})}
                />

                {/* logged in view */}
                {(user.data) && (
                    <AuthenticatedComponent user={user} />
                )}
            </span>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
  
function mapDispatchToProps(dispatch){
    return bindActionCreators({ 
        authenticateUser
    }, dispatch)
}
  
Auth = connect(mapStateToProps, mapDispatchToProps)(Auth);
  
export default Auth;
