import { NAVIGATE } from './types';
import { throwError } from '../../actions/error/index';

export function navigate(target) {
    return async function(dispatch) {        
        try {
            dispatch({type: NAVIGATE, payload: target});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}