import axios from 'axios';
import { throwError } from '../error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_SCANS  } from './types';

export function createScan(qrCode) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/scans`, qrCode);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getScans(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/scans${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_SCANS, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}