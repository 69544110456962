// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '../components/Alert.js';

// UI components
import GoogleAuthButton from '../components/GoogleAuthButton';

// actions
import { toggleLoading } from '../actions/loading/index';
import { login } from '../actions/authentication/index';

class LoginModal extends Component {
    state = {
        isLoading: false,
        email: '',
        password: '',
    };

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    setValue(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    async login() {
        if (!this.state.email || !this.state.password)
            return window.alert('Please complete all required fields');

        this.setState({ isLoading: true });

        const authInfo = {
            email: this.state.email,
            password: this.state.password,
        };

        const user = await this.props.login(authInfo);
        if (!user) {
            window.alert('Invalid email or password');
            return this.setState({ isLoading: false });
        } else {
            this.setState({ isLoading: false });
            return this.props.onClose();
        }
    }

    render() {
        const { isOpen = false, error, onClose } = this.props;
        const { alertIsOpen, isLoading } = this.state;

        return (
            <span>
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                {/* login modal */}
                <div>
                    <Dialog
                        onClose={() => {
                            return;
                        }}
                        open={isOpen}
                        aria-labelledby="form-dialog-title"
                    >
                        <div>
                            <DialogContent>
                                <div className="flex flex-space-between flex-center-y">
                                    <h4 className="mt10 mb10 text-purpleB90">
                                        {isLoading ? 'Loading...' : 'Log In'}
                                    </h4>
                                </div>
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    disabled={isLoading}
                                    placeholder="john@yardengarden.com"
                                    onChange={(e) => this.setValue(e)}
                                />
                                <label>Password</label>
                                <input
                                    minLength="6"
                                    type="password"
                                    name="password"
                                    placeholder="******"
                                    disabled={isLoading}
                                    onChange={(e) => this.setValue(e)}
                                />
                                <div className="flex flex-center-y flex-space-between">
                                    <div>
                                        <a
                                            href="/reset-password"
                                            className="text-small"
                                        >
                                            Forgot your password?
                                        </a>
                                    </div>
                                    <div className="pt15 pb15 float-right">
                                        <div>
                                            <button
                                                className="small btn3"
                                                disabled={isLoading}
                                                onClick={() => this.login()}
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="pb15">
                                    <hr />
                                </div>
                                <div className="flex flex-center pb25">
                                    <GoogleAuthButton
                                        onSuccess={() => onClose()}
                                    />
                                </div>
                            </DialogContent>
                        </div>
                    </Dialog>
                </div>
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLoading,
            login,
        },
        dispatch,
    );
}

LoginModal = connect(mapStateToProps, mapDispatchToProps)(LoginModal);

export default LoginModal;
