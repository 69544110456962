import Compressor from 'compressorjs';

export function compress(file, cb) {
    new Compressor(file, {
        quality: 0.6,
        success(result) {
            var originalFileSize = (file.size / (1024*1024)).toFixed(2);
            var newFileSize = (result.size / (1024*1024)).toFixed(2);
            console.log('ORIGINAL FILE SIZE')
            console.log(`${originalFileSize} MB`);

            console.log('NEW FILE SIZE')
            console.log(`${newFileSize} MB`);
            return cb(result);
        },
        error(err) {
          console.log(err.message);
        },
    });
}