// libraries
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Alert from '../components/Alert.js';
import LoaderModal from '../components/LoaderModal.js';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';

// actions
import { sendEmail } from '../actions/email/index';
import { throwError } from '../actions/error/index';
import { toggleLoading } from '../actions/loading/index';
import { getUsers } from '../actions/users/index';
import { getUser, createUser } from '../actions/user/index';

// helpers
import { formatNumber } from '../formatting/phone';
import logInfo from '../helpers/logInfo';

// styling
import '../css/home.css';

class Invitation extends Component {
    state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        primary: '',
        secondary: '',
        onboardingAgreement: false,
    };

    componentDidMount() {
        const urlParams = Object.fromEntries(
            new URLSearchParams(window.location.search),
        );

        if (urlParams.primary) {
            this.setState({
                primary: urlParams.primary,
            });
        }

        if (urlParams.secondary) {
            this.setState({
                secondary: urlParams.secondary,
            });
        }

        logInfo('Invitation page loaded');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async finish() {
        if (
            !this.state.firstName ||
            !this.state.lastName ||
            !this.state.phoneNumber ||
            !this.state.password ||
            !this.state.confirmPassword
        ) {
            window.alert('Please complete all required fields');
        } else if (this.state.password !== this.state.confirmPassword) {
            window.alert('Password fields do not match');
        } else if (this.state.password.length < 6) {
            window.alert('Password must be at least 6 characters');
        } else {
            // show loading indicator
            this.props.toggleLoading(true);

            // lookup new user email to avoid duplicate accounts
            let user = await this.props.getUsers(
                `email=${this.state.secondary}`,
            );
            if (user.data.length > 0)
                return window.alert('Account already exists');

            // get primary user
            const primary = await this.props.getUser(this.state.primary, true);

            let newUser = {
                user: {
                    type: 'customer',
                    primary: this.state.primary,
                    first_name: this.state.firstName.trim(),
                    last_name: this.state.lastName.trim(),
                    email: this.state.secondary,
                    phone_number: this.state.phoneNumber.replace(/\D/g, ''),
                    address: primary.data.address,
                    city: primary.data.city,
                    state: primary.data.state,
                    zip_code: primary.data.zip_code,
                    county: primary.data.county,
                    geolocation: primary.data.geolocation,
                    garden_info: {},
                },
                password: this.state.password,
            };

            if (primary.data.unit) newUser.user.unit = primary.data.unit;

            // create user
            await this.props.createUser(newUser);

            // hide loading indicator
            this.props.toggleLoading(false);

            // redirect user to success page
            window.location.href = '/invited';
        }
    }

    render() {
        const { loading, error } = this.props;
        const {
            password,
            confirmPassword,
            onboardingAgreement,
            firstName,
            lastName,
            phoneNumber,
            alertIsOpen,
            secondary,
        } = this.state;

        return (
            <div>
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                <LoaderModal isOpen={loading.isLoading} />

                <Grid container spacing={2}>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={10} sm={8} md={8} lg={8} xl={8}>
                        <div>
                            <h2
                                style={{ marginTop: 16, marginBottom: 16 }}
                                className="text-purpleB90"
                            >
                                Accept Invite?
                            </h2>
                            <div className="mb25 bg-green0">
                                <Divider />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={10} sm={4} md={4} lg={4} xl={8}>
                        <p>
                            To accept the invite, confirm account details and
                            set a password. Your password must be at least 6
                            characters in length.
                        </p>
                    </Grid>
                    <Hidden only={['xl', 'lg', 'md', 'sm']}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={1}></Grid>
                    </Hidden>
                    <Grid item xs={10} sm={4} md={4} lg={4} xl={8}>
                        <form>
                            <label className="mt10">Email*</label>
                            <p className="m0 text-small">{secondary}</p>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={6} xs={6}>
                                    <label className="mt10">First Name*</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        placeholder="John"
                                        disabled={loading.isLoading}
                                        value={firstName}
                                        onChange={(e) => this.setValue(e)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={6} xs={6}>
                                    <label className="mt10">Last Name*</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Doe"
                                        disabled={loading.isLoading}
                                        value={lastName}
                                        onChange={(e) => this.setValue(e)}
                                    />
                                </Grid>
                            </Grid>
                            <label className="mt10">Phone Number*</label>
                            <input
                                name="phoneNumber"
                                placeholder="(123) 456-7890"
                                disabled={loading.isLoading}
                                value={formatNumber(phoneNumber)}
                                onChange={(e) => this.setValue(e)}
                            />
                            <label className="mt10">Password*</label>
                            <input
                                minLength="6"
                                type="password"
                                name="password"
                                placeholder="******"
                                disabled={loading.isLoading}
                                value={password}
                                onChange={(e) => this.setValue(e)}
                            />
                            <label className="mt10">Confirm Password*</label>
                            <input
                                minLength="6"
                                type="password"
                                name="confirmPassword"
                                placeholder="******"
                                disabled={loading.isLoading}
                                value={confirmPassword}
                                onChange={(e) => this.setValue(e)}
                            />
                            <div className="pt15">
                                <div>
                                    <label>
                                        I agree to the{' '}
                                        <a
                                            href="/terms-of-service"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            href="/privacy-policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                        *
                                    </label>
                                    <Checkbox
                                        disabled={loading.isLoading}
                                        checked={onboardingAgreement}
                                        onChange={() =>
                                            this.setState({
                                                onboardingAgreement:
                                                    !onboardingAgreement,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </form>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 32 }}>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={10} sm={8} md={8} lg={8} xl={8}>
                        <div className="float-right">
                            <button
                                className="flex flex-center-y"
                                onClick={() => this.finish()}
                            >
                                <Icon>done</Icon>{' '}
                                <span style={{ marginLeft: 8 }}>Finish</span>
                            </button>
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendEmail,
            throwError,
            toggleLoading,
            getUsers,
            getUser,
            createUser,
        },
        dispatch,
    );
}

Invitation = connect(mapStateToProps, mapDispatchToProps)(Invitation);

export default Invitation;
