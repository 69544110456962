// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// styles
import '../css/membership.css';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Icon from '@material-ui/core/Icon';
import Link from './UI/Link';
import LoaderModal from './LoaderModal';

// helpers
import capitalize from '../helpers/capitalize';

// vars
import { ORDER_PENDING } from '../vars/types';
import {
    PHONE_NUMBER,
    APPOINTMENT_URL,
} from '../vars/constants';

const ACTIVE_MEMBERSHIP = 'active membership';
const CANCELLED_MEMBERSHIP = 'cancelled membership';
const NO_MEMBERSHIP = 'no membership';
const CANCEL_MEMBERSHIP_CONFIRMATION = 'cancel membership confirmation';

let Membership = (props) => {
    const {
        deleteSubscription,
        getSubscription,
        getOrders,
        updateOrder,
        updateUser,
        sendEmail,
        sendSms,
        subscription,
        user,
    } = props;
    const [content, setContent] = useState(NO_MEMBERSHIP);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const currentPlan =
        user.data.garden_info && user.data.garden_info.maintenance_plan
            ? user.data.garden_info.maintenance_plan
            : 'none';

    useEffect(() => {
        let contentType = null;
        if (subscription.id) {
            if (
                subscription.status === 'active' ||
                subscription.status === 'trialing'
            ) {
                contentType = ACTIVE_MEMBERSHIP;
            }
            if (subscription.status === 'canceled') {
                contentType = CANCELLED_MEMBERSHIP;
            }
        } else {
            contentType = NO_MEMBERSHIP;
        }

        setContent(contentType);
    }, [subscription]);

    async function cancelMembership() {
        setLoadingModalIsOpen(true);

        await deleteSubscription(subscription.id);

        await getSubscription(subscription.id);

        const orders = await getOrders(`status=${ORDER_PENDING}`, true);
        const maintenanceOrder = orders.data.list.find(
            (order) => order.type === currentPlan,
        );

        if (maintenanceOrder) {
            await updateOrder(maintenanceOrder._id, {
                status: 'cancelled',
            });
        }

        const gardenInfo = {
            ...user.data.garden_info,
            ...{ maintenance_plan: 'none' },
        };

        const paymentInfo = {
            ...user.data.payment_info,
            ...{ plan_id: null },
        };

        await updateUser(user.data._id, { gardenInfo, paymentInfo });

        const memberEmail = {
            email: user.data.email,
            subject: `Yarden - Your maintenance plan has been cancelled`,
            label: 'Membership Cancelled',
            body: `<p>Greetings from Yarden! Your maintenance plan has been cancelled. If you did not make these changes or you believe an unauthorized person has accessed your account, you should change your password as soon as possible or contact Yarden customer service.</p>`,
        };

        await sendEmail(memberEmail);

        if (maintenanceOrder.vendor) {
            const message = `Greetings from Yarden! The maintenance plan for ${capitalize(
                user.data.first_name + ' ' + user.data.last_name[0],
            )}. (${
                user.data.address
            }) has been cancelled, you will no longer see work orders for this customer in your Yarden dashboard. Any work orders you have already completed for this customer will be paid out in your next payroll deposit.`;
            const vendorEmail = {
                email: maintenanceOrder.vendor.email,
                subject: `Yarden - Member plan cancellation`,
                label: 'Membership Cancellation',
                body: `<p>${message}</p>`,
            };

            const vendorTextMessage = {
                from: PHONE_NUMBER,
                to: maintenanceOrder.vendor.phone_number,
                body: message,
            };

            await sendEmail(vendorEmail);
            await sendSms(vendorTextMessage);
        }

        setLoadingModalIsOpen(false);
        setContent(CANCELLED_MEMBERSHIP);
    }

    function renderContent() {
        switch (content) {
            case ACTIVE_MEMBERSHIP:
                return renderActiveMembership();
            case CANCELLED_MEMBERSHIP:
                return renderCancelledMembership();
            case NO_MEMBERSHIP:
                return renderNoMembership();
            case CANCEL_MEMBERSHIP_CONFIRMATION:
                return renderCancelMembershipConfirmation();
            default:
                return <></>;
        }
    }

    function renderActiveMembership() {
        return (
            <div>
                <div className="settings-card-info">
                    <div>
                        <label>Current Plan</label>
                        <h5
                            className="capitalize mb0"
                            style={{ color: 'var(--purpleB)' }}
                        >
                            {currentPlan}
                            {subscription.status === 'trialing' &&
                                ` (Free Trial)`}
                        </h5>
                    </div>
                    <div>
                        <label>Rate</label>
                        <small>
                            {currentPlan === 'none'
                                ? '$0.00'
                                : `$${subscription.plan.amount / 100}.00/${
                                      subscription.plan.interval
                                  }`}
                        </small>
                    </div>
                    <div>
                        <label>Current Billing Period</label>
                        <small>
                            {currentPlan === 'none'
                                ? 'n/a'
                                : `${moment(
                                      subscription.current_period_start * 1000,
                                  ).format('MM/DD/YYYY')} - ${moment(
                                      subscription.current_period_end * 1000,
                                  ).format('MM/DD/YYYY')}`}
                        </small>
                    </div>
                    <div>
                        <Link
                            onClick={() =>
                                setContent(CANCEL_MEMBERSHIP_CONFIRMATION)
                            }
                        >
                            Cancel Membership
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    function renderCancelMembershipConfirmation() {
        return (
            <div>
                <h5 className="mt15">Cancel membership plan?</h5>
                <small className="mt15">
                    By cancelling your membership, Yarden will cancel all your
                    scheduled gardening services. You will not be prorated or
                    reimbursed for any membership payment that was already
                    charged. Once this action has been confirmed, it cannot be
                    undone.
                </small>
                <div className="mt15">
                    <div className="flex flex-space-between">
                        <button
                            className="btn3 small flex flex-center-y"
                            onClick={() => setContent(ACTIVE_MEMBERSHIP)}
                        >
                            <Icon>arrow_back</Icon>{' '}
                            <span style={{ marginLeft: 8 }}>Back</span>
                        </button>
                        <button
                            className="purple small flex flex-center-y"
                            onClick={() => cancelMembership()}
                        >
                            <Icon>done</Icon>{' '}
                            <span style={{ marginLeft: 8 }}>Confirm</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    function renderCancelledMembership() {
        return (
            <div>
                <h5 className="mt15">Your membership was cancelled</h5>
                <small>
                    To re-activate your garden club membership, you will need to
                    schedule an appointment with one of our gardeners to
                    evaluate the current state of your garden.
                </small>
                <button
                    className="btn3 small mr15 flex flex-center-y"
                    onClick={() => (window.location.href = APPOINTMENT_URL)}
                >
                    <Icon>calendar_month</Icon>{' '}
                    <span style={{ marginLeft: 8 }}>Schedule Appointment</span>
                </button>
            </div>
        );
    }

    function renderNoMembership() {
        return (
            <div>
                <h5 className="mt15">No membership was found</h5>
                <small>
                    If you selected a plan during the quote approval process,
                    your garden club membership info will appear here once your
                    garden has been installed. Otherwise, if you would like to
                    enroll in Yarden's garden club membership, please{' '}
                    <a href={APPOINTMENT_URL}>click here</a>.
                </small>
            </div>
        );
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* content */}
            <div className="flex-space-between flex-center">
                <h4>Membership</h4>
                {content === ACTIVE_MEMBERSHIP && (
                    <Link
                        onClick={() =>
                            (window.location.href = `${window.location.origin}/membership-details`)
                        }
                    >
                        Details
                    </Link>
                )}
            </div>
            <hr />
            {renderContent()}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        subscription: state.subscription,
    };
}

Membership = connect(mapStateToProps, mapDispatchToProps)(Membership);

export default Membership;
