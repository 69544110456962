import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createSpecialRequest(specialRequest) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/special-requests`,
                specialRequest,
                { headers: { authorization: getAuthToken() } },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getSpecialRequest(query) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/special-requests${q}`, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
