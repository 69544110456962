// libraries
import React, { useEffect } from 'react';

// styles
import '../css/products-and-services.css';

// UI components
import '../components/HorizontalLogo.js';
import HorizontalLogo from '../components/HorizontalLogo.js';
import HowItWorks from '../components/HowItWorks.js';

// helpers
import logInfo from '../helpers/logInfo';

// vars
import { APPOINTMENT_URL } from '../vars/constants';

// images
const customerDashboard =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/customer_dashboard.png';
const careAndMaintenance =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/cleaning.png';
const customGardenBed =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/custom_garden_bed.png';
const gardenMap =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/garden_map_screen.png';
const hose =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/hose.png';
const largeGardenBed =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/4x8garden_bed.png';
const lumber =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/lumber.png';
const mediumGardenBed =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/4x4garden_bed.png';
const productAndServicesHero =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/product_and_services_hero.png';
const smallGardenBed =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/2x4garden_bed.png';
const soil =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/soil.png';
const appstore =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/appstore.png';
const harvest =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/harvest.png';
const plantselection =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/plantselection.png';

const GardenBeds = () => {
    useEffect(() => {
        logInfo('Products and Services page loaded');
    }, []);

    return (
        <div id="products-and-services">
            {/* hero section */}
            <section className="hero-section">
                <div className="auto-grid-large">
                    <div className="hero-image">
                        <img
                            width="100%"
                            style={{
                                marginBlock: '-20%',
                                transform: 'scale(1.2)',
                                mixBlendMode: 'multiply',
                                pointerEvents: 'none',
                            }}
                            src={productAndServicesHero}
                            alt="garden bed"
                        />
                    </div>
                    <div>
                        <label>Products & Services</label>
                        <h1>Your Guide to Yarden</h1>
                        <p>
                            Learn about our products and services. Dive deep
                            into the details of our offerings before taking the
                            first step in the journey of your growth.{' '}
                        </p>
                        <a href={APPOINTMENT_URL}>
                            <button>Take the First Step</button>
                        </a>
                    </div>
                </div>
            </section>

            {/* introduction section */}
            <section id="introduction-section">
                <h2>Our Mission is Simple</h2>
                <p>
                    Our mission at Yarden is to make gardening simple for
                    everyone. We focus on growing healthy, organic produce
                    without any pesticides. We believe in clear pricing, with no
                    hidden costs. More than anything, we're here to help you
                    live a healthier life, as you enjoy the rewards of your own
                    garden.
                </p>
            </section>

            {/* how it works section */}
            <HowItWorks />

            {/* materials */}
            <section id="materials-section">
                <h2>Materials</h2>
                <p>
                    We care about nature and you. So when we build your garden
                    beds, we choose materials carefully. We look for durability,
                    good looks, and eco-friendliness. That way, you get a great
                    garden that's also good for our planet.
                </p>
                <div className="auto-grid-large">
                    <div>
                        <img
                            className="size-img"
                            src={careAndMaintenance}
                            alt="shovel"
                        />
                        <h5>Care and Maintenance</h5>
                        <small>
                            Our garden beds are made to last. For wooden beds,
                            cleaning with a soft brush and soapy water can help
                            keep them looking good. Check metal parts now and
                            then for any wear or damage. A bit of care can make
                            them last even longer.
                        </small>
                    </div>
                    <div>
                        <img
                            className="size-img"
                            src={lumber}
                            alt="pile of boards"
                        />
                        <h5>Wood</h5>
                        <small>
                            We make garden beds that work well and look good in
                            your backyard. We use top-quality wood that's
                            sustainably sourced because it's strong, attractive,
                            and eco-friendly. Our wood stands up to the weather
                            and resists rot, making your garden bed last longer.
                        </small>
                    </div>
                    <div>
                        <img
                            className="size-img"
                            src={soil}
                            alt="wheelbarrow with soil"
                        />
                        <h5>soil</h5>
                        <small>
                            A garden bed needs good soil for strong, healthy
                            plants. We supply fertile soil that's just right for
                            plant growth. Our soil mix has organic matter, and
                            rich with nutrients and mycorrhizal fungi to help
                            your plants do well.
                        </small>
                    </div>
                    <div>
                        <img
                            className="size-img"
                            src={hose}
                            alt="garden hose"
                        />
                        <h5>Irrigation System</h5>
                        <small>
                            The right watering schedule is the key to getting
                            the most out of your garden. All our garden beds
                            have an automated irrigation system. These systems
                            water evenly across the bed, helping all plants get
                            the water they need. We use sturdy materials in our
                            irrigation systems for long-lasting, worry-free use.
                        </small>
                    </div>
                </div>
            </section>

            {/* sizes section */}
            <section id="sizes-section">
                {/* small garden bed */}
                <div>
                    <h2>Simple Sizing</h2>
                    <p>
                        Knowing how big or small to make your garden isn't
                        always as easy as you might think it would be. We take
                        the guessing out of the equation by offering some garden
                        bed sizes that are proven to work.
                    </p>
                </div>
                <div id="small-beds" className="auto-grid-large">
                    <div>
                        <img
                            className="size-img"
                            style={{ transform: 'scale(0.9)' }}
                            src={smallGardenBed}
                            alt="small garden bed"
                        />
                    </div>
                    <div>
                        <h3>Small Garden Bed</h3>
                        <p>
                            This small, efficient garden bed fits many types of
                            plants. It's great for beginners or those with less
                            outdoor space. In the right conditions, it could{' '}
                            <b>produce at least 10 lbs</b> of food each month.
                        </p>
                        <h5>Benefits:</h5>
                        <ul>
                            <li>Efficient use of space</li>
                            <li>Lower water usage</li>
                            <li>High-yield potential for its size</li>
                            <li>
                                Ideal for growing herbs, leafy greens, and
                                compact vegetable varieties
                            </li>
                        </ul>
                        <br />
                        <div className="pricing-row">
                            <span>2ft &#xd7; 4ft &#xd7; 1ft Garden Bed</span>
                            <div>
                                <label>Materials & Labor Starting at:</label>
                                <h4>$350</h4>
                            </div>{' '}
                        </div>
                        <hr />
                    </div>
                </div>

                {/* medium garden bed */}
                <div id="medium-beds" className="auto-grid-large">
                    <div className="size-img-container">
                        <img
                            className="size-img"
                            style={{ transform: 'scale(1.1)' }}
                            src={mediumGardenBed}
                            alt="medium garden bed"
                        />
                    </div>
                    <div>
                        <h3>Medium Garden Bed</h3>
                        <p>
                            This bed is a good size for many gardeners, offering
                            more space for different plants. "Full-Service"
                            members can expect to <b>yield at least 20 lbs</b>{' '}
                            of food each month.
                        </p>
                        <h5>Benefits:</h5>
                        <ul>
                            <li>More room for plant diversity</li>
                            <li>
                                Efficient watering and nutrient distribution
                            </li>
                            <li>
                                Suitable for most vegetable crops, including
                                tomatoes, peppers, and root vegetables
                            </li>
                        </ul>
                        <br />
                        <div className="pricing-row">
                            <span>4ft &#xd7; 4ft &#xd7; 1ft Garden Bed</span>
                            <div>
                                <label>Materials & Labor Starting at</label>{' '}
                                <h4>$500</h4>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                {/* large beds */}
                <div id="large-beds" className="auto-grid-large">
                    <div className="size-img-container">
                        <img
                            style={{
                                transform: 'scale(1.1)',
                                transformOrigin: 'center',
                            }}
                            className="size-img"
                            width="100%"
                            src={largeGardenBed}
                            alt="large garden bed"
                        />
                    </div>
                    <div>
                        <h3>Large Garden Bed</h3>
                        <p>
                            Our biggest bed offers a lot of space for gardening
                            enthusiasts. "Full-Service" members can look forward
                            to a hefty <b>harvest of at least 40 lbs</b> of food
                            each month.
                        </p>
                        <h5>Benefits:</h5>
                        <ul>
                            <li>Maximized growing space</li>
                            <li>
                                Perfect for larger crops like corn, squash, or
                                for growing a large variety of plants
                            </li>
                            <li>
                                Increased soil depth allows for deeper rooting
                                plants
                            </li>
                        </ul>
                        <br />
                        <div className="pricing-row">
                            <span>4ft &#xd7; 8ft &#xd7; 1ft Garden Bed</span>
                            <div>
                                <label>Materials & Labor Starting at</label>{' '}
                                <h4>$700</h4>
                            </div>{' '}
                        </div>
                        <hr />
                    </div>
                </div>

                {/* custom beds */}
                <div id="custom-beds" className="auto-grid-large">
                    <div className="size-img-container">
                        <img
                            className="size-img"
                            style={{ transform: 'scale(1.2)' }}
                            src={customGardenBed}
                            alt="custom garden bed"
                        />
                    </div>
                    <div>
                        <h3>Custom Garden Beds</h3>
                        <p>
                            We make garden beds to fit any spot – a small
                            balcony, rooftop, or big backyard. With our
                            "Full-Service" plan, you can get the most out of
                            your space.
                        </p>
                        <div className="pricing-row">
                            <span>Custom Design</span>{' '}
                            <div>
                                <label>Contact Sales</label>
                                <h4>Custom Pricing</h4>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </section>

            {/* Garden Bed Pricing */}
            <section id="pricing-section">
                <label>Additional Pricing Details</label>

                <h2>Know Your Costs</h2>
                <p>
                    Our prices include our standard, eco-friendly wood. The
                    total cost might change due to fluctuations in the cost of
                    work and materials. Remember, the price might change based
                    on what you need. If you want a different size or material,
                    we'll create a special price for you. The final price could
                    change based on your choices, to make sure your garden bed
                    is just right for you.
                </p>
                <br />
                <br />
                <div className="auto-grid-large">
                    {/* standard parts and materials */}
                    <div>
                        <h5>Standard Parts & Materials</h5>
                        <p>
                            These items are required in order to guarantee
                            successful harvests.
                        </p>
                        <div>
                            <li className="pricing-row">
                                <span>soil</span>
                                <div>
                                    <label>Per 3 Cubic Ft</label>
                                    <h5>$10</h5>
                                </div>
                            </li>
                            <hr />
                            <li className="pricing-row">
                                <span>Fertilizer</span>
                                <div>
                                    <label>Per Bag</label>
                                    <h5>$150</h5>
                                </div>
                            </li>
                            <hr />
                            <li className="pricing-row">
                                <span>Automatic Irrigation System</span>
                                <div>
                                    <label>Starting at</label>
                                    <h5>$90</h5>
                                </div>
                            </li>
                            <hr />
                            <li className="pricing-row">
                                <span>Mesh Underwire (Pest Protection)</span>
                                <div>
                                    <label>Starting at</label>
                                    <h5>$50</h5>
                                </div>
                            </li>
                            <hr />
                        </div>
                    </div>

                    {/* add-ons */}
                    <div>
                        <h5>Add-ons</h5>
                        <p>
                            We also offer various add-ons & upgrades to enhance
                            your gardening experience.
                        </p>
                        <div>
                            <div className="pricing-row">
                                <span>Trellis</span>
                                <div>
                                    <label>Starting at</label>
                                    <h5>$50</h5>
                                </div>
                            </div>
                            <hr />
                            <div className="pricing-row">
                                <span>Tomato Cage</span>
                                <div>
                                    <label>Starting at</label>
                                    <h5>$40</h5>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="pricing-row">
                                    <span>Pest Proof Enclosure</span>
                                    <div>
                                        <label>Contact Sales</label>
                                        <h5>Custom Pricing</h5>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="pricing-row">
                                    <span>Premium Materials Upgrade</span>
                                    <div>
                                        <label>Contact Sales</label>
                                        <h5>Custom Pricing</h5>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div>
                <br />
            </section>

            {/* mobile app section */}
            <section id="mobile-app-section">
                <div className="auto-grid-large">
                    <div>
                        <label>★★★★★ 5 Stars on the App Store</label>
                        <h2>Our Mobile App</h2>
                        <p>
                            Discover the future of home gardening with our
                            intuitive and easy-to-use Yarden Mobile App. We've
                            harnessed the power of technology to make your
                            gardening journey even more fruitful and enjoyable.
                        </p>
                    </div>
                    <div className="mobile-section-img">
                        <img
                            src={customerDashboard}
                            height="420px"
                            style={{
                                filter: 'drop-shadow(var(--boxShadowDown))',
                            }}
                            alt="two squash"
                        />
                    </div>
                </div>
                <div className="mobile-section-list">
                    <div>
                        <h3>Benefits of the Yarden App:</h3>
                        <p>
                            Our mobile app keeps you connected to your garden in
                            a way that has never been seen before! It tracks
                            plant growth, watering schedule, and tells you when
                            your plants are ready to harvest. Plus, it gives you
                            a way to share detailed plant information between
                            you and your gardener. It's like having a gardening
                            expert on call!
                        </p>
                    </div>
                    <div className="auto-grid-large">
                        <div className="mobile-section-img">
                            <img
                                src={plantselection}
                                height="420px"
                                style={{
                                    filter: 'drop-shadow(var(--boxShadowDown))',
                                }}
                                alt="Garden Setup"
                            />{' '}
                            <img
                                src={gardenMap}
                                height="420px"
                                style={{
                                    filter: 'drop-shadow(var(--boxShadowDown))',
                                }}
                                alt="Garden Map"
                            />
                        </div>
                        <div>
                            <h3>In-App Experience:</h3>
                            <p>
                                The Garden Map feature is like a bird's eye view
                                of your garden. You can follow your garden's
                                growth, keep track of your harvests'{' '}
                                <b>cash value</b>, and plan your meals with
                                ease. Plus, it lets you see the location your
                                plants in your garden bed.
                            </p>
                        </div>
                    </div>
                    <div className="auto-grid-large">
                        <div>
                            <h3>Download Now:</h3>
                            <div>
                                <p>
                                    Discover the joy of gardening with our
                                    mobile app. It's a gardener in your pocket!
                                    Ready to level up your gardening journey?
                                    Use the link below to get started:
                                </p>
                            </div>
                            <div className="step1-qr-code">
                                <div style={{ height: 'var(--unit7)' }}>
                                    <a
                                        href="https://apps.apple.com/us/app/yarden/id1626672979"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            style={{ height: 'var(--unit7)' }}
                                            src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/website-homepage.png"
                                            alt="qr code"
                                        />
                                    </a>
                                    <span className="top-left-corner"></span>
                                    <span className="top-right-corner"></span>
                                    <span className="bottom-left-corner"></span>
                                    <span className="bottom-right-corner"></span>
                                </div>
                            </div>

                            <div style={{ width: '50%', margin: '0 auto' }}>
                                <HorizontalLogo />
                            </div>
                        </div>
                        <div className="mobile-section-img">
                            <img
                                width="50%"
                                height="auto"
                                src={appstore}
                                alt="mobile app preview"
                                style={{
                                    filter: 'drop-shadow(var(--boxShadowDown))',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* cta section */}
            <section id="cta-section" className="auto-grid-large">
                <div>
                    <img width="100%" src={harvest} alt="garden bed" />
                </div>
                <div>
                    <label>Begin Your Journey</label>
                    <h1>Let's Start Growing Together</h1>
                    <p>
                        Excited to turn your gardening dreams into reality?
                        We're here to make it as simple as possible.
                    </p>
                    <a href={APPOINTMENT_URL}>
                        <button>Start Your Garden</button>
                    </a>
                </div>
            </section>
        </div>
    );
};

export default GardenBeds;
