// libs
import React, { Component } from 'react';

// UI components
import Grid from '@material-ui/core/Grid';

// helpers
import logInfo from '../helpers/logInfo';

// styling
import '../css/privacy-policy.css';

class PrivacyPolicy extends Component {
    componentDidMount() {
        logInfo('Privacy Policy page loaded');
    }

    render() {
        return (
            <span>
                {/* privacy policy start */}
                <Grid container spacing={2}>
                    <Grid item md={1} sm={1} xs={1}></Grid>
                    <Grid item md={10} sm={10} xs={10}>
                        <div className="section1-container">
                            <h3>Privacy Policy</h3>
                            <p>
                                This policy covers how YARDEN GARDEN INC, a
                                corporation ("Yarden," "we," "us," or "our")
                                treats personal information that it collects and
                                receives on www.yardengarden.com. We are not in
                                the business of selling information about you:
                                we use information shared with us for the sole
                                purpose of running and improving our services.
                                This notice tells you what information we
                                collect, steps we take to protect and secure it,
                                how we use and share information, and finally,
                                how you can contact us with question or
                                concerns.
                            </p>
                            <h4 id="information-we-gather">
                                Information we gather
                            </h4>
                            <p>
                                Yarden collects personal information when you
                                register with us, use Yarden products or
                                services or post content on a Yarden web site or
                                blog. This information is limited to your email
                                address, and any information you post on your
                                site. Your password is stored securely using a
                                salted, variable work factor, one-way hash
                                function. Yarden automatically receives and
                                records information on our server logs from your
                                browser, including your IP address, cookies, and
                                the pages you request. We also collect other
                                information as part of our analytics services,
                                in order to improve the service. However, we do
                                not link such information to any personally
                                identifiable information you submit while on our
                                site. Yarden may collect billing information if
                                you sign up for a paid service, which may
                                include your name, address, telephone number,
                                credit card details, and other information that
                                is necessary in order to process the
                                transaction. Under no circumstance do we store
                                any of your sensitive billing details. Yarden
                                uses information for the following general
                                purposes: products and services provision,
                                identification and authentication, services
                                improvement, support, contact, research, and
                                anonymous reporting.
                            </p>
                            <h4 id="google-analytics">Google Analytics</h4>
                            <p>
                                This website uses Google Analytics, a web
                                analytics service provided by Google, Inc.
                                ("Google"). Google Analytics uses "cookies",
                                which are text files placed on your computer, to
                                help the website analyze how users use the site.
                                The information generated by the cookie about
                                your use of the website (including your IP
                                address) will be transmitted to and stored by
                                Google on servers in the United States. Google
                                will use this information for the purpose of
                                evaluating your use of the website, compiling
                                reports on website activity for website
                                operators and providing other services relating
                                to website activity and internet usage. Google
                                may also transfer this information to third
                                parties where required to do so by law, or where
                                such third parties process the information on
                                Google's behalf. Google will not associate your
                                IP address with any other data held by Google.
                                You may refuse the use of cookies by selecting
                                the appropriate settings on your browser,
                                however please note that if you do this you may
                                not be able to use the full functionality of
                                this website. By using this website, you consent
                                to the processing of data about you by Google in
                                the manner and for the purposes set out above.
                            </p>
                            <h4 id="google-sign-in">Google Sign-In</h4>
                            <p>
                                When you sign in to our website using Google
                                Auth, we collect certain information from your
                                Google account. We do not collect sensitive
                                information such as passwords or payment
                                information through Google Auth. Information
                                collected may include:
                            </p>
                            <ul>
                                <li>Your name</li>
                                <li>Your email</li>
                                <li>Your profile picture</li>
                                <li>
                                    Other information that you have chosen to
                                    make public on your Google profile.
                                </li>
                            </ul>
                            <p>
                                We use the information we collect through Google
                                Auth for the following purposes:
                            </p>
                            <ul>
                                <li>
                                    To authenticate your identity and allow you
                                    to access our services
                                </li>
                                <li>
                                    To personalize your experience on our
                                    website
                                </li>
                                <li>
                                    To communicate with you about your account
                                    and our services
                                </li>
                                <li>To improve our website and services</li>
                                <li>To comply with legal obligations</li>
                            </ul>
                            <p>
                                We do not sell, trade, or otherwise transfer
                                your personally identifiable information to
                                outside parties.
                            </p>
                            <p>
                                We implement a variety of security measures to
                                maintain the safety of your personal information
                                when you sign in using Google Auth. However, no
                                method of transmission over the internet or
                                electronic storage is 100% secure, and we cannot
                                guarantee its absolute security.
                            </p>
                            <h4 id="billing-information">
                                Billing Information
                            </h4>
                            <p>
                                Should you sign up for a Yarden account and
                                order garden service, we require your billing
                                information in order to process the transaction.
                                We will retain the last 4 digits of your credit
                                card number and credit card type, so that you
                                may identify that card used in the future. We do
                                not store any other billing information, as this
                                information is discarded after your transaction
                                has been processed. If your membership is
                                auto-renewing, a recurring membership will be
                                created at our payment gateway, Stripe.com.
                                Stripe.com will store your billing information
                                on their secure payment gateway for as long as
                                necessary to carry out the terms of your
                                membership. Should you cancel your membership,
                                your billing information will be immediately
                                removed by Stripe.com. All transactions are
                                processed over an industry standard SSL/TLS
                                connection, with a minimum of 128-bit
                                encryption.
                            </p>
                            <h4 id="ip-address">IP Address</h4>
                            <p>
                                Yarden occasionally obtains IP addresses from
                                users depending upon how you access our web
                                sites. IP addresses, browser and session
                                information may be used for various purposes,
                                including to: Diagnose and prevent service or
                                technology problems reported by our users or
                                engineers that are associated with the IP
                                addresses controlled by a specific web company
                                or ISP. Estimate the total number of users
                                visiting Yarden's web sites from specific
                                geographical regions. Help determine which users
                                have access privileges to certain content,
                                services or resources that we offer. Monitor and
                                prevent fraud and abuse.
                            </p>
                            <h4 id="cookies">Cookies</h4>
                            <p>
                                A cookie is a small amount of data, which often
                                includes an anonymous unique identifier that is
                                sent to your browser from a web site's computers
                                and stored on your computer's hard drive. If you
                                reject all cookies, you may not be able to use
                                Yarden services or products that require you to
                                "sign in" and you may not be able to take full
                                advantage of all offerings. You can configure
                                your browser to accept all cookies, reject all
                                cookies or notify you when a cookie is set.
                                Yarden uses its own cookies for a number of
                                purposes, including to: Require you to re-enter
                                your password after a certain period of time has
                                elapsed to protect you against others
                                accidentally accessing your account contents.
                                Keep track of preferences you specify while you
                                are using the Yarden Sites, Products and
                                Services. Estimate and report our total audience
                                size and traffic. Conduct research to improve
                                the content and services provided on the Yarden
                                Sites, Products and Services.
                            </p>
                            <h4 id="information-use-sharing-disclosure">
                                Information Use, Sharing and Disclosure
                            </h4>
                            <p>
                                Yarden does not share personal information we
                                learn about you from your use of the Yarden web
                                sites, products and services with others except
                                as described in this policy. We do not share,
                                sell, rent or trade personally identifiable
                                information with third parties for their
                                promotional purposes. Yarden may use or share
                                your personal information where it is necessary
                                to complete a transaction, to operate or improve
                                the Yarden products and services, or to do
                                something that you have asked us to do. We use
                                other third parties such as a credit card
                                processing company to bill you for goods and
                                services. These third parties are prohibited
                                from using your personally identifiable
                                information for promotional purposes. From time
                                to time, Yarden or a partner may sponsor a
                                promotion, sweepstake or contest on Yarden. You
                                may be asked to provide personal information
                                including name, email address or home address or
                                to answer questions in order to participate.
                                This information will be used strictly for the
                                purpose of notifying and arranging delivery of
                                promotional items to the person selected. When
                                you register for Yarden, we collect
                                user-submitted account information such as
                                username and email address to identify users and
                                send notifications related to the use of the
                                service. When you post content on a Yarden web
                                site or blog, you have the ability to post
                                content which may include personally
                                identifiable information about yourself. If you
                                post personal information online that is
                                accessible to the public, you may receive
                                unsolicited messages from other parties in
                                return. You should be aware that any information
                                you provide in these areas may be read,
                                collected, and used by others who access them.
                            </p>
                            <h4 id="changes-to-this-privacy-policy">
                                Changes to this Privacy Policy
                            </h4>
                            <p>
                                Yarden may revise, modify or update this policy.
                                We will notify you about significant changes in
                                the way we treat personal information by sending
                                a notice to the primary email address specified
                                in your particular Yarden account or by placing
                                a prominent notice on our site prior to the
                                change becoming effective.
                            </p>
                            <h4 id="confidentiality-and-security">
                                Confidentiality and Security
                            </h4>
                            <p>
                                No data transmissions over the Internet can be
                                guaranteed to be 100% secure. Consequently, we
                                cannot ensure or warrant the security of any
                                information you transmit to us and you do so at
                                your own risk. Once we receive your
                                transmission, we make efforts to ensure security
                                on our systems. We use physical, electronic, and
                                procedural safeguards to protect personal
                                information about you. Your account information
                                is password-protected. Additionally, your
                                account's password is stored using a salted,
                                variable work factor, one-way hash algorithm. In
                                certain areas, Yarden uses industry-standard
                                SSL-encryption to protect data transmissions.
                                However, please note that this is not a
                                guarantee that such information may not be
                                accessed, disclosed, altered or destroyed by
                                breach of such firewalls and secure server
                                software. If Yarden learns of a security systems
                                breach we may attempt to notify you
                                electronically so that you can take appropriate
                                protective steps. By using Yarden or providing
                                personal information to us you agree that we can
                                communicate with you electronically regarding
                                security, privacy and administrative issues
                                relating to your use of those sites, products
                                and services. We may post a notice on our
                                applicable web sites if a security breach
                                occurs. If this happens, you will need a web
                                browser enabling you to view the applicable web
                                sites. We may also send an email to you at the
                                email address you have provided to us in these
                                circumstances. Depending on where you live, you
                                may have a legal right to receive notice of a
                                security breach in writing.
                            </p>
                            <h4 id="changing-your-prefernces-and-personal-information">
                                Changing your Preferences and Personal
                                Information
                            </h4>
                            <p>
                                You can edit your account information with
                                Yarden at any time. You can delete your
                                applicable account by visiting the applicable
                                account deletion page; we will retain your
                                information for as long as your account is
                                active or as needed to provide you services,
                                however, please note that some personal
                                information, primarily your contact information,
                                may remain in our records to the extent
                                necessary to protect our legal interests or
                                document compliance with regulatory
                                requirements. If your personally identifiable
                                information changes, or if you no longer desire
                                our service, you may correct, update, or delete
                                such information by making the change on our
                                user home page, or by emailing our Member
                                Support at info@yardengarden.com.
                            </p>
                            <h4>Changes to this Privacy Policy</h4>
                            <p>
                                If you have a privacy concern regarding Yarden,
                                or this policy, please send us an email or write
                                to info@yardengarden.com
                            </p>
                            <p>Last updated 7/1/2024</p>
                        </div>
                    </Grid>
                    <Grid item md={1} sm={1} xs={1}></Grid>
                </Grid>
                {/* privacy policy end */}
            </span>
        );
    }
}

export default PrivacyPolicy;
