// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// actions
import { createScan } from '../actions/scan/index';
import { getIP } from '../actions/ip/index';
import { sendAlert } from '../actions/alert/index';

// helpers
import navigate from '../helpers/navigate';
import logInfo from '../helpers/logInfo';

// styling
import Branding from '../styling/Branding';

class QRCode extends Component {
    async componentDidMount() {
        const urlParams = Object.fromEntries(
            new URLSearchParams(window.location.search),
        );

        // if referral id {...}
        if (urlParams.refId) {
            // get ip address
            const ip = await this.props.getIP();

            // create scan
            const scan = await this.props.createScan({
                qr_code: urlParams.refId,
                location: ip.data,
            });

            const location = `${scan.data.location.city || 'unknown city'}, ${
                scan.data.location.region || 'unknown state'
            } ${scan.data.location.postal_code || 'unknown zip code'}`;

            // send alert to HQ
            await this.props.sendAlert({
                channel: 'qr-code-scans',
                text: `*New QR Scan!* \nSource: "${scan.data.qr_code.source}"\nVariant: "${scan.data.qr_code.variant}"\nLocation: "${location}"`,
            });

            logInfo('QR Code page loaded');
        }

        setTimeout(() => {
            navigate('/', true);
        }, 3000);
    }

    render() {
        return (
            <div className="flex flex-center full-view">
                <div className="flex flex-center-y">
                    <img
                        src={Branding.trademark}
                        width="50"
                        alt="logo"
                        className="mr5"
                    />
                    <h1>Loading...</h1>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createScan,
            getIP,
            sendAlert,
        },
        dispatch,
    );
}

QRCode = connect(mapStateToProps, mapDispatchToProps)(QRCode);

export default QRCode;
