// libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Navbar from '../components/Navbar';
import LoaderModal from '../components/LoaderModal';
import GeneralMessage from '../components/GeneralMessage';

// helpers
import getDeviceType from '../helpers/getDeviceType.js';
import logInfo from '../helpers/logInfo';

// types
import { CUSTOMER } from '../vars/types';

// styles
import '../css/maintenance-results.css';

const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
);

let MaintenanceResults = ({ getReport, getOrder, getAnswers, user }) => {
    const [error, setError] = useState(false);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [note, setNote] = useState(null);
    const [rows, setRows] = useState([]);
    const currentDeviceType = getDeviceType();

    useEffect(() => {
        const initData = async () => {
            if (urlParams.order) {
                try {
                    const maintenanceOrder = await getOrder(urlParams.order);
                    const maintenanceReport = await getReport(urlParams.order);
                    if (maintenanceOrder.data) {
                        setSelectedOrder(maintenanceOrder.data);

                        const imagesPerRow = 1;
                        const newRows = [];
                        for (
                            let i = 0;
                            i < maintenanceOrder.data.images.length;
                            i += imagesPerRow
                        ) {
                            newRows.push(
                                maintenanceOrder.data.images.slice(
                                    i,
                                    i + imagesPerRow,
                                ),
                            );
                        }
                        setRows(newRows);

                        const maintenanceAnswers = await getAnswers(
                            `report=${maintenanceReport.data._id}`,
                        );

                        if (maintenanceAnswers.data) {
                            const noteAnswer = maintenanceAnswers.data.find(
                                (answer) => answer.question.placement === 6,
                            );

                            setNote(noteAnswer);
                            setLoadingModalIsOpen(false);

                            logInfo('Maintenance Results page loaded', {
                                user: user.data,
                            });
                        }
                    }
                } catch (err) {
                    setError(true);
                }
            } else {
                setInvalidUrl(true);
            }

            setLoadingModalIsOpen(false);
        };

        initData();
    }, []);

    function renderMessage() {
        let message = '';
        let isSuccess = false;
        if (invalidUrl) {
            message = 'Invalid URL, please contact support to continue.';
        } else if (error) {
            message =
                'Uh oh! Something went wrong, we are working on a fix now.';
        }

        return (
            <GeneralMessage
                message={message}
                isSuccess={isSuccess}
                buttonText={`Continue to ${
                    user.data.type === CUSTOMER ? 'account' : 'dashboard'
                }`}
                buttonAction={() =>
                    (window.location.href = `${window.location.origin}/${
                        user.data.type === CUSTOMER ? 'account' : 'dashboard'
                    }`)
                }
            />
        );
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* page header */}
            <Navbar />

            {/* page view */}
            {!invalidUrl && selectedOrder && note && (
                <div className="pt75">
                    <div className="bg-gray1 pt25 pb25">
                        <div className="text-center mb25">
                            <h6>Note from gardener</h6>
                        </div>
                        <div className="flex flex-center-x">
                            <div>
                                <img
                                    className="round"
                                    src={selectedOrder.vendor.profile_image}
                                    height={75}
                                    width={75}
                                    alt="gardener"
                                />
                                <div className="text-center">
                                    <label className="capitalize">
                                        {selectedOrder.vendor.first_name}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-center-x mt25">
                        <div style={{ width: 350 }} className="mb50">
                            <label>
                                {moment(note.dt_created).format('MM/DD/YYYY')} @{' '}
                                {moment(note.dt_created).format('hh:mm A')}
                            </label>
                            <div className="message-bubble">
                                <p>{note.result}</p>
                            </div>
                            <div>
                                <div>
                                    <label>Images</label>
                                </div>
                                <div className="maintenance-results-gallery-container">
                                    {rows.map((row, rowIndex) => (
                                        <div
                                            className="maintenance-results-row"
                                            key={rowIndex}
                                        >
                                            {row.map((image, index) => {
                                                const actualIndex =
                                                    rowIndex + index;
                                                return (
                                                    <div
                                                        className="maintenance-results-section"
                                                        key={actualIndex}
                                                    >
                                                        <div className="maintenance-results-img-container">
                                                            <img
                                                                src={image.url}
                                                                alt={`${
                                                                    actualIndex +
                                                                    1
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {currentDeviceType === 'mobile' &&
                                user.data.type === CUSTOMER && (
                                    <a
                                        href={`sms:${selectedOrder.vendor.phone_number}`}
                                    >
                                        <button className="btn2 full-width flex flex-center-y mt15">
                                            <Icon>reply_outline</Icon>
                                            <span style={{ marginLeft: 8 }}>
                                                Reply
                                            </span>
                                        </button>
                                    </a>
                                )}
                            <button
                                className="btn2 full-width flex flex-center-y mt15"
                                onClick={() =>
                                    (window.location.href = `${
                                        window.location.origin
                                    }/${
                                        user.data.type === CUSTOMER
                                            ? 'account'
                                            : 'dashboard'
                                    }`)
                                }
                            >
                                <Icon>home_outlined</Icon>
                                <span style={{ marginLeft: 8 }}>Account</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* message view */}
            {(invalidUrl || error) && (
                <div className="pt75 pb25 pl25 pr25">{renderMessage()}</div>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

MaintenanceResults = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MaintenanceResults);

export default MaintenanceResults;
