// libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import Icon from '@material-ui/core/Icon';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import LoaderModal from '../components/LoaderModal';
import Slider from '../components/UI/Slider';
import Link from '../components/UI/Link';
import Filepicker from '../components/UI/Filepicker';
import Plants from '../components/Plants';
import CustomerInfo from '../components/CustomerInfo';
import StepButtons from '../components/StepButtons';
import GeneralMessage from '../components/GeneralMessage';
import Navbar from '../components/Navbar';

// helpers
import getSeason from '../helpers/getSeason';
import logInfo from '../helpers/logInfo';

// formatting
import convertInchesToFeet from '../formatting/convertInchesToFeet';

// vars
import {
    ANNUAL,
    VEGETABLE,
    CULINARY_HERB,
    FRUIT,
    PLANTED,
    ORDER_COMPLETE,
    FULL_PLAN,
    WEEKLY,
    BI_WEEKLY,
} from '../vars/types';
import vars from '../vars/index';

// styles
import '../css/initial-planting-report.css';

const season = getSeason();

let InitialPlantingReport = ({
    createOrder,
    getOrder,
    updateOrder,
    getDrafts,
    updateDraft,
    getPlantCommonTypes,
    getQuestions,
    getReportType,
    createReport,
    createAnswer,
    createPlantActivity,
    createBed,
    getImage,
    updateImage,
    getConversations,
    createConversation,
    createMessage,
    getPlantSelection,
    createSubscription,
    updateUser,
    createHarvestSchedule,
    drafts,
    plantCommonTypes,
    plantSelection,
    questions,
    user
}) => {
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [reportType, setReportType] = useState(null);
    const [selectedDraft, setSelectedDraft] = useState(null);
    const [newPlants, setNewPlants] = useState([]);
    const [daysInterval, setDaysInterval] = useState(0);
    const [daysOfWeekInterval, setDaysOfWeekInterval] = useState([]);
    const [customIntervals, setCustomIntervals] = useState(false);
    const [minuteDuration, setMinuteDuration] = useState(0);
    const [note, setNote] = useState('');
    const [images, setImages] = useState([]);
    const [reportSubmitted, setReportSubmitted] = useState(false);
    const [reportPreviouslySubmitted, setReportPreviouslySubmitted] =
        useState(false);
    const [error, setError] = useState(false);
    const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );

    useEffect(() => {
        const initData = async () => {
            if (urlParams.order) {
                setLoadingModalIsOpen(true);

                try {
                    const order = await getOrder(urlParams.order);
                    if (order.data.status === ORDER_COMPLETE) {
                        setLoadingModalIsOpen(false);
                        return setReportPreviouslySubmitted(true);
                    }

                    setSelectedOrder(order.data);

                    const maintenanceReportType = await getReportType(
                        `name=${order.data.type}`,
                    );

                    setReportType(maintenanceReportType.data);

                    await getQuestions(
                        `report_type=${maintenanceReportType.data._id}`,
                    );

                    await getDrafts(`order=${order.data._id}&published=false`);

                    await getPlantSelection(order.data.customer._id);

                    await getPlantCommonTypes();

                    logInfo('Initial Planting Report page loaded', {
                        user: user.data,
                    });
                } catch (err) {
                    setError(true);
                }
            } else {
                setInvalidUrl(true);
            }

            setLoadingModalIsOpen(false);
        };

        initData();
    }, []);

    function next() {
        const validationMessage = validateInputs();
        if (validationMessage) {
            return window.alert(validationMessage);
        } else {
            let increment = 1;
            setActiveStep(activeStep + increment);
        }
    }

    function back() {
        let decrement = 1;
        if (activeStep - decrement === 0) {
            if (
                window.confirm(
                    'Go back? You will lose any data that has already been entered.',
                )
            ) {
                window.location.href = `${window.location.origin}/dashboard`;
            }
        } else {
            setActiveStep(activeStep - decrement);
        }
    }

    function validateInputs() {
        let validationMessage = '';
        if (activeStep === 1) {
            const totalDrafts = drafts.data.length;
            let draftsWithPlants = 0;
            drafts.data.forEach((draft) => {
                if (
                    newPlants.some((newPlant) => newPlant.draft === draft._id)
                ) {
                    draftsWithPlants += 1;
                }
            });

            if (draftsWithPlants < totalDrafts) {
                validationMessage =
                    'Please select at least 1 plant for each bed';
            }
        } else if (activeStep === 2) {
            if (minuteDuration === 0) {
                validationMessage = 'Please select a minute duration';
            } else if (!customIntervals && daysInterval === 0) {
                validationMessage = 'Please select at least 1 day';
            } else if (customIntervals && daysOfWeekInterval.length === 0) {
                validationMessage = 'Please select at least 1 day';
            }
        } else if (activeStep === 3 && !note) {
            validationMessage = 'Please add a note';
        }

        return validationMessage;
    }

    async function finish() {
        if (!images || images.length < 2) {
            return window.alert('Please upload at least 2 images');
        }

        setLoadingModalIsOpen(true);

        try {
            const newReport = await createReport({
                type: reportType._id,
                order: selectedOrder._id,
                customer: selectedOrder.customer._id,
            });
            const reportAnswers = [
                {
                    report: newReport.data._id,
                    question: setQuestion(5)._id,
                    result: customIntervals
                        ? {
                              minute_duration: minuteDuration,
                              custom_intervals: {
                                  monday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Monday',
                                  ),
                                  tuesday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Tuesday',
                                  ),
                                  wednesday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Wednesday',
                                  ),
                                  thursday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Thursday',
                                  ),
                                  friday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Friday',
                                  ),
                                  saturday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Saturday',
                                  ),
                                  sunday: daysOfWeekInterval.some(
                                      (dayOfWeek) => dayOfWeek === 'Sunday',
                                  ),
                              },
                          }
                        : {
                              minute_duration: minuteDuration,
                              days_interval: daysInterval,
                          },
                },
                {
                    report: newReport.data._id,
                    question: setQuestion(6)._id,
                    result: note,
                },
            ];
            let createAnswers = [];
            reportAnswers.forEach((data) => {
                createAnswers.push(
                    new Promise(async (resolve) => {
                        await createAnswer({
                            report: data.report,
                            question: data.question,
                            result: data.result,
                        });
                        resolve();
                    }),
                );
            });

            await Promise.all(createAnswers);

            let updateDrafts = [];
            let createBeds = [];
            const vegetablesToAdd = newPlants.filter(
                (newPlant) => newPlant.category === VEGETABLE,
            );
            const herbsToAdd = newPlants.filter(
                (newPlant) => newPlant.category === CULINARY_HERB,
            );
            const fruitToAdd = newPlants.filter(
                (newPlant) => newPlant.category === FRUIT,
            );

            drafts.data.forEach(async (draft) => {
                updateDrafts.push(
                    new Promise(async (resolve) => {
                        await updateDraft(draft._id, { published: true });
                        resolve();
                    }),
                );

                const draftVegetables = vegetablesToAdd
                    .map((vegetable) => {
                        if (vegetable.draft === draft._id) {
                            return {
                                plant: vegetable.plant,
                                qty: vegetable.qty,
                            };
                        } else {
                            return undefined;
                        }
                    })
                    .filter((data) => data !== undefined);
                const draftHerbs = herbsToAdd
                    .map((herb) => {
                        if (herb.draft === draft._id) {
                            return {
                                plant: herb.plant,
                                qty: herb.qty,
                            };
                        } else {
                            return undefined;
                        }
                    })
                    .filter((data) => data !== undefined);
                const draftFruit = fruitToAdd
                    .map((fr) => {
                        if (fr.draft === draft._id) {
                            return {
                                plant: fr.plant,
                                qty: fr.qty,
                            };
                        } else {
                            return undefined;
                        }
                    })
                    .filter((data) => data !== undefined);

                const newBed = {
                    key: draft.key,
                    name: `Garden Bed ${draft.key}`,
                    customer: selectedOrder.customer._id,
                    draft: draft._id,
                    width: draft.width,
                    length: draft.length,
                    height: draft.height,
                    shape: draft.shape._id,
                    plot_points: draft.plot_points,
                    vegetables: draftVegetables,
                    herbs: draftHerbs,
                    fruit: draftFruit,
                };

                createBeds.push(
                    new Promise(async (resolve) => {
                        const bed = await createBed(newBed);
                        resolve(bed.data);
                    }),
                );
            });

            await Promise.all(updateDrafts);
            const beds = await Promise.all(createBeds);

            let createNewPlantActivities = [];
            newPlants.forEach((newPlant) => {
                for (let i = 0; i < newPlant.qty; i++) {
                    createNewPlantActivities.push(
                        new Promise(async (resolve) => {
                            await createPlantActivity({
                                type: PLANTED,
                                owner: selectedOrder.vendor._id,
                                customer: selectedOrder.customer._id,
                                order: selectedOrder._id,
                                plant: newPlant.plant,
                                key: i,
                                bed: beds.find(
                                    (b) => b.draft === newPlant.draft,
                                )._id,
                            });
                            resolve();
                        }),
                    );
                }
            });

            await Promise.all(createNewPlantActivities);

            let uploadImages = [];
            for (let file in images) {
                uploadImages.push(
                    new Promise(async (resolve) => {
                        const uploadedImage = await uploadImage(images[file]);
                        resolve(uploadedImage);
                    }),
                );
            }

            Promise.all(uploadImages).then(async (uploadedImages) => {
                await updateOrder(selectedOrder._id, {
                    images: uploadedImages,
                    status: ORDER_COMPLETE,
                });

                if (
                    selectedOrder.customer.garden_info.maintenance_plan !==
                        'none' &&
                    !selectedOrder.customer.payment_info.plan_id
                ) {
                    const maintenancePlan =
                        selectedOrder.customer.garden_info.maintenance_plan;
                    const orderDescription =
                        maintenancePlan === FULL_PLAN
                            ? vars.orderDescriptions.fullPlan
                            : vars.orderDescriptions.assistedPlan;

                    const maintenanceOrder = {
                        type: maintenancePlan,
                        date: moment().add(1, 'week').startOf('day'),
                        customer: selectedOrder.customer._id,
                        description: orderDescription,
                        vendor: selectedOrder.vendor._id,
                    };

                    await createOrder(maintenanceOrder);

                    const newSubscription = {
                        customerId:
                            selectedOrder.customer.payment_info.customer_id,
                        userId: selectedOrder.customer._id,
                        selectedPlan: maintenancePlan,
                    };

                    const subscription =
                        await createSubscription(newSubscription);

                    const paymentInfo = {
                        name: selectedOrder.customer.payment_info.name,
                        token: selectedOrder.customer.payment_info.token,
                        card_id: selectedOrder.customer.payment_info.card_id,
                        card_brand:
                            selectedOrder.customer.payment_info.card_brand,
                        card_last4:
                            selectedOrder.customer.payment_info.card_last4,
                        card_exp_month:
                            selectedOrder.customer.payment_info.card_exp_month,
                        card_exp_year:
                            selectedOrder.customer.payment_info.card_exp_year,
                        customer_id:
                            selectedOrder.customer.payment_info.customer_id,
                        plan_id: subscription.data.id,
                    };

                    await updateUser(
                        `userId=${selectedOrder.customer._id}`,
                        { paymentInfo },
                        true,
                    );

                    const newHarvestSchedule = {
                        customer: selectedOrder.customer._id,
                        cadence:
                            maintenancePlan === FULL_PLAN ? WEEKLY : BI_WEEKLY,
                        start_date: moment().add(1, 'week').startOf('day'),
                    };

                    await createHarvestSchedule(newHarvestSchedule);
                }

                let message = {
                    sender: selectedOrder.vendor._id,
                    receiver: selectedOrder.customer._id,
                    text: note,
                    attachments: uploadedImages,
                    conversation_id: null,
                };

                const conversations = await getConversations(
                    `users=${selectedOrder.customer._id},${selectedOrder.vendor._id}`,
                    true,
                );

                if (conversations.data.length > 0) {
                    message.conversation_id = conversations.data[0]._id;
                    await createMessage(message);
                } else {
                    const conversation = {
                        users: [
                            selectedOrder.customer._id,
                            selectedOrder.vendor._id,
                        ],
                    };

                    const newConversation =
                        await createConversation(conversation);
                    message.conversation_id = newConversation.data._id;
                    await createMessage(message);
                }

                setReportSubmitted(true);
                setLoadingModalIsOpen(false);
            });
        } catch (err) {
            setError(true);
            setLoadingModalIsOpen(false);
        }
    }

    async function uploadImage(image) {
        const hostedImg = await getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        await updateImage(signedRequest, image, options);

        return {
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        };
    }

    function updatePlants(plant, draftId, action) {
        let plants = [...newPlants];
        const plantIndex = plants.findIndex(
            (p) => p.plant === plant._id && p.draft === draftId,
        );
        if (plantIndex !== -1) {
            if (action === 'add') {
                plants[plantIndex].qty = plants[plantIndex].qty + 1;
            } else if (action === 'subtract' && plants[plantIndex].qty !== 0) {
                if (plants[plantIndex].qty - 1 === 0) {
                    plants.splice(plantIndex, 1);
                } else {
                    plants[plantIndex].qty = plants[plantIndex].qty - 1;
                }
            }
        } else {
            if (action === 'add') {
                plants.push({
                    qty: 1,
                    plant: plant._id,
                    category: plant.primary.category.name,
                    draft: draftId,
                });
            }
        }
        setNewPlants(plants);
    }

    function setPlants(plantCategory) {
        const plants = plantCommonTypes.data
            .map((plantCommonType) => {
                if (
                    plantCommonType.primary.category.name === plantCategory &&
                    (plantCommonType.primary.season === season ||
                        plantCommonType.primary.season === ANNUAL)
                ) {
                    return {
                        plant: plantCommonType,
                    };
                } else {
                    return undefined;
                }
            })
            .filter((data) => data !== undefined);

        return plants;
    }

    function calculateQty(plant) {
        const data = newPlants.find(
            (n) => n.plant === plant._id && n.draft === selectedDraft._id,
        );

        const qty = data ? data.qty : 0;
        return qty;
    }

    function setQuestion(placement) {
        const question = questions.data.find(
            (question) => question.placement === placement,
        );

        return question;
    }

    function renderStep1() {
        return (
            <div>
                {selectedDraft ? (
                    <div>{renderDraft()}</div>
                ) : (
                    <div>
                        <p>Tap on each bed where you added new plants</p>
                        {renderDrafts()}
                    </div>
                )}
            </div>
        );
    }

    function renderStep2() {
        const question = setQuestion(5);
        return (
            <div>
                <p>{question.description}</p>
                {customIntervals
                    ? renderCustomIntervals()
                    : renderRegularIntervals()}
                <hr />
                <div className="flex flex-space-between mb15 mt25">
                    <h6>For...</h6>
                    {renderMinuteDurationControl()}
                </div>
                <Slider
                    min={0}
                    max={60}
                    step={1}
                    value={minuteDuration}
                    onChange={(value) => {
                        setMinuteDuration(value);
                    }}
                />
            </div>
        );
    }

    function renderStep3() {
        const question = setQuestion(6);
        return (
            <div>
                <div className="flex flex-center mb5">
                    <ModeCommentOutlinedIcon
                        fontSize="large"
                        style={{ color: 'var(--purpleB)' }}
                    />
                </div>
                <p>{question.description}</p>
                <textarea
                    placeholder="Captain's log..."
                    rows={4}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </div>
        );
    }

    function renderStep4() {
        return (
            <div>
                <p>Upload a minimum of 2 photos</p>
                <div className="mt10">
                    <Filepicker
                        max={10}
                        onChange={(files) => setImages(files)}
                        onReset={() => setImages([])}
                    />
                </div>
            </div>
        );
    }

    function renderDrafts() {
        let rows = [];
        let columns = [];
        drafts.data.forEach((draft) => columns.push(draft));
        const size = 2;
        while (columns.length > 0) rows.push(columns.splice(0, size));

        return rows.map((row, i) => {
            return (
                <div key={i} className="draft-rows">
                    {row.map((column, index) => {
                        const draftId = column.key;
                        return (
                            <div
                                key={index}
                                className="flex flex-center-x"
                                style={{
                                    width: '45%',
                                }}
                            >
                                <div
                                    className="flex flex-space-between"
                                    style={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    {renderBadge(column)}
                                    <div
                                        className="card mb25"
                                        onClick={() => setSelectedDraft(column)}
                                    >
                                        <img
                                            alt="raised garden bed"
                                            src="https://yarden-garden.s3.us-west-1.amazonaws.com/mobile/raised-bed-01.png"
                                            className="draft"
                                        />
                                        <div className="flex flex-center">
                                            <div>
                                                <div className="text-center">
                                                    <label>
                                                        {`Garden Bed #${draftId}`}
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <label>
                                                        {`${convertInchesToFeet(
                                                            column.width,
                                                        )}' X ${convertInchesToFeet(
                                                            column.length,
                                                        )}' X ${convertInchesToFeet(
                                                            column.height,
                                                        )}'`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    }

    function renderDraft() {
        const vegetables = setPlants(VEGETABLE);
        const herbs = setPlants(CULINARY_HERB);
        const fruit = setPlants(FRUIT);

        return (
            <div>
                <h4>Garden Bed #{selectedDraft.key}</h4>
                <p className="mt15">Select the new plants</p>
                <hr />
                <div className="overflow-y-scroll curved p15 mb25 bg-gray1 plant-selection-grid plants-container">
                    <p className="mt15 mb0">
                        <b>Vegetables</b>
                    </p>
                    {vegetables.length === 0 && 'None'}
                    {vegetables.map((vegetable, index) => {
                        const qty = calculateQty(vegetable.plant);
                        return (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="vegetable plant"
                                            src={vegetable.plant.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {vegetable.plant.name}
                                        </label>
                                    </div>
                                    <div className="flex flex-center-y">
                                        <h4 className="mb5">{qty}</h4>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    vegetable.plant,
                                                    selectedDraft._id,
                                                    'subtract',
                                                )
                                            }
                                        >
                                            <Icon>remove_outline</Icon>
                                        </div>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    vegetable.plant,
                                                    selectedDraft._id,
                                                    'add',
                                                )
                                            }
                                        >
                                            <Icon>add_outline</Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <p className="mt15 mb0">
                        <b>Herbs</b>
                    </p>
                    {herbs.length === 0 && 'None'}
                    {herbs.map((herb, index) => {
                        const qty = calculateQty(herb.plant);
                        return (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="herb plant"
                                            src={herb.plant.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {herb.plant.name}
                                        </label>
                                    </div>
                                    <div className="flex flex-center-y">
                                        <h4 className="mb5">{qty}</h4>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    herb.plant,
                                                    selectedDraft._id,
                                                    'subtract',
                                                )
                                            }
                                        >
                                            <Icon>remove_outline</Icon>
                                        </div>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    herb.plant,
                                                    selectedDraft._id,
                                                    'add',
                                                )
                                            }
                                        >
                                            <Icon>add_outline</Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <p className="mt15 mb0">
                        <b>Fruit</b>
                    </p>
                    {fruit.length === 0 && 'None'}
                    {fruit.map((fr, index) => {
                        const qty = calculateQty(fr.plant);
                        return (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="fruit plant"
                                            src={fr.plant.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {fr.plant.name}
                                        </label>
                                    </div>
                                    <div className="flex flex-center-y">
                                        <h4 className="mb5">{qty}</h4>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    fr.plant,
                                                    selectedDraft._id,
                                                    'subtract',
                                                )
                                            }
                                        >
                                            <Icon>remove_outline</Icon>
                                        </div>
                                        <div
                                            className="pl25"
                                            onClick={() =>
                                                updatePlants(
                                                    fr.plant,
                                                    selectedDraft._id,
                                                    'add',
                                                )
                                            }
                                        >
                                            <Icon>add_outline</Icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <button
                    className="btn3 full-width mt15"
                    onClick={() => setSelectedDraft(null)}
                >
                    Done
                </button>
            </div>
        );
    }

    function renderRegularIntervals() {
        return (
            <div>
                <div className="flex flex-space-between mb15">
                    <h6>Once every...</h6>
                    {renderDayIntervalControl()}
                </div>
                <Slider
                    min={0}
                    max={5}
                    step={1}
                    value={daysInterval}
                    onChange={(value) => {
                        setDaysInterval(value);
                    }}
                />
                <div className="flex flex-space-between mt15">
                    <div />
                    <Link
                        onClick={() => {
                            setDaysInterval(1);
                            setCustomIntervals(true);
                        }}
                    >
                        Custom
                    </Link>
                </div>
            </div>
        );
    }

    function renderCustomIntervals() {
        const daysOfWeek = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];
        return (
            <div>
                <div className="flex flex-space-between mb15">
                    <h6>Once every...</h6>
                </div>
                {daysOfWeek.map((day, index) => (
                    <div className="flex flex-center-y mt15" key={index}>
                        <input
                            type="checkbox"
                            checked={
                                daysOfWeekInterval.find(
                                    (dayOfWeek) => dayOfWeek === day,
                                ) || false
                            }
                            onChange={() => {
                                const dayIndex = daysOfWeekInterval.findIndex(
                                    (dayOfWeek) => dayOfWeek === day,
                                );
                                let days = [...daysOfWeekInterval];
                                if (dayIndex === -1) {
                                    days.push(day);
                                } else {
                                    days.splice(dayIndex, 1);
                                }

                                setDaysOfWeekInterval(days);
                            }}
                        />
                        <label className="ml10">{day}</label>
                    </div>
                ))}
                <div className="flex flex-space-between mt15">
                    <div />
                    <Link onClick={() => setCustomIntervals(false)}>
                        Regular
                    </Link>
                </div>
            </div>
        );
    }

    function renderDayIntervalControl() {
        return (
            <div>
                <h6>
                    {daysInterval} {daysInterval > 1 ? 'Days' : 'Day'}
                </h6>
            </div>
        );
    }

    function renderMinuteDurationControl() {
        return (
            <div>
                <h6>
                    {minuteDuration}{' '}
                    {minuteDuration !== 1 ? 'Minutes' : 'Minute'}
                </h6>
            </div>
        );
    }

    function renderBadge(draft) {
        if (newPlants.some((newPlant) => newPlant.draft === draft._id)) {
            return (
                <div className="badge success">
                    <CheckOutlined />
                </div>
            );
        } else {
            return (
                <div className="badge add">
                    <Icon>add_outline</Icon>
                </div>
            );
        }
    }

    function renderMessage() {
        let message = '';
        let isSuccess = false;
        if (reportSubmitted) {
            message = 'Success! Your service report has been submitted.';
            isSuccess = true;
        } else if (invalidUrl) {
            message = 'Invalid URL, please contact support to continue.';
        } else if (reportPreviouslySubmitted) {
            message = 'You have already submitted a report for this order.';
        } else if (error) {
            message =
                'Uh oh! Something went wrong, we are working on a fix now.';
        }

        return (
            <GeneralMessage
                message={message}
                isSuccess={isSuccess}
                buttonText="Continue to dashboard"
                buttonAction={() => (window.location.pathname = '/dashboard')}
            />
        );
    }

    function renderView() {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            default:
                return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {plantSelection && plantSelection.data && (
                <Plants plantSelection={plantSelection.data} />
            )}

            {/* page header */}
            <Navbar />

            <div className="pt75 pb25 pl25 pr25">
                {/* page view */}
                {!invalidUrl &&
                    !reportSubmitted &&
                    !reportPreviouslySubmitted &&
                    !error &&
                    selectedOrder &&
                    drafts.data &&
                    questions.data && (
                        <div className="step-content">
                            <div className="mt25 text-center">
                                <h6>Initial Planting Report</h6>
                                {
                                    <CustomerInfo
                                        customer={selectedOrder.customer}
                                    />
                                }
                            </div>
                            <div className="mt25">{renderView()}</div>
                            {!selectedDraft && (
                                <div className="mt25">
                                    <StepButtons
                                        next={() => next()}
                                        back={() => back()}
                                        finish={() => finish()}
                                        isLastStep={activeStep === 4}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                {/* message view */}
                {(reportSubmitted ||
                    invalidUrl ||
                    reportPreviouslySubmitted ||
                    error) &&
                    renderMessage()}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        drafts: state.draft,
        plantCommonTypes: state.plantCommonType,
        questions: state.question,
        plantSelection: state.plantSelection,
    };
}

InitialPlantingReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(InitialPlantingReport);

export default InitialPlantingReport;
