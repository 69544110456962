// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// UI components
import Logo from '../components/LogoHorizontal.js';

// styles
import '../css/desktop-nav.css';

class DesktopNav extends Component {
    render() {
        return (
            <div>
                {/* navbar */}
                <div className="desktop-nav">
                    <div className="desktop-nav-logo-and-stories">
                        <a href="/">
                            <Logo height={32} />
                        </a>
                    </div>
                    <ul className="desktop-nav-list">
                        <li className="nav-item">
                            <a href="/gallery">Gallery</a>
                        </li>
                        <li className="nav-item">
                            <a href="/products-and-services">
                                Products & Services
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/contact-us">Contact Us</a>
                        </li>
                        <li className="nav-item">
                            <a href="/login">
                                <button
                                    className="btn2 small"
                                    style={{ fontSize: 'var(--h5)' }}
                                >
                                    Account
                                </button>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
    };
}

DesktopNav = connect(mapStateToProps, null)(DesktopNav);

export default DesktopNav;
