// libraries
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import StarOutlined from '@material-ui/icons/StarOutlined';
import LoaderModal from '../components/LoaderModal.js';
import PlantSelect from '../components/PlantSelect.js';
import PlantFavorites from '../components/PlantFavorites.js';
import Navbar from '../components/Navbar';

// actions
import { getPlantCommonTypes } from '../actions/plantCommonType/index';
import {
    createPlantSelection,
    getPlantSelection,
} from '../actions/plantSelection/index';
import { sendAlert } from '../actions/alert/index';

// helpers
import logInfo from '../helpers/logInfo';

// vars
import {
    SPRING,
    FALL,
    ANNUAL,
    VEGETABLE,
    CULINARY_HERB,
    FRUIT,
} from '../vars/types';

// styles
import '../css/plant-selection.css';

let PlantSelection = (props) => {
    const {
        getPlantCommonTypes,
        createPlantSelection,
        getPlantSelection,
        sendAlert,
        user,
    } = props;
    const [activeStep, setActiveStep] = useState(1);
    const [springSelections, setSpringSelections] = useState([]);
    const [fallSelections, setFallSelections] = useState([]);
    const [favoriteSpringSelections, setFavoriteSpringSelections] = useState(
        [],
    );
    const [favoriteFallSelections, setFavoriteFallSelections] = useState([]);
    const [
        plantSelectionPreviouslyCompleted,
        setPlantSelectionPreviouslyCompleted,
    ] = useState(false);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );
    const isApproval = urlParams.bid || urlParams.job;

    useEffect(() => {
        const initPageData = async function () {
            const plantSelection = await getPlantSelection(user.data._id);
            if (plantSelection.data) {
                setPlantSelectionPreviouslyCompleted(true);
                setLoadingModalIsOpen(false);
            } else {
                const plantCommonTypes = await getPlantCommonTypes();
                const plants = plantCommonTypes.data;

                if (
                    springSelections.length === 0 &&
                    fallSelections.length === 0
                ) {
                    const springPlants = plants.filter(
                        (plant) =>
                            plant.primary.season === SPRING ||
                            plant.primary.season === ANNUAL,
                    );
                    const fallPlants = plants.filter(
                        (plant) =>
                            plant.primary.season === FALL ||
                            plant.primary.season === ANNUAL,
                    );

                    const springPlantsWithSelection = springPlants.map(
                        (springPlant) => {
                            return {
                                plant: springPlant,
                                selected: false,
                            };
                        },
                    );

                    const fallPlantsWithSelection = fallPlants.map(
                        (fallPlant) => {
                            return {
                                plant: fallPlant,
                                selected: false,
                            };
                        },
                    );

                    setSpringSelections(springPlantsWithSelection);
                    setFallSelections(fallPlantsWithSelection);
                    setLoadingModalIsOpen(false);
                    logInfo('Plant Selection page loaded', { user: user.data });
                }
            }
        };

        initPageData();
    }, []);

    useEffect(() => {
        const selectedSpringPlants = springSelections.filter(
            (springSelection) => springSelection.selected === true,
        );
        const selectedFallPlants = fallSelections.filter(
            (fallSelection) => fallSelection.selected === true,
        );
        const favoritePlantsForSpring = selectedSpringPlants.map((plant) => {
            return {
                ...plant,
                ...{ selected: false },
            };
        });
        const favoritePlantsForFall = selectedFallPlants.map((plant) => {
            return {
                ...plant,
                ...{ selected: false },
            };
        });

        setFavoriteSpringSelections(favoritePlantsForSpring);
        setFavoriteFallSelections(favoritePlantsForFall);
    }, [springSelections, fallSelections]);

    const next = () => {
        const springPlants = springSelections.filter((plant) => plant.selected);
        const fallPlants = fallSelections.filter((plant) => plant.selected);
        const springFavorites = favoriteSpringSelections.filter(
            (plant) => plant.selected,
        );
        const fallFavorites = favoriteFallSelections.filter(
            (plant) => plant.selected,
        );
        if (
            (activeStep === 2 && springPlants.length === 0) ||
            (activeStep === 4 && fallPlants.length === 0)
        ) {
            return window.alert('Please select at least 1 plant');
        } else if (activeStep === 6 && springFavorites.length === 0) {
            return window.alert('Please select at least 1 spring plant');
        } else if (activeStep === 6 && fallFavorites.length === 0) {
            return window.alert('Please select at least 1 fall plant');
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const back = () => {
        if (activeStep === 1) {
            if (isApproval) {
                window.location.href = `${window.location.origin}/approve${window.location.search}`;
            } else {
                window.location.href = `${window.location.origin}/account`;
            }
        } else {
            setActiveStep(activeStep - 1);
        }
    };

    const separateSelectionsByCategory = (selections) => {
        const vegetables = selections.filter(
            (selection) => selection.plant.primary.category.name === VEGETABLE,
        );
        const herbs = selections.filter(
            (selection) =>
                selection.plant.primary.category.name === CULINARY_HERB,
        );
        const fruit = selections.filter(
            (selection) => selection.plant.primary.category.name === FRUIT,
        );

        return {
            vegetables,
            herbs,
            fruit,
        };
    };

    const finish = async () => {
        const selectedSpringPlants = springSelections
            .filter((data) => data.selected)
            .map((data) => data.plant._id);

        const selectedFallPlants = fallSelections
            .filter((data) => data.selected)
            .map((data) => data.plant._id);

        const selectedSpringPlantFavorites = favoriteSpringSelections
            .filter((data) => data.selected)
            .map((data) => data.plant._id);

        const selectedFallPlantFavorites = favoriteFallSelections
            .filter((data) => data.selected)
            .map((data) => data.plant._id);

        let springPlantsDescription = '';
        springSelections
            .filter((data) => data.selected)
            .forEach((data) => {
                const springFavorite = favoriteSpringSelections.find(
                    (favoriteSpringSelection) =>
                        favoriteSpringSelection.selected &&
                        favoriteSpringSelection.plant._id === data.plant._id,
                )
                    ? ' ⭐'
                    : '';
                springPlantsDescription += `- ${data.plant.name}${springFavorite}\n`;
            });

        let fallPlantsDescription = '';
        fallSelections
            .filter((data) => data.selected)
            .forEach((data) => {
                const fallFavorite = favoriteFallSelections.find(
                    (favoriteFallSelection) =>
                        favoriteFallSelection.selected &&
                        favoriteFallSelection.plant._id === data.plant._id,
                )
                    ? ' ⭐'
                    : '';
                fallPlantsDescription += `- ${data.plant.name}${fallFavorite}\n`;
            });

        try {
            await createPlantSelection({
                customer: user.data._id,
                spring_plants: selectedSpringPlants,
                fall_plants: selectedFallPlants,
                spring_favorites: selectedSpringPlantFavorites,
                fall_favorites: selectedFallPlantFavorites,
            });
            await sendAlert({
                channel: 'plant-selections',
                text: `*New Plant Selection!* \nGARDEN\n${user.data.first_name} ${user.data.last_name}\nSPRING PLANTS\n${springPlantsDescription}\nFALL PLANTS\n${fallPlantsDescription}`,
            });
            next();
        } catch (err) {
            // todo: add error logging here...
        }
    };

    const renderStep1 = () => {
        return (
            <div className="mb50 mt50">
                <div className="flex flex-center mb25" style={{ height: 100 }}>
                    <img
                        src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/spring-season.png"
                        alt="spring plants with a bee"
                        height={100}
                    />
                </div>
                <p>
                    To get started, please select your plants for the{' '}
                    <b>Spring / Summer</b> season
                </p>
            </div>
        );
    };

    const renderStep2 = () => {
        return (
            <div>
                <p>
                    <b>Spring / Summer</b>
                </p>
                <PlantSelect
                    plants={springSelections}
                    customerId={user.data._id}
                    onChange={(plants) => {
                        const selectedPlants = springSelections.map(
                            (springSelection) => {
                                const match = plants.find(
                                    (p) =>
                                        p.plant._id ===
                                        springSelection.plant._id,
                                );
                                if (match) {
                                    return match;
                                } else {
                                    return springSelection;
                                }
                            },
                        );

                        setSpringSelections(selectedPlants);
                    }}
                />
            </div>
        );
    };

    const renderStep3 = () => {
        return (
            <div className="mb50 mt50">
                <div className="flex flex-center mb25" style={{ height: 100 }}>
                    <img
                        src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/fall-season.png"
                        alt="fall leaves"
                        height={100}
                    />
                </div>
                <p>
                    Great job! Next, please select your plants for the{' '}
                    <b>Fall / Winter</b> season
                </p>
            </div>
        );
    };

    const renderStep4 = () => {
        return (
            <div>
                <p>
                    <b>Fall / Winter</b>
                </p>
                <PlantSelect
                    plants={fallSelections}
                    customerId={user.data._id}
                    onChange={(plants) => {
                        const selectedPlants = fallSelections.map(
                            (fallSelection) => {
                                const match = plants.find(
                                    (p) =>
                                        p.plant._id === fallSelection.plant._id,
                                );
                                if (match) {
                                    return match;
                                } else {
                                    return fallSelection;
                                }
                            },
                        );

                        setFallSelections(selectedPlants);
                    }}
                />
            </div>
        );
    };

    const renderStep5 = () => {
        return (
            <div className="mb50 mt50">
                <div className="flex flex-center mb25" style={{ height: 100 }}>
                    <img
                        src="https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/favorites-list.png"
                        alt="favorites list"
                        height={100}
                    />
                </div>
                <p>
                    Next, please select your <b>top 10 favorite plants</b> from
                    your current selection. While we cannot guarantee their
                    availability at all times, your gardener will make every
                    effort to prioritize these selections in your garden space
                </p>
            </div>
        );
    };

    const renderStep6 = () => {
        return (
            <div>
                <p>
                    <b>Plant Favorites</b>
                </p>
                <PlantFavorites
                    springPlants={favoriteSpringSelections}
                    fallPlants={favoriteFallSelections}
                    onChange={(plants, season) => {
                        if (season === SPRING) {
                            setFavoriteSpringSelections(plants);
                        }
                        if (season === FALL) {
                            setFavoriteFallSelections(plants);
                        }
                    }}
                />
            </div>
        );
    };

    const renderStep7 = () => {
        const selectedSpringPlants = springSelections.filter(
            (springSelection) => springSelection.selected,
        );
        const selectedFallPlants = fallSelections.filter(
            (fallSelection) => fallSelection.selected,
        );
        const springSelectionsByCategory =
            separateSelectionsByCategory(selectedSpringPlants);
        const fallSelectionsByCategory =
            separateSelectionsByCategory(selectedFallPlants);

        return (
            <div>
                <p>Please review and confirm your selections</p>
                <div>
                    <div>
                        <p>
                            <b>Spring / Summer</b>
                        </p>
                    </div>
                    <div
                        className="overflow-y-scroll curved p15 mb25 bg-gray1"
                        style={{
                            maxHeight: 200,
                            border: `1px solid var(--gray2)`,
                        }}
                    >
                        {renderPlantConfirmation(
                            springSelectionsByCategory.vegetables,
                            springSelectionsByCategory.herbs,
                            springSelectionsByCategory.fruit,
                            SPRING,
                        )}
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            <b>Fall / Winter</b>
                        </p>
                    </div>
                    <div
                        className="overflow-y-scroll curved p15 mb25 bg-gray1"
                        style={{
                            maxHeight: 200,
                            border: `1px solid var(--gray2)`,
                        }}
                    >
                        {renderPlantConfirmation(
                            fallSelectionsByCategory.vegetables,
                            fallSelectionsByCategory.herbs,
                            fallSelectionsByCategory.fruit,
                            FALL,
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderPlantConfirmation = (vegetables, herbs, fruit, season) => {
        return (
            <div>
                <div style={{ display: 'grid', gap: 'var(--unit5)' }}>
                    <div>
                        <h6 className="mb15">Vegetables</h6>
                        {renderPlantGroup(vegetables, season)}
                    </div>
                    <div>
                        <h6 className="mb15">Herbs</h6>
                        {renderPlantGroup(herbs, season)}
                    </div>
                    <div>
                        <h6 className="mb15">Fruit</h6>
                        {renderPlantGroup(fruit, season)}
                    </div>
                </div>
            </div>
        );
    };

    const renderPlantGroup = (plants, season) => {
        const favoritesSelection =
            season === SPRING
                ? favoriteSpringSelections
                : favoriteFallSelections;
        return (
            <div className="plant-selection-grid">
                {plants.length > 0 ? (
                    plants.map((p, index) => (
                        <div className="flex flex-center-y mb10" key={index}>
                            <img
                                src={p.plant.image}
                                alt={p.plant.name}
                                width="25"
                                height="25"
                                className="mr5"
                            />
                            <p className="text-small capitalize m0">
                                {p.plant.name}
                            </p>
                            {favoritesSelection.find(
                                (favorite) =>
                                    favorite.selected &&
                                    favorite.plant._id === p.plant._id,
                            ) && (
                                <div className="ml5">
                                    <StarOutlined className="text-purpleB" />
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="text-small">None</p>
                )}
            </div>
        );
    };

    const renderNavigationButtons = () => {
        return (
            <div className="flex flex-center-y flex-space-between">
                <button className="small btn3" onClick={() => back()}>
                    <div className="flex flex-center-y">
                        <ArrowBack />
                        <span style={{ marginLeft: 8 }}>Back</span>
                    </div>
                </button>
                {activeStep === 7 ? (
                    <button className="small purple" onClick={() => finish()}>
                        <div className="flex flex-center-y">
                            <CheckOutlined />
                            <span style={{ marginLeft: 8 }}>Finish</span>
                        </div>
                    </button>
                ) : (
                    <button className="small btn3" onClick={() => next()}>
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Next</span>
                            <ArrowForward />
                        </div>
                    </button>
                )}
            </div>
        );
    };

    const renderSuccessMessage = () => {
        return (
            <div className="mb50 mt50">
                <div className="flex flex-center" style={{ height: 100 }}>
                    <span style={{ fontSize: 35, color: 'var(--purpleB)' }}>
                        ٩( ᐛ )و
                    </span>
                </div>
                <p className="text-center">
                    Success! Your plant selections have been saved.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() =>
                            (window.location.href = isApproval
                                ? `${window.location.origin}/products${window.location.search}`
                                : `${window.location.origin}/account`)
                        }
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Continue</span>
                            <ArrowForward />
                        </div>
                    </button>
                </div>
            </div>
        );
    };

    const renderPreviouslyCompletedMessage = () => {
        return (
            <div className="mb50 mt50 p25">
                <h4 className="text-center mb15">
                    You have already selected your plants.
                </h4>
                <p className="text-center">
                    To update your plant selections, go to your account.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() =>
                            (window.location.href = `${window.location.origin}/account`)
                        }
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Continue</span>
                            <ArrowForward />
                        </div>
                    </button>
                </div>
            </div>
        );
    };

    const renderProgress = () => {
        return (
            <div className="text-center mb25">
                <h6>Step {activeStep} of 7</h6>
            </div>
        );
    };

    const renderStep = () => {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            case 5:
                return renderStep5();
            case 6:
                return renderStep6();
            case 7:
                return renderStep7();
            case 8:
                return renderSuccessMessage();
            default:
                return renderStep1();
        }
    };

    if (loadingModalIsOpen) {
        return <LoaderModal isOpen={true} />;
    } else if (plantSelectionPreviouslyCompleted) {
        return renderPreviouslyCompletedMessage();
    } else {
        return (
            <div>
                <Navbar />
                <div className="pt75 pb25 pl25 pr25">
                    <div className="step-content">
                        <div className="flex flex-center-y flex-space-between mb25 mt25">
                            <h4>Plant Selection</h4>
                        </div>
                        <div className="mb25 bg-green0">
                            <hr />
                        </div>
                        <div>{activeStep !== 8 && renderProgress()}</div>
                        <div>{renderStep()}</div>
                        <div>
                            {activeStep !== 8 && renderNavigationButtons()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

function mapStateToProps(state) {
    return {
        user: state.user,
        beds: state.bed,
    };
}

export function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPlantCommonTypes,
            createPlantSelection,
            getPlantSelection,
            sendAlert,
        },
        dispatch,
    );
}

PlantSelection = connect(mapStateToProps, mapDispatchToProps)(PlantSelection);

export default PlantSelection;
