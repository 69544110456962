export function getBadgeStyles(order) {
    let orderTypeStyles = '';
    if (order.type === 'yard assessment') {
        orderTypeStyles = 'yard-assessment'; // blue
    } else if (order.type === 'installation' || order.type === 'revive') {
        orderTypeStyles = 'new-garden'; // orange
    } else if (order.type === 'full plan' || order.type === 'assisted plan') {
        orderTypeStyles = 'full-plan'; //green
    } else if (order.type === 'crop rotation') {
        orderTypeStyles = 'crop-rotation'; //brown
    } else if (order.type === 'misc') {
        orderTypeStyles = 'misc'; // gray
    } else if (order.type === 'initial planting') {
        orderTypeStyles = 'initial-planting'; // yellow
    } else if (order.type === 'soil analysis' || order.type === 'soil refill') {
        orderTypeStyles = 'soil-order'; // magenta
    }

    return orderTypeStyles;
}
