import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// @params {Boolean} isOpen
// @params {String} message
// @params {Function} onClose

class Alert extends Component {

    render() {

      let { 
        isOpen, 
        message,
        onClose 
      } = this.props;

        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={isOpen}
            autoHideDuration={5000}
            onClose={() => onClose()}
            ContentProps={{'aria-describedby': 'message-id', variant: `elevation`,}}
            message={<span id="message-id">{message}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            ]}
          />
        );
    }
    
}

export default Alert;