import { GET_MESSAGES, GET_UNREAD } from '../actions/message/types';

let initialState = {
  inbox: [],
  unread: 0
}

export default function( state = initialState, action){
    switch(action.type){
      case GET_MESSAGES:
        return {...state, inbox: action.payload}
      case GET_UNREAD:
        return {...state, unread: action.payload}
      default :
        return state;
    }
}