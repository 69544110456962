// libs
import React, { createContext, useState } from "react";

export const LineItemsContext = createContext({
    hours: 0,
    rate: 0,
    materialsRequired: false,
    materials: [{ name: "", url: "", price: "", qty: "" }],
    deliveryRequired: false,
    deliveryItems: "",
    deliveryFee: 0,
    rentalsRequired: false,
    rentalItems: "",
    rentalFee: 0,
    disposalRequired: false,
    disposalItems: "",
    disposalFee: 0,
    setHours: () => {},
    setRate: () => {},
    setMaterialsRequired: () => {},
    setMaterials: () => {},
    setDeliveryRequired: () => {},
    setDeliveryItems: () => {},
    setDeliveryFee: () => {},
    setRentalsRequired: () => {},
    setRentalItems: () => {},
    setRentalFee: () => {},
    setDisposalRequired: () => {},
    setDisposalItems: () => {},
    setDisposalFee: () => {},
})

export function LineItemsContextProvider({ children }) {
    const [hours, setHours] = useState(0);
    const [rate, setRate] = useState(0);
    const [materialsRequired, setMaterialsRequired] = useState(false);
    const [materials, setMaterials] = useState([{ name: "", url: "", price: "", qty: "" }]);
    const [deliveryRequired, setDeliveryRequired] = useState(false);
    const [deliveryItems, setDeliveryItems] = useState("");
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [rentalsRequired, setRentalsRequired] = useState(false);
    const [rentalItems, setRentalItems] = useState("");
    const [rentalFee, setRentalFee] = useState(0);
    const [disposalRequired, setDisposalRequired] = useState(false);
    const [disposalItems, setDisposalItems] = useState("");
    const [disposalFee, setDisposalFee] = useState(0);

    return (
        <LineItemsContext.Provider 
        value={{
            hours,
            rate,
            materialsRequired,
            materials,
            deliveryRequired,
            deliveryItems,
            deliveryFee,
            rentalsRequired,
            rentalItems,
            rentalFee,
            disposalRequired,
            disposalItems,
            disposalFee,
            setHours,
            setRate,
            setMaterialsRequired,
            setMaterials,
            setDeliveryRequired,
            setDeliveryItems,
            setDeliveryFee,
            setRentalsRequired,
            setRentalItems,
            setRentalFee,
            setDisposalRequired,
            setDisposalItems,
            setDisposalFee
        }}>
            {children}
        </LineItemsContext.Provider>
    )
}

