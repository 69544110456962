// libs
import moment from 'moment';

// vars
import { FULL_PLAN, ASSISTED_PLAN } from '../vars/types';

export default function formatOrderTime(order) {
    if (
        !order.time &&
        order.type !== FULL_PLAN &&
        order.type !== ASSISTED_PLAN
    ) {
        return '--:--';
    } else {
        const orderTime =
            order.type === FULL_PLAN || order.type === ASSISTED_PLAN
                ? '9:00 AM - 5:00 PM'
                : moment(order.time, 'HH:mm:ss').format('h:mm A');

        return orderTime;
    }
}
