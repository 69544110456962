// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// actions
import { login } from '../actions/authentication/index';

// UI components
import GoogleAuthButton from '../components/GoogleAuthButton';

// helpers
import logInfo from '../helpers/logInfo';

// types
import { CUSTOMER } from '../vars/types';

// styles
import '../css/login.css';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        logInfo('Login page loaded');
    }, []);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            window.alert('Please complete all required fields');
            return;
        }
        setIsLoading(true);
        const authInfo = { email, password };
        const user = await props.login(authInfo);
        if (user) {
            handleRedirect(user.data);
        } else {
            window.alert('Invalid email or password');
        }
        setIsLoading(false);
    };

    const handleRedirect = (user) => {
        if (user.type === CUSTOMER) {
            window.location.pathname = '/account';
        } else {
            window.location.pathname = '/dashboard';
        }
    };

    return (
        <section className="login-screen">
            <div>
                <h4>Log In</h4>
                <form onSubmit={handleSubmit}>
                    <label>
                        Email:
                        <input
                            placeholder="johndoe@gmail.com"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            disabled={isLoading}
                        />
                    </label>
                    <label>
                        Password:
                        <input
                            placeholder="******"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            disabled={isLoading}
                        />
                    </label>
                    <div className="flex-space-between flex-center-y mt4">
                        <a
                            href="/reset-password"
                            style={{ fontSize: 'var(--h6)' }}
                        >
                            Forgot Password?
                        </a>
                        <button
                            className="small btn3"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Log In'}
                        </button>
                    </div>
                    <div className="pt15 pb15">
                        <hr />
                    </div>
                    <div className="flex flex-center">
                        <GoogleAuthButton
                            onSuccess={(user) => handleRedirect(user.data)}
                        />
                    </div>
                </form>
            </div>
        </section>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            login,
        },
        dispatch,
    );
}

export default connect(null, mapDispatchToProps)(Login);
