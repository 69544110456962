import { UPDATE_ANSWERS } from '../actions/answer/types';

let initialState = {
  data: []
};

export default function( state = initialState, action){
    switch(action.type){
      case UPDATE_ANSWERS :
        return {
          data: action.payload
        }
      default :
        return state;
    }
}