import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_CHANGE_ORDERS } from './types';

export function createChangeOrder(changeOrder) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/change-orders`,
                changeOrder,
                { headers: { authorization: getAuthToken() } },
            );
            dispatch(getChangeOrders(`order=${changeOrder.order}`));
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getChangeOrders(query) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/change-orders${q}`, {
                headers: { authorization: getAuthToken() },
            });
            dispatch({ type: GET_CHANGE_ORDERS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getChangeOrder(id) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/change-orders/${id}`, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function updateChangeOrder(changeOrderId, changeOrder, orderId) {
    return async function (dispatch) {
        try {
            await axios.put(
                `/api/v1/change-orders/${changeOrderId}`,
                changeOrder,
                { headers: { authorization: getAuthToken() } },
            );
            dispatch(getChangeOrders(`order=${orderId}`));
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function deleteChangeOrder(changeOrderId, orderId) {
    return async function (dispatch) {
        try {
            await axios.delete(`/api/v1/change-orders/${changeOrderId}`, {
                headers: { authorization: getAuthToken() },
            });
            dispatch(getChangeOrders(`order=${orderId}`));
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
