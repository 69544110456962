import axios from 'axios';
import { throwError } from '../error/index';

export function createPageView(pageView) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/page-views`, pageView);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}