import { combineReducers } from 'redux';
import user from './user';
import users from './users';
import order from './order';
import application from './application';
import status from './status';
import bidStatus from './bidStatus';
import range from './range';
import cart from './cart';
import subscription from './subscription';
import message from './message';
import conversation from './conversation';
import bid from './bid';
import template from './template';
import rule from './rule';
import item from './item';
import task from './task';
import taskStatus from './taskStatus';
import changeOrder from './changeOrder';
import navigation from './navigation';
import render from './render';
import answer from './answer';
import questionType from './questionType';
import question from './question';
import referral from './referral';
import loading from './loading';
import error from './error';
import plan from './plan';
import qrCode from './qrCode';
import scan from './scan';
import product from './product';
import plant from './plant';
import irrigation from './irrigation';
import shape from './shape';
import draft from './draft';
import bed from './bed';
import plantCommonType from './plantCommonType';
import plantSelection from './plantSelection';
import reviews from './reviews';
import report from './report';
import reschedule from './reschedule';

const rootReducer = combineReducers({
    user,
    users,
    order,
    application,
    status,
    bidStatus,
    range,
    cart,
    subscription,
    message,
    conversation,
    bid,
    template,
    rule,
    item,
    task,
    taskStatus,
    changeOrder,
    navigation,
    render,
    answer,
    referral,
    loading,
    questionType,
    question,
    error,
    plan,
    qrCode,
    scan,
    product,
    plant,
    irrigation,
    shape,
    draft,
    bed,
    plantCommonType,
    plantSelection,
    reviews,
    report,
    reschedule,
});

export default rootReducer;
