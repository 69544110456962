// libs
import React from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

let GoogleAuthButton = ({ googleLogin, onSuccess }) => {
    const handleCredentialResponse = async (response) => {
        const user = await googleLogin({ token: response.credential });
        if (user) {
            onSuccess(user);
        } else {
            window.alert('User account does not exist');
        }
    };

    return (
        <div>
            <GoogleLogin
                onSuccess={(credentialResponse) => {
                    handleCredentialResponse(credentialResponse);
                }}
                onError={() => {
                    window.alert('Failed to log in, please try again');
                }}
                shape="pill"
                text="continue_with"
                useOneTap
            />
        </div>
    );
};

GoogleAuthButton = connect(null, mapDispatchToProps)(GoogleAuthButton);

export default GoogleAuthButton;
