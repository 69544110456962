import { GET_TASKS } from '../actions/task/types';

let initialState = {}

export default function( state = initialState, action){
    switch(action.type){
      case GET_TASKS :
        return {...action.payload}
      default :
        return state;
    }
}