// MobileNav.js

// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';

// components
import Logo from './HorizontalLogo';

// helper
import navigate from '../helpers/navigate';

// vars
import { APPOINTMENT_URL } from '../vars/constants';

// styles
import '../css/mobile-nav.css';

class MobileNav extends Component {
    state = {
        isOpen: false,
    };

    toggleMenu = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    onMenuItemClick = (item) => {
        this.toggleMenu();
        switch (item) {
            // i removed the login from the mobile nav since we already have the "Account" button
            case 'Book Appointment':
                window.location.href = APPOINTMENT_URL;
                break;
            case 'Gallery':
                window.location.href = '/gallery';
                break;
            case 'Products & Services':
                window.location.href = '/products-and-services';
                break;
            case 'Contact Us':
                window.location.href = '/contact-us';
                break;
            default:
                break;
        }
    };

    render() {
        const { isOpen } = this.state;

        return (
            <div>
                <div className="mobile-nav">
                    <button
                        className="hamburger-btn btn3 small"
                        onClick={this.toggleMenu}
                    >
                        <div className="hamburger-btn-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>

                    {isOpen && (
                        <div className="mobile-nav-menu">
                            <ul className="mobile-nav-list">
                                {[
                                    'Book Appointment',
                                    'Gallery',
                                    'Products & Services',
                                    'Contact Us',
                                ].map((text) => (
                                    <li
                                        key={text}
                                        onClick={() =>
                                            this.onMenuItemClick(text)
                                        }
                                    >
                                        {text}
                                    </li>
                                ))}
                            </ul>
                            <button
                                className="mobile-nav-close-btn btn3 small"
                                onClick={this.toggleMenu}
                            >
                                Close
                            </button>
                        </div>
                    )}

                    <div onClick={() => navigate('/', true)}>
                        <Logo height={32} />
                    </div>

                    <a href="/login">
                        <button className="btn2 m0 small">Account</button>
                    </a>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        user: state.user,
    };
}

export default connect(mapStateToProps)(MobileNav);
