// libs
import React from 'react';

const Link = ({ children, onClick }) => {
    return (
        <div className="link" onClick={() => onClick()}>
            {children}
        </div>
    );
};

export default Link;
