export const DEVELOPMENT_URL = 'http://localhost:3000';
export const STAGING_URL = 'https://yarden-staging.herokuapp.com';
export const PRODUCTION_URL = 'https://www.yardengarden.com';
export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';
export const GOOGLE_OAUTH_CLIENT_ID =
    '78672281013-lkjgdrjpsie5f2jam80v16eehh6bv0t0.apps.googleusercontent.com';
export const GOOGLE_OAUTH_CLIENT_SECRET = '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';
export const TEST_STRIPE_CLIENT_KEY = 'pk_test_fTS8QsAzzlfnVn28SaWqGshE';
export const LIVE_STRIPE_CLIENT_KEY = 'pk_live_OfB6JSbGCeGftRTL9ZdJQJzA';
export const TEST_STRIPE_CONNECT_CLIENT_KEY =
    'ca_9wZYKo0SPQfRwmdWFvdRp8km6BWjV9At';
export const LIVE_STRIPE_CONNECT_CLIENT_KEY =
    'ca_9wZYGK1e3Ah54XszoH8MyIxkP0bbYg4R';
export const DATADOG_CLIENT_TOKEN = 'puba005cdd338a179e76c25f7d02152c9a9';
