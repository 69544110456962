// libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// helpers
import logInfo from '../helpers/logInfo';

let DirectDeposit = (props) => {
    const { createDirectDeposit, updateUser } = props;
    const [message, setMessage] = useState(
        'Finalizing direct deposit, do not refresh page...',
    );

    useEffect(() => {
        const initDirectDespositConnection = async () => {
            logInfo('Direct Deposit page loaded', { user: props.user.data });

            const urlParams = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );

            if (urlParams.code) {
                const connectConfig = {
                    code: urlParams.code,
                };

                try {
                    const directDeposit =
                        await createDirectDeposit(connectConfig);

                    await updateUser(null, {
                        payoutInfo: directDeposit.data,
                    });

                    window.location.pathname = '/dashboard';
                } catch (err) {
                    setMessage(`${err.message}, please contact Yarden support`);
                }
            } else {
                setMessage(
                    'No direct deposit code found, please contact Yarden support',
                );
            }
        };

        initDirectDespositConnection();
    }, []);

    return <div className="flex flex-center full-view p25">{message}</div>;
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

DirectDeposit = connect(mapStateToProps, mapDispatchToProps)(DirectDeposit);

export default DirectDeposit;
