import React from "react";
import "../css/toggle-switch.css";

function ToggleButton(props) {
  const handleClick = () => {
    props.onToggle(!props.value);
  };
  return (
    <div
      className={`toggle-button ${props.value ? "on" : "off"}`}
      onClick={handleClick}
    >
      <div className="toggle-knob"></div>
    </div>
  );
}
export default ToggleButton;
