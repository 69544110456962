// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import LoaderModal from '../components/LoaderModal.js';
import Alert from '../components/Alert.js';

// actions
import { toggleLoading } from '../actions/loading/index';
import { updateUser } from '../actions/user/index';
import { createCard, deleteCard } from '../actions/payment/index';
import { createCustomer } from '../actions/payment/index';
import { validateCard } from '../actions/payment/validation';

// helpers
import {
    CreditCard,
    CreditCardDate,
    CreditCardCode,
} from '../formatting/CreditCard';
import Amex from '../formatting/Amex';

class SettingsModal extends Component {
    state = {
        cardType: 'visa',
        nameOnCard: '',
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.props.toggleLoading(false);
            if (this.props.setting === 'name') {
                return this.setState({
                    inputType: this.props.setting,
                    userFirstName: this.props.user.data.first_name,
                    userLastName: this.props.user.data.last_name,
                });
            }

            let value = '';
            if (this.props.setting === 'email')
                value = this.props.user.data.email;
            if (this.props.setting === 'phone number')
                value = this.props.user.data.phone_number;
            if (
                this.props.setting === 'add card' ||
                this.props.setting === 'edit card'
            )
                value = null;
            this.setState({
                inputType: this.props.setting,
                inputValue: value,
            });

            // this is a fix for the html overflow when the confirmation modal closes
            let html = document.getElementsByTagName('html')[0];
            html.style = {
                overflow: !this.props.isOpen ? 'visible' : 'hidden',
            };
        }

        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    setValue(e) {
        let val = { inputValue: e.target.value };
        if (
            this.state.inputType === 'add card' ||
            this.state.inputType === 'edit card' ||
            this.state.inputType === 'name'
        )
            val = { [e.target.name]: e.target.value };
        this.setState(val);
    }

    updateSettings() {
        if (
            this.state.inputType === 'phone number' &&
            this.state.inputValue.replace(/\D/g, '').length < 10
        ) {
            return window.alert('Invalid phone number');
        }

        if (
            (this.state.inputType === 'add card' ||
                this.state.inputType === 'edit card') &&
            (!this.state.cardNumber ||
                !this.state.expDate ||
                !this.state.cardCode)
        ) {
            return window.alert('Please fill out all required fields');
        }

        this.props.toggleLoading(true);

        setTimeout(() => {
            let user = {};
            if (this.state.inputType === 'name')
                user = {
                    userFirstName: this.state.userFirstName.trim(),
                    userLastName: this.state.userLastName.trim(),
                };
            if (this.state.inputType === 'phone number')
                user.userPhoneNumber = this.state.inputValue.replace(/\D/g, '');
            if (this.state.inputType === 'email')
                user.userEmail = this.state.inputValue.trim();

            // update payment information
            if (
                this.state.inputType === 'edit card' ||
                this.state.inputType === 'add card'
            )
                return this.updatePaymentInfo();

            // update user settings
            this.props.updateUser(null, user);
            this.props.toggleLoading(false);
            this.props.onClose();
        }, 2000);
    }

    updatePaymentInfo() {
        const t = this;

        let validate = new Promise((resolve, reject) => {
            if (
                !this.state.nameOnCard ||
                !this.state.cardNumber ||
                !this.state.expDate ||
                !this.state.cardCode
            ) {
                return window.alert('Please complete all required fields');
            } else {
                let card = {
                    name: this.state.nameOnCard,
                    number: this.state.cardNumber.split('-').join(''),
                    exp_month: this.state.expDate.split('/')[0],
                    exp_year: this.state.expDate.split('/')[1],
                    cvc: this.state.cardCode,
                };

                // validate card
                let checkCard = new Promise((resolve, reject) =>
                    validateCard(card, resolve, reject),
                );
                checkCard
                    .then((response) => {
                        this.setState(
                            {
                                card: response.card,
                                token: response.id,
                            },
                            () => {
                                resolve();
                            },
                        );
                    })
                    .catch((err) => {
                        window.alert(err.message);
                        reject();
                    });
            }
        });

        validate.then(async function () {
            let card = {
                token: t.state.token,
                card_id: t.state.card.id,
                card_brand: t.state.card.brand,
                card_last4: t.state.card.last4,
                card_exp_month: t.state.card.exp_month,
                card_exp_year: t.state.card.exp_year,
                name: t.state.card.name,
            };

            if (t.state.inputType === 'edit card') {
                // delete current card
                await t.props.deleteCard();

                // add new card
                await t.props.createCard({ token: t.state.token });
                card.customer_id = t.props.user.data.payment_info.customer_id;
            }

            if (t.state.inputType === 'add card') {
                // create customer
                const paymentInfo = await t.props.createCustomer({
                    userFullName: `${t.props.user.data.first_name} ${t.props.user.data.last_name}`,
                    userEmail: t.props.user.data.email,
                    token: t.state.token,
                });

                card.customer_id = paymentInfo.data.id;
            }

            // add user settings
            await t.props.updateUser(null, { paymentInfo: card });
            t.props.toggleLoading(false);
            t.props.onClose();
        });
    }

    render() {
        const {
            inputType,
            inputValue,
            userFirstName,
            userLastName,
            alertIsOpen,
        } = this.state;

        const { isOpen = false, onClose, message, loading, error } = this.props;

        return (
            <span>
                {/* modals */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                <LoaderModal isOpen={loading.isLoading} />

                {/* settings modal */}
                <div>
                    <Dialog
                        onClose={loading.isLoading ? null : () => onClose()}
                        open={isOpen}
                        aria-labelledby="form-dialog-title"
                    >
                        <div>
                            <DialogContent>
                                <h5 className="m0 pb0">Edit Settings</h5>
                                {(inputType === 'add card' ||
                                    inputType === 'edit card') && (
                                    <div>
                                        <p className="mt5">
                                            {message
                                                ? message
                                                : 'To update your payment method, enter your new card information.'}
                                        </p>
                                    </div>
                                )}

                                {/* first name input  */}
                                {inputType === 'name' && (
                                    <div>
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            name="userFirstName"
                                            placeholder="John"
                                            disabled={loading.isLoading}
                                            value={userFirstName}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                )}

                                {/* last name input  */}
                                {inputType === 'name' && (
                                    <div>
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            name="userLastName"
                                            placeholder="Doe"
                                            disabled={loading.isLoading}
                                            value={userLastName}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                )}

                                {/* email input  */}
                                {inputType === 'email' && (
                                    <div>
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="userEmail"
                                            placeholder="johndoe@gmail.com"
                                            disabled={loading.isLoading}
                                            value={inputValue}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                )}

                                {/* phone number input  */}
                                {inputType === 'phone number' && (
                                    <div>
                                        <label>Phone Number</label>
                                        <input
                                            name="userPhoneNumber"
                                            placeholder="(123) 456-7890"
                                            disabled={loading.isLoading}
                                            value={inputValue}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                )}

                                {/* card input */}
                                {(inputType === 'add card' ||
                                    inputType === 'edit card') && (
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <select
                                                    name="cardType"
                                                    disabled={loading.isLoading}
                                                    value={this.state.cardType}
                                                    onChange={(e) =>
                                                        this.setValue(e)
                                                    }
                                                >
                                                    <option value={'visa'}>
                                                        Visa
                                                    </option>
                                                    <option
                                                        value={'mastercard'}
                                                    >
                                                        Mastercard
                                                    </option>
                                                    <option
                                                        value={
                                                            'american express'
                                                        }
                                                    >
                                                        American Express
                                                    </option>
                                                    <option value={'discover'}>
                                                        Discover
                                                    </option>
                                                </select>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <label>Name on card</label>
                                                <input
                                                    type="text"
                                                    name="nameOnCard"
                                                    placeholder="John Smith"
                                                    disabled={
                                                        !this.state.cardType ||
                                                        loading.isLoading
                                                    }
                                                    value={
                                                        this.state.nameOnCard
                                                    }
                                                    onChange={(e) =>
                                                        this.setValue(e)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <label>Card Number</label>
                                                <Input
                                                    required
                                                    fullWidth
                                                    className={
                                                        !this.state.cardType ||
                                                        this.state.cardType ===
                                                            'visa' ||
                                                        this.state.cardType ===
                                                            'mastercard' ||
                                                        this.state.cardType ===
                                                            'discover'
                                                            ? ''
                                                            : 'hidden'
                                                    }
                                                    inputComponent={CreditCard}
                                                    margin="dense"
                                                    name="cardNumber"
                                                    placeholder="xxxx-xxxx-xxxx-xxxx"
                                                    disabled={
                                                        !this.state.cardType ||
                                                        loading.isLoading
                                                    }
                                                    value={
                                                        this.state.cardNumber
                                                    }
                                                    onChange={(e) =>
                                                        this.setValue(e)
                                                    }
                                                />
                                                <Input
                                                    required
                                                    fullWidth
                                                    className={
                                                        this.state.cardType ===
                                                        'american express'
                                                            ? ''
                                                            : 'hidden'
                                                    }
                                                    inputComponent={Amex}
                                                    margin="dense"
                                                    name="cardNumber"
                                                    placeholder="xxxx-xxxx-xxxx-xxxx"
                                                    disabled={
                                                        !this.state.cardType ||
                                                        loading.isLoading
                                                    }
                                                    value={
                                                        this.state.cardNumber
                                                    }
                                                    onChange={(e) =>
                                                        this.setValue(e)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <label>Exp Date</label>
                                                <Input
                                                    required
                                                    fullWidth
                                                    inputComponent={
                                                        CreditCardDate
                                                    }
                                                    margin="dense"
                                                    name="expDate"
                                                    placeholder="xx/xx"
                                                    disabled={
                                                        !this.state.cardType ||
                                                        loading.isLoading
                                                    }
                                                    value={this.state.expDate}
                                                    onChange={(e) =>
                                                        this.setValue(e)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <label>
                                                    CVV{' '}
                                                    <a
                                                        href="https://www.strategicprofitsinc.com/cvv_info/cvv_info.html"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        [?]
                                                    </a>
                                                </label>
                                                <Input
                                                    required
                                                    fullWidth
                                                    inputComponent={
                                                        CreditCardCode
                                                    }
                                                    margin="dense"
                                                    name="cardCode"
                                                    placeholder="xxx"
                                                    disabled={
                                                        !this.state.cardType ||
                                                        loading.isLoading
                                                    }
                                                    value={this.state.cardCode}
                                                    onChange={(e) =>
                                                        this.setValue(e)
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                <div className="flex flex-space-between mt15">
                                    <button
                                        className="btn3 small flex flex-center-y"
                                        disabled={
                                            loading.isLoading
                                        }
                                        onClick={() => onClose()}
                                    >
                                        <Icon>clear</Icon>
                                        <span style={{ marginLeft: 8 }}>
                                            Close
                                        </span>
                                    </button>
                                    <button
                                        className="purple small flex flex-center-y"
                                        disabled={loading.isLoading}
                                        onClick={() => this.updateSettings()}
                                    >
                                        <Icon>done</Icon>
                                        <span style={{ marginLeft: 8 }}>
                                            Save
                                        </span>
                                    </button>
                                </div>
                            </DialogContent>
                        </div>
                    </Dialog>
                </div>
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLoading,
            updateUser,
            createCard,
            deleteCard,
            createCustomer,
        },
        dispatch,
    );
}

SettingsModal = connect(mapStateToProps, mapDispatchToProps)(SettingsModal);

export default SettingsModal;
