import { GET_SCANS } from '../actions/scan/types';

let initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SCANS:
            return { ...action.payload }
        default:
            return state;
    }
}