import { GET_QR_CODES } from '../actions/qrCode/types';

let initialState = {};

export default function( state = initialState, action){
    switch(action.type){
      case GET_QR_CODES :
        return {...action.payload}
      default :
        return state;
    }
}