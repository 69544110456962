// libs
import React, { useState } from 'react';

// styles
import '../../css/tabs.css';

const Tabs = ({ className, data, defaultValue, onChange, outlined }) => {
    const defaultTab = data.find((d) => d.value === defaultValue);
    const [tab, setTab] = useState(defaultTab);

    return (
        <ul className={(outlined ? 'tabs-outlined' : 'tabs') + ' ' + className}>
            {data.map((item, index) => (
                <li
                    style={{
                        borderRadius:
                            index !== 0 && index !== data.length - 1
                                ? 'initial'
                                : 'none',
                    }}
                    className={
                        tab && item.value === tab.value
                            ? 'tab active pointer'
                            : 'tab pointer'
                    }
                    key={index}
                    onClick={() => {
                        setTab(item);
                        onChange(item);
                    }}
                >
                    {item.icon}
                    <span className="tab-link-text">{item.title}</span>
                </li>
            ))}
        </ul>
    );
};

export default Tabs;
