// libs
import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher';

// helpers
import { formatNumber } from '../formatting/phone';

// vars
import { PHONE_NUMBER } from '../vars/constants';
import { APPROVED, PENDING, REJECTED } from '../vars/types';

let ApplicationDetails = (props) => {
    const {
        selectedApplication,
        updateApplication,
        getApplications,
        sendEmail,
        sendSms,
        onSetApplicationStatus,
    } = props;
    const [applicationApproved, setApplicationApproved] = useState(false);

    const approveApplication = async () => {
        if (window.confirm('Are you sure?')) {
            await updateApplication(selectedApplication._id, {
                applicationStatus: APPROVED,
            });
            await getApplications();
            const accountActivationUrl = `${window.location.origin}/activate?_id=${selectedApplication._id}`;
            const emailNotification = {
                email: selectedApplication.email,
                subject: 'Yarden - (ACTION REQUIRED) Activate Account',
                label: 'Account Activation',
                body:
                    `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                    `<p style="margin-bottom: 15px">Your job application for Yarden has been approved, please activate your account by clicking the button below. If you have already done this, simply disregard this message.</p>` +
                    '<div style="border-top: 1px solid #DDDDDD;">' +
                    '<div style="margin-top: 25px; text-align: center;">' +
                    `<a href="${accountActivationUrl}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>Activate Account</b></a>` +
                    '</div>' +
                    '</div>',
            };

            const smsNotification = {
                from: PHONE_NUMBER,
                to: selectedApplication.phone_number.replace(/\D/g, ''),
                body: `Greetings from Yarden! Your job application for Yarden has been approved, please activate your account: ${accountActivationUrl} (If you have already done this, simply disregard this message)`,
            };

            sendEmail(emailNotification);
            sendSms(smsNotification);
            onSetApplicationStatus(APPROVED);
            setApplicationApproved(true);
        }
    };

    const rejectApplication = async () => {
        if (window.confirm('Are you sure?')) {
            await updateApplication(selectedApplication._id, {
                applicationStatus: REJECTED,
            });
            await getApplications();
            onSetApplicationStatus(REJECTED);
        }
    };

    if (applicationApproved) {
        return (
            <div>
                <div>
                    <p className="mt25">
                        Application approved! An account activation link has
                        been sent to <u>{selectedApplication.email}</u>
                    </p>
                </div>
                <hr />
                <div>
                    <p className="text-small">
                        Please complete the following onboarding tasks
                    </p>
                    <ul>
                        <li>
                            Send applicant onboarding docs{' '}
                            <a
                                href="https://breezedoc.com/templates"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (BreezeDoc)
                            </a>
                            <ul>
                                <li>Release of Liability</li>
                                <li>Gardening Equipment Return Agreement</li>
                                <li>Uniform Policy Agreement</li>
                            </ul>
                        </li>
                        <li>
                            Initiate background check{' '}
                            <a
                                href="https://identity.checkr.com/login"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (Checkr)
                            </a>
                        </li>
                        <li>
                            Add applicant to payroll{' '}
                            <a
                                href="https://app.gusto.com/people/add_team_member/basics"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (Gusto)
                            </a>
                        </li>
                        <li>
                            Order expense card for plants{' '}
                            <a
                                href="https://dashboard.stripe.com/issuing/cards/create"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (Stripe)
                            </a>
                        </li>
                        <li>
                            Purchase tools{' '}
                            <a
                                href="https://www.amazon.com/hz/wishlist/ls/3I2SM2HEHLNVX?ref_=abls_nvfly_yl"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                (Amazon Prime)
                            </a>
                        </li>
                    </ul>
                </div>
                <button
                    className="btn3 small"
                    onClick={() => setApplicationApproved(false)}
                >
                    Close
                </button>
            </div>
        );
    } else {
        return (
            <div>
                <div className="data-detail mb25">
                    <label>Status</label>
                    <select
                        style={{ marginTop: 'var(--unit2)' }}
                        className="fit-content"
                        value={selectedApplication.status || PENDING}
                        disabled={
                            selectedApplication.status === APPROVED ||
                            selectedApplication.status === REJECTED
                        }
                        onChange={(e) => {
                            if (e.target.value === APPROVED)
                                approveApplication();
                            if (e.target.value === REJECTED)
                                rejectApplication();
                        }}
                    >
                        <option value={PENDING}>pending</option>
                        <option value={APPROVED}>approved</option>
                        <option value={REJECTED}>rejected</option>
                    </select>
                </div>
                <div className="data-detail">
                    <label>Date Applied</label>
                    <p>
                        {moment(selectedApplication.date).format('MM/DD/YYYY')}
                    </p>
                </div>
                <div className="data-detail">
                    <label>Role</label>
                    <p>{selectedApplication.role}</p>
                </div>
                <div className="data-detail">
                    <label>Name</label>
                    <p>{`${selectedApplication.first_name} ${selectedApplication.last_name}`}</p>
                </div>
                <div>
                    <label>Email</label>
                    <p>{selectedApplication.email}</p>
                </div>
                <div className="data-detail">
                    <label>Phone Number</label>
                    <p>{formatNumber(selectedApplication.phone_number)}</p>
                </div>
                <div className="data-detail">
                    <label>Address</label>
                    <p>{`${selectedApplication.address}${
                        selectedApplication.unit
                            ? ` #${selectedApplication.unit}`
                            : ''
                    }, ${selectedApplication.city} ${
                        selectedApplication.state
                    } ${selectedApplication.zip_code}`}</p>
                </div>
                <div className="data-detail">
                    <label>Business Name</label>
                    <p>{selectedApplication.business_name || 'n/a'}</p>
                </div>
                <div className="data-detail">
                    <label>Business Website</label>
                    <p>{selectedApplication.business_website || 'n/a'}</p>
                </div>
            </div>
        );
    }
};

ApplicationDetails = connect(null, mapDispatchToProps)(ApplicationDetails);

export default ApplicationDetails;
