// user types
export const ADMIN = 'admin';
export const CUSTOMER = 'customer';
export const GARDENER = 'gardener';
export const PARTNER = 'partner';

// order types
export const YARD_ASSESSMENT = 'yard assessment';
export const INSTALLATION = 'installation';
export const REVIVE = 'revive';
export const INITIAL_PLANTING = 'initial planting';
export const FULL_PLAN = 'full plan';
export const ASSISTED_PLAN = 'assisted plan';
export const CROP_ROTATION = 'crop rotation';
export const SOIL_ANALYSIS = 'soil analysis';
export const SOIL_REFILL = 'soil refill';
export const MISC = 'misc';

// order status types
export const ORDER_PENDING = 'pending';
export const ORDER_COMPLETE = 'complete';

// bid status types
export const BID_REQUESTED = 'bid requested';
export const BID_PENDING_APPROVAL = 'pending approval';

// general status types
export const APPROVED = 'approved';
export const PENDING = 'pending';
export const REJECTED = 'rejected';
export const CANCELLED = 'cancelled';

// season types
export const SPRING = 'spring';
export const FALL = 'fall';
export const ANNUAL = 'annual';

// plant category types
export const VEGETABLE = 'vegetable';
export const CULINARY_HERB = 'culinary herb';
export const FRUIT = 'fruit';

// review types
export const GOOGLE = 'google';

// plant activities
export const PLANTED = 'planted';
export const HARVESTED = 'harvested';
export const DECEASED = 'deceased';

// harvest types
export const FULL_HARVEST = 'full';
export const PARTIAL_HARVEST = 'partial';

// produce types
export const POD = 'pod';
export const ROOT = 'root';
export const BUD = 'bud';
export const LEAF = 'leaf';
export const STEM = 'stem';
export const FLOWER = 'flower';
export const SEED = 'seed';
export const HEAD = 'head';

// harvest schedule types
export const WEEKLY = 'weekly';
export const BI_WEEKLY = 'bi-weekly';

// educational plans
export const GARDEN_BOOTCAMP = 'garden bootcamp';
