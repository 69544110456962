import React from "react";
function LogoMark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      style={ props.style}
      viewBox="0 0 750 746.682"
    >
      <path
        fill="#0f0"
        d="M252.117 346.884a397.092 397.092 0 0053.615 26.368 397.608 397.608 0 0059.878 18.718 401.855 401.855 0 0032.328 5.974 399.638 399.638 0 00-5.963-32.317 397.6 397.6 0 00-18.711-59.87 397.086 397.086 0 00-26.365-53.616 401.278 401.278 0 00-32.501-47.115 409.127 409.127 0 00-30.106-33.662C222.14 109.213 143.204 70.004 57.72 57.785c12.228 85.464 51.504 164.367 113.643 226.506a409.11 409.11 0 0033.645 30.093 401.396 401.396 0 0047.108 32.5z"
      ></path>
      <path d="M455.655 455.655c0-.527-.018-1.05-.02-1.577-.525.527-1.07 1.031-1.597 1.556.54.001 1.077.02 1.618.02zM455.635 454.078c17.3-17.359 33.588-35.721 48.825-54.954 76.483-96.547 125.853-215.545 136.577-345.518a666.395 666.395 0 001.624-25.668c.667-15.231-11.552-27.939-26.798-27.939-14.326 0-26.129 11.269-26.751 25.582a612.842 612.842 0 01-1.868 28.024c-5.436 60.698-20.105 119.669-43.891 175.904-23.438 55.413-54.898 106.353-93.736 152.005a459.943 459.943 0 012.863 20.495 459.08 459.08 0 013.154 52.068z"></path>
      <path d="M722.224.017c-14.181.492-25.309 12.498-25.842 26.677-.339 9-.847 17.971-1.523 26.912-5.678 75.17-23.333 148.163-52.717 217.636a694.737 694.737 0 01-58.793 110.19 694.09 694.09 0 01-50.485 67.677c-12.706 15.035-26.047 29.616-40.093 43.662-14.045 14.045-28.626 27.387-43.661 40.092a694.943 694.943 0 01-177.867 109.278c-60.177 25.453-122.997 42.105-187.584 49.796-15.98 1.903-30.052-10.57-30.052-26.663 0-13.532 10.073-25.005 23.51-26.61 120.801-14.432 231.313-62.328 322.005-134.175a647.19 647.19 0 0054.916-48.854 460.226 460.226 0 01-72.533-6.018c-45.646 38.842-96.583 70.301-151.994 93.737-46.773 19.783-95.439 33.259-145.416 40.362-16.105 2.289-30.487-10.312-30.487-26.579 0-13.284 9.722-24.622 22.873-26.501 88.368-12.628 169.764-46.832 238.735-97.071a452.647 452.647 0 01-59.716-24.141c-52.041 32.604-109.703 54.82-170.399 65.563-16.424 2.907-31.492-9.752-31.492-26.431 0-13.002 9.334-24.102 22.134-26.384a425.807 425.807 0 00126.088-43.72 457.568 457.568 0 01-47.156-36.385 371.137 371.137 0 01-74.263 24.573 375.49 375.49 0 01-19.052 3.625C26.094 376.034 0 406.13 0 441.863v232.294c0 42.849 37.005 76.618 79.617 72.123l.793-.084c69.464-7.4 136.088-24.276 198.57-49.312 21.214-8.5 41.964-17.912 62.169-28.242 29.421-15.041 57.69-32.003 84.704-50.654A754.683 754.683 0 00617.99 425.851a749.862 749.862 0 0050.584-84.661 746.37 746.37 0 0028.31-62.212c31.286-78.08 49.86-162.627 53.097-251.093.568-15.533-12.087-28.414-27.758-27.87z"></path>
      <path d="M425.522 53.607a433.822 433.822 0 002.375-24.85C428.931 13.22 416.72 0 401.15 0c-14.187 0-25.8 11.065-26.741 25.221a380.854 380.854 0 01-2.958 28.385c-4.998 35.152-14.904 69.112-29.375 101.076a457.568 457.568 0 0136.38 47.151c24.15-45.189 40.455-95.2 47.067-148.227zM533.404 53.607a542.02 542.02 0 001.927-25.464C536.129 12.838 523.866 0 508.54 0c-14.316 0-26.003 11.26-26.76 25.556a496.471 496.471 0 01-2.284 28.05c-7.936 72.241-31.86 140.931-70.069 201.898a452.552 452.552 0 0124.146 59.738c54.546-74.892 90.178-164.435 99.829-261.637z"></path>
      <path d="M255.498 409.424a452.647 452.647 0 00126.007 40.193 460.24 460.24 0 0072.533 6.018c.527-.525 1.072-1.03 1.597-1.556a459.066 459.066 0 00-3.154-52.068 456.825 456.825 0 00-2.863-20.495 452.633 452.633 0 00-40.19-126.01 455.24 455.24 0 00-30.973-53.671 457.46 457.46 0 00-36.38-47.151c-35.968-40.848-79.129-75.2-127.515-101.076C165.58 27.413 111.251 9.935 53.645 3.17 36.047 1.104 18.154 0 0 0c0 18.157 1.095 36.057 3.161 53.66 6.76 57.607 24.253 111.924 50.446 160.901 25.874 48.381 60.222 91.54 101.066 127.506a457.46 457.46 0 0047.156 36.385 455.155 455.155 0 0053.669 30.973zM57.72 57.785c85.483 12.219 164.42 51.428 226.571 113.579a408.944 408.944 0 0130.106 33.662 401.566 401.566 0 0132.501 47.115 397.263 397.263 0 0126.365 53.616 397.6 397.6 0 0118.711 59.87 400.155 400.155 0 015.963 32.317 401.334 401.334 0 01-32.328-5.974 397.608 397.608 0 01-59.878-18.718 397.15 397.15 0 01-53.615-26.368 401.275 401.275 0 01-47.108-32.5 408.743 408.743 0 01-33.645-30.093C109.224 222.152 69.948 143.249 57.72 57.785z"></path>
      <path d="M589.181 619.343L595.649 619.343 595.649 643.278 601.256 643.278 601.256 619.343 607.725 619.343 607.725 614.513 589.181 614.513 589.181 619.343z"></path>
      <path d="M633.903 614.513L625.925 632.798 618.292 614.513 614.238 614.513 608.89 643.278 614.583 643.278 617.516 625.553 624.89 643.278 626.788 643.278 634.507 625.553 637.095 643.278 642.787 643.278 637.957 614.513 633.903 614.513z"></path>
    </svg>
  );
}

export default LogoMark;
