// libs
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

// UI components
import Icon from '@material-ui/core/Icon';
import FormatQuoteOutlinedIcon from '@material-ui/icons/FormatQuoteOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import Link from '../components/UI/Link';
import LoaderModal from '../components/LoaderModal';
import Membership from '../components/Membership';
import PaymentMethod from '../components/PaymentMethod';
import MainContentWindow from '../components/MainContentWindow';
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import CustomerOrderDetails from '../components/CustomerOrderDetails';
import CustomerBidDetails from '../components/CustomerBidDetails';
import CustomerPlantSelect from '../components/CustomerPlantSelect';
import CustomerPlantFavorites from '../components/CustomerPlantFavorites';
import Card from '../components/UI/Card';
import Tabs from '../components/UI/Tabs';
import LinearSavingIndicator from '../components/UI/LinearSavingIndicator';
import HarvestSchedule from '../components/HarvestSchedule';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// helpers
import { formatNumber } from '../formatting/phone';
import capitalize from '../helpers/capitalize';
import formatOrderTime from '../formatting/orderTime';
import logInfo from '../helpers/logInfo';

// vars
import {
    ORDER_PENDING,
    ORDER_COMPLETE,
    BID_PENDING_APPROVAL,
    FULL_PLAN,
    ASSISTED_PLAN,
    GOOGLE,
    SPRING,
    FALL,
    ANNUAL,
} from '../vars/types';

// styling
import '../css/account.css';
import '../css/customer-orders.css';
import '../css/customer-quotes.css';
import '../css/google-review-prompt.css';

//images
const googleReviewImage =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/google_review_harvest.png';

export const ADD_PAYMENT_METHOD = 'add payment method';
export const CHANGE_PAYMENT_METHOD = 'change payment method';
export const DISPLAY_PAYMENT_METHOD_INFO = 'display payment method info';

let Account = (props) => {
    const {
        logout,
        getOrders,
        getBids,
        getPlantSelection,
        getSubscription,
        getReviews,
        getBeds,
        getPlantCommonTypes,
        updatePlantSelection,
        user,
        orders,
        bids,
        beds,
        plantSelection,
        commonTypes,
    } = props;
    const [activeTitle, setActiveTitle] = useState('Plants');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedBid, setSelectedBid] = useState(null);
    const [springSelections, setSpringSelections] = useState([]);
    const [fallSelections, setFallSelections] = useState([]);
    const [favoriteSpringSelections, setFavoriteSpringSelections] = useState(
        [],
    );
    const [favoriteFallSelections, setFavoriteFallSelections] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(SPRING);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const [isEditingUser, setIsEditingUser] = useState(false);
    const [userFirstName, setUserFirstName] = useState(user.data.first_name);
    const [userLastName, setUserLastName] = useState(user.data.last_name);
    const [userEmail, setUserEmail] = useState(user.data.email);
    const [userPhoneNumber, setUserPhoneNumber] = useState(
        formatNumber(user.data.phone_number),
    );
    const [paymentMethodAction, setPaymentMethodAction] = useState(
        DISPLAY_PAYMENT_METHOD_INFO,
    );
    const [reviewsPromptIsOpen, setReviewsPromptIsOpen] = useState(false);
    const [activeView, setActiveView] = useState('plants');
    const [activePlantsTab, setActivePlantsTab] = useState('varieties');
    const [isSaving, setIsSaving] = useState(false);
    const tabContent = [
        // {
        //     title: 'Messages',
        //     icon: '✉️',
        //     active: false,
        //     onClick: () => {
        //         setActiveView('messages');
        //     },
        //     disabled: true,
        // },
        {
            title: 'Logout',
            icon: '➡️',
            active: false,
            onClick: () => {
                logout();
            },
            disabled: false,
        },
    ];

    const sideNavContent = [
        {
            title: 'plants',
            icon: <Icon>list_alt_outlined</Icon>,
            disabled: false,
        },
        {
            title: 'quotes',
            icon: <FormatQuoteOutlinedIcon />,
            disabled: false,
        },
        {
            title: 'orders',
            icon: <EventAvailableOutlinedIcon />,
            disabled: false,
        },
    ];

    const debounceEditPlantSelectionRef = useRef(null);
    const debounceEditPlantFavoritesRef = useRef(null);

    useEffect(() => {
        const initPageData = async () => {
            await getOrders();
            await getBids();
            await getBeds(`customer=${user.data._id}`);
            await getPlantSelection(user.data._id);

            if (user.data.payment_info && user.data.payment_info.plan_id) {
                await getSubscription(user.data.payment_info.plan_id);
            }
            const reviews = await getReviews(
                `customer=${user.data._id}&type=${GOOGLE}`,
            );

            if (reviews.data.length === 0) {
                setReviewsPromptIsOpen(true);
            }

            setLoadingModalIsOpen(false);
            logInfo('Account page loaded', { user: user.data });
        };

        initPageData();
    }, []);

    useEffect(() => {
        setActiveTitle(capitalize(activeView));
    }, [activeView]);

    useEffect(() => {
        if (selectedOrder !== null) {
            setActiveView('order details');
        }

        if (selectedBid !== null) {
            setActiveView('quote details');
        }
    }, [selectedOrder, selectedBid]);

    useEffect(() => {
        const setPlantSelections = async () => {
            const plantCommonTypes = commonTypes.data
                ? commonTypes
                : await getPlantCommonTypes();

            const springPlants = plantCommonTypes.data.filter(
                (plant) =>
                    plant.primary.season === SPRING ||
                    plant.primary.season === ANNUAL,
            );
            const fallPlants = plantCommonTypes.data.filter(
                (plant) =>
                    plant.primary.season === FALL ||
                    plant.primary.season === ANNUAL,
            );

            if (plantSelection.data) {
                const springPlantList = springPlants
                    .map((commonType) => {
                        return {
                            plant: commonType,
                            selected: plantSelection.data.spring_plants.some(
                                (plant) => plant._id === commonType._id,
                            ),
                        };
                    })
                    .filter((plant) => plant !== undefined);

                const fallPlantList = fallPlants
                    .map((commonType) => {
                        return {
                            plant: commonType,
                            selected: plantSelection.data.fall_plants.some(
                                (plant) => plant._id === commonType._id,
                            ),
                        };
                    })
                    .filter((plant) => plant !== undefined);

                setSpringSelections(springPlantList);
                setFallSelections(fallPlantList);
            }
        };

        setPlantSelections();
    }, [plantSelection]);

    useEffect(() => {
        const selectedSpringPlants = springSelections.filter(
            (springSelection) => springSelection.selected === true,
        );
        const selectedFallPlants = fallSelections.filter(
            (fallSelection) => fallSelection.selected === true,
        );

        const favoritePlantsForSpring = selectedSpringPlants.map((p) => {
            const isFavorite = plantSelection.data.spring_favorites.some(
                (springFavorite) => springFavorite._id === p.plant._id,
            );
            return {
                ...{ plant: p.plant },
                ...{ selected: isFavorite },
            };
        });

        const favoritePlantsForFall = selectedFallPlants.map((p) => {
            const isFavorite = plantSelection.data.fall_favorites.some(
                (springFavorite) => springFavorite._id === p.plant._id,
            );
            return {
                ...{ plant: p.plant },
                ...{ selected: isFavorite },
            };
        });

        setFavoriteSpringSelections(favoritePlantsForSpring);
        setFavoriteFallSelections(favoritePlantsForFall);
    }, [springSelections, fallSelections]);

    useEffect(() => {
        debounceEditPlantSelectionRef.current = debounce((plants) => {
            editPlantSelection(plants);
        }, 3000);

        return () => {
            if (debounceEditPlantSelectionRef.current) {
                debounceEditPlantSelectionRef.current.cancel();
            }
        };
    }, [editPlantSelection]);

    useEffect(() => {
        debounceEditPlantFavoritesRef.current = debounce((plants) => {
            editFavorites(plants);
        }, 3000);

        return () => {
            if (debounceEditPlantFavoritesRef.current) {
                debounceEditPlantFavoritesRef.current.cancel();
            }
        };
    }, [editFavorites]);

    const handlePlantSelectionChange = useCallback((plants) => {
        if (debounceEditPlantSelectionRef.current) {
            debounceEditPlantSelectionRef.current(plants);
        }
    }, []);

    const handlePlantFavoritesChange = useCallback((plants) => {
        if (debounceEditPlantFavoritesRef.current) {
            debounceEditPlantFavoritesRef.current(plants);
        }
    }, []);

    function handleOrderClick(order) {
        setSelectedOrder(order);
    }

    function handleOrderBackClick() {
        setSelectedOrder(null);
        setActiveView('orders');
    }

    function handleBidClick(bid) {
        setSelectedBid(bid);
    }

    function handleBidBackClick() {
        setSelectedBid(null);
        setActiveView('quotes');
    }

    function resetSelectedData() {
        setSelectedOrder(null);
        setSelectedBid(null);
    }

    function editPlantSelection(plants) {
        setIsSaving(true);
        setTimeout(async () => {
            const selectedPlantsIds = plants
                .map((p) => {
                    if (p.selected) {
                        return p.plant._id;
                    } else {
                        return undefined;
                    }
                })
                .filter((plant) => plant !== undefined);

            let updatedPlantSelection = {};
            if (selectedSeason === SPRING) {
                updatedPlantSelection.springPlants = selectedPlantsIds;
                const updatedFavoritePlantsForSpring = selectedPlantsIds
                    .map((plantId) => {
                        const isFavorite =
                            plantSelection.data.spring_favorites.some(
                                (springFavorite) =>
                                    springFavorite._id === plantId,
                            );

                        if (isFavorite) {
                            return plantId;
                        } else {
                            return undefined;
                        }
                    })
                    .filter((plantId) => plantId !== undefined);
                updatedPlantSelection.springFavorites =
                    updatedFavoritePlantsForSpring;
            } else if (selectedSeason === FALL) {
                updatedPlantSelection.fallPlants = selectedPlantsIds;
                const updatedFavoritePlantsForFall = selectedPlantsIds
                    .map((plantId) => {
                        const isFavorite =
                            plantSelection.data.fall_favorites.some(
                                (fallFavorite) => fallFavorite._id === plantId,
                            );

                        if (isFavorite) {
                            return plantId;
                        } else {
                            return undefined;
                        }
                    })
                    .filter((plantId) => plantId !== undefined);
                updatedPlantSelection.fallFavorites =
                    updatedFavoritePlantsForFall;
            }

            await updatePlantSelection(
                plantSelection.data._id,
                updatedPlantSelection,
            );

            getPlantSelection(user.data._id);
        }, 2000);
    }

    async function editFavorites(plants) {
        setIsSaving(true);
        setTimeout(async () => {
            const selectedPlantsIds = plants
                .map((p) => {
                    if (p.selected) {
                        return p.plant._id;
                    } else {
                        return undefined;
                    }
                })
                .filter((plant) => plant !== undefined);
            let updatedPlantSelection = {};
            if (selectedSeason === SPRING) {
                updatedPlantSelection.springFavorites = selectedPlantsIds;
            } else if (selectedSeason === FALL) {
                updatedPlantSelection.fallFavorites = selectedPlantsIds;
            }

            await updatePlantSelection(
                plantSelection.data._id,
                updatedPlantSelection,
            );

            getPlantSelection(user.data._id);
        }, 2000);
    }

    function renderOrdersTab() {
        if (orders.data) {
            return (
                <div id="customer-orders">
                    <div className="auto-grid-large orders-board">
                        {/* pending orders */}
                        <div className="orders-column">
                            <div className="orders-container">
                                <div className="flex-space-between">
                                    <h4>Pending...</h4>
                                    <data className="order-count">
                                        {
                                            orders.data.list.filter((order) => {
                                                return (
                                                    order.status ===
                                                    ORDER_PENDING
                                                );
                                            }).length
                                        }
                                    </data>
                                </div>
                                {orders.data.list.map((order, index) => {
                                    if (order.status === ORDER_PENDING) {
                                        return (
                                            <div
                                                className="order-card-container"
                                                key={index}
                                            >
                                                <div
                                                    className="order-card"
                                                    onClick={() =>
                                                        handleOrderClick(order)
                                                    }
                                                >
                                                    <div>
                                                        <label>
                                                            Order type
                                                        </label>
                                                        <h5>
                                                            {order.type ===
                                                                FULL_PLAN ||
                                                            order.type ===
                                                                ASSISTED_PLAN
                                                                ? 'Maintenance'
                                                                : capitalize(
                                                                      order.type,
                                                                  )}
                                                        </h5>
                                                    </div>
                                                    <div className="order-card-header">
                                                        <div className="order-date info">
                                                            <label>Date</label>
                                                            <data>
                                                                {new Date(
                                                                    order.date,
                                                                ).toLocaleDateString(
                                                                    'en-US',
                                                                    {
                                                                        weekday:
                                                                            'long',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    },
                                                                )}
                                                            </data>
                                                        </div>
                                                        <div className="order-time info">
                                                            <label>Time</label>
                                                            <data>
                                                                {formatOrderTime(
                                                                    order,
                                                                )}
                                                            </data>
                                                        </div>
                                                        {order.vendor && (
                                                            <div className="order-vendor info">
                                                                <label className="capitalize">
                                                                    {
                                                                        order
                                                                            .vendor
                                                                            .type
                                                                    }
                                                                </label>

                                                                <div className="vendor-info">
                                                                    <data>
                                                                        <img
                                                                            src={
                                                                                order
                                                                                    .vendor
                                                                                    .profile_image
                                                                            }
                                                                            className="avatar"
                                                                            alt={
                                                                                order
                                                                                    .vendor
                                                                                    .first_name
                                                                            }
                                                                        />
                                                                        <span>
                                                                            &nbsp;
                                                                            {capitalize(
                                                                                order
                                                                                    .vendor
                                                                                    .first_name,
                                                                            )}
                                                                        </span>
                                                                    </data>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    if (
                                        !orders.data.list.some(
                                            (order) =>
                                                order.status === ORDER_PENDING,
                                        )
                                    ) {
                                        return (
                                            <small
                                                key={index}
                                                className="column-empty disabled"
                                            >
                                                There are no pending orders
                                            </small>
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                        </div>

                        {/* complete orders */}
                        <div className="orders-column">
                            <div className="orders-container">
                                <div className="flex-space-between">
                                    <h4>...Completed</h4>
                                    <data className="order-count">
                                        {
                                            orders.data.list.filter((order) => {
                                                return (
                                                    order.status ===
                                                    ORDER_COMPLETE
                                                );
                                            }).length
                                        }
                                    </data>
                                </div>
                                {orders.data.list.map((order, index) => {
                                    if (order.status === ORDER_COMPLETE) {
                                        return (
                                            <div
                                                className="order-card-container"
                                                key={index}
                                            >
                                                <div
                                                    className="order-card"
                                                    onClick={() =>
                                                        handleOrderClick(order)
                                                    }
                                                >
                                                    <div>
                                                        <label>
                                                            Order Type:
                                                        </label>
                                                        <h5>
                                                            {order.type ===
                                                                FULL_PLAN ||
                                                            order.type ===
                                                                ASSISTED_PLAN
                                                                ? 'Maintenance'
                                                                : order.type}
                                                        </h5>
                                                    </div>
                                                    <div className="order-card-header">
                                                        <div className="order-date info">
                                                            <label>Date</label>
                                                            <data>
                                                                {new Date(
                                                                    order.date,
                                                                ).toLocaleDateString(
                                                                    'en-US',
                                                                    {
                                                                        weekday:
                                                                            'long',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    },
                                                                )}
                                                            </data>
                                                        </div>
                                                        <div className="order-time info">
                                                            <label>Time</label>
                                                            <data>
                                                                {formatOrderTime(
                                                                    order,
                                                                )}
                                                            </data>
                                                        </div>
                                                        {order.vendor && (
                                                            <div className="order-vendor info">
                                                                <label className="capitalize">
                                                                    {
                                                                        order
                                                                            .vendor
                                                                            .type
                                                                    }
                                                                </label>
                                                                <div className="vendor-info">
                                                                    <data>
                                                                        <img
                                                                            src={
                                                                                order
                                                                                    .vendor
                                                                                    .profile_image
                                                                            }
                                                                            className="avatar"
                                                                            alt={
                                                                                order
                                                                                    .vendor
                                                                                    .first_name
                                                                            }
                                                                        />
                                                                        <span>
                                                                            &nbsp;
                                                                            {capitalize(
                                                                                order
                                                                                    .vendor
                                                                                    .first_name,
                                                                            )}
                                                                        </span>
                                                                    </data>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    if (
                                        !orders.data.list.some(
                                            (order) =>
                                                order.status === ORDER_COMPLETE,
                                        )
                                    ) {
                                        return (
                                            <small className="column-empty disabled">
                                                There are no complete orders
                                            </small>
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

    function renderQuotesTab() {
        if (bids.data) {
            return (
                <div id="customer-quotes">
                    <div className="auto-grid-large">
                        <div className="bids-container pending">
                            <div className="flex-space-between flex-center-y">
                                <h4 className="mb4">Pending Approval...</h4>
                                <data className="order-count">
                                    {
                                        bids.data.list.filter((bid) => {
                                            return (
                                                bid.status ===
                                                BID_PENDING_APPROVAL
                                            );
                                        }).length
                                    }
                                </data>
                            </div>

                            {bids.data.list.some(
                                (bid) => bid.status === BID_PENDING_APPROVAL,
                            ) ? (
                                bids.data.list.map((bid, index) => {
                                    if (bid.status === BID_PENDING_APPROVAL) {
                                        return (
                                            <div
                                                key={index}
                                                className="bid-card"
                                                onClick={() => {
                                                    handleBidClick(bid);
                                                }}
                                            >
                                                <div className="bid-type">
                                                    <label>Title</label>
                                                    <h5>{bid.title}</h5>
                                                </div>
                                                <small>{bid.description}</small>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            ) : (
                                <div className="column-empty p15">
                                    <small>
                                        You do not have any quotes that need
                                        approval
                                    </small>
                                </div>
                            )}
                        </div>
                        <div className="bids-container approved">
                            <div className="flex-space-between flex-center-y">
                                <h4 className="mb4">... Approved</h4>
                                <data className="order-count">
                                    {
                                        bids.data.list.filter((bid) => {
                                            return bid.status === 'approved';
                                        }).length
                                    }
                                </data>
                            </div>

                            {bids.data.list.some(
                                (bid) => bid.status === 'approved',
                            ) ? (
                                bids.data.list.map((bid, index) => {
                                    if (bid.status === 'approved') {
                                        return (
                                            <div
                                                key={index}
                                                className="bid-card"
                                                onClick={() => {
                                                    handleBidClick(bid);
                                                }}
                                            >
                                                <div className="bid-type">
                                                    <label>Title</label>
                                                    <h5>{bid.title}</h5>
                                                </div>
                                                <small>{bid.description}</small>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            ) : (
                                <div className="column-empty">
                                    <small>
                                        You do not have any quotes that need
                                        approval
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <>Loading...</>;
        }
    }

    function renderPlantsTab() {
        if (!loadingModalIsOpen) {
            if (plantSelection.data) {
                const plantsTabs = [
                    {
                        title: `Varieties`,
                        icon: <Icon>grass_outlined</Icon>,
                        content: 'varieties',
                        value: 'varieties',
                    },
                    {
                        title: `Favorites`,
                        icon: <Icon>star_border_outlined</Icon>,
                        content: 'favorites',
                        value: 'favorites',
                    },
                ];

                return (
                    <>
                        <div className="flex flex-space-between flex-center-y mt4">
                            <span>
                                <select
                                    value={selectedSeason}
                                    onChange={(e) =>
                                        setSelectedSeason(e.target.value)
                                    }
                                >
                                    <option value={SPRING}>
                                        Spring / Summer
                                    </option>
                                    <option value={FALL}>Fall / Winter</option>
                                </select>
                            </span>
                            <Tabs
                                className="plants-tabs mt4 mb4"
                                data={plantsTabs}
                                defaultValue={'varieties'}
                                onChange={(item) => {
                                    const value = item.value;
                                    setActivePlantsTab(value);
                                }}
                            />
                        </div>
                        {activePlantsTab === 'varieties' && (
                            <div>
                                <CustomerPlantSelect
                                    plants={
                                        selectedSeason === SPRING
                                            ? springSelections
                                            : fallSelections
                                    }
                                    customerId={user.data._id}
                                    onChange={handlePlantSelectionChange}
                                />
                            </div>
                        )}
                        {activePlantsTab === 'favorites' && (
                            <div>
                                <CustomerPlantFavorites
                                    plants={
                                        selectedSeason === SPRING
                                            ? favoriteSpringSelections
                                            : favoriteFallSelections
                                    }
                                    onChange={handlePlantFavoritesChange}
                                />
                            </div>
                        )}
                    </>
                );
            } else {
                return (
                    <div className="mt25">
                        <h5 className="mb15">No plant selection was found</h5>
                        {beds.data && beds.data.length > 0 ? (
                            <button
                                className="btn3 small bg-white"
                                onClick={() =>
                                    (window.location.href = '/plant-selection')
                                }
                            >
                                <span
                                    style={{
                                        marginRight: 8,
                                    }}
                                >
                                    Select Plants
                                </span>
                            </button>
                        ) : (
                            <small>
                                Your plants will appear here once your garden
                                has been planted
                            </small>
                        )}
                    </div>
                );
            }
        }
    }

    function renderHarvestTab() {
        const pendingMaintenanceOrder = orders.data.list.find(
            (order) =>
                order.status === ORDER_PENDING &&
                (order.type === FULL_PLAN || order.type === ASSISTED_PLAN),
        );

        return (
            <div>
                <HarvestSchedule
                    pendingMaintenanceOrder={pendingMaintenanceOrder}
                />
            </div>
        );
    }

    function renderSettingsTab() {
        return (
            <div id="customer-settings" className="auto-grid-large">
                {/* account info */}
                <div className="settings-card" id="customer-account-info">
                    <div className="flex-space-between flex-center">
                        <h4>Account Info</h4>
                        {!isEditingUser && (
                            <Link onClick={() => setIsEditingUser(true)}>
                                Edit
                            </Link>
                        )}
                    </div>
                    <hr />
                    {isEditingUser ? (
                        <div>
                            <div
                                className="flex"
                                style={{ gap: 'var(--unit4)' }}
                            >
                                <div>
                                    <label>First Name*</label>
                                    <input
                                        type="text"
                                        placeholder="John"
                                        value={userFirstName}
                                        onChange={(e) =>
                                            setUserFirstName(e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Last Name*</label>
                                    <input
                                        type="text"
                                        placeholder="Smith"
                                        value={userLastName}
                                        onChange={(e) =>
                                            setUserLastName(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Email*</label>
                                <input
                                    type="email"
                                    placeholder="john@yardengarden.com"
                                    value={userEmail}
                                    onChange={(e) =>
                                        setUserEmail(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label>Phone Number*</label>
                                <input
                                    type="text"
                                    placeholder="(123) 456-7890"
                                    value={formatNumber(userPhoneNumber || '')}
                                    onChange={(e) =>
                                        setUserPhoneNumber(e.target.value)
                                    }
                                />
                            </div>
                            <div className="mt4 flex flex-center-y flex-space-between">
                                <Link onClick={() => setIsEditingUser(false)}>
                                    Cancel
                                </Link>
                                <button
                                    className="purple small flex flex-center-y"
                                    onClick={() => updateUserSettings()}
                                >
                                    <Icon>done</Icon>{' '}
                                    <span style={{ marginLeft: 8 }}>Save</span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="settings-card-info">
                            <div>
                                <label>Name</label>
                                <small className="capitalize">
                                    {user.data.first_name} {user.data.last_name}
                                </small>
                            </div>
                            <div>
                                <label>Email</label>
                                <small>{user.data.email}</small>
                            </div>

                            <div>
                                <label>Phone Number</label>
                                <small>
                                    {formatNumber(user.data.phone_number || '')}
                                </small>
                            </div>
                        </div>
                    )}
                </div>

                {/* billing info */}
                <div id="customer-billing-info" className="settings-card">
                    <div className="flex-space-between flex-center">
                        <h4>Payment Method</h4>
                        {paymentMethodAction === DISPLAY_PAYMENT_METHOD_INFO &&
                            user.data.payment_info && (
                                <Link
                                    onClick={() =>
                                        setPaymentMethodAction(
                                            CHANGE_PAYMENT_METHOD,
                                        )
                                    }
                                >
                                    Edit
                                </Link>
                            )}
                    </div>
                    <hr style={{ width: '100%' }} />
                    <PaymentMethod
                        action={paymentMethodAction}
                        onCancel={() =>
                            setPaymentMethodAction(DISPLAY_PAYMENT_METHOD_INFO)
                        }
                        onUpdate={() =>
                            setPaymentMethodAction(DISPLAY_PAYMENT_METHOD_INFO)
                        }
                    />
                </div>

                {/* membership */}
                <div className="settings-card">
                    <Membership />
                </div>
            </div>
        );
    }

    function renderMessages() {
        if (true) {
            return <div>Messages Content</div>;
        } else {
            return <></>;
        }
    }

    function renderReviewsPrompt() {
        return (
            <div className="google-review-prompt">
                <Card>
                    <div>
                        <div
                            className="pointer flex-end"
                            onClick={() => setReviewsPromptIsOpen(false)}
                        >
                            <Icon>close</Icon>
                        </div>
                        <img
                            loading="lazy"
                            className="google-review-image"
                            src={googleReviewImage}
                            alt="harveset basket"
                        />
                        <div style={{ paddingInline: 'var(--unit4)' }}>
                            <h3 style={{ color: 'var(--purpleB)' }}>
                                Love Yarden?
                            </h3>
                            <p style={{ textTransform: 'none' }}>
                                Write a review to get 1 FREE month of service!
                            </p>
                        </div>
                        <div>
                            <button
                                className="flex-center"
                                style={{ width: '100%' }}
                                onClick={() =>
                                    (window.location.href = '/reviews')
                                }
                            >
                                Write Review
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    function renderContent() {
        switch (activeView) {
            case 'orders':
                return renderOrdersTab();
            case 'order details':
                return (
                    <CustomerOrderDetails
                        order={selectedOrder}
                        onBack={handleOrderBackClick}
                    />
                );
            case 'quotes':
                return renderQuotesTab();
            case 'quote details':
                return (
                    <CustomerBidDetails
                        bid={selectedBid}
                        onBack={handleBidBackClick}
                    />
                );
            case 'plants':
                return renderPlantsTab();
            case 'harvest':
                return renderHarvestTab();
            case 'messages':
                return renderMessages();
            case 'settings':
                return renderSettingsTab();
            default:
                return <></>;
        }
    }

    async function updateUserSettings() {
        const { updateUser } = props;
        const updatedUserSettings = {
            userFirstName: userFirstName.trim(),
            userLastName: userLastName.trim(),
            userEmail: userEmail.trim(),
            userPhoneNumber: userPhoneNumber.replace(/\D/g, ''),
        };

        await updateUser(null, updatedUserSettings);
        setIsEditingUser(false);
    }

    return (
        <div id="customer-dashboard">
            <section id="customer-dashboard-content" style={{ maxWidth: 1366 }}>
                {/* modals */}
                <LoaderModal isOpen={loadingModalIsOpen} />

                {isSaving && (
                    <LinearSavingIndicator
                        isSaving={isSaving}
                        saveDuration={1000}
                        onFinish={() => setIsSaving(false)}
                    />
                )}

                {/* main content window */}
                <MainContentWindow
                    title={activeTitle}
                    content={renderContent()}
                    prompt={reviewsPromptIsOpen ? renderReviewsPrompt() : null}
                />

                {/* sidebar navigation */}
                <SideNav
                    userInitials={`${user.data.first_name[0]}${user.data.last_name[0]}`}
                    userFirstName={user.data.first_name}
                    content={sideNavContent}
                    onNavigate={(title) => {
                        setActiveView(title);
                        resetSelectedData();
                    }}
                />
            </section>

            {/* header */}
            <TopNav content={tabContent} />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        orders: state.order,
        bids: state.bid,
        beds: state.bed,
        plantSelection: state.plantSelection,
        commonTypes: state.plantCommonType,
        reviews: state.reviews,
    };
}

Account = connect(mapStateToProps, mapDispatchToProps)(Account);

export default Account;
