// libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Icon from '@material-ui/core/Icon';
import CustomerInfo from '../components/CustomerInfo';
import StepButtons from '../components/StepButtons';
import GeneralMessage from '../components/GeneralMessage';
import LoaderModal from '../components/LoaderModal';
import Navbar from '../components/Navbar';
import Filepicker from '../components/UI/Filepicker';

// formatting
import { setQty } from '../formatting/setQty.js';

// helpers
import logInfo from '../helpers/logInfo';

// vars
import vars from '../vars/index';
import {
    ORDER_COMPLETE,
    BID_PENDING_APPROVAL,
    SOIL_REFILL,
} from '../vars/types';
import { STANDARD_LABOR_RATE_PER_HOUR, PHONE_NUMBER } from '../vars/constants';

// styles
import '../css/soil-analysis-report.css';

let SoilAnalysisReport = ({
    getOrder,
    getItems,
    getImage,
    updateImage,
    updateOrder,
    createBid,
    sendEmail,
    sendSms,
    user,
}) => {
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [images, setImages] = useState([]);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [reportPreviouslySubmitted, setReportPreviouslySubmitted] =
        useState(false);
    const [reportSubmitted, setReportSubmitted] = useState(false);
    const [soilComponents, setSoilComponents] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [laborHours, setLaborHours] = useState(0);
    const [error, setError] = useState(false);
    const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search),
    );

    useEffect(() => {
        const initData = async () => {
            if (urlParams.order) {
                setLoadingModalIsOpen(true);

                try {
                    const order = await getOrder(urlParams.order);
                    if (order.data.status === ORDER_COMPLETE) {
                        setLoadingModalIsOpen(false);
                        return setReportPreviouslySubmitted(true);
                    }

                    setSelectedOrder(order.data);

                    const items = await getItems();

                    const soilItems = items.data.filter(
                        (item) =>
                            item.name === 'Soil' ||
                            item.name === 'Potting Mix' ||
                            item.name === 'Biochar' ||
                            item.name === 'Bio-Live (5 lb)',
                    );

                    setSoilComponents(soilItems);

                    logInfo('Soil Analysis Report page loaded', {
                        user: user.data,
                    });
                } catch (err) {
                    setError(true);
                }
            } else {
                setInvalidUrl(true);
            }

            setLoadingModalIsOpen(false);
        };

        initData();
    }, []);

    function next() {
        const validationMessage = validateInputs();
        if (validationMessage) {
            return window.alert(validationMessage);
        } else {
            let increment = 1;
            setActiveStep(activeStep + increment);
        }
    }

    function back() {
        let decrement = 1;
        if (activeStep - decrement === 0) {
            if (
                window.confirm(
                    'Go back? You will lose any data that has already been entered.',
                )
            ) {
                window.location.href = `${window.location.origin}/dashboard`;
            }
        } else {
            setActiveStep(activeStep - decrement);
        }
    }

    function validateInputs() {
        let validationMessage = '';
        if (activeStep === 1) {
            const totalItems = selectedItems.length;

            if (totalItems < 1) {
                validationMessage = 'Please select at least 1 item';
            }
        } else if (activeStep === 2) {
            if (!laborHours) {
                validationMessage = 'Please enter the required labor hours';
            }
        }

        return validationMessage;
    }

    function finish() {
        if (!images || images.length < 2) {
            return window.alert('Please upload at least 2 images');
        }

        setLoadingModalIsOpen(true);

        try {
            let uploadImages = [];
            for (let file in images) {
                uploadImages.push(
                    new Promise(async (resolve) => {
                        const uploadedImage = await uploadImage(images[file]);
                        resolve(uploadedImage);
                    }),
                );
            }

            Promise.all(uploadImages).then(async (uploadedImages) => {
                await updateOrder(selectedOrder._id, {
                    images: uploadedImages,
                    status: ORDER_COMPLETE,
                });

                let lineItems = {
                    labor: {
                        qty: laborHours,
                        price: STANDARD_LABOR_RATE_PER_HOUR,
                    },
                    delivery: {
                        name: 'Soil',
                        price: 25,
                    },
                    materials: [],
                };

                let materialsDescription = '';

                selectedItems.forEach((data, index) => {
                    const item = soilComponents.find(
                        (soilComponent) => soilComponent._id === data.item,
                    );

                    lineItems.materials.push({
                        name: item.name,
                        url: item.url,
                        price: item.price,
                        qty: data.qty,
                    });

                    materialsDescription += `(${data.qty}) ${item.name}${
                        index !== selectedItems.length - 1 ? ', ' : ''
                    }`;
                });

                const newSoilBid = {
                    status: BID_PENDING_APPROVAL,
                    estimated_start_dt: moment().add(2, 'weeks'),
                    customer: selectedOrder.customer._id,
                    attachments: uploadedImages,
                    type: SOIL_REFILL,
                    title: 'Soil Refill',
                    description:
                        vars.orderDescriptions.soilRefill +
                        ' Materials: ' +
                        materialsDescription,
                    line_items: lineItems,
                };

                const bid = await createBid(newSoilBid);

                const emailNotification = {
                    email: selectedOrder.customer.email,
                    subject: `Yarden - (ACTION REQUIRED) Soil Refill`,
                    label: 'Soil Refill',
                    body:
                        `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                        `<p style="margin-bottom: 15px">The soil levels for your garden are too low, please click the button below to review and approve the quote to refill.</p>` +
                        '<div style="border-top: 1px solid #DDDDDD;">' +
                        '<div style="margin-top: 25px; text-align: center;">' +
                        `<a href="${window.location.origin}/approve?bid=${bid.data._id}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>View Quote</b></a>` +
                        '</div>' +
                        '</div>',
                };

                const smsNotification = {
                    from: PHONE_NUMBER,
                    to: selectedOrder.customer.phone_number.replace(/\D/g, ''),
                    body: `Greetings from Yarden! The soil levels in your garden are too low, here is the quote to refill: ${window.location.origin}/approve?bid=${bid.data._id}`,
                };

                await sendEmail(emailNotification);
                await sendSms(smsNotification);

                setReportSubmitted(true);
                setLoadingModalIsOpen(false);
            });
        } catch (err) {
            setError(true);
            setLoadingModalIsOpen(false);
        }
    }

    function updateItems(itemId, action) {
        let items = [...selectedItems];
        const itemIndex = items.findIndex((i) => i.item === itemId);
        if (itemIndex !== -1) {
            if (action === 'add') {
                items[itemIndex].qty = items[itemIndex].qty + 1;
            } else if (action === 'subtract' && items[itemIndex].qty !== 0) {
                if (items[itemIndex].qty - 1 === 0) {
                    items.splice(itemIndex, 1);
                } else {
                    items[itemIndex].qty = items[itemIndex].qty - 1;
                }
            }
        } else {
            if (action === 'add') {
                items.push({
                    qty: 1,
                    item: itemId,
                });
            }
        }
        setSelectedItems(items);
    }

    function calculateQty(itemId) {
        const data = selectedItems.find((n) => n.item === itemId);
        const qty = data ? data.qty : 0;
        return qty;
    }

    async function uploadImage(image) {
        const hostedImg = await getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        await updateImage(signedRequest, image, options);

        return {
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        };
    }

    function renderStep1() {
        return (
            <div>
                <p>
                    Please indicate the number of bags needed for each soil
                    component to fully refill the garden beds
                </p>
                {soilComponents.map((soilComponent, index) => {
                    const qty = calculateQty(soilComponent._id);
                    return (
                        <div key={index}>
                            <div className="flex flex-space-between flex-center-y mb10">
                                <div className="flex flex-center-y">
                                    <label className="ml10 capitalize">
                                        {soilComponent.name}
                                    </label>
                                </div>
                                <div className="flex flex-center-y">
                                    <h4 className="mb5">{qty}</h4>
                                    <div
                                        className="pl25"
                                        onClick={() =>
                                            updateItems(
                                                soilComponent._id,
                                                'subtract',
                                            )
                                        }
                                    >
                                        <Icon>remove_outline</Icon>
                                    </div>
                                    <div
                                        className="pl25"
                                        onClick={() =>
                                            updateItems(
                                                soilComponent._id,
                                                'add',
                                            )
                                        }
                                    >
                                        <Icon>add_outline</Icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderStep2() {
        return (
            <div>
                <p>
                    How many labor hours will it require to refill the garden
                    beds?
                </p>
                <input
                    type="number"
                    value={laborHours}
                    onChange={async (e) => {
                        const qty = await setQty(laborHours, e.target.value);
                        setLaborHours(qty);
                    }}
                />
            </div>
        );
    }

    function renderStep3() {
        return (
            <div>
                <p>
                    Upload a minimum of 2 photos. The photos should display a
                    ruler next to the wall of each garden bed, showing the
                    garden club member that the soil level is more than 6" from
                    the top of the bed.
                </p>
                <div className="mt10">
                    <Filepicker
                        onChange={(files) => setImages(files)}
                        onReset={() => setImages([])}
                    />
                </div>
            </div>
        );
    }

    function renderMessage() {
        let message = '';
        let isSuccess = false;
        if (reportSubmitted) {
            message =
                'Success! Your soil analysis report has been submitted. A quote has been sent to the garden club member for approval.';
            isSuccess = true;
        } else if (invalidUrl) {
            message = 'Invalid URL, please contact support to continue.';
        } else if (reportPreviouslySubmitted) {
            message = 'You have already submitted a report for this order.';
        } else if (error) {
            message =
                'Uh oh! Something went wrong, we are working on a fix now.';
        }

        return (
            <GeneralMessage
                message={message}
                isSuccess={isSuccess}
                buttonText="Continue to dashboard"
                buttonAction={() => (window.location.pathname = '/dashboard')}
            />
        );
    }

    function renderView() {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            default:
                return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* page header */}
            <Navbar />

            <div className="pt75 pb25 pl25 pr25">
                {/* page view */}
                {!invalidUrl &&
                    !reportSubmitted &&
                    !reportPreviouslySubmitted &&
                    !error &&
                    selectedOrder && (
                        <div className="step-content">
                            <div className="mt25 text-center">
                                <h6>Soil Analysis Report</h6>
                                {
                                    <CustomerInfo
                                        customer={selectedOrder.customer}
                                    />
                                }
                            </div>
                            <div className="mt25">{renderView()}</div>
                            <div className="mt25">
                                <StepButtons
                                    next={() => next()}
                                    back={() => back()}
                                    finish={() => finish()}
                                    isLastStep={activeStep === 3}
                                />
                            </div>
                        </div>
                    )}

                {/* message view */}
                {(reportSubmitted ||
                    invalidUrl ||
                    reportPreviouslySubmitted ||
                    error) &&
                    renderMessage()}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        items: state.item,
    };
}

SoilAnalysisReport = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SoilAnalysisReport);

export default SoilAnalysisReport;
