import { RENDER_ORDERS, RENDER_MAP } from '../actions/render/types';

let initialState = {
  orders: true,
  map: false
}

export default function( state = initialState, action){
    switch(action.type){
        case RENDER_ORDERS:
            return {
                orders: true,
                map: false
            }
        case RENDER_MAP:
            return {
                orders: false,
                map: true
            }
        default:
            return state;
    }
}