// libs
import axios from 'axios';

// actions
import { throwError } from '../../actions/error/index';

// helpers
import getAuthToken from '../../formatting/getAuthToken';

// types
import { GET_SUBSCRIPTION } from './types';

export function createSubscription(subscription) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/subscriptions`,
                subscription,
                { headers: { authorization: getAuthToken() } },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getSubscription(id) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/subscriptions/${id}`, {
                headers: { authorization: getAuthToken() },
            });
            dispatch({ type: GET_SUBSCRIPTION, payload: response.data });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function deleteSubscription(id) {
    return async function (dispatch) {
        try {
            await axios.delete(`/api/v1/subscriptions/${id}`, {
                headers: { authorization: getAuthToken() },
            });
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function setSubscription(subscription) {
    return async function (dispatch) {
        try {
            dispatch({ type: GET_SUBSCRIPTION, payload: subscription });
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
