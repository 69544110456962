// libs
import React, { Component } from 'react';

// UI components
import Grid from '@material-ui/core/Grid';

// helpers
import logInfo from '../helpers/logInfo';

// styling
import '../css/terms-of-service.css';

class TermsOfService extends Component {
    componentDidMount() {
        logInfo('Terms of Service page loaded');
    }

    render() {
        return (
            <span>
                {/* terms of service start */}
                <Grid container spacing={2}>
                    <Grid item md={1} sm={1} xs={1}></Grid>
                    <Grid item md={10} sm={10} xs={10}>
                        <div className="section1-container">
                            <h3>Terms of Service</h3>
                            <p>
                                Please Read Carefully Before Using This Website:
                                The following terms of service (“Terms of
                                Service”) govern your use of the Yarden Website
                                (the “Site”) and the Yarden garden development &
                                maintenance service accessed through the Site
                                (“Service”), both of which are operated by
                                YARDEN GARDEN INC (“Yarden”). By using the Site
                                and/or the Service, you irrevocably agree that
                                such use is subject to these Terms of Service.
                                If you do not agree to these Terms of Service,
                                you may not use the Site or the Service. If you
                                are entering into these Terms of Service on
                                behalf of an entity, you represent that you have
                                the actual authority to bind such entity to
                                these Terms of Service. Yarden expressly
                                reserves the right to modify the Terms of
                                Service at any time in its sole discretion, and
                                without prior notice to you, by including such
                                alteration and/or modification in these Terms of
                                Service, along with a notice of the effective
                                date of such modified Terms of Service. Any
                                continued use by you of the Site or the Service
                                after the posting of such modified Terms of
                                Service shall be deemed to indicate your
                                irrevocable agreement to such modified Terms of
                                Service. Accordingly, if at any time you do not
                                agree to be subject to any modified Terms of
                                Service, you may no longer use the Site or
                                Service.
                            </p>
                            <h4 id="service-offer">1. Service Offer</h4>
                            <p>
                                Yarden is a garden services platform in the
                                field of urban food systems. For this purpose,
                                Yarden has developed a Service, which can be
                                accessed by you on the Internet to initiate
                                garden development & maintenance services at
                                your residence. The important functionality of
                                Yarden is to automate garden development &
                                maintenance services by connecting the all
                                parties involved to a central hub. The Yarden
                                site will be referred to collectively as the
                                Yarden platform.
                            </p>
                            <h4 id="user-accounts-passwords-and-fees">
                                2. User Accounts, Passwords and Fees
                            </h4>
                            <p>
                                (a) Account Registration and Use License: In
                                order to access and use all of the features of
                                the Service, you are required to open an account
                                (“User Account”) by registering with Yarden.
                                When you register for your User Account you must
                                provide true, accurate, current and complete
                                information (“User Profile”), and you agree to
                                update the Account Information in order to
                                ensure that it is current. Upon proper
                                registration and opening of a User Account, and
                                subject to all of the terms and conditions of
                                these Terms of Service, Yarden hereby grants to
                                you the personal, non-transferable right and
                                license to use the Service, solely for your own
                                internal business purposes, until such time as
                                either you or Yarden elect to terminate such
                                right in accordance with these Terms and
                                Conditions. (b) Eligibility: As an express
                                condition of being permitted to open a User
                                Account, you represent and warrant that you (i)
                                have the legal capacity (including, without
                                limitation, being of sufficient age) to enter
                                into contracts under the law of the jurisdiction
                                in which you reside. (c) Passwords: Upon
                                registration on the Site, you will provide
                                Yarden with a password to access your account.
                                You are responsible for maintaining the
                                confidentiality of your password and for all of
                                your activities and those of any third party
                                that occur through your account, whether or not
                                authorized by you. You agree to immediately
                                notify Yarden of any suspected or actual
                                unauthorized use of your User Account. You agree
                                that Yarden will not under any circumstances be
                                liable for any cost, loss, damages or expenses
                                arising out of a failure by you to maintain the
                                security of your password.
                            </p>
                            <h4 id="site-content">3. Site Content</h4>
                            <p>
                                (a) Yarden Content: Except as may be otherwise
                                noted, the information, materials (including,
                                without limitation, HTML, text, audio, video,
                                white papers, press releases, data sheets,
                                product descriptions, software and FAQs and
                                other content) available on the Site and/or the
                                Service (collectively, “Yarden Content”) are the
                                copyrighted works of Yarden and its licensors,
                                and Yarden and its licensors expressly retain
                                all right title and interest in and to the
                                Yarden Content, including, without limitation,
                                all intellectual property rights therein and
                                thereto. Except as expressly permitted in these
                                Terms of Service, any use of the Yarden Content
                                may violate copyright and/or other applicable
                                laws. (b) Third Party Content: In addition to
                                Yarden Content, the Site and/or the Service may
                                contain information and materials provided to
                                Yarden by third parties (collectively, “Third
                                Party Content”). Third Party Content is the
                                copyrighted work of its owner, who expressly
                                retains all right title and interest in and to
                                the Third Party Content, including, without
                                limitation, all intellectual property rights
                                therein and thereto. In addition to being
                                subject to these Terms of Service, Third Party
                                Content may also be subject to different and/or
                                additional terms of use and/or privacy policies
                                of such third parties. Please contact the
                                appropriate third party for further information
                                regarding any such different and/or additional
                                terms of use applicable to Third Party Content.
                                (c) Limited Site Content License: Yarden grants
                                you the limited, revocable, non-transferable,
                                non-exclusive right to use the Yarden Content
                                and Third Party Content (collectively, “Site
                                Content”) by displaying the Site Content on your
                                computer, and downloading and printing pages
                                from the Site containing Site Content, under the
                                condition that (i) such activity is solely for
                                your personal, education or other noncommercial
                                use, (ii) you do not modify or prepare
                                derivative works from the Site Content, (iii)
                                you do not obscure, alter or remove any notice
                                of copyright set forth on any Site pages or Site
                                Content, (iv) you do not otherwise reproduce,
                                re-distribute or publicly display any of the
                                Site Content and (v) you do not copy any Site
                                Content to any other media or other storage
                                format.
                            </p>
                            <h4 id="use-of-the-service">
                                4. Use of the service
                            </h4>
                            <p>
                                (a) Account and Use of Service: You may use your
                                User Account for the Service only in accordance
                                with these Terms of Service and only for lawful
                                purposes. You are responsible for your own
                                communications, including the upload,
                                transmission and posting of information, and are
                                responsible for the consequences of their
                                posting on or through the Service. (b) Fees:
                                Some features of the Service may only be
                                accessed and used upon the payment of applicable
                                fees (“Fees”). Fees may vary depending on usage
                                in accordance with our current pricing policy.
                                (c) Prohibited Conduct: Yarden specifically
                                prohibits any use of the Site or the Service
                                for: (i) posting any (1) information which is
                                incomplete, false, inaccurate or not your own,
                                (2) trade secrets or material that is
                                copyrighted or otherwise owned by a third party
                                unless you have a valid license from the owner
                                which permits you to post it, (3) material that
                                infringes on any other intellectual property,
                                privacy or publicity right of another, (4)
                                advertisement, promotional materials or
                                solicitation related to any product or service
                                that is competitive with Yarden products or
                                services or (5) software or programs which
                                contain any harmful code, including, but not
                                limited to, viruses, worms, time bombs or Trojan
                                horses; (ii) impersonating another person; (iii)
                                engaging in or encouraging conduct that would
                                constitute a criminal offense, give rise to
                                civil liability or otherwise violate any city,
                                state, national or international law or
                                regulation, or which fails to comply with
                                accepted Internet protocol. (d) User Content: If
                                you elect to display, post, submit or otherwise
                                make available to others, on the Site any
                                content or works of authorship, including,
                                without limitation, images, audio files, text,
                                software (but excluding User software) or other
                                materials, (collectively, “User Content”), you
                                hereby grant to Yarden a perpetual, irrevocable,
                                royalty-free, worldwide, non-exclusive right and
                                license, including the right to grant
                                sublicenses to third parties, to use, reproduce,
                                publicly display, publicly perform, prepare
                                derivative works from and distribute such User
                                Content, for any purpose. In addition, you
                                hereby irrevocably represent and warrant to
                                Yarden that (i) you have all necessary power,
                                authority, right, title and/or licenses to grant
                                to Yarden the foregoing right and license and
                                (ii) the posting, submission, display by you of
                                User Content on the Site, and the exercise by
                                Yarden of the foregoing license does not and
                                will not (1) violate any applicable law or
                                government regulation or (2) infringe any right
                                of publicity or invades the privacy of others,
                                or any intellectual property right of any third
                                party, (iii) no User Content you provide (1)
                                will constitute obscene, pornographic, indecent,
                                profane or otherwise objectionable material, (2)
                                is discriminatory, hateful or bigoted toward, or
                                abusive of, any group or individual, or (3) is
                                libelous or defamatory. (e) Suggestions: You
                                hereby grant to Yarden a royalty-free,
                                worldwide, transferable, sublicenseable,
                                irrevocable, perpetual license to use or
                                incorporate into the Site and/or the Service any
                                suggestions, enhancement requests,
                                recommendations or other feedback provided by
                                you to Yarden that is related to the Site and/or
                                the Service. (f) Indemnification: You hereby
                                irrevocably agree to indemnify, defend and hold
                                Yarden, its affiliates, directors, officers,
                                employees and agents harmless from and against
                                any and all loss, costs, damages, liabilities
                                and expenses (including attorneys’ fees) arising
                                out of or related to (i) any third party claim
                                resulting from a breach by you of any of your
                                covenants, representations or warranties
                                contained in these Terms of Service and/or (ii)
                                your use of the Site and/or the Service. (g)
                                Export Control: You hereby represent and warrant
                                that you understand and acknowledge that some
                                Site Content may be subject to export, re-export
                                and import restrictions under applicable law.
                            </p>
                            <h4 id="linked-websites">5. Linked Websites</h4>
                            <p>
                                The Site may contain links to other websites
                                that are not owned or operated by Yarden, and
                                you agree that Yarden provides links to such
                                websites solely as a convenience and has no
                                responsibility for the content or availability
                                of such websites, and that Yarden does not
                                endorse such websites or any products or
                                services associated therewith. Your use of such
                                websites will be subject to the terms of use
                                applicable to each such website.
                            </p>
                            <h4 id="location-of-the-site-and-your-use">
                                6. Location of the site and your use
                            </h4>
                            <p>
                                Yarden operates or controls the operation of
                                this Site and the Service from offices in the
                                United States. In addition, the Site and the
                                Service may be mirrored, and other websites
                                operated or controlled by Yarden may be located,
                                at various locations in and outside of the
                                United States. Yarden makes no representation or
                                warranty that all of the features of this Site
                                or Service will be available to you outside of
                                the United States, or that they are permitted to
                                be accessed outside of the United States. You
                                acknowledge that you are solely responsible for
                                any decision by you to use of this Site and/or
                                the Service from other locations, and that such
                                use may be subject to, and that you are
                                responsible for, compliance with applicable
                                local laws. These Terms of Service constitute
                                the entire agreement and understanding between
                                the parties concerning the subject matter
                                hereof, notwithstanding any different or
                                additional terms that may be contained in the
                                form of purchase order or other document used by
                                you to place orders or otherwise effect
                                transactions hereunder, which such terms are
                                hereby rejected. These Terms of Service
                                supersede all prior or contemporaneous
                                discussions, proposals and agreements between
                                you and Yarden relating to the subject matter
                                hereof. No amendment, modification or waiver of
                                any provision of these Terms of Service will be
                                effective unless in writing and signed by an
                                officer of Yarden. If any provision of these
                                Terms of Service is held to be invalid or
                                unenforceable, the remaining portions will
                                remain in full force and effect and such
                                provision will be enforced to the maximum extent
                                possible so as to effect the intent of the
                                parties and will be reformed to the extent
                                necessary to make such provision valid and
                                enforceable. No waiver of rights by Yarden may
                                be implied from any actions or failures to
                                enforce rights under these Terms of Service.
                                These Terms of Service are intended to be and
                                are solely for the benefit of Yarden and you and
                                do not create any right in favor of any third
                                party. These Terms of Service will be governed
                                by and construed in accordance with the laws of
                                the State of Delaware, without reference to its
                                conflict of laws principles. All disputes
                                arising out of or relating to these Terms of
                                Service will be submitted to the exclusive
                                jurisdiction of a court of competent
                                jurisdiction located in Washington D.C, United
                                States, and each party irrevocably consents to
                                such personal jurisdiction and waives all
                                objections to this venue.
                            </p>
                            <h4 id="sms-communication">7. SMS Communication</h4>
                            <p>
                                By agreeing to these Terms of Service, you
                                consent to receive text message communications
                                from Yarden Garden Inc, standard messaging rates
                                may apply; to opt-out, reply "STOP" to (888)
                                828-9287 at any time, but note that you may miss
                                important updates, so we recommend staying
                                subscribed.
                            </p>
                            <h4 id="quote-generation">8. Quote Generation</h4>
                            <p>
                                Yarden Garden Inc. will provide a detailed quote
                                for the requested gardening services, which may
                                include, but is not limited to, landscaping,
                                planting, maintenance, and garden design
                                services. This quote will itemize the full cost
                                of materials, delivery charges, tool rental
                                fees, disposal fees, and labor costs associated
                                with the proposed services.
                            </p>
                            <h4 id="quote-approval-process">
                                9. Quote Approval Process
                            </h4>
                            <p>
                                Upon receiving a quote from Yarden Garden Inc.,
                                you ("the Client") have the option to approve
                                the quote electronically via the method provided
                                by Yarden Garden Inc. Approval of the quote
                                indicates the Client's agreement to the
                                services, costs, and terms outlined within the
                                quote.
                            </p>
                            <h4
                                id="payment-of-job-materials-and-other-upfront
                                costs"
                            >
                                10. Payment of Job Materials and Other Upfront
                                Costs
                            </h4>
                            <p>
                                Once a quote is approved by the Client, Yarden
                                Garden Inc. will charge the credit or debit card
                                on file for the total cost of materials,
                                delivery, tool rentals, and disposal fees as
                                listed in the approved quote. This charge will
                                occur prior to the commencement of any work.
                            </p>
                            <h4 id="job-completion-and-final-payment">
                                11. Job Completion and Final Payment
                            </h4>
                            <p>
                                Upon completion of the job, as described in the
                                quote, Yarden Garden Inc. will issue a
                                completion notice to the Client. Following this
                                notice, the card on file will be charged for the
                                labor amount listed in the quote. It is the
                                responsibility of the Client to ensure that
                                funds are available to cover this charge.
                            </p>
                            <h4 id="job-refund-policy">
                                12. Job Refund Policy
                            </h4>
                            <p>
                                Once a quote has been approved by the Client,
                                refunds for any charges related to materials,
                                delivery, tool rentals, disposal, and labor
                                costs are not permitted. Yarden Garden Inc.
                                commits to fulfilling the services as described
                                in the approved quote, and as such, all sales
                                are final upon quote approval.
                            </p>
                            <h4 id="discrepancies-and-disputes">
                                13. Discrepancies and Disputes
                            </h4>
                            <p>
                                In the event of a discrepancy between the
                                services delivered and the services listed in
                                the approved quote, it is the responsibility of
                                the Client to notify Yarden Garden Inc. within 7
                                days of job completion. Yarden Garden Inc. will
                                investigate all claims and take appropriate
                                action to ensure resolution in accordance with
                                the agreed-upon services.
                            </p>
                            <h4 id="changes-to-quote-approval">
                                14. Changes to Quote Approval
                            </h4>
                            <p>
                                Any changes to the scope of services after quote
                                approval may incur additional charges. Such
                                changes must be agreed upon in writing by both
                                Yarden Garden Inc. and the Client.
                            </p>
                            <h4 id="membership-billing-and-payment">
                                15. Membership Billing and Payment
                            </h4>
                            <ul>
                                <li>
                                    <p>
                                        <b>Membership Fee</b>: The card on file
                                        will be automatically charged at the
                                        current membership rate for the selected
                                        service plan. This rate will be charged
                                        on a recurring basis, as specified in
                                        the service plan details (e.g., monthly,
                                        quarterly).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        {' '}
                                        <b>Payment Schedule</b>: The initial
                                        charge will occur upon membership
                                        activation. Subsequent charges will
                                        occur on the anniversary of the
                                        membership start date, according to the
                                        selected frequency of the service plan.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Payment Method</b>: It is the
                                        Member's responsibility to ensure that a
                                        valid and current payment method is on
                                        file. Failure to process payment due to
                                        outdated or invalid payment information
                                        may result in interruption or
                                        cancellation of services.
                                    </p>
                                </li>
                            </ul>
                            <h4 id="membership-plans-and-services">
                                16. Membership Plans and Services
                            </h4>
                            <p>
                                Yarden Garden Inc. offers various membership
                                plans. Details of the services provided under
                                each plan, including the frequency of services,
                                are outlined during the membership process and
                                can be reviewed in the Member's user account.
                                Services may include, but are not limited to,
                                garden maintenance, planting, seasonal
                                preparation, and consultation services. See the{' '}
                                <a href="/membership-details">
                                    membership details page
                                </a>{' '}
                                for full scope of service.
                            </p>
                            <h4 id="cancellation-policy">
                                17. Cancellation Policy
                            </h4>
                            <ul>
                                <li>
                                    <p>
                                        <b>Cancellation by Member</b>: Members
                                        may cancel their membership at any time
                                        through their user account on the Yarden
                                        Garden Inc. website or app.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Effect of Cancellation</b>: Upon
                                        cancellation, no further charges will be
                                        applied to the Member's card. Any
                                        services scheduled to occur after the
                                        cancellation date will not be performed.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Membership Refunds</b>: No refunds
                                        will be provided for any membership fees
                                        already charged. Yarden Garden Inc.
                                        prides itself on providing continuous,
                                        high-quality service up until the point
                                        of cancellation.
                                    </p>
                                </li>
                            </ul>
                            <h4 id="changes-to-membership-terms-or-fees">
                                18. Changes to Membership Terms or Fees
                            </h4>
                            <p>
                                Yarden Garden Inc. reserves the right to modify
                                membership fees, service offerings, or terms of
                                service. Any changes will be communicated to
                                current Members at least 30 days prior to taking
                                effect. Continued membership after changes take
                                effect constitutes acceptance of the new terms.
                            </p>
                            <h4 id="video-photo-policy">
                                19. Video / Photo Policy
                            </h4>
                            <p>
                                By agreeing to these Terms of Service, you
                                hereby grant Yarden Garden Inc. permission to
                                take photographs and videos of your garden, and
                                you acknowledge that you will not receive any
                                compensation for the use of the photographs and
                                videos. The media is the property of Yarden
                                Garden Inc., and the company reserves all rights
                                to the media. Personal identifying information
                                such as your name, address, and specific
                                location details will not be disclosed in
                                connection with the media without your explicit
                                consent. This consent includes, but is not
                                limited to, the following purposes:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        <b>Historical Documentation</b>: To keep
                                        a record of the garden’s progress and
                                        transformations over time.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Marketing and Advertising</b>: To use
                                        the photographs and videos for
                                        promotional materials, including but not
                                        limited to, social media posts, website
                                        content, brochures, and advertisements.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item md={1} sm={1} xs={1}></Grid>
                </Grid>
                {/* terms of service end */}
            </span>
        );
    }
}

export default TermsOfService;
