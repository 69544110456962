import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_MESSAGES, GET_UNREAD } from './types';

export function createMessage(message) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/messages`, message, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getMessages(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/messages${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_MESSAGES, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateMessage(id, message) {
    return async function(dispatch) {
        try {
            await axios.put(`/api/v1/messages/${id}`, message, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getUnread(user) {
    return async function(dispatch) {
        try {
                
            // get messages
            const messages = await axios.get(`/api/v1/messages?receiver=${user.data._id}&opened=false`, {headers: {authorization: getAuthToken()}});
    
            if(messages.data.length > 0) {
                dispatch({type: GET_UNREAD, payload: messages.data.length});
            }
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }

}