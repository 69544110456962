import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_TEMPLATES } from './types';

export function createTemplate(template) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/templates`, template, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getTemplates(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/templates/owner${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_TEMPLATES, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getTemplate(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/templates${q}`, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateTemplate(id, template) {
    return async function(dispatch) {
        try {
            await axios.put(`/api/v1/templates/${id}`, template, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function deleteTemplate(id) {
    return async function(dispatch) {
        try {
            await axios.delete(`/api/v1/templates/${id}`, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}