// libs
import axios from 'axios';

// actions
import { throwError } from '../../actions/error/index';

// helpers
import getAuthToken from '../../formatting/getAuthToken';

// types
import { GET_REPORTS } from './types';

export function createReport(report) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/reports`, report, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getReports(query, override) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/reports${q}`, {
                headers: { authorization: getAuthToken() },
            });
            if (!override) dispatch({ type: GET_REPORTS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getReport(orderId) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/reports/${orderId}`, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
