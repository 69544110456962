// libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import Divider from '@material-ui/core/Divider';
import YardenTable from './YardenTable.js';

class QRCodeDetails extends Component {
  render() {
    const { selectedQRCode, scans } = this.props;

    const scanRows =
      scans.data && scans.data
        ? scans.data.map((scan) => {
            return {
              tableData: {
                date: moment(scan.dt_created).format('MM/DD/YYYY'),
                ip_location: scan.location.city || 'Unable to locate',
                zip_code: scan.location.postal_code || 'Unable to locate',
                state: scan.location.region || 'Unable to locate',
              },
              originalData: scan,
            };
          })
        : [];

    const scanColumns = [
      {
        accessor: 'date',
        Header: 'Date',
      },
      {
        accessor: 'ip_location',
        Header: 'IP Location',
      },
      {
        accessor: 'zip_code',
        Header: 'Zip Code',
      },
      {
        accessor: 'state',
        Header: 'State',
      },
    ];

    return (
      <div>
        {/* qr code details */}
        <div>
          <div className={`data-detail`}>
            <label>Source</label>
            <p>{selectedQRCode.source}</p>
          </div>
          <div className="data-detail">
            <label>Variant</label>
            <p>{selectedQRCode.variant}</p>
          </div>
          <div className="data-detail">
            <label>QR Code</label>
            <img src={selectedQRCode.qr_code} alt="qr code" className="mt10" />
          </div>
        </div>

        {/* scans */}
        {scanRows && (
          <div className="mt25">
            <Divider />
            <h5 className="mb15 mt15">Scans</h5>
            <YardenTable
              columns={scanColumns}
              data={scanRows}
              onSelect={() => {}}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    scans: state.scan,
  };
}

QRCodeDetails = connect(mapStateToProps, null)(QRCodeDetails);

export default QRCodeDetails;
