// libs
import React, { useEffect } from 'react';

// helpers
import logInfo from '../helpers/logInfo';

// styling
import '../css/careers.css';

// images
const gardener =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/careers_page_images/careers_gardener.png';
const mobile2 =
    'https://s3-us-west-1.amazonaws.com/yarden-garden/static+assets/mobile-2.png';

const Careers = () => {
    useEffect(() => {
        logInfo('Careers page loaded');
    }, []);

    return (
        <span>
            <section className="auto-grid-large mt4 mb4">
                <div>
                    <img
                        style={{ width: '100%' }}
                        src={gardener}
                        alt="gardener"
                    />
                </div>
                <div className="flex-center-x flex-column">
                    <label className="opacity07 m0">
                        Experience true growth
                    </label>
                    <div>
                        <h2>
                            Do you love the art of gardening? Passionate about
                            plant-life?
                        </h2>
                    </div>
                    <div className="mt4">
                        <button
                            onClick={() =>
                                (window.location.pathname = '/apply')
                            }
                        >
                            Apply Now
                        </button>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <section className="auto-grid-large mt4 mb4">
                <div>
                    <h4>Competitive Earnings</h4>
                    <p>
                        Increase your income at fair earning rates for the
                        services you provide for Yarden's customers.
                    </p>
                </div>

                <div>
                    <h4>Flexible Scheduling</h4>
                    <p>
                        Work at your pace. Set your own appointments. Manage all
                        your orders through Yarden's website.
                    </p>
                </div>

                <div>
                    <h4>Direct Deposit Payments</h4>
                    <p>
                        No waiting on checks to clear. Get paid automatically
                        upon the successful completion of each job.
                    </p>
                </div>

                <div>
                    <h4>Reliable Support & Training</h4>
                    <p>
                        Yarden is there to guide you every step of the way. Our
                        friendly support team is available 8am - 8pm.
                    </p>
                </div>
            </section>
            <br />
            <br />
            <section className="auto-grid-large mt4 mb4">
                <div>
                    <h3>
                        <b>Manage Everything Through Our Software</b>
                    </h3>
                    <p>
                        Yarden provides you with an online dashboard for
                        managing services and building quotes for customers.
                    </p>
                    <ol>
                        <li>Complete the work</li>
                        <li>Upload your photos</li>
                        <li>Get paid!</li>
                    </ol>
                    <button
                        className="mb4"
                        onClick={() => (window.location.pathname = '/apply')}
                    >
                        Get Started
                    </button>
                </div>

                <div className="flex flex-center-x">
                    <img src={mobile2} width="300" alt="mobile website" />
                </div>
            </section>
            <section className="section5-container mt50 text-center p4">
                <h3 className="mb4">
                    "Yarden allows me to focus on the projects that I'm
                    passionate about while maintaining profitibility."
                </h3>
                <label>
                    — James A. <br />
                    San Jose, CA
                </label>
            </section>
        </span>
    );
};

export default Careers;
