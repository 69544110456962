import axios from 'axios';
import { GET_ORDERS } from './types';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createOrder(order) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/orders`, order, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getOrders(query, override) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/orders${q}`, {
                headers: { authorization: getAuthToken() },
            });
            if (!override) dispatch({ type: GET_ORDERS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getOrder(id) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/orders/${id}`, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function checkOrders(query) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.head(`/api/v1/orders${q}`, {
                headers: { authorization: getAuthToken() },
            });

            if (response.status === 200) {
                return true;
            } else if (response.status === 204) {
                return false;
            }
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function updateOrder(id, order) {
    return async function (dispatch) {
        try {
            const updatedOrder = await axios.put(
                `/api/v1/orders/${id}`,
                order,
                { headers: { authorization: getAuthToken() } },
            );
            return updatedOrder;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function setOrders(orders) {
    return async function (dispatch) {
        dispatch({ type: GET_ORDERS, payload: orders });
    };
}
