import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function getImage(fileName, fileType) {
    return async function(dispatch) {        
        try {
            const response = await axios.get(`/api/v1/images?name=${fileName}&type=${fileType}`, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateImage(signedRequest, file, options) {
    return async function(dispatch) {        
        try {
            const response = await axios.put(signedRequest, file, options);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}