// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// UI components
import Pricing from '../components/Pricing';
import LoaderModal from '../components/LoaderModal';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import Icon from '@material-ui/core/Icon';
import Navbar from '../components/Navbar';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher';

// helpers
import logInfo from '../helpers/logInfo';

let Products = ({ user }) => {
    const [activeStep, setActiveStep] = useState(1);
    const [invalidURL, setInvalidURL] = useState(false);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);

    useEffect(() => {
        const urlParams = Object.fromEntries(
            new URLSearchParams(window.location.search),
        );
        if (!urlParams.bid && !urlParams.job) {
            setInvalidURL(true);
        }

        logInfo('Products page loaded', { user: user.data });
    }, []);

    function next() {
        setActiveStep(activeStep + 1);
    }

    function back() {
        setActiveStep(activeStep - 1);
    }

    async function finish(product) {
        setLoadingModalIsOpen(true);
        window.location.href = `${window.location.origin}/approve${window.location.search}&product=${product}`;
    }

    function renderStep1() {
        return (
            <div>
                <Pricing onSelect={(product) => finish(product)} />
                <div
                    className="flex flex-center pt25 mt25 mb25"
                    style={{ borderTop: '1px solid #cccccc' }}
                >
                    <button onClick={() => next()} className="small btn3">
                        I don't want help with my garden
                    </button>
                </div>
            </div>
        );
    }

    function renderStep2() {
        return (
            <div className="mt50 pr25 pl25 pb15 flex flex-center">
                <div>
                    <h4 className="mb15">Are you sure?</h4>
                    <p>
                        Garden owners with a Yarden membership achieve success
                        at a rate 5 times higher than those without a plan.
                    </p>
                    <div className="flex flex-center-y flex-space-between mt25">
                        <button className="small btn3" onClick={() => back()}>
                            <div className="flex flex-center-y">
                                <ArrowBack />
                                <span style={{ marginLeft: 8 }}>Back</span>
                            </div>
                        </button>
                        <button
                            className="small purple"
                            onClick={() => finish('none')}
                        >
                            <div className="flex flex-center-y">
                                <CheckOutlined />
                                <span style={{ marginLeft: 8 }}>Confirm</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    function renderInvalidURL() {
        return (
            <div className="p50">
                <p className="text-center">
                    Invalid URL, please contact support to continue.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => (window.location.pathname = '/account')}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>
                                Continue to account
                            </span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    function renderView() {
        switch (activeStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            default:
                return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* main content */}
            <Navbar />
            {invalidURL ? (
                renderInvalidURL()
            ) : (
                <div className="pt75 pb25 pl25 pr25">{renderView()}</div>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

Products = connect(mapStateToProps, mapDispatchToProps)(Products);

export default Products;
