// libs
import React, { useEffect } from 'react';

// helpers
import logInfo from '../helpers/logInfo';

const MembershipDetails = () => {
    useEffect(() => {
        logInfo('Membership Details page loaded');
    }, []);

    return (
        <div>
            <div className="text-center">
                <h1 className="mt25 mb25">Membership Details</h1>
                <p className="capitalize mb0">Garden Maintenance Service</p>
            </div>
            <div className="p15">
                <hr className="bg-green0" />
                <h5 className="mt25">What's included</h5>
                <p className="text-small mt5">
                    These services are included as part of your membership
                </p>
                <ul>
                    <li>Harvesting</li>
                    <li>Removal of weeds from garden</li>
                    <li>Pest prevention</li>
                    <li>Irrigation management</li>
                    <li>Seasonal crop rotation</li>
                </ul>
                <h5 className="mt25">What's not included</h5>
                <p className="text-small mt5">
                    These services / products will incur additional cost outside
                    of your regular membership fee
                </p>
                <ul>
                    <li>
                        Replacement of garden parts
                        <br />
                        - Water timer
                        <br />
                        - Irrigation lines
                        <br />
                        - Hose
                        <br />
                        - Fasteners
                        <br />- Wood
                    </li>
                    <li>Tomato / other cages</li>
                    <li>Trellises</li>
                    <li>Pest deterrant devices</li>
                    <li>Soil top-offs</li>
                    <li>Soil amendments</li>
                    <li>Non-garden related tasks</li>
                </ul>
            </div>
        </div>
    );
};

export default MembershipDetails;
