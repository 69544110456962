import React from "react";

function LinkedInIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      viewBox="0 0 14 14"
    >
      <path
        fill="#309"
        d="M13 32H1a1 1 0 00-1 1.009v11.982A1 1 0 001 46h12a1.007 1.007 0 001-1.009V33.009A1.007 1.007 0 0013 32zM4.231 44H2.156v-6.681h2.078V44zm-1.037-7.594a1.2 1.2 0 111.2-1.2 1.2 1.2 0 01-1.2 1.2zM12.009 44H9.934v-3.25c0-.775-.016-1.772-1.078-1.772-1.081 0-1.247.844-1.247 1.716V44H5.534v-6.681h1.991v.912h.028a2.186 2.186 0 011.966-1.078c2.1 0 2.491 1.384 2.491 3.184z"
        data-name="brand linkedin"
        transform="translate(0 -32)"
      ></path>
    </svg>
  );
}

export default LinkedInIcon;