// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';

// actions
import { updateOrder } from '../actions/order/index';

class DescriptionModal extends Component {
  state = {};

  componentDidMount() {
    this.setState(this.props.order);
  }

  async save() {
    const { order, onChange, close, updateOrder } = this.props;
    const { description } = this.state;
    await updateOrder(order._id, { description });
    onChange();
    close();
  }

  render() {
    const { isOpen, close } = this.props;
    const { description } = this.state;

    return (
      <span>
        <Dialog open={isOpen || false} onClose={() => close()}>
          <DialogContent>
            <div>
              <div className="mb20 mt10">
                <h5 className="m0">Edit Description</h5>
              </div>
              <div>
                <textarea
                  required
                  rows="4"
                  cols="50"
                  value={description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                ></textarea>
              </div>
              <div className="mb10 mt10 float-right">
                <div className="flex flex-center-y">
                  <button className="btn3 small mr10" onClick={() => close()}>
                    <Icon>clear</Icon>
                    <span style={{ marginLeft: 8 }}>Close</span>
                  </button>
                  <button className="purple small" onClick={() => this.save()}>
                    <Icon>done</Icon>
                    <span style={{ marginLeft: 8 }}>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrder,
    },
    dispatch,
  );
}

DescriptionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DescriptionModal);

export default DescriptionModal;
