import axios from 'axios';
import { GET_BEDS } from './types';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createBed(bed) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/beds`, bed, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function getBeds(query, override) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/beds${q}`, {
                headers: { authorization: getAuthToken() },
            });
            if (!override) dispatch({ type: GET_BEDS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function updateBed(id, order) {
    return async function (dispatch) {
        try {
            const updatedBed = await axios.put(`/api/v1/beds/${id}`, order, {
                headers: { authorization: getAuthToken() },
            });
            return updatedBed;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
