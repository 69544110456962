import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createHarvestSchedule(harvestSchedule) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/harvest-schedules`,
                harvestSchedule,
                {
                    headers: { authorization: getAuthToken() },
                },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getHarvestSchedule(customerId) {
    return async function (dispatch) {
        try {
            const response = await axios.get(
                `/api/v1/harvest-schedules/${customerId}`,
                {
                    headers: { authorization: getAuthToken() },
                },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function updateHarvestSchedule(id, harvestSchedule) {
    return async function (dispatch) {
        try {
            const updatedHarvestSchedule = await axios.put(
                `/api/v1/harvest-schedules/${id}`,
                harvestSchedule,
                { headers: { authorization: getAuthToken() } },
            );
            return updatedHarvestSchedule;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
