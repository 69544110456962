// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import ImageModal from '../components/ImageModal';
import LoaderModal from '../components/LoaderModal.js';

// actions
import { getImage, updateImage } from '../actions/image/index';
import { updateOrder } from '../actions/order/index';

// helpers
import { compress } from '../formatting/compress';

class Images extends Component {

    state = {
        uploadPreview: [],
        processedImages: []
    }

    viewImageDetails(img) {
        this.setState({
            imageModalIsOpen: true,
            selectedImage: img
        })
    }

    startImageUpload() {
        let uploadPreview = this.state.uploadPreview;
        for (let file in this.selectedFiles.files) {
            if (typeof this.selectedFiles.files[file] === 'object') uploadPreview.push(this.selectedFiles.files[file]);
        }

        this.setState({
            uploadPreview: uploadPreview
        }, () => {
            if (uploadPreview.length === this.selectedFiles.files.length) this.compressImages();
        });
    }

    compressImages() {
        this.setState({ loadingModalIsOpen: true }, () => {
            this.state.uploadPreview.forEach(async (file) => {
                if (typeof file === 'object') await compress(file, this.addToUploadQueue.bind(this));
            })
        });
    }

    async addToUploadQueue(image) {
        let hostedImg = await this.props.getImage(image.name, image.type);
        let returnData = hostedImg.data.data.returnData;
        let signedRequest = returnData.signedRequest;
        let options = {
            headers: {
                'Content-Type': image.type
            }
        };

        await this.props.updateImage(signedRequest, image, options);

        let processedImages = this.state.processedImages;
        processedImages.push({
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url
        })

        this.setState({
            processedImages: processedImages
        }, () => {
            if (this.state.processedImages.length === this.state.uploadPreview.length) this.finish();
        })
    }

    async finish() {
        let dataToUpload = [];
        let orderUpdate;

        if (this.state.processedImages.length > 0) {
            dataToUpload = this.props.selectedOrder.images;
            this.state.processedImages.forEach((img) => {
                dataToUpload.push(img);
            })

            orderUpdate = { images: dataToUpload };
        }

        await this.props.updateOrder(this.props.selectedOrder._id, orderUpdate);

        this.setState({
            uploadPreview: [],
            receiptsPreview: [],
            processedImages: [],
            processedReceipts: [],
            loadingModalIsOpen: false
        });

        this.props.onUpload();
    }

    render() {

        const {
            selectedImage,
            imageModalIsOpen,
            loadingModalIsOpen
        } = this.state;

        const {
            selectedOrder,
            title,
            user
        } = this.props;

        return (
            <div>
                {/* modals */}
                <ImageModal
                    image={selectedImage}
                    isOpen={imageModalIsOpen}
                    onClose={() => this.setState({ imageModalIsOpen: false })}
                />

                <LoaderModal
                    isOpen={loadingModalIsOpen}
                />

                {/* images */}
                <div>
                    <div className="flex flex-space-between flex-center-y pb15">
                        <div className="mb15">
                            <h5 className="capitalize">{title}</h5>
                        </div>
                        <div>
                            <div className={((selectedOrder.status === 'complete') && (user.data.type !== 'customer')) ? 'flex' : 'hidden'}>
                                <div className="ml15 flex">
                                    <button
                                        className="btn3 small flex flex-center-y"
                                        onClick={() => {
                                            document.getElementById('image-upload').click();
                                        }}>
                                        <AddIcon />
                                        <span style={{ marginLeft: 8 }}>Add Images</span>
                                    </button>
                                    <input
                                        multiple
                                        id="image-upload"
                                        type="file"
                                        className="filepicker invisible"
                                        ref={(inp) => this.selectedFiles = inp}
                                        onChange={() => this.startImageUpload()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Grid container spacing={2}>
                            {selectedOrder.images.map((i, index) =>
                                <Grid item md={3} sm={3} xs={12} key={index++} className="pb15">
                                    <div className="relative">
                                        <label className="order-date">{moment(selectedOrder.date).format('MM/DD/YY')}</label>
                                        <img
                                            src={i.url}
                                            style={{ width: '100%', aspectRatio: '4/3', objectFit: 'contain' }}
                                            alt="order result"
                                            onClick={() => this.viewImageDetails(i)}
                                        />
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        error: state.error
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateImage,
        getImage,
        updateOrder
    }, dispatch)
}

Images = connect(mapStateToProps, mapDispatchToProps)(Images);

export default Images;