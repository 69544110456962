import React from "react";

function FacebookIcon
(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      data-name="brand facebook"
      viewBox="0 0 16 15.903"
    >
      <path
        fill="#309"
        d="M24 16a8 8 0 10-9.25 7.9v-5.587h-2.032V16h2.032v-1.763a2.823 2.823 0 013.022-3.112 12.312 12.312 0 011.791.156v1.968h-1.009a1.156 1.156 0 00-1.3 1.249V16h2.219l-.355 2.313H17.25V23.9A8 8 0 0024 16z"
        data-name="brand facebook"
        transform="translate(-8 -8)"
      ></path>
    </svg>
  );
}

export default FacebookIcon
;
