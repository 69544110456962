import axios from 'axios';
import { GET_DRAFTS } from './types';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createDraft(draft) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/drafts`, draft, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getDrafts(query, override) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/drafts${q}`, {
                headers: { authorization: getAuthToken() },
            });
            if (!override) dispatch({ type: GET_DRAFTS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function updateDraft(id, draft) {
    return async function (dispatch) {
        try {
            const updatedBed = await axios.put(`/api/v1/drafts/${id}`, draft, {
                headers: { authorization: getAuthToken() },
            });
            return updatedBed;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
