// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// helpers
import navigate from '../helpers/navigate';
import logInfo from '../helpers/logInfo';

// styling
import Branding from '../styling/Branding';

// actions
import { createPageView } from '../actions/pageView/index';

class Referral extends Component {
    async componentDidMount() {
        const urlParams = Object.fromEntries(
            new URLSearchParams(window.location.search),
        );

        // if referral id {...}
        if (urlParams.refId) {
            // set referral tracking
            localStorage.setItem(`refId`, urlParams.refId);
            await this.props.createPageView({ referrer: urlParams.refId });
            logInfo('Referral page loaded');
        }

        setTimeout(() => {
            navigate('/', true);
        }, 3000);
    }

    render() {
        return (
            <div className="flex flex-center full-view">
                <div className="flex flex-center-y">
                    <img
                        src={Branding.trademark}
                        width="50"
                        alt="logo"
                        className="mr5"
                    />
                    <h1>Loading...</h1>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createPageView,
        },
        dispatch,
    );
}

Referral = connect(mapStateToProps, mapDispatchToProps)(Referral);

export default Referral;
