import React, { useState } from 'react';
import Compressor from 'compressorjs';
import Icon from '@material-ui/core/Icon';
import LoaderModal from '../LoaderModal';

const Filepicker = ({ onChange, onReset, max }) => {
    const [fileList, setFileList] = useState([]);
    const [filepickerInput, setFilepickerInput] = useState(null);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);

    const compress = (file, modifiedFilename) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    var originalFileSize = (file.size / (1024 * 1024)).toFixed(
                        2,
                    );
                    var newFileSize = (result.size / (1024 * 1024)).toFixed(2);
                    console.info('ORIGINAL FILE SIZE');
                    console.info(`${originalFileSize} MB`);

                    console.info('NEW FILE SIZE');
                    console.info(`${newFileSize} MB`);

                    resolve(
                        new File([result], modifiedFilename, {
                            type: file.type,
                        }),
                    );
                },
                error(err) {
                    reject(err);
                },
            });
        });
    };

    const replaceSpacesWithUnderscores = (text) => {
        return text.replace(/\s+/g, '_');
    };

    const addFilesToList = async () => {
        setLoadingModalIsOpen(true);
        const selectedFiles = [...fileList];

        if (max && fileList.length + filepickerInput.files.length > max) {
            window.alert(
                `Cannot select more than ${max} file${max > 1 ? 's' : ''}`,
            );
            setLoadingModalIsOpen(false);
            return;
        }

        for (const file of filepickerInput.files) {
            if (file instanceof File) {
                const filename = file.name;
                const modifiedFilename = replaceSpacesWithUnderscores(filename);
                try {
                    const compressedFile = await compress(
                        file,
                        modifiedFilename,
                    );
                    selectedFiles.push(compressedFile);
                } catch (err) {
                    window.alert('Error during file upload');
                }
            }
        }

        setFileList(selectedFiles);
        onChange(selectedFiles);
        setLoadingModalIsOpen(false);
    };

    return (
        <div>
            <LoaderModal isOpen={loadingModalIsOpen} />

            <div
                onClick={() => document.getElementById('image-upload').click()}
                style={{ height: 200, border: '2px dashed #EEEEEE' }}
                className="flex flex-center curved pointer"
            >
                <div>
                    <div className="flex flex-center">
                        <Icon style={{ color: 'var(--purpleB)' }}>
                            file_upload
                        </Icon>
                    </div>
                    <div className="flex flex-center">
                        <p className="mt5">
                            {fileList.length > 0
                                ? `${fileList.length} image${
                                      fileList.length > 1 ? 's' : ''
                                  } selected`
                                : 'Click here to upload'}
                        </p>
                    </div>
                </div>
            </div>
            <input
                multiple
                id="image-upload"
                type="file"
                className="filepicker invisible"
                ref={(inp) => setFilepickerInput(inp)}
                onChange={addFilesToList}
            />
            <div className="flex flex-space-between mt15">
                <div></div>
                <p
                    className="text-small text-purpleB pointer"
                    onClick={() => {
                        setFileList([]);
                        onReset();
                    }}
                >
                    <span className="flex flex-center-y">
                        Reset <Icon>restart_alt</Icon>
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Filepicker;
