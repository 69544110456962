import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_ITEMS } from './types';

export function createItem(item) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/items`, item, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getItems(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/items${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_ITEMS, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateItem(id, item) {
    return async function(dispatch) {
        try {
            await axios.put(`/api/v1/items/${id}`, item, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function deleteItem(id) {
    return async function(dispatch) {
        try {
            await axios.delete(`/api/v1/items/${id}`, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}
