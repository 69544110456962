// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Alert from './Alert.js';
import LoaderModal from './LoaderModal';
import Icon from '@material-ui/core/Icon';
import { Divider } from '@material-ui/core';

// actions
import { createBid } from '../actions/bid/index';
import { getUsers } from '../actions/users/index';
import { getUser } from '../actions/user/index';
import { updateImage, getImage } from '../actions/image/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { throwError } from '../actions/error/index';

// helpers
import { truncate } from '../formatting/truncate';
import { compress } from '../formatting/compress';
import capitalize from '../formatting/capitalize';

class NewBid extends Component {
    state = {
        step: 1,
        step1: true,
        selectedVendor: 'Select Vendor',
        title: '',
        searchQuery: '',
        uploadPreview: [],
        processedImages: [],
        bidType: 'misc',
    };

    componentDidMount() {
        if (this.props.user.data.type === 'customer') {
            this.setState({
                selectedUser: this.props.user.data,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }

        if (!this.props.users) {
            this.props.getUsers();
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    addToUploadPreview() {
        let uploadPreview = this.state.uploadPreview;
        for (let file in this.selectedFiles.files) {
            if (typeof this.selectedFiles.files[file] === 'object')
                uploadPreview.push(this.selectedFiles.files[file]);
        }

        this.setState({ uploadPreview });
    }

    removeFromUploadPreview(file) {
        const fileIndex = this.state.uploadPreview.indexOf(file);
        let uploadPreview = this.state.uploadPreview;
        uploadPreview.splice(fileIndex, 1);
        this.setState({ uploadPreview: uploadPreview });
    }

    async next() {
        if (!this.state.title || !this.state.description)
            return window.alert('Please complete all required fields');

        let vendor = false;

        if (this.state.selectedVendor !== 'Select Vendor')
            vendor = await this.props.getUser(this.state.selectedVendor, true);

        this.setState({
            step: 2,
            vendor: vendor ? vendor.data : false,
        });

        window.scrollTo(0, 0);
    }

    compressImages() {
        this.state.uploadPreview.forEach(async (file) => {
            if (typeof file === 'object')
                await compress(file, this.addToUploadQueue.bind(this));
        });
    }

    async addToUploadQueue(image) {
        // get image url
        const hostedImg = await this.props.getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        // save image
        await this.props.updateImage(signedRequest, image, options);

        let processedImages = this.state.processedImages;
        processedImages.push({
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        });

        this.setState(
            {
                processedImages: processedImages,
            },
            () => {
                if (
                    this.state.processedImages.length ===
                    this.state.uploadPreview.length
                )
                    this.finish();
            },
        );
    }

    finish() {
        this.setState({ loadingModalIsOpen: true });
        setTimeout(async () => {
            const newBid = {
                type: this.state.bidType,
                customer: this.state.selectedUser._id,
                title: this.state.title,
                description: this.state.description,
            };

            if (this.state.vendor) newBid.vendor = this.state.vendor._id;
            if (this.state.processedImages.length > 0)
                newBid.attachments = this.state.processedImages;

            const bid = await this.props.createBid(newBid);

            if (this.state.vendor) {
                const vendor = this.state.vendor;
                const notification = {
                    email: vendor.email,
                    subject: `Yarden - (ACTION REQUIRED) New Quote`,
                    label: 'Bid Assignment',
                    body: `<p>Greetings from Yarden! You have been assigned a new quote. The details are in your dashboard at ${window.location.origin}/dashboard</p>`,
                };

                await this.props.sendEmail(notification);

                const sms = {
                    from: '8888289287',
                    to: vendor.phone_number.replace(/\D/g, ''),
                    body: `Greetings from Yarden! You have been assigned a new quote. The details are in your dashboard at ${window.location.origin}/dashboard`,
                };

                await this.props.sendSms(sms);
            }

            this.setState({ loadingModalIsOpen: false });
            this.props.onCreateBid({
                ...bid.data,
                customer: this.state.selectedUser,
            });
        }, 1000);
    }

    render() {
        const {
            step,
            alertIsOpen,
            selectedUser,
            selectedVendor,
            title,
            description,
            uploadPreview,
            loadingModalIsOpen,
            vendor,
            searchQuery,
            bidType,
        } = this.state;

        const { users, user, error } = this.props;

        const customers = searchQuery
            ? users.data.filter(
                  (user) =>
                      user.type === 'customer' &&
                      (user.first_name
                          .toLowerCase()
                          .startsWith(searchQuery.toLowerCase()) ||
                          user.last_name
                              .toLowerCase()
                              .startsWith(searchQuery.toLowerCase())),
              )
            : [];

        return (
            <div>
                {/* modals */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                <LoaderModal isOpen={loadingModalIsOpen} />

                {/* step 1 */}
                <div className={step === 1 ? null : 'hidden'}>
                    {!selectedUser ? (
                        <div>
                            <div className="mt15">
                                <label>Member</label>
                                <input
                                    placeholder="Search Customers"
                                    type="text"
                                    name="searchQuery"
                                    onChange={(e) =>
                                        this.setState({
                                            searchQuery: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            {customers.map((customer, index) => (
                                <div key={index}>
                                    <div
                                        className="pointer p15"
                                        onClick={() =>
                                            this.setState({
                                                selectedUser: customer,
                                                loadingModalIsOpen: false,
                                            })
                                        }
                                    >
                                        {customer.first_name}{' '}
                                        {customer.last_name}
                                    </div>
                                    <Divider />
                                </div>
                            ))}
                        </div>
                    ) : (
                        selectedUser && (
                            <div>
                                <div>
                                    <label>Member*</label>
                                    <p className="mt5">
                                        {selectedUser.first_name}{' '}
                                        {selectedUser.last_name}
                                        <br />
                                        {selectedUser.address}
                                        {selectedUser.unit
                                            ? ` #${selectedUser.unit}`
                                            : ''}
                                        , {selectedUser.city}{' '}
                                        {selectedUser.state}{' '}
                                        {selectedUser.zip_code}
                                        <br />
                                        {selectedUser.email}
                                        <br />
                                        {selectedUser.phone_number}
                                        <br />
                                    </p>
                                </div>
                                <div className="mt15">
                                    <div>
                                        <label>Quote Type *</label>
                                    </div>
                                    <select
                                        margin="dense"
                                        name="bidType"
                                        value={bidType}
                                        onChange={(e) =>
                                            this.setState({
                                                bidType: e.target.value,
                                            })
                                        }
                                    >
                                        <option value={'installation'}>
                                            Installation
                                        </option>
                                        <option value={'revive'}>Revive</option>
                                        <option value={'misc'}>Misc</option>
                                    </select>
                                </div>
                                <div
                                    className={
                                        user.data.type === 'admin'
                                            ? 'mt10'
                                            : 'hidden'
                                    }
                                >
                                    <div>
                                        <label>Vendor (optional)</label>
                                    </div>
                                    <select
                                        name="selectedVendor"
                                        disabled={loadingModalIsOpen}
                                        value={selectedVendor}
                                        onChange={async (e) => {
                                            const vendorId = e.target.value;
                                            this.setState({
                                                selectedVendor: vendorId,
                                                vendor:
                                                    vendorId === 'Select Vendor'
                                                        ? false
                                                        : this.state.vendor,
                                            });
                                        }}
                                    >
                                        <option value={'Select Vendor'}>
                                            Select Vendor
                                        </option>
                                        {users.data ? (
                                            users.data
                                                .filter(
                                                    (user) =>
                                                        user.type ===
                                                            'partner' ||
                                                        user.type ===
                                                            'gardener',
                                                )
                                                .map((vendor, index) => (
                                                    <option
                                                        key={index}
                                                        value={vendor._id}
                                                    >{`${vendor.first_name} ${vendor.last_name}`}</option>
                                                ))
                                        ) : (
                                            <div></div>
                                        )}
                                    </select>
                                </div>
                                <div>
                                    <label>Title*</label>
                                    <input
                                        type="text"
                                        name="title"
                                        placeholder="New Fence"
                                        value={title}
                                        disabled={loadingModalIsOpen}
                                        onChange={(e) => this.setValue(e)}
                                    />
                                </div>
                                <div className="mt15">
                                    <label>Description*</label>
                                    <textarea
                                        type="text"
                                        name="description"
                                        placeholder="Please include any important details about your project here, such as dimensions, weight, etc."
                                        disabled={loadingModalIsOpen}
                                        value={description}
                                        onChange={(e) => this.setValue(e)}
                                    />
                                </div>
                                <div className="mt15">
                                    <div>
                                        <label>Images (optional)</label>
                                    </div>
                                    <input
                                        multiple
                                        type="file"
                                        className="filepicker"
                                        ref={(inp) =>
                                            (this.selectedFiles = inp)
                                        }
                                        onChange={() =>
                                            this.addToUploadPreview()
                                        }
                                    />
                                </div>
                                <div className="mt15">
                                    <label
                                        className={
                                            uploadPreview.length > 0
                                                ? null
                                                : 'hidden'
                                        }
                                    >
                                        Attachments
                                    </label>
                                    {uploadPreview.map((file, index) => {
                                        let fileName = file.name.split('.');
                                        let fileType =
                                            fileName[fileName.length - 1];
                                        return (
                                            <div key={index}>
                                                <p className="pt10 pb10 flex flex-center-y flex-space-between">
                                                    {truncate(
                                                        file.name,
                                                        20,
                                                        fileType,
                                                    )}
                                                    <span
                                                        className="pointer"
                                                        onClick={() =>
                                                            this.removeFromUploadPreview(
                                                                file,
                                                            )
                                                        }
                                                    >
                                                        <Icon>clear</Icon>
                                                    </span>
                                                </p>
                                                <Divider />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="flex flex-space-between mt25">
                                    <button
                                        className="btn3 small mr15"
                                        disabled={loadingModalIsOpen}
                                        onClick={() =>
                                            this.setState({
                                                selectedUser: null,
                                                searchQuery: '',
                                            })
                                        }
                                    >
                                        <div className="flex flex-center-y">
                                            <Icon>arrow_back</Icon>
                                            <span style={{ marginLeft: 8 }}>
                                                Back
                                            </span>
                                        </div>
                                    </button>
                                    <button
                                        className="btn3 small"
                                        disabled={loadingModalIsOpen}
                                        onClick={() => this.next()}
                                    >
                                        <div className="flex flex-center-y">
                                            <span style={{ marginRight: 8 }}>
                                                Next
                                            </span>
                                            <Icon>arrow_forward</Icon>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        )
                    )}
                </div>

                {/* step 2 */}
                <div className={step === 2 ? null : 'hidden'}>
                    <div>
                        <div>
                            <label>Member</label>
                            {selectedUser && (
                                <p>
                                    {selectedUser.first_name}{' '}
                                    {selectedUser.last_name}
                                    <br />
                                    {selectedUser.address}
                                    {selectedUser.unit
                                        ? ` #${selectedUser.unit}`
                                        : ''}
                                    , {selectedUser.city} {selectedUser.state}{' '}
                                    {selectedUser.zip_code}
                                    <br />
                                    {selectedUser.email}
                                    <br />
                                    {selectedUser.phone_number}
                                </p>
                            )}
                        </div>
                        {vendor && (
                            <div>
                                <label>Vendor</label>
                                <p>
                                    {vendor &&
                                        `${vendor.first_name} ${vendor.last_name}`}
                                </p>
                            </div>
                        )}
                        <div>
                            <label>Type</label>
                            <p>{capitalize(bidType)}</p>
                        </div>
                        <div>
                            <label>Title</label>
                            <p>{title}</p>
                        </div>
                        <div>
                            <label>Description</label>
                            <p>{description}</p>
                        </div>
                        <div className="mt15">
                            <label
                                className={
                                    uploadPreview.length > 0 ? null : 'hidden'
                                }
                            >
                                Attachments
                            </label>
                            {uploadPreview.map((file, index) => {
                                let fileName = file.name.split('.');
                                let fileType = fileName[fileName.length - 1];
                                return (
                                    <div key={index}>
                                        <p className="pt10 pb10 flex flex-center-y flex-space-between">
                                            {truncate(file.name, 20, fileType)}
                                            <span
                                                className="pointer"
                                                onClick={() =>
                                                    this.removeFromUploadPreview(
                                                        file,
                                                    )
                                                }
                                            >
                                                <Icon>clear</Icon>
                                            </span>
                                        </p>
                                        <Divider />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex flex-space-between mt25">
                            <button
                                className="btn3 small mr15"
                                disabled={loadingModalIsOpen}
                                onClick={() => this.setState({ step: 1 })}
                            >
                                <div className="flex flex-center-y">
                                    <Icon>arrow_back</Icon>
                                    <span style={{ marginLeft: 8 }}>Back</span>
                                </div>
                            </button>
                            <button
                                className="purple small flex flex-center-y"
                                disabled={loadingModalIsOpen}
                                onClick={() => {
                                    if (uploadPreview.length > 0) {
                                        return this.compressImages();
                                    } else {
                                        this.finish();
                                    }
                                }}
                            >
                                <Icon>done</Icon>
                                <span style={{ marginLeft: 8 }}>Finish</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createBid,
            getUsers,
            getUser,
            updateImage,
            getImage,
            sendEmail,
            sendSms,
            throwError,
        },
        dispatch,
    );
}

NewBid = connect(mapStateToProps, mapDispatchToProps)(NewBid);

export default NewBid;
