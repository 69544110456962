import moment from 'moment';
import { UPDATE_RANGE } from './../actions/range/types';

let initialState = {
    startDate: moment().startOf('day'), 
    endDate: moment().endOf('day')
}

export default function( state = initialState, action){
    switch(action.type){
        case UPDATE_RANGE:
            return action.payload;
        default:
            return state;
    }
}