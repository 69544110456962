import axios from 'axios';
import { throwError } from '../error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_SHAPES  } from './types';

export function getShapes(query, override) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/shapes${q}`, {headers: {authorization: getAuthToken()}});
            if(!override) dispatch({type: GET_SHAPES, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}