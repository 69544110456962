import {
    BID_REQUESTED,
    BID_PENDING_APPROVAL,
    APPROVED,
} from './../actions/status/types';

import getAuthToken from '../formatting/getAuthToken';

const authToken = getAuthToken();
const payload = authToken
    ? JSON.parse(window.atob(authToken.split('.')[1]))
    : null;

const initialState = {
    type:
        payload && payload.type === 'customer'
            ? 'pending approval'
            : 'bid requested',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BID_REQUESTED:
            return {
                type: 'bid requested',
            };
        case BID_PENDING_APPROVAL:
            return {
                type: 'pending approval',
            };
        case APPROVED:
            return {
                type: 'approved',
            };
        default:
            return state;
    }
}
