// libs
import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';

// actions
import { updateOrder } from '../actions/order/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';

// helpers
import capitalize from '../helpers/capitalize';

// types
import { ADMIN } from '../vars/types';

// constants
import { PHONE_NUMBER } from '../vars/constants';

class DateTimeModal extends Component {
    state = {
        sendNotificationToCustomer: false,
        sendNotificationToVendor: false,
    };

    componentDidMount() {
        this.setState(this.props.order);
    }

    async save() {
        const { order, onChange, close, updateOrder, sendEmail, sendSms } =
            this.props;
        const {
            date,
            time,
            sendNotificationToCustomer,
            sendNotificationToVendor,
        } = this.state;
        const update = { date, time };

        await updateOrder(order._id, update);

        const customer = order.customer;
        const vendor = order.vendor;
        const message = `Greetings from Yarden! Your service "${capitalize(
            order.type,
        )}" at ${capitalize(
            order.customer.address,
        )} was rescheduled for ${moment(date).format('MM-DD-YYYY')}`;

        const emailNotification = {
            email: customer.email,
            subject: 'Yarden - Your service has been rescheduled',
            label: 'Service Rescheduled',
            body: `<p>${message}</p>`,
        };

        const smsNotification = {
            from: PHONE_NUMBER,
            to: customer.phone_number.replace(/\D/g, ''),
            body: message,
        };

        if (sendNotificationToCustomer) {
            await sendEmail(emailNotification);
            await sendSms(smsNotification);
        }

        if (sendNotificationToVendor) {
            emailNotification.email = vendor.email;
            smsNotification.to = vendor.phone_number;
            await sendEmail(emailNotification);
            await sendSms(smsNotification);
        }

        this.setState(
            {
                sendNotificationToCustomer: false,
                sendNotificationToVendor: false,
            },
            () => {
                onChange();
                close();
            },
        );
    }

    render() {
        const { isOpen, close, user } = this.props;
        const {
            date,
            time,
            sendNotificationToCustomer,
            sendNotificationToVendor,
        } = this.state;

        return (
            <span>
                <Dialog open={isOpen || false} onClose={() => close()}>
                    <DialogContent>
                        <div>
                            <div className="mb20 mt10">
                                <h5 className="m0">Edit Date / Time</h5>
                            </div>
                            <div className="mb15">
                                <input
                                    name="date"
                                    type="date"
                                    value={moment(date).format('YYYY-MM-DD')}
                                    min={moment().format('YYYY-MM-DD')}
                                    onChange={(e) =>
                                        this.setState({
                                            date: moment(
                                                e.target.value,
                                            ).startOf('day'),
                                        })
                                    }
                                />
                            </div>
                            <div className={!time ? 'hidden' : null}>
                                <select
                                    value={time || '09'}
                                    onChange={(e) =>
                                        this.setState({ time: e.target.value })
                                    }
                                >
                                    <option value={'00'}>12:00am</option>
                                    <option value={'01'}>1:00am</option>
                                    <option value={'02'}>2:00am</option>
                                    <option value={'03'}>3:00am</option>
                                    <option value={'04'}>4:00am</option>
                                    <option value={'05'}>5:00am</option>
                                    <option value={'06'}>6:00am</option>
                                    <option value={'07'}>7:00am</option>
                                    <option value={'08'}>8:00am</option>
                                    <option value={'09'}>9:00am</option>
                                    <option value={'10'}>10:00am</option>
                                    <option value={'11'}>11:00am</option>
                                    <option value={'12'}>12:00pm</option>
                                    <option value={'13'}>1:00pm</option>
                                    <option value={'14'}>2:00pm</option>
                                    <option value={'15'}>3:00pm</option>
                                    <option value={'16'}>4:00pm</option>
                                    <option value={'17'}>5:00pm</option>
                                    <option value={'18'}>6:00pm</option>
                                    <option value={'19'}>7:00pm</option>
                                    <option value={'20'}>8:00pm</option>
                                    <option value={'21'}>9:00pm</option>
                                    <option value={'22'}>10:00pm</option>
                                    <option value={'23'}>11:00pm</option>
                                </select>
                            </div>
                            <div>
                                <div>
                                    <p className="mt15 mb5 text-small">
                                        * Send notification about this update?
                                    </p>
                                    <div className="flex-center-y mt15">
                                        <input
                                            style={{
                                                marginRight: 'var(--unit3)',
                                            }}
                                            type="checkbox"
                                            checked={sendNotificationToCustomer}
                                            onChange={() =>
                                                this.setState({
                                                    sendNotificationToCustomer:
                                                        !sendNotificationToCustomer,
                                                })
                                            }
                                        />
                                        <label>Customer</label>
                                    </div>
                                </div>
                                {user.data.type === ADMIN && (
                                    <div className="flex-center-y mt15">
                                        <input
                                            style={{
                                                marginRight: 'var(--unit3)',
                                            }}
                                            type="checkbox"
                                            checked={sendNotificationToVendor}
                                            onChange={() =>
                                                this.setState({
                                                    sendNotificationToVendor:
                                                        !sendNotificationToVendor,
                                                })
                                            }
                                        />
                                        <label>Vendor</label>
                                    </div>
                                )}
                            </div>
                            <div className="mb10 mt10 float-right">
                                <div className="flex flex-center-y">
                                    <button
                                        className="btn3 small mr10"
                                        onClick={() => close()}
                                    >
                                        <Icon>clear</Icon>
                                        <span style={{ marginLeft: 8 }}>
                                            Close
                                        </span>
                                    </button>
                                    <button
                                        className="purple small"
                                        onClick={() => this.save()}
                                    >
                                        <Icon>done</Icon>
                                        <span style={{ marginLeft: 8 }}>
                                            Save
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendEmail,
            sendSms,
            updateOrder,
        },
        dispatch,
    );
}

DateTimeModal = connect(mapStateToProps, mapDispatchToProps)(DateTimeModal);

export default DateTimeModal;
