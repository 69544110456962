// libs
import React from 'react';

// vars
import {
    VISA,
    MASTERCARD,
    AMERICAN_EXPRESS,
    DISCOVER,
} from '../vars/constants';

const CardTypeInput = ({ type, onChange }) => {
    return (
        <div>
            <select
                name="cardType"
                className="capitalize"
                value={type}
                onChange={(e) => onChange(e.target.value)}
            >
                <option value={VISA}>{VISA}</option>
                <option value={MASTERCARD}>{MASTERCARD}</option>
                <option value={AMERICAN_EXPRESS}>{AMERICAN_EXPRESS}</option>
                <option value={DISCOVER}>{DISCOVER}</option>
            </select>
        </div>
    );
};

export default CardTypeInput;
