// libs
import React from 'react';

const MainContentWindow = ({ content, title, prompt }) => {
    return (
        <main
            id="customer-dashboard-main"
            className="customer-dashboard-main-content"
        >
            <h3 className="mb4">{title}</h3>
            {}
            <hr style={{margin: 0}} />
            {prompt}
            {content}
        </main>
    );
};

export default MainContentWindow;
