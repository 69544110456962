const Spacing = {
  noMargin: '0px',
  noPadding: '0px',
  marginSmTop: '15px',
  marginSmBottom: '15px',
  marginSmRight: '15px',
  marginSmLeft: '15px',
  marginMdTop: '25px',
  marginMdBottom: '25px',
  marginMdRight: '25px',
  marginMdLeft: '25px',
  marginLgTop: '50px',
  marginLgBottom: '50px',
  marginLgRight: '50px',
  marginLgLeft: '50px',
  marginXlTop: '100px',
  marginXlBottom: '100px',
  marginXlRight: '100px',
  marginXlLeft: '100px',
  paddingSmTop: '15px',
  paddingSmBottom: '15px',
  paddingSmRight: '15px',
  paddingSmLeft: '15px',
  paddingMdTop: '25px',
  paddingMdBottom: '25px',
  paddingMdRight: '25px',
  paddingMdLeft: '25px',
  paddingLgTop: '50px',
  paddingLgBottom: '50px',
  paddingLgRight: '50px',
  paddingLgLeft: '50px',
  paddingXlTop: '100px',
  paddingXlBottom: '100px',
  paddingXlRight: '100px',
  paddingXlLeft: '100px'
}

export default Spacing;
