// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import QRCode from 'qrcode';

// UI components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';

// actions
import { createTask, getTasks } from '../actions/task/index';
import { getUsers } from '../actions/users/index';
import {
    createQRCode,
    updateQRCode,
    getQRCodes,
} from '../actions/qrCode/index';

class NewQRModal extends Component {
    state = {
        source: '',
        variant: '',
        isSaving: false,
    };

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async createQRCode() {
        this.setState({ isSaving: true });
        const qrData = {
            source: this.state.source,
            variant: this.state.variant,
        };

        const qrCode = await this.props.createQRCode(qrData);
        const base64 = await QRCode.toDataURL(
            `${window.location.origin}/qr-code?refId=${qrCode.data._id}`,
        );
        await this.props.updateQRCode(qrCode.data._id, { qrCode: base64 });
        await this.props.getQRCodes();
        await this.props.onClose();

        this.setState({
            source: '',
            variant: '',
            isSaving: false,
        });
    }

    render() {
        const { source, variant, isSaving } = this.state;

        const { isOpen = false, onClose } = this.props;

        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={isSaving ? null : () => onClose()}
                >
                    <DialogContent>
                        <h5>{isSaving ? 'Saving...' : 'New QR Code'}</h5>
                        <label>Source* (example: "Billboard")</label>
                        <input
                            name="source"
                            onChange={(e) => this.setValue(e)}
                        />
                        <label>Variant* (example: "Downtown LA")</label>
                        <input
                            name="variant"
                            onChange={(e) => this.setValue(e)}
                        />
                        <div className="pt15 pb15 float-right">
                            <div className="flex">
                                <button
                                    className="btn3 small flex flex-center-y mr10"
                                    disabled={isSaving}
                                    onClick={() => onClose()}
                                >
                                    <Icon>clear</Icon>
                                    <span style={{ marginLeft: 8 }}>
                                        Cancel
                                    </span>
                                </button>
                                <button
                                    className="purple small flex flex-center-y"
                                    disabled={isSaving || !source || !variant}
                                    onClick={() => this.createQRCode()}
                                >
                                    <Icon>save</Icon>{' '}
                                    <span style={{ marginLeft: 8 }}>Save</span>
                                </button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        taskStatus: state.taskStatus,
        loading: state.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createTask,
            getTasks,
            getUsers,
            createQRCode,
            updateQRCode,
            getQRCodes,
        },
        dispatch,
    );
}

NewQRModal = connect(mapStateToProps, mapDispatchToProps)(NewQRModal);

export default NewQRModal;
