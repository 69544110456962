// libs
import axios from 'axios';

// actions
import { throwError } from '../error/index';

// helpers
import getAuthToken from '../../formatting/getAuthToken';

// vars
import { GET_PLANT_SELECTION } from './types';

export function createPlantSelection(plantSelection) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/plant-selections`,
                plantSelection,
                { headers: { authorization: getAuthToken() } },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getPlantSelection(customerId, override) {
    return async function (dispatch) {
        try {
            const response = await axios.get(
                `/api/v1/plant-selections/${customerId}`,
                { headers: { authorization: getAuthToken() } },
            );
            if (!override)
                dispatch({ type: GET_PLANT_SELECTION, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function updatePlantSelection(id, plantSelection) {
    return async function (dispatch) {
        try {
            const updatedPlantSelection = await axios.put(
                `/api/v1/plant-selections/${id}`,
                plantSelection,
                { headers: { authorization: getAuthToken() } },
            );
            return updatedPlantSelection;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
