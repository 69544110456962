// libs
import axios from 'axios';
import moment from 'moment';

// actions
import { throwError } from '../../actions/error/index';

// helpers
import getAuthToken from '../../formatting/getAuthToken';

// types
import { GET_USER } from './types';

// vars
import { ADMIN } from '../../vars/types';

export function createUser(user, override) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/users/signup`, user);
            if (!override) {
                localStorage.setItem(
                    `${window.location.origin}-token`,
                    response.data.token,
                );
                localStorage.setItem(
                    `${window.location.origin}-token-exp`,
                    moment(moment().add('7', 'days')).format(),
                );
                dispatch({ type: GET_USER, payload: response.data.user });
            }

            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getUser(id, override) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/users/${id}`);
            if (!override) {
                dispatch({ type: GET_USER, payload: response });
            }
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function updateUser(query, user) {
    return async function (dispatch) {
        const q = query ? `?${query}` : '';
        try {
            const response = await axios.put(`/api/v1/users${q}`, user, {
                headers: { authorization: getAuthToken() },
            });
            if (
                window.location.pathname === '/dashboard' &&
                response.data.type !== ADMIN
            ) {
                return response;
            } else {
                dispatch({ type: GET_USER, payload: response });
                return response;
            }
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function deleteUser() {
    return async function (dispatch) {
        try {
            const response = await axios.delete(`/api/v1/users`, {
                headers: { authorization: getAuthToken() },
            });
            localStorage.removeItem(`${window.location.origin}-token`);
            localStorage.removeItem(`${window.location.origin}-token-exp`);
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function authenticateUser() {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/users/authenticate`, {
                headers: { authorization: getAuthToken() },
            });
            dispatch({ type: GET_USER, payload: response });
            return response;
        } catch (error) {
            dispatch(throwError(error, 'Please log in to continue'));
            localStorage.removeItem(`${window.location.origin}-token`);
            localStorage.removeItem(`${window.location.origin}-token-exp`);
            window.location.href = `${window.location.origin}`;
        }
    };
}

export function validateUser(email) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/users/validate/${email}`);
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function initiatePasswordReset(email) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/users/password-reset/${email}`,
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
