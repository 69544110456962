export function applyGardenRules(i, rules, sqft, cf, vf, lf) {
    let items = i;

    // apply item rules
    for(let i = 0; i < items.length; i++) {
        for(let j = 0; j < rules.data.length; j++) {
            if(items[i].name === rules.data[j].key.name) {
                if(rules.data[j].unit === 'bid') {
                    items[i].qty = rules.data[j].item_qty;
                } else {
                    let factor;
                    const unit = rules.data[j].unit;
                    if(unit === 'sqft') factor = sqft;
                    if(unit === 'cf') factor = cf;
                    if(unit === 'vf') factor = vf;
                    if(unit === 'lf') factor = lf;
                    const unitValue = (rules.data[j].item_qty / rules.data[j].unit_qty);
                    items[i].qty = Math.ceil((factor * unitValue));
                }
            }
        }
    }

    return items;
}