import React from 'react';
import '../../css/slider.css';

const Slider = ({ min, max, step, value, onChange }) => {
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (onChange) {
            onChange(parseFloat(newValue));
        }
    };

    return (
        <div className="slider-container">
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
                className="slider"
            />
        </div>
    );
};

export default Slider;
