import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import Alert from '../components/Alert.js';
import {
    createChangeOrder,
    getChangeOrders,
    updateChangeOrder,
} from '../actions/changeOrder/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { toggleLoading } from '../actions/loading/index';
import { setPrice } from '../formatting/setPrice.js';
import { setQty } from '../formatting/setQty.js';
import delimit from '../formatting/delimit';
import vars from '../vars/index';

class ChangeOrderModal extends Component {
    state = {
        activeStep: 0,
        steps: 7,
        changeOrderDescription: '',
        materialsRequired: false,
        laborRequired: false,
        deliveryRequired: false,
        rentalsRequired: false,
        disposalRequired: false,
        hours: '',
        rate: '',
        deliveryFee: '',
        rentalFee: '',
        disposalFee: '',
        deliveryItems: '',
        rentalItems: '',
        disposalItems: '',
        materials: [{ name: '', url: '', price: '', qty: '' }],
    };

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }

        if (prevProps.isOpen !== this.props.isOpen) {
            if (!this.props.isOpen) {
                this.setState({
                    activeStep: 0,
                    changeOrderDescription: '',
                    materialsRequired: false,
                    laborRequired: false,
                    deliveryRequired: false,
                    rentalsRequired: false,
                    disposalRequired: false,
                    hours: '',
                    rate: '',
                    deliveryFee: '',
                    rentalFee: '',
                    disposalFee: '',
                    deliveryItems: '',
                    rentalItems: '',
                    disposalItems: '',
                    materials: [{ name: '', url: '', price: '', qty: '' }],
                });
            } else {
                // if change order {...}
                if (this.props.changeOrder) {
                    // set initial form values
                    let formValues = {};

                    // if line items exists {...}
                    if (this.props.changeOrder.line_items) {
                        // set form values using existing line item data
                        formValues = {
                            materialsRequired: this.props.changeOrder.line_items
                                .materials
                                ? true
                                : false,
                            laborRequired: this.props.changeOrder.line_items
                                .labor
                                ? true
                                : false,
                            deliveryRequired: this.props.changeOrder.line_items
                                .delivery
                                ? true
                                : false,
                            rentalsRequired: this.props.changeOrder.line_items
                                .rentals
                                ? true
                                : false,
                            disposalRequired: this.props.changeOrder.line_items
                                .disposal
                                ? true
                                : false,
                            materials: this.props.changeOrder.line_items
                                .materials
                                ? this.props.changeOrder.line_items.materials
                                : [{ name: '', url: '', price: '', qty: '' }],
                            hours: this.props.changeOrder.line_items.labor
                                ? this.props.changeOrder.line_items.labor.qty
                                : '',
                            rate: this.props.changeOrder.line_items.labor
                                ? this.props.changeOrder.line_items.labor.price
                                : '',
                            deliveryFee: this.props.changeOrder.line_items
                                .delivery
                                ? this.props.changeOrder.line_items.delivery
                                      .price
                                : '',
                            deliveryItems: this.props.changeOrder.line_items
                                .delivery
                                ? this.props.changeOrder.line_items.delivery
                                      .name
                                : '',
                            rentalFee: this.props.changeOrder.line_items.rentals
                                ? this.props.changeOrder.line_items.rentals
                                      .price
                                : '',
                            rentalItems: this.props.changeOrder.line_items
                                .rentals
                                ? this.props.changeOrder.line_items.rentals.name
                                : '',
                            disposalFee: this.props.changeOrder.line_items
                                .disposal
                                ? this.props.changeOrder.line_items.disposal
                                      .price
                                : '',
                            disposalItems: this.props.changeOrder.line_items
                                .disposal
                                ? this.props.changeOrder.line_items.disposal
                                      .name
                                : '',
                        };
                    }

                    // set change order description
                    formValues.changeOrderDescription =
                        this.props.changeOrder.description;

                    // update UI
                    this.setState(formValues);
                }
            }
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    getStepContent(step) {
        const taxes = this.state.materialsRequired
            ? this.calculateMaterials(this.state.materials) * vars.tax.ca
            : 0;
        const materialCost = this.state.materialsRequired
            ? this.calculateMaterials(this.state.materials)
            : 0;

        switch (step) {
            case 0:
                return (
                    <span>
                        <h5 className="mt5 mb10">
                            What changes would the customer like made to this
                            order?
                        </h5>
                        <Divider />
                        <div className="mt10 mb15">
                            <label className="text-small">
                                List your order changes here
                            </label>
                            <textarea
                                margin="dense"
                                type="text"
                                name="changeOrderDescription"
                                placeholder="Member would like an additional 10 ft of fence..."
                                value={this.state.changeOrderDescription}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                    </span>
                );
            case 1:
                return (
                    <span>
                        <h5 className="mt5 mb10">
                            Will this change require additional <u>materials</u>
                            ?
                        </h5>
                        <Divider />
                        <div className="mt10 mb15">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="materialsRequired"
                                    value={this.state.materialsRequired}
                                    onChange={(e) => {
                                        this.setValue({
                                            target: {
                                                name: e.target.name,
                                                value:
                                                    e.target.value === 'true',
                                            },
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div
                            className={
                                this.state.materialsRequired ? null : 'hidden'
                            }
                        >
                            {this.state.materials.map((item, index) => (
                                <div key={index}>
                                    <div className="mt25 mb15">
                                        <Divider />
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} sm={3} xs={12}>
                                            <label>Item</label>
                                            <input
                                                type="text"
                                                placeholder={`2" x 6" x 8' douglas fir lumber`}
                                                value={item.name}
                                                onChange={(e) =>
                                                    this.setItems({
                                                        name: 'name',
                                                        value: e.target.value,
                                                        index: index,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={12}>
                                            <label>URL</label>
                                            <input
                                                type="text"
                                                placeholder={`https://www.homedepot.com/s/lumber?NCNI-5`}
                                                value={item.url}
                                                onChange={(e) =>
                                                    this.setItems({
                                                        name: 'url',
                                                        value: e.target.value,
                                                        index: index,
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={12}>
                                            <label>Price</label>
                                            <input
                                                type="number"
                                                name="price"
                                                placeholder="0"
                                                value={item.price}
                                                onChange={async (e) => {
                                                    const price =
                                                        await setPrice(
                                                            item.price,
                                                            e.target.value,
                                                        );
                                                    this.setItems({
                                                        name: 'price',
                                                        value: price,
                                                        index: index,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={3} xs={12}>
                                            <label>Qty</label>
                                            <div className="flex flex-center-y">
                                                <input
                                                    type="number"
                                                    name="qty"
                                                    placeholder="0"
                                                    value={item.qty}
                                                    onChange={async (e) => {
                                                        const qty =
                                                            await setQty(
                                                                item.qty,
                                                                e.target.value,
                                                            );
                                                        this.setItems({
                                                            name: 'qty',
                                                            value: qty,
                                                            index: index,
                                                        });
                                                    }}
                                                />
                                                <div className="ml5">
                                                    <Icon
                                                        className="pointer"
                                                        onClick={() =>
                                                            this.removeRow(
                                                                index,
                                                            )
                                                        }
                                                    >
                                                        clear
                                                    </Icon>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                            <div className="mt15 mb15">
                                <Button
                                    variant="outlined"
                                    className="shadow"
                                    onClick={() => this.addNewRow()}
                                >
                                    Add Item <AddIcon />
                                </Button>
                            </div>
                        </div>
                    </span>
                );
            case 2:
                return (
                    <span>
                        <h5 className="mt5 mb10">
                            Will this change require additional <u>labor</u>?
                        </h5>
                        <Divider />
                        <div className="mt10 mb15">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="laborRequired"
                                    value={this.state.laborRequired}
                                    onChange={(e) => {
                                        this.setValue({
                                            target: {
                                                name: e.target.name,
                                                value:
                                                    e.target.value === 'true',
                                            },
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div
                            className={
                                this.state.laborRequired ? null : 'hidden'
                            }
                        >
                            <div className="mt15">
                                <Divider />
                            </div>
                            <p className="text-small mt15">
                                TIP: Quotes with a labor cost of{' '}
                                <u>$35/hr or less</u> have a high customer
                                approval rating!
                            </p>
                            <Hidden only={['xs']}>
                                <div className="flex flex-center">
                                    <p className="mr10">
                                        This change order will require an
                                        additional
                                    </p>
                                    <input
                                        type="number"
                                        name="hours"
                                        placeholder="40"
                                        value={this.state.hours}
                                        onChange={async (e) => {
                                            const bidHours = await setQty(
                                                this.state.hours,
                                                e.target.value,
                                            );
                                            this.setState({ hours: bidHours });
                                        }}
                                    />
                                    <p className="mr10 ml10 text-center">
                                        hours to complete, at a rate of
                                    </p>
                                    <input
                                        type="number"
                                        name="rate"
                                        placeholder="35"
                                        value={this.state.rate}
                                        onChange={async (e) => {
                                            const bidRate = await setPrice(
                                                this.state.rate,
                                                e.target.value,
                                            );
                                            this.setState({ rate: bidRate });
                                        }}
                                    />
                                    <p className="ml10 text-center">per hour</p>
                                </div>
                            </Hidden>
                            <Hidden only={['sm', 'md', 'lg']}>
                                <div>
                                    <p className="mr10">
                                        This change order will require an
                                        additional
                                    </p>
                                    <input
                                        type="number"
                                        name="hours"
                                        placeholder="40"
                                        value={this.state.hours}
                                        onChange={async (e) => {
                                            const bidHours = await setQty(
                                                this.state.hours,
                                                e.target.value,
                                            );
                                            this.setState({ hours: bidHours });
                                        }}
                                    />
                                    <p>hours to complete, at a rate of</p>
                                    <input
                                        type="number"
                                        name="rate"
                                        placeholder="35"
                                        value={this.state.rate}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        onChange={async (e) => {
                                            const bidRate = await setPrice(
                                                this.state.rate,
                                                e.target.value,
                                            );
                                            this.setState({ rate: bidRate });
                                        }}
                                    />
                                    <p>per hour</p>
                                </div>
                            </Hidden>
                        </div>
                    </span>
                );
            case 3:
                return (
                    <span>
                        <h5 className="mt5 mb10">
                            Will this change require additional <u>delivery</u>?
                        </h5>
                        <Divider />
                        <div className="mt10 mb15">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="deliveryRequired"
                                    value={this.state.deliveryRequired}
                                    onChange={(e) => {
                                        this.setValue({
                                            target: {
                                                name: e.target.name,
                                                value:
                                                    e.target.value === 'true',
                                            },
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div
                            className={
                                this.state.deliveryRequired ? null : 'hidden'
                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item md={8} sm={8} xs={6}>
                                    <label>Delivery Items*</label>
                                    <div>
                                        <textarea
                                            type="text"
                                            name="deliveryItems"
                                            placeholder="80 lumber boards, 20 bags of soil, etc."
                                            disabled={
                                                this.props.loading.isLoading
                                            }
                                            value={this.state.deliveryItems}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={4} xs={6}>
                                    <label>Delivery Fee*</label>
                                    <input
                                        type="number"
                                        name="deliveryFee"
                                        placeholder="75"
                                        value={this.state.deliveryFee}
                                        onChange={async (e) => {
                                            const fee = await setPrice(
                                                this.state.deliveryFee,
                                                e.target.value,
                                            );
                                            this.setState({ deliveryFee: fee });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </span>
                );
            case 4:
                return (
                    <span>
                        <h5 className="mt5 mb10">
                            Will this change require additional{' '}
                            <u>tool rental</u>?
                        </h5>
                        <Divider />
                        <div className="mt10 mb15">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="rentalsRequired"
                                    value={this.state.rentalsRequired}
                                    onChange={(e) => {
                                        this.setValue({
                                            target: {
                                                name: e.target.name,
                                                value:
                                                    e.target.value === 'true',
                                            },
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div
                            className={
                                this.state.rentalsRequired ? null : 'hidden'
                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item md={8} sm={8} xs={6}>
                                    <label>Rental Items*</label>
                                    <div>
                                        <textarea
                                            type="text"
                                            name="rentalItems"
                                            placeholder="Bobcat, rototiller, etc."
                                            disabled={
                                                this.props.loading.isLoading
                                            }
                                            value={this.state.rentalItems}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={4} xs={6}>
                                    <label>Rental Fee*</label>
                                    <input
                                        type="number"
                                        name="rentalFee"
                                        placeholder="75"
                                        value={this.state.rentalFee}
                                        onChange={async (e) => {
                                            const fee = await setPrice(
                                                this.state.rentalFee,
                                                e.target.value,
                                            );
                                            this.setState({ rentalFee: fee });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </span>
                );
            case 5:
                return (
                    <span>
                        <h5 className="mt5 mb10">
                            Will this change require additional <u>disposal</u>?
                        </h5>
                        <Divider />
                        <div className="mt10 mb15">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="disposalRequired"
                                    value={this.state.disposalRequired}
                                    onChange={(e) => {
                                        this.setValue({
                                            target: {
                                                name: e.target.name,
                                                value:
                                                    e.target.value === 'true',
                                            },
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div
                            className={
                                this.state.disposalRequired ? null : 'hidden'
                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item md={8} sm={8} xs={6}>
                                    <label>Disposal Items*</label>
                                    <div>
                                        <textarea
                                            type="text"
                                            name="disposalItems"
                                            placeholder="Debris, brush, etc."
                                            disabled={
                                                this.props.loading.isLoading
                                            }
                                            value={this.state.disposalItems}
                                            onChange={(e) => this.setValue(e)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={4} sm={4} xs={6}>
                                    <label>Disposal Fee*</label>
                                    <input
                                        type="number"
                                        name="disposalFee"
                                        placeholder="75"
                                        style={{ marginTop: '8px' }}
                                        value={this.state.disposalFee}
                                        onChange={async (e) => {
                                            const fee = await setPrice(
                                                this.state.disposalFee,
                                                e.target.value,
                                            );
                                            this.setState({ disposalFee: fee });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </span>
                );
            case 6:
                return (
                    <div className="mb20">
                        <h5 className="mt5 mb10">Review & Confirm Change</h5>
                        <Divider />
                        <div className="mt15 mb15">
                            <div className="mt15">
                                <label>Member</label>
                                <p className="mt0 capitalize">
                                    {this.props.order.customer.first_name}{' '}
                                    {this.props.order.customer.last_name}
                                    <br />
                                    {this.props.order.customer.address}
                                    {this.props.order.customer.unit
                                        ? this.props.order.customer.unit
                                        : ''}
                                    , {this.props.order.customer.city}{' '}
                                    {this.props.order.customer.state}{' '}
                                    {this.props.order.customer.zip_code}
                                    <br />
                                    {this.props.order.customer.phone_number}
                                </p>
                            </div>
                            <div className="mt15">
                                <label>
                                    What change did the customer request?
                                </label>
                                <p className="mt0">
                                    {this.state.changeOrderDescription}
                                </p>
                            </div>
                            <div className="mt15">
                                {this.state.materialsRequired &&
                                    this.state.materials && (
                                        <div>
                                            <h5 className="mt0 mb5">
                                                Materials $
                                                {delimit(
                                                    this.calculateMaterials(
                                                        this.state.materials,
                                                    ).toFixed(2),
                                                )}
                                            </h5>
                                            <Grid
                                                container
                                                spacing={2}
                                                className="overflow-x-scroll overflow-y-scroll table-container"
                                            >
                                                <Grid
                                                    item
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="table-cell">
                                                                    Item
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    Price
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    Qty
                                                                </TableCell>
                                                                <TableCell className="table-cell">
                                                                    Subtotal
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.materials.map(
                                                                (
                                                                    item,
                                                                    index,
                                                                ) => (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            <a
                                                                                href={
                                                                                    item.url
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </a>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                item.price.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.qty
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            $
                                                                            {delimit(
                                                                                (
                                                                                    item.qty *
                                                                                    item.price
                                                                                ).toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ),
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                {this.state.laborRequired && (
                                    <div>
                                        <h5 className="mt0 mb5">
                                            Labor $
                                            {delimit(
                                                (
                                                    this.state.rate *
                                                    this.state.hours
                                                ).toFixed(2),
                                            )}
                                        </h5>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="overflow-x-scroll"
                                        >
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="table-cell">
                                                                Item
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Price
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Qty
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Subtotal
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <label>
                                                                    Hourly Rate
                                                                </label>
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.rate.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    this.state
                                                                        .hours
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    (
                                                                        this
                                                                            .state
                                                                            .rate *
                                                                        this
                                                                            .state
                                                                            .hours
                                                                    ).toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {this.state.deliveryRequired && (
                                    <div>
                                        <h5 className="mt0 mb5">
                                            Delivery $
                                            {this.state.deliveryFee.toFixed(2)}
                                        </h5>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="overflow-x-scroll"
                                        >
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="table-cell">
                                                                Item
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Price
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Qty
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Subtotal
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <label>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .deliveryItems
                                                                    }
                                                                </label>
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.deliveryFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {1}
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.deliveryFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {this.state.rentalsRequired && (
                                    <div>
                                        <h5 className="mt0 mb5">
                                            Rentals $
                                            {this.state.rentalFee.toFixed(2)}
                                        </h5>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="overflow-x-scroll"
                                        >
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="table-cell">
                                                                Item
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Price
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Qty
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Subtotal
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <label>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .rentalItems
                                                                    }
                                                                </label>
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.rentalFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {1}
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.rentalFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {this.state.disposalRequired && (
                                    <div>
                                        <h5 className="mt0 mb5">
                                            Disposal $
                                            {this.state.disposalFee.toFixed(2)}
                                        </h5>
                                        <Grid
                                            container
                                            spacing={2}
                                            className="overflow-x-scroll"
                                        >
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="table-cell">
                                                                Item
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Price
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Qty
                                                            </TableCell>
                                                            <TableCell className="table-cell">
                                                                Subtotal
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <label>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .disposalItems
                                                                    }
                                                                </label>
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.disposalFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {1}
                                                            </TableCell>
                                                            <TableCell>
                                                                $
                                                                {delimit(
                                                                    this.state.disposalFee.toFixed(
                                                                        2,
                                                                    ),
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                <div
                                    className={
                                        !this.state.materialsRequired &&
                                        !this.state.laborRequired &&
                                        !this.state.deliveryRequired &&
                                        !this.state.rentalsRequired &&
                                        !this.state.disposalRequired
                                            ? 'hidden '
                                            : 'mb15'
                                    }
                                >
                                    <Grid container spacing={2}>
                                        <Grid item md={6} sm={6} xs={12}>
                                            <div className="p15">
                                                <h5 className="mb15">
                                                    Quote Summary
                                                </h5>
                                                <div
                                                    className={
                                                        this.state
                                                            .materialsRequired
                                                            ? 'flex flex-space-between'
                                                            : 'hidden'
                                                    }
                                                >
                                                    <p className="m0">
                                                        Materials
                                                    </p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            materialCost.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        this.state
                                                            .laborRequired ||
                                                        this.state
                                                            .deliveryRequired ||
                                                        this.state
                                                            .rentalsRequired ||
                                                        this.state
                                                            .disposalRequired
                                                            ? 'flex flex-space-between'
                                                            : 'hidden'
                                                    }
                                                >
                                                    <p className="m0">
                                                        Labor
                                                        {this.state
                                                            .deliveryRequired >
                                                        0
                                                            ? ', Delivery'
                                                            : ''}
                                                        {this.state
                                                            .rentalsRequired
                                                            ? ', Rentals'
                                                            : ''}
                                                        {this.state
                                                            .disposalRequired
                                                            ? ', Disposal'
                                                            : ''}
                                                    </p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            (
                                                                this.state
                                                                    .rate *
                                                                    this.state
                                                                        .hours +
                                                                (this.state
                                                                    .deliveryRequired
                                                                    ? this.state
                                                                          .deliveryFee
                                                                    : 0) +
                                                                (this.state
                                                                    .rentalsRequired
                                                                    ? this.state
                                                                          .rentalFee
                                                                    : 0) +
                                                                (this.state
                                                                    .disposalRequired
                                                                    ? this.state
                                                                          .disposalFee
                                                                    : 0)
                                                            ).toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-space-between">
                                                    <p className="m0">
                                                        Processing Fee
                                                    </p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            (
                                                                (materialCost +
                                                                    (this.state
                                                                        .rate *
                                                                        this
                                                                            .state
                                                                            .hours +
                                                                        (this
                                                                            .state
                                                                            .deliveryRequired
                                                                            ? this
                                                                                  .state
                                                                                  .deliveryFee
                                                                            : 0) +
                                                                        (this
                                                                            .state
                                                                            .rentalsRequired
                                                                            ? this
                                                                                  .state
                                                                                  .rentalFee
                                                                            : 0) +
                                                                        (this
                                                                            .state
                                                                            .disposalRequired
                                                                            ? this
                                                                                  .state
                                                                                  .disposalFee
                                                                            : 0)) +
                                                                    materialCost *
                                                                        vars.tax
                                                                            .ca) *
                                                                vars.fees
                                                                    .payment_processing
                                                            ).toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        this.state
                                                            .materialsRequired
                                                            ? 'flex flex-space-between'
                                                            : 'hidden'
                                                    }
                                                >
                                                    <p className="m0">Taxes</p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            taxes.toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="mt10 mb10">
                                                    <Divider />
                                                </div>
                                                <div className="flex flex-space-between">
                                                    <p className="m0">
                                                        Change Total
                                                    </p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            (
                                                                materialCost +
                                                                (this.state
                                                                    .rate *
                                                                    this.state
                                                                        .hours +
                                                                    (this.state
                                                                        .deliveryRequired
                                                                        ? this
                                                                              .state
                                                                              .deliveryFee
                                                                        : 0) +
                                                                    (this.state
                                                                        .rentalsRequired
                                                                        ? this
                                                                              .state
                                                                              .rentalFee
                                                                        : 0) +
                                                                    (this.state
                                                                        .disposalRequired
                                                                        ? this
                                                                              .state
                                                                              .disposalFee
                                                                        : 0)) +
                                                                taxes +
                                                                (materialCost +
                                                                    (this.state
                                                                        .rate *
                                                                        this
                                                                            .state
                                                                            .hours +
                                                                        (this
                                                                            .state
                                                                            .deliveryRequired
                                                                            ? this
                                                                                  .state
                                                                                  .deliveryFee
                                                                            : 0) +
                                                                        (this
                                                                            .state
                                                                            .rentalsRequired
                                                                            ? this
                                                                                  .state
                                                                                  .rentalFee
                                                                            : 0) +
                                                                        (this
                                                                            .state
                                                                            .disposalRequired
                                                                            ? this
                                                                                  .state
                                                                                  .disposalFee
                                                                            : 0)) +
                                                                    materialCost *
                                                                        vars.tax
                                                                            .ca) *
                                                                    vars.fees
                                                                        .payment_processing
                                                            ).toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className="payments-separator"
                                        >
                                            <div
                                                className={
                                                    this.state.laborRequired
                                                        ? 'p15'
                                                        : 'hidden'
                                                }
                                            >
                                                <h5>Payout Information</h5>
                                                <div className="flex flex-space-between">
                                                    <p className="m0">Labor</p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            (
                                                                this.state
                                                                    .rate *
                                                                this.state.hours
                                                            ).toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="flex flex-space-between">
                                                    <p className="m0">
                                                        Yarden Fee (10%)
                                                    </p>
                                                    <p className="m0">
                                                        - $
                                                        {delimit(
                                                            (
                                                                this.state
                                                                    .rate *
                                                                this.state
                                                                    .hours *
                                                                vars.fees.misc
                                                            ).toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="mt10 mb10">
                                                    <Divider />
                                                </div>
                                                <div className="flex flex-space-between flex-center-y">
                                                    <p className="m0">Profit</p>
                                                    <p className="m0">
                                                        $
                                                        {delimit(
                                                            (
                                                                this.state
                                                                    .rate *
                                                                    this.state
                                                                        .hours -
                                                                this.state
                                                                    .rate *
                                                                    this.state
                                                                        .hours *
                                                                    vars.fees
                                                                        .misc
                                                            ).toFixed(2),
                                                        )}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-small text-gray3">
                                                        Payout Information is
                                                        only visible to you, not
                                                        the customer
                                                    </p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <span></span>;
        }
    }

    handleNext() {
        if (this.state.activeStep === 0) {
            if (!this.state.changeOrderDescription)
                return window.alert('Please enter a description');
            this.setState({ activeStep: this.state.activeStep + 1 });
        } else if (this.state.activeStep === 1) {
            if (this.state.materialsRequired) {
                this.state.materials.forEach((item, index) => {
                    if (!item.price || !item.qty || !item.url || !item.name) {
                        return window.alert('Please enter material details');
                    }

                    if (index + 1 === this.state.materials.length)
                        this.setState({
                            activeStep: this.state.activeStep + 1,
                        });
                });
            } else {
                this.setState({ activeStep: this.state.activeStep + 1 });
            }
        } else if (this.state.activeStep === 2) {
            if (this.state.laborRequired) {
                if (!this.state.rate || !this.state.hours)
                    return window.alert('Please enter labor details');
            }
            this.setState({ activeStep: this.state.activeStep + 1 });
        } else if (this.state.activeStep === 3) {
            if (this.state.deliveryRequired) {
                if (!this.state.deliveryItems || !this.state.deliveryFee)
                    return window.alert('Please enter delivery details');
            }
            this.setState({ activeStep: this.state.activeStep + 1 });
        } else if (this.state.activeStep === 4) {
            if (this.state.rentalsRequired) {
                if (!this.state.rentalItems || !this.state.rentalFee)
                    return window.alert('Please enter rental details');
            }
            this.setState({ activeStep: this.state.activeStep + 1 });
        } else if (this.state.activeStep === 5) {
            if (this.state.disposalRequired) {
                if (!this.state.disposalItems || !this.state.disposalFee)
                    return window.alert('Please enter disposal details');
            }
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    }

    handleBack() {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
            finalStep: false,
        }));
    }

    addNewRow() {
        let items = this.state.materials;
        items.push({ name: '', url: '', price: 0, qty: 0 });
        this.setState({ materials: items });
    }

    removeRow(index) {
        if (this.state.materials.length === 1) return;
        let items = this.state.materials;
        items.splice(index, 1);
        this.setState({ materials: items });
    }

    setItems(info) {
        let items = this.state.materials;
        items[info.index][info.name] = info.value;
        this.setState({ materials: items });
    }

    calculateMaterials(materials) {
        let materialsTotal = 0;
        if (materials) {
            materials.forEach((item) => {
                materialsTotal += item.price * item.qty;
            });
        }

        return materialsTotal;
    }

    finish() {
        this.props.toggleLoading(true);
        setTimeout(async () => {
            const changeOrder = {
                description: this.state.changeOrderDescription,
                line_items: {},
            };

            if (this.state.materialsRequired)
                changeOrder.line_items.materials = this.state.materials;
            if (this.state.laborRequired)
                changeOrder.line_items.labor = {
                    name: 'hourly rate',
                    price: this.state.rate,
                    qty: this.state.hours,
                };
            if (this.state.deliveryRequired)
                changeOrder.line_items.delivery = {
                    name: this.state.deliveryItems,
                    price: this.state.deliveryFee,
                };
            if (this.state.rentalsRequired)
                changeOrder.line_items.rentals = {
                    name: this.state.rentalItems,
                    price: this.state.rentalFee,
                };
            if (this.state.disposalRequired)
                changeOrder.line_items.disposal = {
                    name: this.state.disposalItems,
                    price: this.state.disposalFee,
                };

            // if current change order {...}
            if (this.props.changeOrder) {
                // if change order is in "change requested" status, update status to "pending approval"
                if (this.props.changeOrder.status === 'change requested')
                    changeOrder.status = 'pending approval';

                // if adding a change with no additional costs, auto-approve change order
                if (
                    !this.state.materialsRequired &&
                    !this.state.laborRequired &&
                    !this.state.deliveryRequired &&
                    !this.state.rentalsRequired &&
                    !this.state.disposalRequired
                )
                    changeOrder.status = 'approved';

                // update change order
                await this.props.updateChangeOrder(
                    this.props.changeOrder._id,
                    changeOrder,
                    this.props.order._id,
                );
            } else {
                changeOrder.order = this.props.order._id;
                changeOrder.status = 'pending approval';

                // if adding a change with no additional costs, auto-approve change order
                if (
                    !this.state.materialsRequired &&
                    !this.state.laborRequired &&
                    !this.state.deliveryRequired &&
                    !this.state.rentalsRequired &&
                    !this.state.disposalRequired
                ) {
                    changeOrder.status = 'approved';

                    // create change order
                    this.props.createChangeOrder(changeOrder);

                    await this.setState({
                        activeStep: 0,
                        changeOrderDescription: '',
                    });

                    this.props.onClose();
                    return this.props.toggleLoading(false);
                }

                // create change order
                await this.props.createChangeOrder(changeOrder);

                const notification = {
                    email: this.props.order.customer.email,
                    subject: `Yarden - New change order`,
                    label: 'New Change Order',
                    body:
                        `<p>Greetings from Yarden!</p>` +
                        `<p>There has been a change to one of your orders, please log in to your Yarden App to review and approve.</p>`,
                };

                // send email notification to customer
                await this.props.sendEmail(notification);

                const sms = {
                    from: '8888289287',
                    to: this.props.order.customer.phone_number.replace(
                        /\D/g,
                        '',
                    ),
                    body: `Greetings from Yarden! There has been a change to one of your orders, please log in to your Yarden App to review and approve.`,
                };

                // send sms notification to customer
                await this.props.sendSms(sms);
            }

            await this.setState({
                activeStep: this.state.activeStep + 1,
            });

            this.props.toggleLoading(false);
        }, 1000);
    }

    render() {
        const { activeStep, alertIsOpen, steps } = this.state;

        const { isOpen = false, onClose, error, loading } = this.props;

        return (
            <span>
                {/* modals start */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />
                {/* modals start */}

                {/* modal start */}
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    onClose={() => onClose()}
                    open={isOpen}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <div>
                            <div>
                                {activeStep === 7 ? (
                                    <div className="text-center p15">
                                        <h4>Done!</h4>
                                        <p>
                                            Your change order has been sent to
                                            the customer for approval. Please do
                                            not complete any changes until the
                                            change order has been approved, you
                                            will receive a notification once the
                                            change order is approved.
                                        </p>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            className="shadow"
                                            onClick={() => {
                                                this.props.onClose();
                                            }}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        <span>
                                            {this.getStepContent(activeStep)}
                                        </span>
                                        <div>
                                            <MobileStepper
                                                variant="text"
                                                steps={steps}
                                                position="static"
                                                activeStep={activeStep}
                                                className={
                                                    activeStep === 7
                                                        ? 'hidden'
                                                        : 'full flex-grow'
                                                }
                                                nextButton={
                                                    <span>
                                                        <span
                                                            className={
                                                                activeStep < 6
                                                                    ? null
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            <Button
                                                                size="small"
                                                                className="no-outline"
                                                                onClick={() =>
                                                                    this.handleNext()
                                                                }
                                                            >
                                                                Next
                                                                <KeyboardArrowRight />
                                                            </Button>
                                                        </span>
                                                        <span
                                                            className={
                                                                activeStep > 5
                                                                    ? null
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            <Button
                                                                size="small"
                                                                className="no-outline"
                                                                onClick={() =>
                                                                    this.finish()
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        loading.isLoading
                                                                            ? 'hidden'
                                                                            : null
                                                                    }
                                                                >
                                                                    Finish
                                                                </span>
                                                                <span
                                                                    className={
                                                                        loading.isLoading
                                                                            ? ''
                                                                            : 'hidden'
                                                                    }
                                                                >
                                                                    <CircularProgress
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                </span>
                                                            </Button>
                                                        </span>
                                                    </span>
                                                }
                                                backButton={
                                                    <Button
                                                        size="small"
                                                        className="no-outline"
                                                        onClick={() =>
                                                            this.handleBack()
                                                        }
                                                        disabled={
                                                            this.state
                                                                .activeStep ===
                                                            0
                                                        }
                                                    >
                                                        <KeyboardArrowLeft />
                                                        Back
                                                    </Button>
                                                }
                                            ></MobileStepper>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                {/* modal start */}
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateChangeOrder,
            getChangeOrders,
            createChangeOrder,
            sendEmail,
            sendSms,
            toggleLoading,
        },
        dispatch,
    );
}

ChangeOrderModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChangeOrderModal);

export default ChangeOrderModal;
