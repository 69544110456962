export default function validateBeds(beds) {
    let validation = {
        valid: true,
        message: null,
    };
    const minWidth = 12;
    const minLength = 12;

    beds.forEach((bed) => {
        if (!bed.width || !bed.length || !bed.height || !bed.qty) {
            validation.valid = false;
            validation.message = 'Please add garden bed information';
        } else {
            if (bed.width < minWidth) {
                validation.valid = false;
                validation.message =
                    'Width is too small, dimensions must use inches';
            } else if (bed.length < minLength) {
                validation.valid = false;
                validation.message =
                    'Length is too small, dimensions must use inches';
            } else if (bed.width > bed.length) {
                validation.valid = false;
                validation.message = 'Width cannot be greater than length';
            }
        }
    });

    return validation;
}
