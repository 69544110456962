// libs
import axios from 'axios';
import { throwError } from '../../actions/error/index';

// helpers
import getEnv from '../../helpers/getEnv';

// vars
import { PRODUCTION } from '../../config/index';

const currentEnv = getEnv();

// NOTE: A Slack channel must be PUBLIC to send notifications to it
export function sendAlert(alert) {
    return async function (dispatch) {
        try {
            if (currentEnv === PRODUCTION) axios.post('/api/v1/alerts', alert);
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
