// libs
import React from 'react';

const CardCodeInput = ({ type, code, onChange }) => {
    const codeLength = {
        visa: 3,
        mastercard: 3,
        americanexpress: 4,
        discover: 3,
    };

    const handleCodeChange = (e) => {
        const input = e.target.value;
        let formattedInput = input.replace(/\D/g, ''); // Remove non-numeric characters

        // Limit the CVV length based on card type
        formattedInput = formattedInput.slice(0, codeLength[type]);

        onChange(formattedInput);
    };

    return (
        <input
            type="text"
            placeholder="xxx"
            value={code}
            onChange={handleCodeChange}
            maxLength={codeLength[type]} // Set max length based on card type
        />
    );
};

export default CardCodeInput;
