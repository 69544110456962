// libs
import React, { useState } from 'react';

// UI components
import Icon from '@material-ui/core/Icon';

// vars
import { SPRING, FALL, VEGETABLE, CULINARY_HERB, FRUIT } from '../vars/types';

// styles
import '../css/plants.css';

const Plants = ({ plantSelection }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState(SPRING);
    const plants =
        selectedSeason === SPRING
            ? plantSelection.spring_plants
            : plantSelection.fall_plants;
    const vegetables = plants.filter(
        (p) => p.primary.category.name === VEGETABLE,
    );
    const herbs = plants.filter(
        (p) => p.primary.category.name === CULINARY_HERB,
    );
    const fruit = plants.filter((p) => p.primary.category.name === FRUIT);

    return (
        <div>
            {isExpanded ? (
                <div className="plant-list">
                    <div className="flex flex-center-y flex-space-between">
                        <h4>Plant Selection</h4>
                        <Icon onClick={() => setIsExpanded(false)}>close</Icon>
                    </div>
                    <p className="mt15 text-small">
                        This list shows the preferred plant types for the
                        currently selected garden club member
                    </p>
                    <div className="mt15">
                        <label>Season</label>
                        <div>
                            <select
                                onChange={(e) =>
                                    setSelectedSeason(e.target.value)
                                }
                            >
                                <option value={SPRING}>Spring</option>
                                <option value={FALL}>Fall</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                    <div className="overflow-y-scroll curved p15 mb25 bg-gray1 plant-selection-grid plants-container">
                        <p className="mt15 mb0">
                            <b>Vegetables</b>
                        </p>
                        {vegetables.map((vegetable, index) => (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="vegetable plant"
                                            src={vegetable.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {vegetable.name}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <p className="mt15 mb0">
                            <b>Herbs</b>
                        </p>
                        {herbs.map((herb, index) => (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="herb plant"
                                            src={herb.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {herb.name}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <p className="mt15 mb0">
                            <b>Fruit</b>
                        </p>
                        {fruit.map((fr, index) => (
                            <div key={index}>
                                <div className="flex flex-space-between flex-center-y mb10">
                                    <div className="flex flex-center-y">
                                        <img
                                            alt="fr plant"
                                            src={fr.image}
                                            width={35}
                                        />
                                        <label className="ml10 capitalize">
                                            {fr.name}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <button
                    className="expand-button"
                    onClick={() => setIsExpanded(true)}
                >
                    <Icon>grass</Icon>
                </button>
            )}
        </div>
    );
};

export default Plants;
