// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';

// actions
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { toggleLoading } from '../actions/loading/index';

class BidChangesModal extends Component {
    state = {
        bidChanges: '',
        step1: true,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (!this.props.isOpen) {
                // this is a fix for the html overflow when the modal closes
                let html = document.getElementsByTagName('html')[0];
                html.style = {
                    overflow: !this.props.isOpen ? 'visible' : 'hidden',
                };
            }
        }
    }

    send() {
        this.props.toggleLoading(true);
        setTimeout(async () => {
            if (this.props.bid.vendor) {
                const redirectUrl = `${window.location.origin}/dashboard`;
                const notification = {
                    email: this.props.bid.vendor.email,
                    subject: `Yarden - Changes requested`,
                    label: 'Change Request',
                    body:
                        '<p>Greetings from Yarden!</p>' +
                        '<p>Changes have been requested for your quote "' +
                        this.props.bid.description +
                        '", please log in to your <a href="' +
                        redirectUrl +
                        '">Dashboard</a> to update.</p>' +
                        '<p style="margin-top: 15px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>FROM</b></p>' +
                        '<p>' +
                        this.props.bid.customer.first_name +
                        ' ' +
                        this.props.bid.customer.last_name +
                        '</p>' +
                        '<p style="margin-top: 5px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>CHANGES REQUESTED</b></p>' +
                        '<p>' +
                        this.state.bidChanges +
                        '</p>',
                };

                await this.props.sendEmail(notification);

                const sms = {
                    from: '8888289287',
                    to: this.props.bid.vendor.phone_number.replace(/\D/g, ''),
                    body: `Greetings from Yarden! Changes have been requested for your quote "${this.props.bid.description}", please check your email for details.`,
                };

                await this.props.sendSms(sms);
            } else {
                const notification = {
                    email: 'info@yardengarden.com',
                    subject: `Yarden - Changes requested, no vendor`,
                    label: 'Change Request',
                    body:
                        '<p>Greetings from Yarden!</p>' +
                        '<p>Changes have been requested for your quote "' +
                        this.props.bid.description +
                        '", but there is currently no vendor assigned to this quote. Please assign to a vendor or update the quote.</p>' +
                        '<p style="margin-top: 15px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>FROM</b></p>' +
                        '<p>' +
                        this.props.bid.customer.first_name +
                        ' ' +
                        this.props.bid.customer.last_name +
                        '</p>' +
                        '<p style="margin-top: 5px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>CHANGES REQUESTED</b></p>' +
                        '<p>' +
                        this.state.bidChanges +
                        '</p>',
                };

                await this.props.sendEmail(notification);
            }

            this.setState({
                step1: false,
                step2: true,
            });

            this.props.toggleLoading(false);
        }, 1000);
    }

    render() {
        const { isOpen, onClose, loading, bid } = this.props;
        const { bidChanges, step1, step2 } = this.state;

        return (
            <span>
                <Dialog open={isOpen || false} onClose={() => onClose()}>
                    <DialogContent>
                        {step1 && (
                            <div>
                                <h4 className="mt0 mb15">Request Changes</h4>
                                <label>List your requested changes here</label>
                                <textarea
                                    rows="4"
                                    name="bidChanges"
                                    placeholder="I would like to add 1 more garden bed..."
                                    disabled={loading.isLoading}
                                    value={bidChanges}
                                    onChange={(e) =>
                                        this.setState({
                                            bidChanges: e.target.value,
                                        })
                                    }
                                />
                                <div className="mt15 mb15 flex flex-space-between">
                                    <button
                                        className="small btn3"
                                        onClick={() => onClose()}
                                    >
                                        <Icon>close</Icon>
                                        <span style={{ marginLeft: 8 }}>
                                            Close
                                        </span>
                                    </button>
                                    <button
                                        className="small purple"
                                        disabled={
                                            loading.isLoading ||
                                            bidChanges.length < 1
                                        }
                                        onClick={() => this.send()}
                                    >
                                        <span style={{ marginRight: 8 }}>
                                            Send
                                        </span>
                                        <Icon>send</Icon>
                                    </button>
                                </div>
                            </div>
                        )}
                        {step2 && (
                            <div className="text-center p15">
                                <p className="mt0">
                                    Your changes have been requested!
                                </p>
                                <p className="mb15">
                                    A revised quote will be sent to{' '}
                                    <b>{bid.customer.email}</b> once the
                                    requested changes have been made. Please do
                                    not approve the current quote as it will not
                                    include your requested changes.
                                </p>
                                <button
                                    className="small btn3 full-width"
                                    variant="outlined"
                                    onClick={() => {
                                        window.location.href = `${window.location.origin}/account`;
                                    }}
                                >
                                    Go to account
                                </button>
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendEmail,
            sendSms,
            toggleLoading,
        },
        dispatch,
    );
}

BidChangesModal = connect(mapStateToProps, mapDispatchToProps)(BidChangesModal);

export default BidChangesModal;
