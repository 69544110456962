import React, { useState, useEffect } from 'react';
import '../../css/linear-saving-indicator.css';

const LinearSavingIndicator = ({ isSaving, saveDuration, onFinish }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (isSaving) {
            setProgress(0);
            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => {
                            onFinish();
                        }, 1000);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, saveDuration / 100);

            return () => clearInterval(interval);
        }
    }, [isSaving, saveDuration, onFinish]);

    return (
        <div className="linear-saving-indicator">
            <div
                className="progress-bar"
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};

export default LinearSavingIndicator;
