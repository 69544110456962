// libs
import axios from 'axios';
import moment from 'moment';
import { googleLogout } from '@react-oauth/google';

// types
import { GET_USER } from '../user/types';

// actions
import { throwError } from '../../actions/error/index';

export function login(user) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/users/login`, user);
            if (response.data.error) return response;
            localStorage.setItem(
                `${window.location.origin}-token`,
                response.data.token,
            );
            localStorage.setItem(
                `${window.location.origin}-token-exp`,
                moment(moment().add('30', 'days')).format(),
            );
            response.data = response.data.user;
            dispatch({ type: GET_USER, payload: response });
            return response;
        } catch (error) {
            if (error.response.status !== 401) {
                dispatch(
                    throwError(
                        error,
                        'Something went wrong. We are working on a fix now!',
                    ),
                );
            }
        }
    };
}

export function logout() {
    return async function () {
        localStorage.removeItem(`${window.location.origin}-token`);
        localStorage.removeItem(`${window.location.origin}-token-exp`);
        const isGoogleLogin = localStorage.getItem('google-login');
        if(isGoogleLogin) {
            localStorage.removeItem(`google-login`);
            googleLogout();
        }
        window.location.href = `${window.location.origin}`;
    };
}

export function resetPassword(id, user) {
    return async function (dispatch) {
        try {
            const response = await axios.put(
                `/api/v1/users/password-reset/${id}`,
                user,
            );
            if (response.data.error) return response;
            localStorage.setItem(
                `${window.location.origin}-token`,
                response.data.token,
            );
            localStorage.setItem(
                `${window.location.origin}-token-exp`,
                moment(moment().add('30', 'days')).format(),
            );
            dispatch({ type: GET_USER, payload: response.data.user });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function googleLogin(token) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/auth/google`, token);
            if (response.data.error) return response;
            localStorage.setItem(
                `${window.location.origin}-token`,
                response.data.token,
            );
            localStorage.setItem(
                `${window.location.origin}-token-exp`,
                moment(moment().add('30', 'days')).format(),
            );
            localStorage.setItem(`google-login`, true);
            response.data = response.data.user;
            dispatch({ type: GET_USER, payload: response });
            return response;
        } catch (error) {
            if (error.response.status !== 404) {
                dispatch(
                    throwError(
                        error,
                        'Something went wrong. We are working on a fix now!',
                    ),
                );
            }
        }
    };
}
