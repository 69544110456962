import { validateCurrency } from './validateCurrency.js';

export function setPrice(originalValue, newValue) {
    let price;
    if(newValue.length < 1) {
        price = '';
    } else {
        if(!validateCurrency(newValue)) return price = originalValue;
        price = parseFloat(newValue);
    }

    return price;
}