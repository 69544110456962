// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// UI components
import Icon from '@material-ui/core/Icon';
import LoaderModal from '../components/LoaderModal';
import GoogleAuthButton from '../components/GoogleAuthButton';
import PasswordStrengthIndicator from '../components/PasswordStrengthIndicator';
import Navbar from '../components/Navbar';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher';

// helpers
import capitalize from '../helpers/capitalize';
import validatePassword from '../helpers/validatePassword';
import logInfo from '../helpers/logInfo';

// styling
import '../css/home.css';

let SetPassword = ({ getAccount, updateAccount, resetPassword }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const [account, setAccount] = useState(null);
    const [userId, setUserId] = useState(null);
    const [job, setJob] = useState(null);
    const [bid, setBid] = useState(null);
    const [invalidURL, setInvalidURL] = useState(false);

    useEffect(() => {
        const initData = async () => {
            const urlParams = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );

            if (urlParams.user) {
                const account = await getAccount(urlParams.user);
                setAccount(account.data);
                setUserId(urlParams.user);
            } else {
                setInvalidURL(true);
            }

            if (urlParams.bid) {
                setBid(urlParams.bid);
            }

            if (urlParams.job) {
                setJob(urlParams.job);
            }

            logInfo('Set Password page loaded');
        };

        initData();
    }, []);

    async function savePassword() {
        const validPassword = validatePassword(password);
        const validConfirmPassword = validatePassword(confirmPassword);
        if (!validPassword || !validConfirmPassword) {
            return window.alert('Password does not meet requirements');
        } else if (password !== confirmPassword) {
            return window.alert(
                "Password and confirm password fields don't match",
            );
        }

        setLoadingModalIsOpen(true);

        try {
            await resetPassword(userId, {
                userPassword: password,
            });

            handleRedirect();
        } catch (err) {
            window.alert('Something went wrong! We are working on a fix now.');
        } finally {
            setLoadingModalIsOpen(false);
        }
    }

    async function handleRedirect() {
        await updateAccount(userId, {
            status: 'activated',
            dt_activated: new Date(),
        });

        const redirect =
            account.status === 'pending'
                ? `${window.location.origin}/approve?${
                      job ? `job=${job}` : `bid=${bid}`
                  }`
                : `${window.location.origin}/account`;

        window.location.href = redirect;
    }

    function renderInvalidURL() {
        return (
            <div className="p50">
                <p className="text-center">
                    Invalid URL, please contact support to continue.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => (window.location.pathname = '/')}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Go Home</span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    function renderView() {
        if (account) {
            return (
                <div className="flex flex-center">
                    <div className="mt25">
                        <h4 className="mb15">Activate Account</h4>
                        <div className="mb25">
                            <small>
                                Hello{' '}
                                <b>{capitalize(account.user.first_name)}</b>,
                                please set a password for your account
                            </small>
                        </div>
                        <PasswordStrengthIndicator password={password} />
                        <label className="mt10">Password *</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="******"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="mt10">Confirm Password *</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="******"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="mt10">
                            <button
                                className="purple small flex flex-center full-width"
                                onClick={() => savePassword()}
                            >
                                <Icon>done</Icon>
                                <span style={{ marginLeft: 8 }}>
                                    Save & Continue
                                </span>
                            </button>
                        </div>
                        <div className="pt15 pb15">
                            <hr />
                        </div>
                        <div className="flex flex-center">
                            <GoogleAuthButton
                                onSuccess={() => handleRedirect()}
                            />
                        </div>
                        <div className="pt25 flex flex-center">
                            <label className="text-center">
                                By activating your account, you agree to
                                Yarden's{' '}
                                <a
                                    href="/terms-of-service"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Service
                                </a>{' '}
                                and acknowledge that you have read our{' '}
                                <a
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                *
                            </label>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    }

    return (
        <div>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* main content */}
            <Navbar />
            {invalidURL ? (
                renderInvalidURL()
            ) : (
                <div className="pt75 pr25 pl25 pb15">{renderView()}</div>
            )}
        </div>
    );
};

SetPassword = connect(null, mapDispatchToProps)(SetPassword);

export default SetPassword;
