// libs
import React from 'react';

// UI components
import Icon from '@material-ui/core/Icon';

const GeneralMessage = ({ message, isSuccess, buttonText, buttonAction }) => {
    return (
        <div className="p50">
            {isSuccess && (
                <div className="flex flex-center" style={{ height: 100 }}>
                    <span style={{ fontSize: 35, color: 'var(--purpleB)' }}>
                        ٩( ᐛ )و
                    </span>
                </div>
            )}
            <p className="text-center">{message}</p>
            {buttonText && buttonAction && (
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => buttonAction()}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>{buttonText}</span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};

export default GeneralMessage;
