// libs
import React from 'react';

const CardExpirationDateInput = ({ expirationDate, onChange }) => {
    const handleExpiryDateChange = (e) => {
        const input = e.target.value;
        let formattedInput = input.replace(/\D/g, ''); // Remove non-numeric characters

        if (formattedInput.length > 2) {
            formattedInput =
                formattedInput.slice(0, 2) + '/' + formattedInput.slice(2);
        }

        onChange(formattedInput);
    };

    return (
        <input
            type="text"
            placeholder="xx/xx"
            value={expirationDate}
            onChange={handleExpiryDateChange}
            maxLength={5} // Maximum length for MM/YY format
        />
    );
};

export default CardExpirationDateInput;
