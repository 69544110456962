import { GET_SHAPES } from '../actions/shape/types';

let initialState = {}

export default function( state = initialState, action){
    switch(action.type){
      case GET_SHAPES :
        return {...action.payload}
      default :
        return state;
    }
}