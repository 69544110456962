// libs
import React from 'react';

// UI components
import Logo from './LogoMark';

// styles
import '../css/navbar.css';

const Navbar = () => {
    return (
        <div className="navbar flex flex-center">
            <div
                className="pointer"
                onClick={() =>
                    (window.location.href = `${window.location.origin}`)
                }
            >
                <Logo height={36} />
            </div>
        </div>
    );
};

export default Navbar;
