// libs
import React, { useState } from 'react';

// helpers
import formatOrderTime from '../formatting/orderTime';
import capitalize from '../helpers/capitalize';

// vars
import { FULL_PLAN, ASSISTED_PLAN } from '../vars/types';

// styles
import '../css/customer-order-details.css';

function ImageModal({ isOpen, onClose, image }) {
    if (!isOpen || !image) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <img src={image.url} alt="Detailed view" />
                <button onClick={onClose} className="close-modal btn3 small">
                    Close
                </button>
            </div>
        </div>
    );
}

const CustomerOrderDetails = ({ order, onBack }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    function handleImgClick(image) {
        setSelectedImage(image);
    }

    function closeModal() {
        setSelectedImage(null);
    }

    if (!order) {
        return <div>No order details available.</div>;
    }
    return (
        <div className="customer-order-details-container">
            <button className="btn3 small mb4" onClick={onBack}>
                Back
            </button>

            <div className="customer-order-details">
                {/* order type */}
                <div className="order-type flex-space-between">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Type</label>
                        <h4>
                            {order.type === FULL_PLAN ||
                            order.type === ASSISTED_PLAN
                                ? 'Maintenance'
                                : capitalize(order.type)}
                        </h4>
                    </div>

                    {/* order status */}
                    <div className="order-data order-status">
                        <label>Status</label>
                        <data>{order.status}</data>
                    </div>
                </div>

                <hr style={{ width: '100%' }} />

                <div className="auto-grid-medium">
                    {/* order address */}
                    <div className="order-data order-address">
                        <label>Address</label>
                        <data className="capitalize">
                            <div>{order.customer.address}</div>
                            <div>
                                <span>{order.customer.city}</span>,&nbsp;
                                <span className="uppercase">
                                    {order.customer.state}
                                </span>
                                <span>{order.customer.zipCode}</span>
                            </div>
                        </data>
                    </div>

                    {/* order date */}
                    <div className="order-data order-date">
                        <label>Date</label>
                        <data>
                            {new Date(order.date).toLocaleDateString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </data>
                    </div>

                    {/* order time */}
                    <div className="order-data order-time">
                        <label>Time</label>
                        {formatOrderTime(order)}
                    </div>

                    {/* vendor */}
                    {order.vendor && (
                        <div className="order-data vendor">
                            <label className="capitalize">{order.vendor.type}</label>
                            <small className="flex-center-y">
                                <img
                                    className="avatar"
                                    src={order.vendor.profile_image}
                                    alt={order.vendor.first_name}
                                />
                                <data className="capitalize">&nbsp;{order.vendor.first_name}</data>
                            </small>
                        </div>
                    )}

                    {/* date created */}
                    {order.dt_created && (
                        <div className="order-data order-dt_created">
                            <label>Date Created</label>
                            <data>
                                {new Date(order.dt_created).toLocaleDateString(
                                    'en-US',
                                    {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    },
                                )}
                            </data>
                        </div>
                    )}

                    {/* date completed */}
                    {order.dt_completed && (
                        <div className="order-data order-dt_completed">
                            <label>Date Completed</label>
                            <data>
                                {new Date(
                                    order.dt_completed,
                                ).toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                            </data>
                        </div>
                    )}
                </div>

                {/* order images */}
                {order.images && order.images.length > 0 && (
                    <div className="order-data order-images">
                        <label className="mb1">Images</label>
                        <div className="order-images-container">
                            {order.images.map((image, index) => (
                                <img
                                    key={index}
                                    className="thumbnail"
                                    src={image.url}
                                    alt={`Order Result ${index + 1}`}
                                    onClick={() => handleImgClick(image)}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Modal for displaying images */}
                <ImageModal
                    isOpen={!!selectedImage}
                    onClose={closeModal}
                    image={selectedImage}
                />
            </div>
        </div>
    );
};

CustomerOrderDetails.defaultProps = {
    order: {},
    vendor: {},
};

export default CustomerOrderDetails;
