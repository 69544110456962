import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Spacing from '../styling/Spacing';

const styles = {
  modalBody: {
    padding: '50px 50px',
    textAlign: 'center'
  },
  loadingDescription: {
    paddingTop: Spacing.paddingSmTop
  }
}

class LoaderModal extends Component {

  componentDidUpdate(prevProps) {
    if(prevProps.isOpen !== this.props.isOpen) {
      if(!this.props.isOpen) {
        // this is a fix for the html overflow when the modal closes
        let html = document.getElementsByTagName('html')[0];            
        html.style = {overflow: (!this.props.isOpen) ? 'visible' : 'hidden'};
      }
    }
  } 

  render() {

    let { isOpen } = this.props;

    return (
      <span>
        <Dialog open={isOpen || false}>
          <DialogContent>
            <div style={styles.modalBody}>
              <CircularProgress />
              <h5 style={styles.loadingDescription}>Loading...</h5>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    )
  }
}

export default LoaderModal;
