import {
    ORDER_PENDING,
    ORDER_COMPLETE,
    BID_REQUESTED,
    BID_PENDING_APPROVAL,
    APPROVED,
    PENDING_TASKS,
    COMPLETE_TASKS,
} from './types';

export function updateOrderStatus(status) {
    return async function (dispatch) {
        dispatch({
            type: status === 'pending' ? ORDER_PENDING : ORDER_COMPLETE,
        });
    };
}

export function updateBidStatus(status) {
    return async function (dispatch) {
        let statusType = BID_REQUESTED;
        if (status === 'pending approval') statusType = BID_PENDING_APPROVAL;
        if (status === 'approved') statusType = APPROVED;
        dispatch({ type: statusType });
    };
}

export function updateTaskStatus(status) {
    return async function (dispatch) {
        dispatch({
            type: status === 'pending' ? PENDING_TASKS : COMPLETE_TASKS,
        });
    };
}
