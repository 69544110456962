import axios from 'axios';
import { GET_USERS } from './types';
import { throwError } from '../../actions/error/index';

export function getUsers(query, override) {
    return async function (dispatch) {
        const q = query ? `?${query}` : '';
        try {
            const response = await axios.get(`/api/v1/users${q}`);
            if (!override) dispatch({ type: GET_USERS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
