// libs
import axios from 'axios';

// types
import { GET_RESCHEDULES } from './types';

// actions
import { throwError } from '../error/index';

// helpers
import getAuthToken from '../../formatting/getAuthToken';

export function createReschedule(reschedule) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/reschedules`,
                reschedule,
                {
                    headers: { authorization: getAuthToken() },
                },
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export async function getReschedules(query, override) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/reschedules${q}`, {
                headers: { authorization: getAuthToken() },
            });
            if (!override)
                dispatch({ type: GET_RESCHEDULES, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
