import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        defaultZoom={9}
        zoom={props.zoom}
        defaultCenter={
          props.data.length > 0
            ? props.data[0].props.position
            : { lat: 37.8150976313471, lng: -122.277689495511 }
        }
        defaultOptions={{
          streetViewControl: false,
          scaleControl: false,
          panControl: true,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {props.isMarkerShown && props.data.length > 0 && (
          <span>{props.data}</span>
        )}
      </GoogleMap>
    );
  })
);

export default Map;