// libs
import React from 'react';

// UI components
import Icon from '@material-ui/core/Icon';

// styling
import '../css/password-strength-indicator.css';

const PasswordStrengthIndicator = ({ password }) => {
    const passwordIsLongEnough = password.length > 5;
    const passwordContainsUppercaseCharacter = /[A-Z]/.test(password);
    const passwordContainsLowercaseCharacter = /[a-z]/.test(password);
    const passwordContainsAtLeastOneNumberOrSpecialCharacter =
        /[0-9!@#$%^&*]/.test(password);

    return (
        <div>
            <small>
                <i>Password Requirements</i>
            </small>
            <div className="flex flex-center-y">
                · 6 character length minimum{' '}
                {passwordIsLongEnough && (
                    <Icon className="ml5 valid">done</Icon>
                )}
            </div>
            <div className="flex flex-center-y">
                · 1 uppercase character{' '}
                {password && passwordContainsUppercaseCharacter && (
                    <Icon className="ml5 valid">done</Icon>
                )}
            </div>
            <div className="flex flex-center-y">
                · 1 lowercase character{' '}
                {passwordContainsLowercaseCharacter && (
                    <Icon className="ml5 valid">done</Icon>
                )}
            </div>
            <div className="flex flex-center-y">
                · 1 number or special character{' '}
                {passwordContainsAtLeastOneNumberOrSpecialCharacter && (
                    <Icon className="ml5 valid">done</Icon>
                )}
            </div>
        </div>
    );
};

export default PasswordStrengthIndicator;
