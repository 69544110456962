// order status's
export const ORDER_PENDING = 'Pending';
export const ORDER_COMPLETE = 'Complete';

// bid status's
export const BID_REQUESTED = 'Bid Requested';
export const BID_PENDING_APPROVAL = 'Pending Approval';
export const APPROVED = 'Approved';

// task status's
export const PENDING_TASKS = 'Pending Tasks';
export const COMPLETE_TASKS = 'Complete Tasks';
