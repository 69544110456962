import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_RULES  } from './types';

export function createRule(rule) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/rules`, rule, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getRules(query, override) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/rules${q}`, {headers: {authorization: getAuthToken()}});
            if(!override) dispatch({type: GET_RULES, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateRule(id, rule) {
    return async function(dispatch) {
        try {
            await axios.put(`/api/v1/rules/${id}`, rule, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function deleteRule(id) {
    return async function(dispatch) {
        try {
            await axios.delete(`/api/v1/rules/${id}`, {headers: {authorization: getAuthToken()}});
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}