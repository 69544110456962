import axios from 'axios';
import { throwError } from '../../actions/error/index';

export function createDirectDeposit(info) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/payouts`, info);
            return response;
        } catch (error) {
            if (error.response.status === 500) {
                dispatch(
                    throwError(
                        error,
                        'Something went wrong. We are working on a fix now!',
                    ),
                );
            } else {
                throw Error(error.response.data);
            }
        }
    };
}
