// libs
import React from 'react';

// UI components
import Logo from './LogoHorizontal';
import NavTabs from './NavTabs';

const TopNav = ({ content }) => {
    return (
        <header id="customer-dashboard-header">
            <div className="flex-center pl4">
                <Logo height={32} />
            </div>
            <div>
                <NavTabs className="header-nav" data={content} />
            </div>
        </header>
    );
};

export default TopNav;
