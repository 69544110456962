import axios from 'axios';
import { throwError } from '../../actions/error/index';

export function getIP() {
    return async function(dispatch) {
        try {
            const response = await axios.get(`https://ipgeolocation.abstractapi.com/v1/?api_key=66541dd7c5ee44a5ad0f094c56376e2b`);
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}