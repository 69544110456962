import vars from '../vars/index';

export default function calculateLabor(height, sqft) {

    // set initial value
    let totalCost = 0;

    switch (height) {
        case 12:
            totalCost = (sqft * vars.pricing.installation.sqFtLabor.twelve);
            return totalCost;

        case 18:
            totalCost = (sqft * vars.pricing.installation.sqFtLabor.eighteen);
            return totalCost;

        case 24:
            totalCost = (sqft * vars.pricing.installation.sqFtLabor.twentyFour);
            return totalCost;
        case 36:
            totalCost = (sqft * vars.pricing.installation.sqFtLabor.thirtySix);
            return totalCost;

        default:
            return totalCost;
    }
}
