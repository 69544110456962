// libs
import React, { useContext, useEffect } from 'react';

// UI components
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Divider from '@material-ui/core/Divider';

// helpers
import { setQty } from '../formatting/setQty';
import { setPrice } from '../formatting/setPrice';

// context
import { LineItemsContext } from '../context/LineItemsContext';

const LineItems = ({ data }) => {
    const {
        hours,
        rate,
        materialsRequired,
        materials,
        deliveryRequired,
        deliveryItems,
        deliveryFee,
        rentalsRequired,
        rentalItems,
        rentalFee,
        disposalRequired,
        disposalItems,
        disposalFee,
        setHours,
        setRate,
        setMaterialsRequired,
        setMaterials,
        setDeliveryRequired,
        setDeliveryItems,
        setDeliveryFee,
        setRentalsRequired,
        setRentalItems,
        setRentalFee,
        setDisposalRequired,
        setDisposalItems,
        setDisposalFee,
    } = useContext(LineItemsContext);

    useEffect(() => {
        if (data && data.line_items) {
            if (data.line_items.delivery) {
                setDeliveryRequired(true);
                setDeliveryItems(data.line_items.delivery.name);
                setDeliveryFee(data.line_items.delivery.price);
            }

            if (data.line_items.rentals) {
                setRentalsRequired(true);
                setRentalItems(data.line_items.rentals.name);
                setRentalFee(data.line_items.rentals.price);
            }

            if (data.line_items.disposal) {
                setDisposalRequired(true);
                setDisposalItems(data.line_items.disposal.name);
                setDisposalFee(data.line_items.disposal.price);
            }
        }
    }, []);

    const setItems = (info) => {
        let items = materials.map((m) => m);
        items[info.index][info.name] = info.value;
        setMaterials(items);
    };

    const addNewRow = () => {
        let items = materials.map((m) => m);
        items.unshift({ name: '', url: '', price: '', qty: '' });
        setMaterials(items);
    };

    const removeRow = (index) => {
        if (materials.length === 1) return;
        let items = materials.map((m) => m);
        items.splice(index, 1);
        setMaterials(items);
    };

    return (
        <div>
            <h6>Labor *</h6>
            <div>
                <label>Total Hours *</label>
                <input
                    type="number"
                    name="hours"
                    placeholder="40"
                    value={hours}
                    onChange={async (e) => {
                        const bidHours = await setQty(hours, e.target.value);
                        setHours(bidHours);
                    }}
                />
                <label>Rate Per Hour *</label>
                <input
                    type="number"
                    name="rate"
                    placeholder="35"
                    value={rate}
                    onChange={async (e) => {
                        const bidRate = await setPrice(rate, e.target.value);
                        setRate(bidRate);
                    }}
                />
            </div>
            <div className="mt15 mb15">
                <Divider />
            </div>
            <h6>Materials</h6>
            <div>
                <div className="flex flex-center-y flex-space-between mt10">
                    <select
                        style={{ width: 'fit-content' }}
                        name="materialsRequired"
                        value={materialsRequired}
                        onChange={(e) => {
                            const required =
                                e.target.value === 'true' ? true : false;
                            setMaterialsRequired(required);
                        }}
                    >
                        <option value={true}>Included</option>
                        <option value={false}>Excluded</option>
                    </select>
                    {materialsRequired && (
                        <div className="flex flex-center-y">
                            <button
                                className="small btn3 mr10"
                                onClick={() => addNewRow()}
                            >
                                <AddIcon />
                            </button>
                            <button
                                className="small btn3"
                                onClick={() => removeRow(0)}
                            >
                                <RemoveIcon />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {materialsRequired && (
                <div>
                    {materials.map((item, index) => (
                        <div key={index}>
                            <div className="auto-grid-small">
                                <div>
                                    <label>Item *</label>
                                    <input
                                        type="text"
                                        placeholder={`2" x 6" x 8' douglas fir lumber`}
                                        value={item.name}
                                        onChange={(e) =>
                                            setItems({
                                                name: 'name',
                                                value: e.target.value,
                                                index: index,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>URL *</label>
                                    <input
                                        type="text"
                                        placeholder={`https://www.homedepot.com/s/lumber?NCNI-5`}
                                        value={item.url}
                                        onChange={(e) =>
                                            setItems({
                                                name: 'url',
                                                value: e.target.value,
                                                index: index,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Price *</label>
                                    <input
                                        type="number"
                                        name="price"
                                        placeholder="0"
                                        value={item.price}
                                        onChange={async (e) => {
                                            const price = await setPrice(
                                                item.price,
                                                e.target.value,
                                            );
                                            setItems({
                                                name: 'price',
                                                value: price,
                                                index: index,
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <label>Qty *</label>
                                    <div className="flex flex-center-y">
                                        <input
                                            type="number"
                                            name="qty"
                                            placeholder="0"
                                            value={item.qty}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    item.qty,
                                                    e.target.value,
                                                );
                                                setItems({
                                                    name: 'qty',
                                                    value: qty,
                                                    index: index,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {index !== materials.length - 1 && <hr />}
                        </div>
                    ))}
                </div>
            )}
            <div className="mt15 mb15">
                <Divider />
            </div>
            <h6>Delivery</h6>
            <div>
                <div className="mt10">
                    <select
                        style={{ width: 'fit-content' }}
                        name="deliveryRequired"
                        value={deliveryRequired}
                        onChange={(e) => {
                            const required =
                                e.target.value === 'true' ? true : false;
                            setDeliveryRequired(required);
                        }}
                    >
                        <option value={true}>Included</option>
                        <option value={false}>Excluded</option>
                    </select>
                </div>
            </div>
            {deliveryRequired && (
                <div>
                    <Grid container spacing={2}>
                        <Grid item md={10} sm={10} xs={9}>
                            <label>Delivery Items*</label>
                            <div>
                                <input
                                    type="text"
                                    name="deliveryItems"
                                    placeholder="80 lumber boards, 20 bags of soil, etc."
                                    value={deliveryItems}
                                    onChange={(e) =>
                                        setDeliveryItems(e.target.value)
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item md={2} sm={2} xs={3}>
                            <label>Delivery Fee*</label>
                            <input
                                type="number"
                                name="deliveryFee"
                                placeholder="75"
                                value={deliveryFee}
                                onChange={async (e) => {
                                    const fee = await setPrice(
                                        deliveryFee,
                                        e.target.value,
                                    );
                                    setDeliveryFee(`${fee}`);
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            <div className="mt15 mb15">
                <Divider />
            </div>
            <h6>Tool Rentals</h6>
            <div>
                <div className="mt10">
                    <select
                        style={{ width: 'fit-content' }}
                        name="rentalsRequired"
                        value={rentalsRequired}
                        onChange={(e) => {
                            const required =
                                e.target.value === 'true' ? true : false;
                            setRentalsRequired(required);
                        }}
                    >
                        <option value={true}>Included</option>
                        <option value={false}>Excluded</option>
                    </select>
                </div>
            </div>
            {rentalsRequired && (
                <div>
                    <Grid container spacing={2}>
                        <Grid item md={10} sm={10} xs={9}>
                            <label>Rental Items*</label>
                            <div>
                                <input
                                    type="text"
                                    name="rentalItems"
                                    placeholder="Bobcat, rototiller, etc."
                                    value={rentalItems}
                                    onChange={(e) =>
                                        setRentalItems(e.target.value)
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item md={2} sm={2} xs={3}>
                            <label>Rental Fee*</label>
                            <input
                                type="number"
                                name="rentalFee"
                                placeholder="75"
                                value={rentalFee}
                                onChange={async (e) => {
                                    const fee = await setPrice(
                                        rentalFee,
                                        e.target.value,
                                    );
                                    setRentalFee(fee);
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
            <div className="mt15 mb15">
                <Divider />
            </div>
            <h6>Disposal</h6>
            <div>
                <div className="mt10">
                    <select
                        style={{ width: 'fit-content' }}
                        name="disposalRequired"
                        value={disposalRequired}
                        onChange={(e) => {
                            const required =
                                e.target.value === 'true' ? true : false;
                            setDisposalRequired(required);
                        }}
                    >
                        <option value={true}>Included</option>
                        <option value={false}>Excluded</option>
                    </select>
                </div>
            </div>
            {disposalRequired && (
                <div>
                    <Grid container spacing={2}>
                        <Grid item md={10} sm={10} xs={9}>
                            <label>Disposal Items*</label>
                            <div>
                                <input
                                    type="text"
                                    name="disposalItems"
                                    placeholder="Debris, brush, etc."
                                    value={disposalItems}
                                    onChange={(e) =>
                                        setDisposalItems(e.target.value)
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item md={2} sm={2} xs={3}>
                            <label>Disposal Fee*</label>
                            <input
                                type="number"
                                name="disposalFee"
                                placeholder="75"
                                value={disposalFee}
                                onChange={async (e) => {
                                    const fee = await setPrice(
                                        disposalFee,
                                        e.target.value,
                                    );
                                    setDisposalFee(fee);
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default LineItems;
