// libraries
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Icon from '@material-ui/core/Icon';
import Logo from '../components/LogoHorizontal';
import Filepicker from '../components/UI/Filepicker';
import LoaderModal from '../components/LoaderModal';

// helpers
import capitalize from '../helpers/capitalize';
import logInfo from '../helpers/logInfo';

// vars
import { GOOGLE } from '../vars/types';

let Reviews = (props) => {
    const {
        getImage,
        updateImage,
        getReviews,
        createReview,
        sendAlert,
        sendEmail,
        user,
    } = props;
    const [screenshot, setScreenshot] = useState(null);
    const [reviewSubmitted, setReviewSubmitted] = useState(false);
    const [previousReviewFound, setPreviousReviewFound] = useState(false);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);

    useEffect(() => {
        const initPageData = async () => {
            setLoadingModalIsOpen(true);
            const reviews = await getReviews(
                `customer=${user.data._id}&type=${GOOGLE}`,
            );

            if (reviews.data.length > 0) {
                setPreviousReviewFound(true);
            }

            setLoadingModalIsOpen(false);
            logInfo('Reviews page loaded', { user: user.data });
        };

        initPageData();
    }, []);

    async function submit() {
        if (!screenshot) {
            return window.alert('Please upload a screenshot');
        }

        setLoadingModalIsOpen(true);

        const hostedImg = await getImage(screenshot.name, screenshot.type);
        const reviewImage = hostedImg.data.data.returnData.url;
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': screenshot.type,
            },
        };

        await updateImage(signedRequest, screenshot, options);

        const review = {
            customer: user.data._id,
            type: GOOGLE,
            image: reviewImage,
        };

        await createReview(review);

        const alert = {
            channel: 'reviews',
            text: `*New Review!* \nUSER\n${capitalize(
                user.data.first_name,
            )} ${capitalize(user.data.last_name)}\n\nREVIEW\n${reviewImage}`,
        };

        await sendAlert(alert);

        const message = {
            email: 'isaac.grey@yardengarden.com',
            subject: `Yarden - (ACTION REQUIRED) New review reward`,
            label: 'New Review',
            body:
                '<p>Hello <b>Yarden HQ</b>,</p>' +
                '<p>A new review has been submitted, please credit this member with 1 month of FREE gardening service.</p>' +
                '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Name</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                capitalize(user.data.first_name) +
                ' ' +
                capitalize(user.data.last_name) +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Review</b></p>' +
                '</td>' +
                '<td>' +
                `<a href="${reviewImage}">${reviewImage}</a>` +
                '</td>' +
                '</tr>' +
                '</table>',
        };

        await sendEmail(message);

        setReviewSubmitted(true);
        setLoadingModalIsOpen(false);
    }

    function renderReviewForm() {
        return (
            <div
                className="p15"
                style={{ maxWidth: 'var(--unit9)', margin: '0 auto' }}
            >
                <h3>Write a Review</h3>
                <h3>
                    <Icon>star_border</Icon>
                    <Icon>star_border</Icon>
                    <Icon>star_border</Icon>
                    <Icon>star_border</Icon>
                    <Icon>star_border</Icon>
                </h3>
                <p className="mt25">
                    Earn 1 FREE month of gardening service for writing a review!
                </p>
                <hr />
                <div>
                    <h4 className="mt15 mb5">1. Add Google review</h4>
                    <small>
                        Simply{' '}
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.google.com/maps/place/Yarden+Garden,+Inc./@39.6610454,-125.4807831,6z/data=!4m12!1m2!2m1!1syarden+garden!3m8!1s0x808f7fbbbf661127:0xb36921cf9a1793d0!8m2!3d37.878638!4d-122.4203375!9m1!1b1!15sCg15YXJkZW4gZ2FyZGVuWg8iDXlhcmRlbiBnYXJkZW6SAQhnYXJkZW5lcpoBJENoZERTVWhOTUc5blMwVkpRMEZuU1VObE5scFBWbmxCUlJBQuABAA!16s%2Fg%2F11tcvh69qv"
                        >
                            Click Here
                        </a>{' '}
                        to add your feedback
                    </small>
                    <h4 className="mt15 mb5">2. Take a screenshot</h4>
                    <small>
                        Once you've created your review, take a screenshot
                    </small>
                    <h4 className="mt15 mb5">3. Upload photo</h4>
                    <small>Upload the photo of the screenshot here</small>
                    <div className="mt15">
                        <Filepicker
                            max={1}
                            onChange={(files) => setScreenshot(files[0])}
                            onReset={() => setScreenshot(null)}
                        />
                    </div>
                </div>
                <div className="flex flex-center flex-space-between mt25 mb25">
                    <button
                        onClick={() => (window.location.href = '/account')}
                        className="btn3 small flex flex-center-y"
                    >
                        <Icon>arrow_back</Icon>
                        <span style={{ marginLeft: 8 }}>Back</span>
                    </button>
                    <button
                        className="purple small flex flex-center-y"
                        onClick={() => submit()}
                    >
                        <Icon>done</Icon>{' '}
                        <span style={{ marginLeft: 8 }}>Finish</span>
                    </button>
                </div>
            </div>
        );
    }

    function renderSuccessMessage() {
        return (
            <div className="p50">
                <div className="flex flex-center" style={{ height: 100 }}>
                    <span style={{ fontSize: 35, color: 'var(--purpleB)' }}>
                        ٩( ᐛ )و
                    </span>
                </div>
                <p className="text-center">
                    Success! Your review has been saved. Your FREE month of
                    gardening service will start at the beginnning of your next
                    billing cycle.
                </p>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => (window.location.pathname = '/account')}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>
                                Continue to account
                            </span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    function renderPreviousReviewFoundMessage() {
        return (
            <div className="mb50 mt50 p25">
                <h4 className="text-center mb15">
                    You have previously submitted a review.
                </h4>
                <div className="flex flex-center">
                    <button
                        className="small btn3"
                        onClick={() => (window.location.pathname = '/account')}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>
                                Continue to account
                            </span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    }

    let content = <></>;
    if (previousReviewFound) {
        content = renderPreviousReviewFoundMessage();
    } else {
        content = reviewSubmitted ? renderSuccessMessage() : renderReviewForm();
    }

    return (
        <div className="mt25">
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* main content */}
            <div className="flex-center mb50">
                <Logo height={32} />
            </div>
            {content}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        reviews: state.reviews,
    };
}

Reviews = connect(mapStateToProps, mapDispatchToProps)(Reviews);

export default Reviews;
