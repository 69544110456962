// libs
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// actions
import { updateUser } from '../actions/user/index';
import { createCard, deleteCard } from '../actions/payment/index';
import { createCustomer } from '../actions/payment/index';
import { validateCard } from '../actions/payment/validation';

// UI components
import Icon from '@material-ui/core/Icon';
import Link from './UI/Link';
import LoaderModal from './LoaderModal';
import CardTypeInput from './CardTypeInput';
import CardNumberInput from './CardNumberInput';
import CardExpirationDateInput from './CardExpirationDateInput';
import CardCodeInput from './CardCodeInput';

// vars
import { AMERICAN_EXPRESS, VISA } from '../vars/constants';
import {
    ADD_PAYMENT_METHOD,
    CHANGE_PAYMENT_METHOD,
    DISPLAY_PAYMENT_METHOD_INFO,
} from '../screens/Account';

let PaymentMethod = ({
    updateUser,
    createCard,
    deleteCard,
    createCustomer,
    onCancel,
    onUpdate,
    action,
    user,
}) => {
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const [cardType, setCardType] = useState(VISA);
    const [nameOnCard, setNameOnCard] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpirationDate, setCardExpirationDate] = useState('');
    const [cardCode, setCardCode] = useState('');

    useEffect(() => {
        setNameOnCard('');
        setCardNumber('');
        setCardExpirationDate('');
        setCardCode('');
    }, [cardType]);

    function updatePaymentInfo() {
        if (
            !cardType ||
            !nameOnCard ||
            !cardNumber ||
            !cardExpirationDate ||
            !cardCode
        ) {
            return window.alert('Please fill out all required fields');
        } else {
            setLoadingModalIsOpen(true);
            const validate = new Promise((resolve, reject) => {
                const newCard = {
                    name: nameOnCard,
                    number: cardNumber.split('-').join(''),
                    exp_month: cardExpirationDate.split('/')[0],
                    exp_year: cardExpirationDate.split('/')[1],
                    cvc: cardCode,
                };

                const checkCard = new Promise((resolve, reject) =>
                    validateCard(newCard, resolve, reject),
                );
                checkCard
                    .then((response) => {
                        resolve({
                            cardData: response.card,
                            token: response.id,
                        });
                    })
                    .catch((err) => {
                        window.alert(err.message);
                        setLoadingModalIsOpen(false);
                        reject();
                    });
            });

            validate.then(async function ({ cardData, token }) {
                let paymentInfo = {
                    token: token,
                    card_id: cardData.id,
                    card_brand: cardData.brand,
                    card_last4: cardData.last4,
                    card_exp_month: cardData.exp_month,
                    card_exp_year: cardData.exp_year,
                    name: cardData.name,
                    customer_id: null,
                    plan_id: null,
                };

                if (action === CHANGE_PAYMENT_METHOD) {
                    await deleteCard();
                    await createCard({ token });
                    paymentInfo.customer_id =
                        user.data.payment_info.customer_id;
                    if (user.data.payment_info.plan_id) {
                        paymentInfo.plan_id = user.data.payment_info.plan_id;
                    }
                }

                if (action === ADD_PAYMENT_METHOD) {
                    const paymentInfo = await createCustomer({
                        userFullName: `${user.data.first_name} ${user.data.last_name}`,
                        userEmail: user.data.email,
                        token,
                    });

                    paymentInfo.customer_id = paymentInfo.data.id;
                }

                await updateUser(null, { paymentInfo });
                setLoadingModalIsOpen(false);
                onUpdate();
            });
        }
    }

    function renderContent() {
        switch (action) {
            case DISPLAY_PAYMENT_METHOD_INFO:
                return renderCardInfo();
            case CHANGE_PAYMENT_METHOD:
                return renderChangeCard();
            default:
                return <></>;
        }
    }

    function renderChangeCard() {
        return (
            <div style={{ display: 'flex', flexGrow: 1 }}>
                <div>
                    <div>
                        <label>Card Type *</label>
                        <CardTypeInput
                            type={cardType}
                            onChange={(type) => setCardType(type)}
                        />
                    </div>
                    <div>
                        <label>Name on card *</label>
                        <input
                            type="text"
                            name="nameOnCard"
                            placeholder="John Smith"
                            value={nameOnCard}
                            onChange={(e) => setNameOnCard(e.target.value)}
                        />
                    </div>

                    <div>
                        <label>Card Number *</label>
                        <CardNumberInput
                            type={cardType}
                            number={cardNumber}
                            onChange={(digits) => setCardNumber(digits)}
                        />
                    </div>

                    <div className="flex" style={{ gap: 'var(--unit4)' }}>
                        <div>
                            <label>Card Expiration Date *</label>
                            <CardExpirationDateInput
                                expirationDate={cardExpirationDate}
                                onChange={(expirationDate) =>
                                    setCardExpirationDate(expirationDate)
                                }
                            />
                        </div>

                        <div>
                            <label>
                                Card CVV *{' '}
                                <a
                                    href="https://www.strategicprofitsinc.com/cvv_info/cvv_info.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    [?]
                                </a>
                            </label>
                            <CardCodeInput
                                type={cardType}
                                code={cardCode}
                                onChange={(code) => setCardCode(code)}
                            />
                        </div>
                    </div>

                    <div className="mt4 flex flex flex-center-y flex-space-between">
                        <Link onClick={() => onCancel()}>Cancel</Link>
                        <button
                            className="purple small flex flex-center-y"
                            onClick={() => updatePaymentInfo()}
                        >
                            <Icon>done</Icon>{' '}
                            <span style={{ marginLeft: 8 }}>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    function renderCardInfo() {
        if (user.data.payment_info) {
            const {
                card_brand,
                card_exp_month,
                card_exp_year,
                card_last4,
                name,
            } = user.data.payment_info;

            const cardMask =
                card_brand.toLowerCase() === AMERICAN_EXPRESS
                    ? `**** ****** *${card_last4}`
                    : `**** **** **** ${card_last4}`;

            return (
                <div className="flex-center" style={{ flexGrow: 1 }}>
                    <div className="credit-card">
                        <div className="cc-logo">
                            <h5>{card_brand}</h5>
                        </div>
                        <div>
                            <data className="cc-number">{cardMask}</data>
                        </div>
                        <div
                            className="flex-space-between"
                            style={{ jsutifySelf: 'end' }}
                        >
                            <div className="cc-name">
                                <label>Name</label>
                                <data
                                    className="capitalize"
                                    style={{
                                        maxWidth: '16ch',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {name}
                                </data>
                            </div>

                            <div className="cc-date">
                                <label>Exp. Date</label>
                                <data>
                                    {card_exp_month}/{card_exp_year}
                                </data>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <h5 className="mt15">No payment method was found</h5>
                    <small>
                        Your payment info will appear here once you have
                        approved your first quote.
                    </small>
                </div>
            );
        }
    }

    return (
        <div style={{ display: 'flex', flexGrow: 1 }}>
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* content */}
            {renderContent()}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateUser,
            createCard,
            deleteCard,
            createCustomer,
        },
        dispatch,
    );
}

PaymentMethod = connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);

export default PaymentMethod;
