// libs
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// actions
import { mapDispatchToProps } from '../actions/Dispatcher.js';

// UI components
import Logo from '../components/LogoMark';
import GeneralMessage from '../components/GeneralMessage';

// helpers
import capitalize from '../helpers/capitalize.js';
import logInfo from '../helpers/logInfo';

// types
import { CUSTOMER } from '../vars/types';

const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
);

let NewPassword = ({ getUser, resetPassword }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userToUpdate, setUserToUpdate] = useState(null);
    const [invalidUrl, setInvalidUrl] = useState(false);

    useEffect(() => {
        const initData = async () => {
            if (!urlParams.user) {
                setInvalidUrl(true);
            } else {
                const user = await getUser(urlParams.user);
                if (user.data) {
                    setUserToUpdate(user.data);
                    logInfo('New Password page loaded', { user: user.data });
                } else {
                    window.alert('Invalid user ID');
                }
            }
        };

        initData();
    }, []);

    async function setNewPassword() {
        if (!password || !confirmPassword) {
            return window.alert('Please complete all required fields');
        } else if (password !== confirmPassword) {
            return window.alert('Password fields do not match');
        } else if (password.length < 6) {
            return window.alert('Password must be at least 6 characters');
        }

        await resetPassword(urlParams.user, { userPassword: password });

        const redirect =
            userToUpdate.type === CUSTOMER
                ? `${window.location.origin}/account`
                : `${window.location.origin}/dashboard`;
        window.location.href = redirect;
    }

    function renderInvalidURLMessage() {
        return (
            <GeneralMessage
                message="Invalid URL, please contact support to continue."
                buttonText="Go Home"
                buttonAction={() =>
                    (window.location.pathname = window.location.origin)
                }
            />
        );
    }

    if (invalidUrl) {
        return renderInvalidURLMessage();
    } else if (userToUpdate) {
        return (
            <div className="p15 flex flex-center">
                <div>
                    <div className="flex flex-center mb25 mt25">
                        <Logo height={36} />
                    </div>
                    <h2 className="mb25 mt25 text-purpleB90 text-center">
                        New Password
                    </h2>
                    <div className="mb25 bg-green0">
                        <hr />
                    </div>
                    <p>
                        Hello <b>{capitalize(userToUpdate.first_name)}</b>,
                        please set a new password
                    </p>
                    <div>
                        <label className="mt10">New Password *</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="******"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="mt10">Confirm Password *</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="******"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button
                            className="purple small flex flex-center full-width mt15 "
                            onClick={() => setNewPassword()}
                        >
                            <span style={{ marginLeft: 8 }}>Submit</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

NewPassword = connect(null, mapDispatchToProps)(NewPassword);

export default NewPassword;
