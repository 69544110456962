// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Icon from '@material-ui/core/Icon';
import Alert from '../components/Alert.js';
import LoaderModal from '../components/LoaderModal.js';

// helpers
import { formatNumber } from '../formatting/phone';

// actions
import { sendEmail } from '../actions/email/index';
import { throwError } from '../actions/error/index';
import { toggleLoading } from '../actions/loading/index';

// styling
import '../css/home.css';

class ContactUs extends Component {
    state = {
        name: '',
        message: '',
        phoneNumber: '',
    };

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async sendMessage() {
        if (!this.state.name || !this.state.message || !this.state.phoneNumber)
            return window.alert('Please complete all required fields');

        this.props.toggleLoading(true);

        const message = {
            email: 'info@yardengarden.com',
            subject: `Yarden - (ACTION REQUIRED) Website Message`,
            label: 'Website Message',
            body:
                '<p>Hello <b>Yarden HQ</b>,</p>' +
                '<p>There is a new message sent from the Contact Us page.</p>' +
                '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Name</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.state.name +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Phone Number</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.state.phoneNumber +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Message</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.state.message +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '</table>',
        };

        // send message to Yarden HQ
        await this.props.sendEmail(message);

        this.setState({ messageSent: true });

        this.props.toggleLoading(false);
    }

    render() {
        const { message, phoneNumber, name, alertIsOpen, messageSent } =
            this.state;

        const { error, loading } = this.props;

        return (
            <section id="contact-us">
                {/* modals */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />
                <LoaderModal isOpen={loading.isLoading} />

                {/* contact us */}
                <div className="contact-form">
                    <h2 className="contact-header">Contact Us</h2>

                    {!messageSent && (
                        <div>
                            <small>
                                Want to talk to us? Just send us a message and
                                one of our team members will follow up with you
                                shortly!
                            </small>

                            <label className="mt10">Name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="John Smith"
                                value={name}
                                onChange={(e) => this.setValue(e)}
                            />
                            <label className="mt10">Phone Number</label>
                            <input
                                type="text"
                                name="phoneNumber"
                                placeholder="(123) 456-7890"
                                value={formatNumber(phoneNumber)}
                                onChange={(e) => this.setValue(e)}
                            />
                            <label className="mt10">Message</label>
                            <textarea
                                type="text"
                                name="message"
                                placeholder="Write message here..."
                                value={message}
                                onChange={(e) => this.setValue(e)}
                            />
                        </div>
                    )}
                    {messageSent && (
                        <div className="mt25">
                            <h3 className="text-purpleB90">
                                <span role="img" aria-label="checkmark">
                                    ✅
                                </span>{' '}
                                Success!
                            </h3>
                            <p>
                                Your message has been sent, someone from our
                                team will reach out to you shorty.
                            </p>
                        </div>
                    )}

                    {!messageSent && (
                        <div
                            className="float-right"
                            style={{ margin: 'var(--unit4) 0' }}
                        >
                            <button
                                className="purple flex flex-center-y"
                                onClick={() => this.sendMessage()}
                            >
                                <span style={{ marginRight: 8 }}>Send</span>
                                <Icon>send</Icon>
                            </button>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendEmail,
            throwError,
            toggleLoading,
        },
        dispatch,
    );
}

ContactUs = connect(mapStateToProps, mapDispatchToProps)(ContactUs);

export default ContactUs;
