import React from 'react';

const CustomerInfo = ({ customer }) => {
    return (
        <div className="capitalize">
            <div>
                <label>
                    {customer.first_name} {customer.last_name[0]}.
                </label>
            </div>
            <div>
                <label>
                    {customer.address}, {customer.city}{' '}
                    <span className="uppercase">{customer.state}</span>{' '}
                    {customer.zip_code}
                </label>
            </div>
        </div>
    );
};

export default CustomerInfo;
