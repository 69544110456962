import axios from 'axios';
import getAuthToken from '../../formatting/getAuthToken';
import { throwError } from '../../actions/error/index';

export function createApproval(approval) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/approvals`, approval, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}