// libs
import React from 'react';

// styles
import '../css/image-modal.css';

function ImageModal({ isOpen, onClose, image }) {
    if (!isOpen || !image) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <img src={image.url} alt="Detailed view" />
                <button onClick={onClose} className="close-modal btn3 small">
                    Close
                </button>
            </div>
        </div>
    );
}

export default ImageModal;
