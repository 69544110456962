export default async function generatePlotPoints(rows, columns) {
    // set initial plot points list
    let plotPoints = [];

    // set initial plot points id
    let plotPointId = 0;

    // iterate rows
    for (let i = 0; i < rows; i++) {
        // set initial row value
        let row = [];

        // iterate columns
        for (let j = 0; j < columns; j++) {
            // set plot point id
            plotPointId += 1;

            // set row
            row.push({
                id: plotPointId,
                plant: null,
                selected: false,
            });
        }

        // set plot points
        plotPoints.push(row);
    }

    return plotPoints;
}
