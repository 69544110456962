import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { GET_QR_CODES  } from './types';

export function createQRCode(qrCode) {
    return async function(dispatch) {        
        try {
            const response = await axios.post(`/api/v1/qr-codes`, qrCode, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getQRCodes(query) {
    return async function(dispatch) {        
        try {
            const q = (query) ? `?${query}` : '';
            const response = await axios.get(`/api/v1/qr-codes${q}`, {headers: {authorization: getAuthToken()}});
            dispatch({type: GET_QR_CODES, payload: response});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function updateQRCode(id, qrCode) {
    return async function(dispatch) {
        try {
            const updatedQRCode = await axios.put(`/api/v1/qr-codes/${id}`, qrCode, {headers: {authorization: getAuthToken()}});
            return updatedQRCode;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}