import { GET_QUESTION_TYPES } from '../actions/questionType/types';

let initialState = {}

export default function( state = initialState, action){
    switch(action.type){
      case GET_QUESTION_TYPES :
        return {...action.payload}
      default :
        return state;
    }
}