// libs
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// actions
import { getBeds } from '../actions/bed/index';

// UI components
import Tabs from '../components/UI/Tabs.js';

// vars
import { VEGETABLE, CULINARY_HERB, FRUIT } from '../vars/types';
import { MAX_PLANT_VARIETY_LIMIT_PER_GARDEN } from '../vars/constants';

let PlantSelect = (props) => {
    const { plants, onChange } = props;
    const [currentSelection, setCurrentSelection] = useState(plants);
    const [plantCategory, setPlantCategory] = useState(VEGETABLE);
    const selected = currentSelection.filter((p) => p.selected);

    useEffect(() => {
        setCurrentSelection(plants);
    }, [plants]);

    const renderTabs = () => {
        const totalVegetablesSelected = currentSelection
            ? currentSelection.reduce((total, current) => {
                  if (
                      current.plant.primary.category.name === VEGETABLE &&
                      current.selected
                  ) {
                      return total + 1;
                  }
                  return total;
              }, 0)
            : 0;

        const totalHerbsSelected = currentSelection
            ? currentSelection.reduce((total, current) => {
                  if (
                      current.plant.primary.category.name === CULINARY_HERB &&
                      current.selected
                  ) {
                      return total + 1;
                  }
                  return total;
              }, 0)
            : 0;

        const totalFruitSelected = currentSelection
            ? currentSelection.reduce((total, current) => {
                  if (
                      current.plant.primary.category.name === FRUIT &&
                      current.selected
                  ) {
                      return total + 1;
                  }
                  return total;
              }, 0)
            : 0;

        const plantCategoryTabs = [
            {
                title: `Vegetables (${totalVegetablesSelected})`,
                icon: '',
                content: 'veggies',
                value: VEGETABLE,
            },
            {
                title: `Herbs (${totalHerbsSelected})`,
                icon: '',
                content: 'herbs',
                value: CULINARY_HERB,
            },
            {
                title: `Fruit (${totalFruitSelected})`,
                icon: '',
                content: 'fruit',
                value: FRUIT,
            },
        ];

        return (
            <div>
                <Tabs
                    className="plant-selection-tabs"
                    outlined={true}
                    data={plantCategoryTabs}
                    defaultValue={VEGETABLE}
                    onChange={(item) => {
                        const value = item.title.split(' ')[0];
                        if (value === 'Vegetables') {
                            setPlantCategory(VEGETABLE);
                        }
                        if (value === 'Fruit') {
                            setPlantCategory(FRUIT);
                        }
                        if (value === 'Herbs') {
                            setPlantCategory(CULINARY_HERB);
                        }
                    }}
                />
            </div>
        );
    };

    const validatePlantSelectionSize = (plants) => {
        return plants <= MAX_PLANT_VARIETY_LIMIT_PER_GARDEN;
    };

    const updatePlantSelection = (plant) => {
        let isMaxSizeLimit = false;
        let plantsToUpdate = [...currentSelection];
        plantsToUpdate.forEach((plantToUpdate) => {
            if (plantToUpdate.plant._id === plant._id) {
                if (plantToUpdate.selected) {
                    plantToUpdate.selected = false;
                } else {
                    if (validatePlantSelectionSize(selected.length + 1)) {
                        plantToUpdate.selected = true;
                    } else {
                        isMaxSizeLimit = true;
                    }
                }
            }
        });
        if (isMaxSizeLimit) {
            return window.alert('You have reached your plant selection limit');
        } else {
            setCurrentSelection(plantsToUpdate);
            onChange(plantsToUpdate);
        }
    };

    const renderGardenSizeLimitMessage = () => {
        return (
            <div>
                <p className="text-small">
                    * You can select up to {MAX_PLANT_VARIETY_LIMIT_PER_GARDEN}{' '}
                    plant varieties
                </p>
            </div>
        );
    };

    const renderPlants = () => {
        const plantsInCategory = currentSelection.filter(
            (p) => p.plant.primary.category.name === plantCategory,
        );

        const list = plantsInCategory.map((plantData, index) => {
            return (
                <div key={index}>
                    <div
                        className="flex flex-center-y flex-space-between"
                        style={{
                            paddingVertical: 'var(--unit4)',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                borderRightColor: '#ddd',
                                borderRightWidth: 1,
                                width: '100%',
                            }}
                        >
                            <div className="flex flex-center-y">
                                <input
                                    type="checkbox"
                                    checked={plantData.selected}
                                    onChange={() => {
                                        updatePlantSelection(plantData.plant);
                                    }}
                                />
                                <img
                                    style={{
                                        height: 'var(--unit5)',
                                        width: 'var(--unit5)',
                                        marginRight: 'var(--unit3)',
                                        marginLeft: 'var(--unit3)',
                                    }}
                                    src={plantData.plant.image}
                                    alt="plant"
                                />
                                <p className="capitalize text-small m0">
                                    {plantData.plant.name}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return list;
    };

    return (
        <div>
            <div className="mb15">{renderTabs()}</div>
            {renderGardenSizeLimitMessage()}
            <div
                className="overflow-y-scroll curved p15 mb25 bg-gray1 plant-selection-grid"
                style={{
                    maxHeight: 400,
                    border: `1px solid var(--gray2)`,
                }}
            >
                {renderPlants()}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        beds: state.bed,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBeds,
        },
        dispatch,
    );
}

PlantSelect = connect(mapStateToProps, mapDispatchToProps)(PlantSelect);

export default PlantSelect;
