// libraries
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Icon from '@material-ui/core/Icon';
import HeroSection from '../components/HeroSection';
import Alert from '../components/Alert';
import ContactForm from '../components/ContactForm';
import Pricing from '../components/Pricing';
import GoogleReviews from '../components/GoogleReviews';

// actions
import { createDirectDeposit } from '../actions/payout/index';
import { updateUser } from '../actions/user/index';

// helpers
import logInfo from '../helpers/logInfo';

// vars
import { APPOINTMENT_URL, GARDEN_BOOTCAMP_URL } from '../vars/constants';
import { FULL_PLAN, GARDEN_BOOTCAMP } from '../vars/types';

// styles
import '../css/home.css';

// assets
const assessment =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/assessment.png';
const customerDashboard =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/customer_dashboard.png';
const productAndServicesHero =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/product_and_services_hero.png';
const babyPhoto =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/baby_photo.png';
const shield =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/shield.png';
const organic =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/organic.png';
const aijason =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/homepage_images/aijason.png';

class Home extends Component {
    state = {
        bannerClass: 'show-banner',
        email: '',
        orderDate: '',
        dateInputPlaceholer: 'MM/DD/YYYY',
    };

    componentDidMount() {
        logInfo('Home page loaded');
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { alertIsOpen, errorMessage } = this.state;

        return (
            <div className="homepage">
                <Alert
                    isOpen={alertIsOpen}
                    message={errorMessage}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                {/* hero section */}
                <HeroSection
                    backgroundImage={
                        'https://yarden-garden.s3.us-west-1.amazonaws.com/static+assets/bg-veggies.jpg'
                    }
                ></HeroSection>

                {/* intro section */}
                <section id="get-started">
                    <div className="flex-center" style={{ padding: 12 }}></div>
                    <div className="flex-center mb25">
                        <h2
                            style={{ paddingBlock: 'var(--unit5)' }}
                            className="flex-center"
                        >
                            Start Your Garden in 3 Simple Steps
                        </h2>
                    </div>
                </section>
                <section className="three-steps-section">
                    <div className="step">
                        <img
                            className="size-img"
                            src={assessment}
                            alt="two people shaking hands"
                        />
                        <div className="step-text">
                            <h5 className="mt15">
                                1. Schedule an appointment with us
                            </h5>
                            <small className="mt10">
                                Get started by scheduling a FREE appointment
                                with one of our gardeners
                            </small>
                        </div>
                    </div>
                    <div className="step">
                        <img
                            className="size-img"
                            src={productAndServicesHero}
                            alt="small garden bed"
                        />
                        <div className="step-text">
                            <h5 className="mt15">
                                2. Choose your garden size & plants
                            </h5>
                            <small className="mt10">
                                Choose from a variety of different size garden
                                beds and local plant varieties!
                            </small>
                        </div>
                    </div>
                    <div className="step">
                        <img
                            style={{ width: '38% ', margin: '0 auto' }}
                            src={customerDashboard}
                            alt="customer dashboard with harvest report"
                        />
                        <div className="step-text">
                            <h5>3. Track your garden progress</h5>
                            <small className="mt10">
                                Easily keep track of your gardens using our
                                mobile app - which records harvest yields.
                            </small>
                        </div>
                    </div>
                </section>

                {/* pricing */}
                <div className="mt75">
                    <Pricing
                        onSelect={(product) => {
                            if (product === FULL_PLAN) {
                                window.location.href = APPOINTMENT_URL;
                            }

                            if (product === GARDEN_BOOTCAMP) {
                                window.location.href = GARDEN_BOOTCAMP_URL;
                            }
                        }}
                    />
                </div>

                {/* google review */}
                <GoogleReviews />

                {/* why yarden */}
                <section
                    id="why-yarden"
                    className="auto-grid-large homepage-lower-section"
                    style={{ paddingTop: 'var(--unit7)' }}
                >
                    <div className="flex-center">
                        <iframe
                            style={{ aspectRatio: '16/9' }}
                            className="full-width"
                            src="https://www.youtube.com/embed/APVQvFnEdgY"
                            title="Yarden"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div>
                        <h3 style={{ marginTop: 'var(--unit4)' }}>
                            We're Helping To Better The Planet, One Garden At A
                            Time
                        </h3>
                        <small className="mt10">
                            We are fearless when it comes to growing great
                            vegetable gardens - we aren't afraid to try new
                            things to improve on old processes. We are
                            transparent at all times, no gimmicks. We are
                            eco-friendly, using all-natural methods of growing
                            (no pesticides!). We are generous, always giving
                            back to communities through our food sourcing
                            programs. We are gritty, we don't do what is easy -
                            we do what is right.
                        </small>
                    </div>
                </section>

                {/* vegetable gardens and more */}
                <section className="auto-grid-large homepage-lower-section">
                    <div className="flex-center">
                        <div>
                            <h4>Vegetable Gardens and More</h4>
                            <small className="mt10">
                                We grow quality, aesthetically pleasing
                                vegetable gardens that yield an average of 25 -
                                50 lb. of fresh produce each month! Need an
                                additional yard project completed too? We've got
                                you covered. Yarden's qualified landscape
                                partners will gladly provide you an estimate for
                                additional landscape tasks after the successful
                                installation of your vegetable garden.
                            </small>
                            <div>
                                <button
                                    style={{ marginTop: 'var(--unit4)' }}
                                    onClick={() =>
                                        (window.location.href = APPOINTMENT_URL)
                                    }
                                >
                                    <Icon>calendar_month</Icon>
                                    <span style={{ marginLeft: 8 }}>
                                        Book Appointment
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex-center">
                        <img
                            src={babyPhoto}
                            className="full-width"
                            alt="yellow peppers"
                        />
                    </div>
                </section>

                {/* safety */}
                <section className="auto-grid-large homepage-lower-section">
                    <div className="flex-center">
                        <img className="full-width" src={shield} alt="shield" />
                    </div>
                    <div className="flex-center flex-column">
                        <div>
                            <h4>Safety - Our Pledge to our Customers</h4>
                            <small className="mt10">
                                We are dedicated to keeping our customers safe
                                at all times. That's why we pre-screen all of
                                our gardeners with a background check and make
                                sure they recieve proper training before they
                                are sent into the field.
                            </small>
                        </div>
                    </div>
                </section>

                {/* re-inventing gardening */}
                <section className="auto-grid-large homepage-lower-section">
                    <div className="flex-center">
                        <div>
                            <h4>How We Are Re-Inventing Gardening</h4>
                            <small className="mt10">
                                We are revolutionizing the home gardening
                                industry by combining modern technology with
                                traditional growing methods. Find out how we are
                                impacting communities everywhere by giving them
                                the ability to grow their own food, without
                                needing to be garden experts themselves.
                            </small>
                        </div>
                    </div>

                    <div className="flex-center">
                        <img
                            src={aijason}
                            className="full-width"
                            alt="gardener-with-customer"
                        />
                    </div>
                </section>

                <section id="organic-section" className="flex-center">
                    <img style={{ width: '80%' }} src={organic} alt="" />
                </section>

                {/* contact form */}
                <ContactForm />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createDirectDeposit,
            updateUser,
        },
        dispatch,
    );
}

Home = connect(mapStateToProps, mapDispatchToProps)(Home);

export default Home;
