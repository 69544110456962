import { GET_APPLICATIONS } from '../actions/application/types';

let initialState = {};

export default function( state = initialState, action){
    switch(action.type){
        case GET_APPLICATIONS :
            return {...action.payload}
        default :
            return state;
    }
}