// libs
import React, { useState, useEffect } from 'react';

// UI components
import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined';
import StarOutlined from '@material-ui/icons/StarOutlined';

const StarCheckbox = ({ checked, onChange }) => {
    const [active, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(!checked);
    }, [checked]);

    return (
        <div
            style={{
                height: 'calc(var(--unit4) + var(--unit3))',
                width: 'calc(var(--unit4) + var(--unit3))'
            }}
            onClick={() => {
                onChange();
            }}
        >
            {active ? (
                <StarBorderOutlined className="text-purpleB" />
            ) : (
                <StarOutlined className="text-purpleB" />
            )}
        </div>
    );
};

export default StarCheckbox;
