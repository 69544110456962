// libraries
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import domtoimage from 'dom-to-image';

// UI components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import SettingsModal from '../components/SettingsModal.js';
import LoaderModal from '../components/LoaderModal.js';
import BidChangesModal from '../components/BidChangesModal.js';
import StepIndicatorV2 from '../components/StepIndicatorV2';
import Navbar from '../components/Navbar';

// actions
import { toggleLoading } from '../actions/loading/index';
import { getBid, getBids, updateBid } from '../actions/bid/index';
import { chargeCard } from '../actions/payment/index';
import { createOrder } from '../actions/order/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { sendAlert } from '../actions/alert/index';
import {
    getChangeOrder,
    updateChangeOrder,
} from '../actions/changeOrder/index';
import { getImage, updateImage } from '../actions/image/index';
import { getIP } from '../actions/ip/index';
import { createApproval } from '../actions/approval/index';
import { getReferral, updateReferral } from '../actions/referral/index';
import { createSpecialRequest } from '../actions/specialRequest/index';
import { getPlantSelection } from '../actions/plantSelection/index.js';
import { updateUser } from '../actions/user/index.js';

// formatting
import delimit from '../formatting/delimit';

// helpers
import getQuotePricing from '../helpers/getQuotePricing';
import capitalize from '../helpers/capitalize';
import logInfo from '../helpers/logInfo';

// vars
import {
    BID_PENDING_APPROVAL,
    APPROVED,
    CANCELLED,
    INSTALLATION,
    REVIVE,
    GARDEN_BOOTCAMP,
    FULL_PLAN,
} from '../vars/types';
import vars from '../vars/index';

// styling
import '../css/approve.css';

const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
);

let approvalType = null;
if (urlParams.bid) {
    approvalType = 'bid';
} else if (urlParams.job) {
    approvalType = 'job';
} else if (urlParams.changeOrder) {
    approvalType = 'change order';
}

const { gardenBootcamp, fullPlan } = vars.products;
let selectedProduct = null;
if (urlParams.product === GARDEN_BOOTCAMP) {
    selectedProduct = gardenBootcamp;
} else if (urlParams.product === FULL_PLAN) {
    selectedProduct = fullPlan;
}

class Approve extends Component {
    state = {
        materialsTotal: 0,
        laborTotal: 0,
        deliveryTotal: 0,
        rentalTotal: 0,
        disposalTotal: 0,
        step: 1,
        tosAgreement: false,
        stepTitle: 'Review Quote',
        userSignature: '',
        discoveryAnswer: '',
        discoverySource: '',
        discoveryQuestionIsComplete: false,
        product: selectedProduct,
    };

    async componentDidMount() {
        this.props.toggleLoading(true);

        await this.props.getPlantSelection(this.props.user.data._id);
        if (urlParams.bid) {
            // single bid
            const bid = await this.props.getBid(urlParams.bid);
            const {
                laborTotal,
                materialsTotal,
                deliveryTotal,
                rentalTotal,
                disposalTotal,
                firstPayment,
                secondPayment,
            } = getQuotePricing(bid.data);

            const preTaxTotal =
                firstPayment.preTaxTotal + secondPayment.preTaxTotal;
            const salesTax = firstPayment.salesTax + secondPayment.salesTax;
            const paymentProcessingFee =
                firstPayment.paymentProcessingFee +
                secondPayment.paymentProcessingFee;
            const totalSale = firstPayment.total + secondPayment.total;

            if (bid.data !== null) {
                const selectedBid = bid.data;
                this.setState({
                    selectedBid,
                    laborTotal,
                    materialsTotal,
                    deliveryTotal,
                    rentalTotal,
                    disposalTotal,
                    preTaxTotal,
                    salesTax,
                    paymentProcessingFee,
                    totalSale,
                    firstPayment: firstPayment.total,
                    secondPayment: secondPayment.total,
                });
            }
        } else if (urlParams.job) {
            // multiple bids
            const bids = await this.props.getBids(
                `job=${urlParams.job}&status=${BID_PENDING_APPROVAL}`,
            );

            this.combineMaterials(bids.data.list);
            const selectedBids = bids.data.list;
            let materialsTotal = 0;
            let laborTotal = 0;
            let deliveryTotal = 0;
            let rentalTotal = 0;
            let disposalTotal = 0;
            let preTaxTotal = 0;
            let salesTax = 0;
            let paymentProcessingFee = 0;
            let totalSale = 0;
            let payment1 = 0;
            let payment2 = 0;

            bids.data.list.forEach((bid, index) => {
                const pricing = getQuotePricing(bid);
                const { firstPayment, secondPayment } = pricing;
                materialsTotal += pricing.materialsTotal;
                laborTotal += pricing.laborTotal;
                deliveryTotal += pricing.deliveryTotal;
                rentalTotal += pricing.rentalTotal;
                disposalTotal += pricing.disposalTotal;
                preTaxTotal +=
                    firstPayment.preTaxTotal + secondPayment.preTaxTotal;
                salesTax += firstPayment.salesTax + secondPayment.salesTax;
                paymentProcessingFee +=
                    firstPayment.paymentProcessingFee +
                    secondPayment.paymentProcessingFee;
                totalSale += firstPayment.total + secondPayment.total;
                payment1 += firstPayment.total;
                payment2 += secondPayment.total;

                if (index === bids.data.list.length - 1) {
                    this.setState({
                        selectedBids,
                        laborTotal,
                        materialsTotal,
                        deliveryTotal,
                        rentalTotal,
                        disposalTotal,
                        preTaxTotal,
                        salesTax,
                        paymentProcessingFee,
                        totalSale,
                        firstPayment: payment1,
                        secondPayment: payment2,
                    });
                }
            });
        } else if (urlParams.changeOrder) {
            // change order
            const changeOrder = await this.props.getChangeOrder(
                urlParams.changeOrder,
            );
            const {
                laborTotal,
                materialsTotal,
                deliveryTotal,
                rentalTotal,
                disposalTotal,
                firstPayment,
                secondPayment,
            } = getQuotePricing(changeOrder.data);
            const selectedChangeOrder = changeOrder.data;
            const stepTitle = 'Review Change Order';
            const preTaxTotal =
                firstPayment.preTaxTotal + secondPayment.preTaxTotal;
            const salesTax = firstPayment.salesTax + secondPayment.salesTax;
            const paymentProcessingFee =
                firstPayment.paymentProcessingFee +
                secondPayment.paymentProcessingFee;
            const totalSale = firstPayment.total + secondPayment.total;

            this.setState({
                selectedChangeOrder,
                stepTitle,
                laborTotal,
                materialsTotal,
                deliveryTotal,
                rentalTotal,
                disposalTotal,
                preTaxTotal,
                salesTax,
                paymentProcessingFee,
                totalSale,
                firstPayment: firstPayment.total,
                secondPayment: secondPayment.total,
            });
        }

        this.props.toggleLoading(false);
        logInfo('Approve page loaded', { user: this.props.user.data });
    }

    combineMaterials(bids) {
        let materials = [];
        bids.forEach((bid) => {
            if (bid.line_items.materials) {
                bid.line_items.materials.forEach((item) => {
                    let exists = materials.find(
                        (material) => material.name === item.name,
                    );
                    if (exists) {
                        exists.qty += item.qty;
                    } else {
                        materials.push(item);
                    }
                });
            }
        });

        this.setState({
            materials,
        });
    }

    editSettings(setting) {
        this.setState({
            settingsModalIsOpen: true,
            selectedSetting: setting,
        });
    }

    async notifyVendor(vendor, order) {
        const vendorMessage = this.state.selectedChangeOrder
            ? `Greetings from Yarden! Your change order has been approved for ${order.customer.address}, ${order.customer.city}, ${order.customer.state}. The details are in your dashboard at ${window.location.origin}/dashboard`
            : `Greetings from Yarden! You have been assigned a new work order scheduled for ${moment(
                  order.date,
              ).format('MM-DD-YYYY')} in ${order.customer.city}, ${
                  order.customer.state
              }. The details are in your dashboard at ${
                  window.location.origin
              }/dashboard`;

        const messageSubject = this.state.selectedChangeOrder
            ? `Yarden - Change order approved`
            : `Yarden - New work order`;

        const messageLabel = this.state.selectedChangeOrder
            ? `Change Order Approval`
            : `Order Assignment`;

        const notification = {
            email: vendor.email,
            subject: messageSubject,
            label: messageLabel,
            body: `<p>${vendorMessage}</p>`,
        };

        await this.props.sendEmail(notification);

        const sms = {
            from: '8888289287',
            to: vendor.phone_number.replace(/\D/g, ''),
            body: vendorMessage,
        };

        await this.props.sendSms(sms);
    }

    async notifyHQ(bid) {
        await this.props.sendAlert({
            channel: 'conversions',
            text: `*New Conversion!* \nNAME\n${capitalize(
                this.props.user.data.first_name,
            )} ${capitalize(this.props.user.data.last_name)}\n\nTITLE\n"${
                bid.title
            }"\n\nDESCRIPTION\n"${bid.description}"\n\nAMOUNT\n$${delimit(
                this.state.totalSale.toFixed(2),
            )}`,
        });
    }

    async notifyCustomer(order, address) {
        let customerMessage;

        if (this.state.selectedBids) {
            customerMessage =
                '<p>Hello <b>' +
                this.props.user.data.first_name +
                '</b>,</p>' +
                `<p>Your service appointments have been confirmed, you can view the time/dates by clicking the link below.</p>` +
                `<p><a href="${window.location.origin}/account">VIEW DETAILS</a></p>`;
        } else if (this.state.selectedBid) {
            customerMessage =
                '<p>Hello <b>' +
                this.props.user.data.first_name +
                '</b>,</p>' +
                '<p>Your order has been confirmed, if you have any questions please let us know!</p>' +
                '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Date</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                moment(order.date).format('MM-DD-YYYY') +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Time</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                moment(order.time, `HH:mm:ss`).format(`h:mm A`) +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Service</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.state.selectedBid.description +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Full Name</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.props.user.data.first_name +
                ' ' +
                this.props.user.data.last_name +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Email</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.props.user.data.email +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Phone Number</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                this.props.user.data.phone_number +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Address</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                address +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '</table>';
        } else if (this.state.selectedChangeOrder) {
            customerMessage =
                '<p>Hello <b>' +
                this.props.user.data.first_name +
                '</b>,</p>' +
                `<p>Your change order has been confirmed, please log in to your Yarden App to view the details.</p>`;
        }

        const messageSubject = this.state.selectedChangeOrder
            ? `Yarden - Your change order has been approved`
            : `Yarden - Your service${this.state.selectedBids ? 's' : ''} ${
                  this.state.selectedBids ? 'have' : 'has'
              } been scheduled`;

        const messageLabel = this.state.selectedChangeOrder
            ? 'Change Order Confirmation'
            : 'Order Confirmation';

        const email = {
            email: this.props.user.data.email,
            subject: messageSubject,
            label: messageLabel,
            body: customerMessage,
        };

        await this.props.sendEmail(email);

        const queryParams = this.state.selectedChangeOrder
            ? `?changeOrder=${this.state.selectedChangeOrder._id}`
            : '';

        // NOTE: This timeout is to allow the email response to return before changing pages, otherwise it causes an error
        setTimeout(() => {
            window.location.href = `${window.location.origin}/approved${queryParams}`;
        }, 3000);
    }

    approve() {
        const {
            specialRequest,
            userSignature,
            materialsTotal,
            deliveryTotal,
            rentalTotal,
            disposalTotal,
            selectedBids,
            selectedBid,
            selectedChangeOrder,
            firstPayment,
        } = this.state;

        const { user } = this.props;

        if (!userSignature || !user.data.payment_info)
            return window.alert('Please complete all required fields');

        this.props.toggleLoading(true);

        setTimeout(async () => {
            if (
                materialsTotal > 0 ||
                deliveryTotal > 0 ||
                rentalTotal > 0 ||
                disposalTotal > 0
            ) {
                const total = parseFloat(firstPayment).toFixed(2) * 100;
                let description = `Materials - `;

                if (selectedBids) {
                    selectedBids.forEach((bid, index) => {
                        description += `(${index + 1}) ${bid.description}${
                            index === selectedBids.length - 1 ? '' : `; `
                        }`;
                    });
                } else if (selectedBid) {
                    description += `${selectedBid.description}`;
                } else if (selectedChangeOrder) {
                    description += `${selectedChangeOrder.description}`;
                }

                const payment = {
                    amount: total,
                    currency: 'usd',
                    description: description,
                    statement_descriptor_suffix: `Materials`,
                };

                try {
                    await this.props.chargeCard(payment, user.data);
                } catch (err) {
                    return;
                }
            }

            const address = `${user.data.address}${
                user.data.unit ? ' #' + user.data.unit : ''
            }, ${user.data.city} ${user.data.state} ${user.data.zip_code}`;

            const ip = await this.props.getIP();

            var node = document.getElementById('approval');
            domtoimage.toBlob(node).then(async (blob) => {
                const hostedImg = await this.props.getImage(
                    `approval`,
                    blob.type,
                );
                const returnData = hostedImg.data.data.returnData;
                const signedRequest = returnData.signedRequest;
                const options = {
                    headers: {
                        'Content-Type': blob.type,
                    },
                };

                await this.props.updateImage(signedRequest, blob, options);

                const newApproval = {
                    image: returnData.url,
                    location: ip.data,
                };

                const approval = await this.props.createApproval(newApproval);

                const referral = await this.props.getReferral(user.data._id);

                const setDiscoveryAnswer = async () => {
                    if (this.state.discoveryAnswer) {
                        const source = this.state.discoverySource
                            ? `\n\nSOURCE\n${capitalize(
                                  this.state.discoverySource,
                              )}`
                            : '';
                        await this.props.sendAlert({
                            channel: 'discovery-answers',
                            text: `*New Discovery Answer!* \n\nNAME\n${capitalize(
                                user.data.first_name,
                            )} ${capitalize(
                                user.data.last_name,
                            )}\n\nDISCOVERY SOURCE\n${capitalize(
                                this.state.discoveryAnswer,
                            )}${source}`,
                        });
                    }
                };

                await setDiscoveryAnswer();

                const setProductOptIns = async () => {
                    if (this.state.product) {
                        if (
                            this.state.product.title ===
                            vars.products.fullPlan.title
                        ) {
                            await this.props.updateUser(null, {
                                gardenInfo: {
                                    maintenance_plan: FULL_PLAN,
                                },
                            });

                            await this.props.sendAlert({
                                channel: 'maintenance-service-opt-ins',
                                text: `*New Maintenance Service Opt-In!* \nNAME\n${capitalize(
                                    user.data.first_name,
                                )} ${capitalize(
                                    user.data.last_name,
                                )}\n\nNOTE\nThis user opted-in for maintenance service during their quote approval.`,
                            });
                        } else if (
                            this.state.product.title ===
                            vars.products.gardenBootcamp.title
                        ) {
                            await this.props.sendAlert({
                                channel: 'garden-bootcamp-opt-ins',
                                text: `*New Garden Bootcamp Opt-In!* \nNAME\n${capitalize(
                                    user.data.first_name,
                                )} ${capitalize(
                                    user.data.last_name,
                                )}\n\nNOTE\nThis user opted-in to join the garden bootcamp during their quote approval, please enroll them as a student.`,
                            });
                        }
                    }
                };

                await setProductOptIns();

                if (selectedBids) {
                    selectedBids.forEach(async (bid, index) => {
                        let newOrder = {
                            customer: bid.customer._id,
                            approval: approval.data._id,
                            type: bid.type,
                            date: moment().add(2, 'weeks'),
                            time: '10',
                            description: bid.description,
                            point_of_sale: window.location.pathname,
                            bid: bid._id,
                        };

                        const newGarden =
                            bid.type === INSTALLATION || bid.type === REVIVE;

                        if (newGarden) {
                            newOrder.phase = 1;
                        }

                        if (bid.vendor) newOrder.vendor = bid.vendor._id;

                        const order = await this.props.createOrder(newOrder);

                        await this.props.updateBid(bid._id, {
                            status: APPROVED,
                        });

                        if (specialRequest && newGarden) {
                            await this.props.createSpecialRequest({
                                order: order.data._id,
                                description: specialRequest,
                            });
                        }

                        if (referral.data) {
                            if (
                                !referral.data.order &&
                                (bid.type === INSTALLATION ||
                                    bid.type === REVIVE)
                            ) {
                                await this.props.updateReferral(
                                    referral.data._id,
                                    {
                                        order: order.data._id,
                                    },
                                );

                                const notification = {
                                    email: referral.data.referrer.email,
                                    subject: `Yarden - You received a referral reward!`,
                                    label: 'Referral Reward',
                                    body:
                                        '<p>Greetings from Yarden!</p>' +
                                        '<p>Someone has purchased a Yarden garden using your referral link, so as a referral reward you will receive <u>' +
                                        referral.data.reward.description +
                                        '</u>. If you have any questions, please let us know!</p>',
                                };

                                await this.props.sendEmail(notification);

                                const notificationHQ = {
                                    email: 'info@yardengarden.com',
                                    subject: `Yarden - (ACTION REQUIRED) New referral reward`,
                                    label: 'Referral Reward',
                                    body:
                                        '<p>Hello <b>Yarden HQ</b>,</p>' +
                                        '<p>There was a new referral, please process the referral reward.</p>' +
                                        '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                                        '<tr>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><b>Referrer</b></p>' +
                                        '</td>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><em>' +
                                        referral.data.referrer.first_name +
                                        ' ' +
                                        referral.data.referrer.last_name +
                                        '</em></p>' +
                                        '</td>' +
                                        '</tr>' +
                                        '<tr>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><b>Referee</b></p>' +
                                        '</td>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><em>' +
                                        referral.data.referee.first_name +
                                        ' ' +
                                        referral.data.referee.last_name +
                                        '</em></p>' +
                                        '</td>' +
                                        '</tr>' +
                                        '<tr>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><b>Reward Type</b></p>' +
                                        '</td>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><em>' +
                                        referral.data.reward.name +
                                        '</em></p>' +
                                        '</td>' +
                                        '</tr>' +
                                        '<tr>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><b>Reward Description</b></p>' +
                                        '</td>' +
                                        '<td>' +
                                        '<p style="margin-bottom: 0px"><em>' +
                                        referral.data.reward.description +
                                        '</em></p>' +
                                        '</td>' +
                                        '</tr>' +
                                        '</table>',
                                };

                                await this.props.sendEmail(notificationHQ);
                            }
                        }

                        await this.notifyHQ(bid);

                        if (bid.vendor)
                            await this.notifyVendor(bid.vendor, order.data);

                        if (index === selectedBids.length - 1)
                            await this.notifyCustomer(order.data, address);
                    });
                } else if (selectedBid) {
                    let newOrder = {
                        customer: selectedBid.customer._id,
                        approval: approval.data._id,
                        type: selectedBid.type ? selectedBid.type : 'misc',
                        date: moment().add(2, 'weeks'),
                        time: '10',
                        description: selectedBid.description,
                        point_of_sale: window.location.pathname,
                        bid: selectedBid._id,
                    };

                    if (
                        selectedBid.type === INSTALLATION ||
                        selectedBid.type === REVIVE
                    ) {
                        newOrder.phase = 1;
                    }

                    if (selectedBid.vendor)
                        newOrder.vendor = selectedBid.vendor._id;

                    const order = await this.props.createOrder(newOrder);

                    await this.props.updateBid(selectedBid._id, {
                        status: APPROVED,
                    });

                    if (specialRequest) {
                        await this.props.createSpecialRequest({
                            order: order.data._id,
                            description: specialRequest,
                        });
                    }

                    if (referral.data) {
                        if (
                            !referral.data.order &&
                            (selectedBid.type === INSTALLATION ||
                                selectedBid.type === REVIVE)
                        ) {
                            await this.props.updateReferral(referral.data._id, {
                                order: order.data._id,
                            });

                            const notification = {
                                email: referral.data.referrer.email,
                                subject: `Yarden - You received a referral reward!`,
                                label: 'Referral Reward',
                                body:
                                    '<p>Greetings from Yarden!</p>' +
                                    '<p>Someone has purchased a Yarden garden using your referral link, so as a referral reward you will receive <u>' +
                                    referral.data.reward.description +
                                    '</u>. If you have any questions, please let us know!</p>',
                            };

                            await this.props.sendEmail(notification);

                            const notificationHQ = {
                                email: 'info@yardengarden.com',
                                subject: `Yarden - (ACTION REQUIRED) New referral reward`,
                                label: 'Referral Reward',
                                body:
                                    '<p>Hello <b>Yarden HQ</b>,</p>' +
                                    '<p>There was a new referral, please process the referral reward.</p>' +
                                    '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                                    '<tr>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><b>Referrer</b></p>' +
                                    '</td>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><em>' +
                                    referral.data.referrer.first_name +
                                    ' ' +
                                    referral.data.referrer.last_name +
                                    '</em></p>' +
                                    '</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><b>Referee</b></p>' +
                                    '</td>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><em>' +
                                    referral.data.referee.first_name +
                                    ' ' +
                                    referral.data.referee.last_name +
                                    '</em></p>' +
                                    '</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><b>Reward Type</b></p>' +
                                    '</td>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><em>' +
                                    referral.data.reward.name +
                                    '</em></p>' +
                                    '</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><b>Reward Description</b></p>' +
                                    '</td>' +
                                    '<td>' +
                                    '<p style="margin-bottom: 0px"><em>' +
                                    referral.data.reward.description +
                                    '</em></p>' +
                                    '</td>' +
                                    '</tr>' +
                                    '</table>',
                            };

                            await this.props.sendEmail(notificationHQ);
                        }
                    }

                    await this.notifyHQ(selectedBid);

                    if (selectedBid.vendor)
                        await this.notifyVendor(selectedBid.vendor, order.data);

                    await this.notifyCustomer(order.data, address);
                } else if (selectedChangeOrder) {
                    await this.props.updateChangeOrder(
                        selectedChangeOrder._id,
                        {
                            status: APPROVED,
                            approval: approval.data._id,
                        },
                        selectedChangeOrder.order._id,
                    );

                    if (selectedChangeOrder.order.vendor)
                        await this.notifyVendor(
                            selectedChangeOrder.order.vendor,
                            selectedChangeOrder.order,
                        );

                    await this.notifyCustomer(
                        selectedChangeOrder.order,
                        address,
                    );
                }
            });
        }, 1000);
    }

    next() {
        let title = this.state.stepTitle;
        if (this.state.step + 1 === 2) title = 'Review Costs';
        if (this.state.step + 1 === 3) title = 'Approve Quote';
        this.setState({
            step: this.state.step + 1,
            stepTitle: title,
        });
        window.scrollTo(0, 0);
    }

    back() {
        let title = this.state.stepTitle;
        if (this.state.step - 1 === 1)
            title = this.state.selectedChangeOrder
                ? 'Review Change Order'
                : 'Review Quote';
        if (this.state.step - 1 === 2) title = 'View Costs';
        this.setState({
            step: this.state.step - 1,
            stepTitle: title,
        });
        window.scrollTo(0, 0);
    }

    cancelBid(bid) {
        if (this.state.selectedBids.length < 2) {
            return window.alert('Must have at least 1 item');
        }

        if (window.confirm('Are you sure?')) {
            this.props.toggleLoading(true);
            setTimeout(async () => {
                await this.props.updateBid(bid._id, { status: CANCELLED });
                const address = `${bid.customer.address}${
                    bid.customer.unit ? ` #${bid.customer.unit}` : ''
                }, ${bid.customer.city} ${bid.customer.state} ${
                    bid.customer.zip_code
                }`;
                const notification = {
                    email: 'info@yardengarden.com',
                    subject: `Yarden - Member cancelled quote`,
                    label: 'Quote Cancellation',
                    body:
                        '<p>Greetings from Yarden!</p>' +
                        '<p>The customer at ' +
                        address +
                        ' has cancelled your recent quote "' +
                        bid.description +
                        '", please do not complete any of this work.</p>',
                };

                await this.props.sendEmail(notification);

                if (bid.vendor) {
                    notification.email = bid.vendor.email;

                    await this.props.sendEmail(notification);
                }

                window.location.reload();
            }, 1000);

            this.props.toggleLoading(false);
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    renderPlantSelectionMessage() {
        const redirectUrl = `${
            window.location.origin
        }/plant-selection?${approvalType}=${
            approvalType === 'bid' ? urlParams.bid : urlParams.job
        }`;
        return (
            <div>
                <Navbar />
                <div className="flex flex-center pt75 pb25 pl25 pr25">
                    <div className="step-content mt25">
                        <div className="flex flex-center-y flex-space-between mb25">
                            <h4>Getting Started</h4>
                        </div>
                        <div className="mb25 bg-green0">
                            <hr />
                        </div>
                        <p className="capitalize">
                            Hello <b>{this.props.user.data.first_name}</b>!
                        </p>
                        <p>
                            Before you review & approve your quote, you will
                            need to select which plants you would like in your
                            garden. Click the button below to get started.
                        </p>
                        <div className="flex flex-end">
                            <button
                                className="small btn3"
                                onClick={() =>
                                    (window.location.href = redirectUrl)
                                }
                            >
                                <span style={{ marginRight: 8 }}>Next</span>
                                <Icon>arrow_forward</Icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDiscoveryQuestion() {
        const { discoveryAnswer, discoverySource } = this.state;
        return (
            <div>
                <Navbar />
                <div className="pt75 pb25 pl25 pr25 flex flex-center">
                    <div className="mt25">
                        <p>How did you hear about us?</p>
                        <div className="radio-header">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={discoveryAnswer === 'facebook'}
                                    onChange={() =>
                                        this.setState({
                                            discoveryAnswer: 'facebook',
                                        })
                                    }
                                />
                                <h5 className="ml4">Facebook</h5>
                            </div>
                        </div>
                        <div className="radio-header mt15">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={discoveryAnswer === 'google'}
                                    onChange={() =>
                                        this.setState({
                                            discoveryAnswer: 'google',
                                        })
                                    }
                                />
                                <h5 className="ml4">Google</h5>
                            </div>
                        </div>
                        <div className="radio-header mt15">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={discoveryAnswer === 'yard sign'}
                                    onChange={() =>
                                        this.setState({
                                            discoveryAnswer: 'yard sign',
                                        })
                                    }
                                />
                                <h5 className="ml4">Yard Sign</h5>
                            </div>
                        </div>
                        <div className="radio-header mt15">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={discoveryAnswer === 'referral'}
                                    onChange={() =>
                                        this.setState({
                                            discoveryAnswer: 'referral',
                                        })
                                    }
                                />
                                <h5 className="ml4">
                                    I was referred by someone
                                </h5>
                            </div>
                            {discoveryAnswer === 'referral' && (
                                <div>
                                    <label>Referral Name</label>
                                    <input
                                        type="text"
                                        value={discoverySource}
                                        onChange={(e) =>
                                            this.setState({
                                                discoverySource: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="radio-header mt15">
                            <div className="flex-center-y">
                                <input
                                    type="radio"
                                    checked={discoveryAnswer === 'other'}
                                    onChange={() =>
                                        this.setState({
                                            discoveryAnswer: 'other',
                                        })
                                    }
                                />
                                <h5 className="ml4">Other</h5>
                            </div>
                            {discoveryAnswer === 'other' && (
                                <div>
                                    <label>Source</label>
                                    <input
                                        type="text"
                                        value={discoverySource}
                                        onChange={(e) =>
                                            this.setState({
                                                discoverySource: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt15 flex flex-end">
                            <button
                                className="btn3 small flex flex-center-y"
                                onClick={() => {
                                    if (!discoveryAnswer) {
                                        return window.alert(
                                            'Please select an option',
                                        );
                                    } else {
                                        if (
                                            discoveryAnswer === 'referral' ||
                                            discoveryAnswer === 'other'
                                        ) {
                                            if (!discoverySource) {
                                                return window.alert(
                                                    'Please add an answer',
                                                );
                                            }
                                        }
                                    }

                                    this.setState({
                                        discoveryQuestionIsComplete: true,
                                    });
                                }}
                            >
                                <span className="mr10">Next</span>{' '}
                                <Icon>arrow_forward</Icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            selectedBid,
            selectedBids,
            materialsTotal,
            laborTotal,
            deliveryTotal,
            rentalTotal,
            disposalTotal,
            settingsModalIsOpen,
            selectedSetting,
            bidChangesModalIsOpen,
            step,
            stepTitle,
            changeOrder,
            materials,
            selectedChangeOrder,
            userSignature,
            specialRequest,
            salesTax,
            paymentProcessingFee,
            totalSale,
            firstPayment,
            secondPayment,
            discoveryQuestionIsComplete,
            product,
        } = this.state;

        const { user, loading, plantSelection } = this.props;
        let newGarden = false;

        if (selectedBids) {
            newGarden =
                selectedBids &&
                selectedBids.find(
                    (bid) => bid.type === INSTALLATION || bid.type === REVIVE,
                );
        } else if (selectedBid) {
            newGarden =
                selectedBid.type === INSTALLATION ||
                selectedBid.type === REVIVE;
        }

        if (newGarden) {
            if (!plantSelection.data) {
                return this.renderPlantSelectionMessage();
            } else {
                if (!discoveryQuestionIsComplete) {
                    return this.renderDiscoveryQuestion();
                }
            }
        }

        return (
            <div>
                {/* modals */}
                <SettingsModal
                    isOpen={settingsModalIsOpen}
                    onClose={() =>
                        this.setState({ settingsModalIsOpen: false })
                    }
                    setting={selectedSetting}
                    user={user.data}
                />

                <LoaderModal isOpen={loading.isLoading} />

                <BidChangesModal
                    bid={changeOrder}
                    isOpen={bidChangesModalIsOpen}
                    onClose={() =>
                        this.setState({ bidChangesModalIsOpen: false })
                    }
                />

                {/* approval */}
                {((selectedBid &&
                    selectedBid.status === BID_PENDING_APPROVAL) ||
                    (selectedBids &&
                        selectedBids.find(
                            (bid) => bid.status === BID_PENDING_APPROVAL,
                        )) ||
                    (selectedChangeOrder &&
                        selectedChangeOrder.status ===
                            BID_PENDING_APPROVAL)) && (
                    <div>
                        <Navbar />
                        <div className="pt75">
                            <Grid container className="full-span mt25">
                                <Grid item md={2} sm={2} xs={1}></Grid>
                                <Grid item md={8} sm={8} xs={10}>
                                    <div>
                                        <StepIndicatorV2
                                            currentStep={step}
                                            maxSteps={3}
                                        />
                                        <div className="flex flex-center-y flex-space-between">
                                            <h4
                                                style={{
                                                    marginTop: 16,
                                                    marginBottom: 16,
                                                }}
                                                className="text-purpleB90"
                                            >
                                                {step}. {stepTitle}
                                            </h4>
                                        </div>
                                        <div className="mb25 bg-green0">
                                            <Divider />
                                        </div>
                                        <div
                                            className={
                                                step === 1 ? null : 'hidden'
                                            }
                                        >
                                            <div className="mt15">
                                                <h5 className="mb15">
                                                    Your Info
                                                </h5>
                                                <p className="m0">
                                                    <label>Name</label>
                                                    <br />
                                                    {`${user.data.first_name} ${user.data.last_name}`}
                                                    <br />
                                                    <label>Address</label>
                                                    <br />
                                                    {user.data.address}
                                                    {user.data.unit
                                                        ? ` #${user.data.unit}`
                                                        : ''}
                                                    , {user.data.city}{' '}
                                                    {user.data.state}{' '}
                                                    {user.data.zip_code}
                                                    <br />
                                                </p>
                                                <div className="mb25 mt25">
                                                    <Divider />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    selectedBids
                                                        ? 'mb15 mt15'
                                                        : 'hidden'
                                                }
                                            >
                                                <h5 className="mb15">
                                                    Services
                                                </h5>
                                                {selectedBids &&
                                                    selectedBids.map(
                                                        (bid, index) => (
                                                            <div
                                                                key={index}
                                                                className="mb15"
                                                            >
                                                                <p>
                                                                    {
                                                                        bid.description
                                                                    }
                                                                </p>
                                                                <p className="text-small">
                                                                    <label>
                                                                        Estimated
                                                                        Start
                                                                        Date
                                                                    </label>
                                                                    <br />
                                                                    {moment(
                                                                        bid.estimated_start_dt,
                                                                    ).format(
                                                                        'MM/DD/YYYY',
                                                                    )}
                                                                </p>
                                                                <div className="flex flex-center-y text-small">
                                                                    <p
                                                                        className="link mr10 mb0"
                                                                        onClick={() =>
                                                                            this.setState(
                                                                                {
                                                                                    bidChangesModalIsOpen: true,
                                                                                    changeOrder:
                                                                                        bid,
                                                                                },
                                                                            )
                                                                        }
                                                                    >
                                                                        Request
                                                                        Changes
                                                                    </p>
                                                                    <div>|</div>
                                                                    <p
                                                                        className="link ml10 mb0"
                                                                        onClick={() =>
                                                                            this.cancelBid(
                                                                                bid,
                                                                            )
                                                                        }
                                                                    >
                                                                        Delete
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ),
                                                    )}
                                                <div className="mb25 mt25">
                                                    <Divider />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    selectedBid
                                                        ? 'mb15 mt15'
                                                        : 'hidden'
                                                }
                                            >
                                                <h5 className="mb15">
                                                    Services
                                                </h5>
                                                <div className="mb15">
                                                    <p>
                                                        <label>
                                                            Description
                                                        </label>
                                                        <br />
                                                        {selectedBid &&
                                                            selectedBid.description}
                                                        <br />
                                                        <label>
                                                            Estimated Start Date
                                                        </label>
                                                        <br />
                                                        {moment(
                                                            selectedBid &&
                                                                selectedBid.estimated_start_dt,
                                                        ).format('MM/DD/YYYY')}
                                                    </p>
                                                    <div>
                                                        <p
                                                            className="link"
                                                            onClick={() =>
                                                                this.setState({
                                                                    bidChangesModalIsOpen: true,
                                                                    changeOrder:
                                                                        selectedBid,
                                                                })
                                                            }
                                                        >
                                                            Request Changes
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mb25 mt25">
                                                    <Divider />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    selectedChangeOrder
                                                        ? null
                                                        : 'hidden'
                                                }
                                            >
                                                <div>
                                                    <label>Description</label>
                                                    <p className="m0">
                                                        {selectedChangeOrder &&
                                                            selectedChangeOrder.description}
                                                    </p>

                                                    <label>Change For</label>
                                                    <p className="m0">
                                                        {selectedChangeOrder &&
                                                            selectedChangeOrder
                                                                .order
                                                                .description}
                                                    </p>
                                                </div>
                                                <div className="mb25 mt25">
                                                    <Divider />
                                                </div>
                                            </div>
                                            {product && (
                                                <div>
                                                    <p>
                                                        <label>
                                                            Description
                                                        </label>
                                                        <br />
                                                        {product.title}
                                                    </p>
                                                    <div>
                                                        <p
                                                            className="link"
                                                            onClick={() =>
                                                                this.setState({
                                                                    product:
                                                                        null,
                                                                })
                                                            }
                                                        >
                                                            Remove
                                                        </p>
                                                    </div>
                                                    <div className="mb25 mt25">
                                                        <Divider />
                                                    </div>
                                                </div>
                                            )}

                                            <div
                                                className={
                                                    newGarden
                                                        ? 'mb15'
                                                        : 'hidden'
                                                }
                                            >
                                                <div className="mt25 mb25">
                                                    <h5 className="mb15">
                                                        Note to gardener
                                                    </h5>
                                                    <p className="text-small">
                                                        Have any special
                                                        requests? Let your
                                                        gardener know here.
                                                    </p>
                                                    <textarea
                                                        type="text"
                                                        name="specialRequest"
                                                        value={specialRequest}
                                                        placeholder="Example: I want roma tomatoes and iceberg lettuce..."
                                                        onChange={(e) =>
                                                            this.setValue(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                step === 2 ? null : 'hidden'
                                            }
                                        >
                                            {((selectedBids &&
                                                selectedBids.find(
                                                    (bid) =>
                                                        bid.line_items
                                                            .materials,
                                                )) ||
                                                (selectedBid &&
                                                    selectedBid.line_items
                                                        .materials) ||
                                                (selectedChangeOrder &&
                                                    selectedChangeOrder
                                                        .line_items
                                                        .materials)) && (
                                                <div className="mb15">
                                                    <h5 className="mt0 mb5">
                                                        Materials $
                                                        {delimit(
                                                            materialsTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h5>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll overflow-y-scroll table-container"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedBids &&
                                                                        materials.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <TableRow
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        <a
                                                                                            href={
                                                                                                item.url
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </a>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            item.price.toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            item.qty
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            (
                                                                                                item.qty *
                                                                                                item.price
                                                                                            ).toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ),
                                                                        )}
                                                                    {selectedBid &&
                                                                        selectedBid.line_items.materials.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <TableRow
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        <a
                                                                                            href={
                                                                                                item.url
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </a>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            item.price.toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            item.qty
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            (
                                                                                                item.qty *
                                                                                                item.price
                                                                                            ).toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ),
                                                                        )}
                                                                    {selectedChangeOrder &&
                                                                        selectedChangeOrder.line_items.materials.map(
                                                                            (
                                                                                item,
                                                                                index,
                                                                            ) => (
                                                                                <TableRow
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        <a
                                                                                            href={
                                                                                                item.url
                                                                                            }
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </a>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            item.price.toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            item.qty
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            (
                                                                                                item.qty *
                                                                                                item.price
                                                                                            ).toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ),
                                                                        )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {((selectedBids &&
                                                selectedBids.find(
                                                    (bid) =>
                                                        bid.line_items.labor,
                                                )) ||
                                                (selectedBid &&
                                                    selectedBid.line_items
                                                        .labor) ||
                                                (selectedChangeOrder &&
                                                    selectedChangeOrder
                                                        .line_items.labor)) && (
                                                <div className="mb15">
                                                    <h5 className="mt0 mb5">
                                                        Labor $
                                                        {delimit(
                                                            laborTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h5>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Description
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Hourly
                                                                            Rate
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedBids &&
                                                                        selectedBids.map(
                                                                            (
                                                                                bid,
                                                                                index,
                                                                            ) => (
                                                                                <TableRow
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        <label>
                                                                                            {
                                                                                                bid.description
                                                                                            }
                                                                                        </label>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            bid.line_items.labor.price.toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            bid
                                                                                                .line_items
                                                                                                .labor
                                                                                                .qty
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        $
                                                                                        {delimit(
                                                                                            (
                                                                                                bid
                                                                                                    .line_items
                                                                                                    .labor
                                                                                                    .price *
                                                                                                bid
                                                                                                    .line_items
                                                                                                    .labor
                                                                                                    .qty
                                                                                            ).toFixed(
                                                                                                2,
                                                                                            ),
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ),
                                                                        )}
                                                                    {selectedBid && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedBid.description
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.labor.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    selectedBid
                                                                                        .line_items
                                                                                        .labor
                                                                                        .qty
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    (
                                                                                        selectedBid
                                                                                            .line_items
                                                                                            .labor
                                                                                            .price *
                                                                                        selectedBid
                                                                                            .line_items
                                                                                            .labor
                                                                                            .qty
                                                                                    ).toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    {selectedChangeOrder && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedChangeOrder.description
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.labor.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    selectedChangeOrder
                                                                                        .line_items
                                                                                        .labor
                                                                                        .qty
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    (
                                                                                        selectedChangeOrder
                                                                                            .line_items
                                                                                            .labor
                                                                                            .price *
                                                                                        selectedChangeOrder
                                                                                            .line_items
                                                                                            .labor
                                                                                            .qty
                                                                                    ).toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {((selectedBids &&
                                                selectedBids.find(
                                                    (bid) =>
                                                        bid.line_items.delivery,
                                                )) ||
                                                (selectedBid &&
                                                    selectedBid.line_items
                                                        .delivery) ||
                                                (selectedChangeOrder &&
                                                    selectedChangeOrder
                                                        .line_items
                                                        .delivery)) && (
                                                <div className="mb15">
                                                    <h5 className="mt0 mb5">
                                                        Delivery $
                                                        {delimit(
                                                            deliveryTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h5>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedBids &&
                                                                        selectedBids.map(
                                                                            (
                                                                                bid,
                                                                                index,
                                                                            ) => {
                                                                                if (
                                                                                    bid
                                                                                        .line_items
                                                                                        .delivery
                                                                                ) {
                                                                                    return (
                                                                                        <TableRow
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <TableCell>
                                                                                                <label>
                                                                                                    {
                                                                                                        bid
                                                                                                            .line_items
                                                                                                            .delivery
                                                                                                            .name
                                                                                                    }
                                                                                                </label>
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                $
                                                                                                {delimit(
                                                                                                    bid.line_items.delivery.price.toFixed(
                                                                                                        2,
                                                                                                    ),
                                                                                                )}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                1
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                $
                                                                                                {delimit(
                                                                                                    bid.line_items.delivery.price.toFixed(
                                                                                                        2,
                                                                                                    ),
                                                                                                )}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            },
                                                                        )}
                                                                    {selectedBid && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedBid
                                                                                            .line_items
                                                                                            .delivery
                                                                                            .name
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.delivery.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                1
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.delivery.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    {selectedChangeOrder && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedChangeOrder
                                                                                            .line_items
                                                                                            .delivery
                                                                                            .name
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.delivery.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                1
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.delivery.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {((selectedBids &&
                                                selectedBids.find(
                                                    (bid) =>
                                                        bid.line_items.rentals,
                                                )) ||
                                                (selectedBid &&
                                                    selectedBid.line_items
                                                        .rentals) ||
                                                (selectedChangeOrder &&
                                                    selectedChangeOrder
                                                        .line_items
                                                        .rentals)) && (
                                                <div className="mb15">
                                                    <h5 className="mt0 mb5">
                                                        Rentals $
                                                        {delimit(
                                                            rentalTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h5>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedBids &&
                                                                        selectedBids.map(
                                                                            (
                                                                                bid,
                                                                                index,
                                                                            ) => {
                                                                                if (
                                                                                    bid
                                                                                        .line_items
                                                                                        .rentals
                                                                                ) {
                                                                                    return (
                                                                                        <TableRow
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <TableCell>
                                                                                                <label>
                                                                                                    {
                                                                                                        bid
                                                                                                            .line_items
                                                                                                            .rentals
                                                                                                            .name
                                                                                                    }
                                                                                                </label>
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                $
                                                                                                {delimit(
                                                                                                    bid.line_items.rentals.price.toFixed(
                                                                                                        2,
                                                                                                    ),
                                                                                                )}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                1
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                $
                                                                                                {delimit(
                                                                                                    bid.line_items.rentals.price.toFixed(
                                                                                                        2,
                                                                                                    ),
                                                                                                )}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            },
                                                                        )}
                                                                    {selectedBid && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedBid
                                                                                            .line_items
                                                                                            .rentals
                                                                                            .name
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.rentals.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                1
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.rentals.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    {selectedChangeOrder && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedChangeOrder
                                                                                            .line_items
                                                                                            .rentals
                                                                                            .name
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.rentals.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                1
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.rentals.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                            {((selectedBids &&
                                                selectedBids.find(
                                                    (bid) =>
                                                        bid.line_items.disposal,
                                                )) ||
                                                (selectedBid &&
                                                    selectedBid.line_items
                                                        .disposal) ||
                                                (selectedChangeOrder &&
                                                    selectedChangeOrder
                                                        .line_items
                                                        .disposal)) && (
                                                <div className="mb15">
                                                    <h5 className="mt0 mb5">
                                                        Disposal $
                                                        {delimit(
                                                            disposalTotal.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </h5>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="overflow-x-scroll"
                                                    >
                                                        <Grid
                                                            item
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className="table-cell">
                                                                            Item
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Price
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Qty
                                                                        </TableCell>
                                                                        <TableCell className="table-cell">
                                                                            Subtotal
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {selectedBids &&
                                                                        selectedBids.map(
                                                                            (
                                                                                bid,
                                                                                index,
                                                                            ) => {
                                                                                if (
                                                                                    bid
                                                                                        .line_items
                                                                                        .disposal
                                                                                ) {
                                                                                    return (
                                                                                        <TableRow
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <TableCell>
                                                                                                <label>
                                                                                                    {
                                                                                                        bid
                                                                                                            .line_items
                                                                                                            .disposal
                                                                                                            .name
                                                                                                    }
                                                                                                </label>
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                $
                                                                                                {delimit(
                                                                                                    bid.line_items.disposal.price.toFixed(
                                                                                                        2,
                                                                                                    ),
                                                                                                )}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                1
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                $
                                                                                                {delimit(
                                                                                                    bid.line_items.disposal.price.toFixed(
                                                                                                        2,
                                                                                                    ),
                                                                                                )}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            },
                                                                        )}
                                                                    {selectedBid && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedBid
                                                                                            .line_items
                                                                                            .disposal
                                                                                            .name
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.disposal.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                1
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedBid.line_items.disposal.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    {selectedChangeOrder && (
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <label>
                                                                                    {
                                                                                        selectedChangeOrder
                                                                                            .line_items
                                                                                            .disposal
                                                                                            .name
                                                                                    }
                                                                                </label>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.disposal.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                1
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                $
                                                                                {delimit(
                                                                                    selectedChangeOrder.line_items.disposal.price.toFixed(
                                                                                        2,
                                                                                    ),
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                step === 3 ? null : 'hidden'
                                            }
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                className="overflow-x-scroll"
                                            >
                                                <Grid
                                                    item
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <div>
                                                        <div className="mb15">
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    md={4}
                                                                    sm={6}
                                                                    xs={12}
                                                                >
                                                                    <h5 className="mb15">
                                                                        Quote
                                                                        Summary
                                                                    </h5>
                                                                    <div className="flex flex-space-between">
                                                                        <p className="m0">
                                                                            Materials
                                                                        </p>
                                                                        <p className="m0">
                                                                            $
                                                                            {delimit(
                                                                                materialsTotal.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex flex-space-between">
                                                                        <p className="m0">
                                                                            Labor
                                                                            {deliveryTotal >
                                                                            0
                                                                                ? ', Delivery'
                                                                                : ''}
                                                                            {rentalTotal >
                                                                            0
                                                                                ? ', Rentals'
                                                                                : ''}
                                                                            {disposalTotal >
                                                                            0
                                                                                ? ', Disposal'
                                                                                : ''}
                                                                        </p>
                                                                        <p className="m0">
                                                                            $
                                                                            {delimit(
                                                                                (
                                                                                    laborTotal +
                                                                                    deliveryTotal +
                                                                                    rentalTotal +
                                                                                    disposalTotal
                                                                                ).toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex flex-space-between">
                                                                        <p className="m0">
                                                                            Processing
                                                                            Fee
                                                                        </p>
                                                                        <p className="m0">
                                                                            $
                                                                            {delimit(
                                                                                paymentProcessingFee.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="flex flex-space-between">
                                                                        <p className="m0">
                                                                            Taxes
                                                                        </p>
                                                                        <p className="m0">
                                                                            $
                                                                            {delimit(
                                                                                salesTax.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                    <div className="mt10 mb10">
                                                                        <Divider />
                                                                    </div>
                                                                    <div className="flex flex-space-between">
                                                                        <p className="m0">
                                                                            Quote
                                                                            Total
                                                                        </p>
                                                                        <p className="m0">
                                                                            $
                                                                            {delimit(
                                                                                totalSale.toFixed(
                                                                                    2,
                                                                                ),
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={8}
                                                                ></Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="mb25 mt25">
                                                            <Divider />
                                                        </div>
                                                        <div className="mb15">
                                                            <h5 className="mb15">
                                                                Payment Schedule
                                                            </h5>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    md={6}
                                                                    sm={6}
                                                                    xs={12}
                                                                >
                                                                    <p className="mb0">
                                                                        <u>
                                                                            Payment
                                                                            #1
                                                                        </u>
                                                                    </p>
                                                                    <div className="m0">
                                                                        <ul>
                                                                            <li
                                                                                className={
                                                                                    materialsTotal >
                                                                                    0
                                                                                        ? null
                                                                                        : 'hidden'
                                                                                }
                                                                            >
                                                                                Materials
                                                                            </li>
                                                                            <li
                                                                                className={
                                                                                    deliveryTotal >
                                                                                    0
                                                                                        ? null
                                                                                        : 'hidden'
                                                                                }
                                                                            >
                                                                                Delivery
                                                                            </li>
                                                                            <li
                                                                                className={
                                                                                    rentalTotal >
                                                                                    0
                                                                                        ? null
                                                                                        : 'hidden'
                                                                                }
                                                                            >
                                                                                Rentals
                                                                            </li>
                                                                            <li
                                                                                className={
                                                                                    disposalTotal >
                                                                                    0
                                                                                        ? null
                                                                                        : 'hidden'
                                                                                }
                                                                            >
                                                                                Disposal
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <p className="text-small">
                                                                        Amount
                                                                        to be
                                                                        charged
                                                                        today{' '}
                                                                        {moment().format(
                                                                            'MM/DD/YYYY',
                                                                        )}
                                                                    </p>
                                                                    <p className="m0">
                                                                        $
                                                                        {delimit(
                                                                            firstPayment.toFixed(
                                                                                2,
                                                                            ),
                                                                        )}
                                                                    </p>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={6}
                                                                    sm={6}
                                                                    xs={12}
                                                                >
                                                                    <p className="mb0">
                                                                        <u>
                                                                            Payment
                                                                            #2
                                                                        </u>
                                                                    </p>
                                                                    <div className="m0">
                                                                        <ul>
                                                                            <li>
                                                                                Labor
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <p className="text-small">
                                                                        Amount
                                                                        to be
                                                                        charged
                                                                        when all{' '}
                                                                        {selectedChangeOrder
                                                                            ? 'changes'
                                                                            : 'projects'}{' '}
                                                                        are
                                                                        completed
                                                                    </p>
                                                                    <p className="m0">
                                                                        $
                                                                        {delimit(
                                                                            secondPayment.toFixed(
                                                                                2,
                                                                            ),
                                                                        )}
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                        <div className="mb25 mt25">
                                                            <Divider />
                                                        </div>
                                                        <div
                                                            className="mb15"
                                                            id="approval"
                                                        >
                                                            <div>
                                                                <h5 className="mb15">
                                                                    Quote
                                                                    Approval
                                                                </h5>
                                                                <div>
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            md={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <label>
                                                                                Electronic
                                                                                Signature
                                                                            </label>
                                                                            <div className="mt5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="userSignature"
                                                                                    placeholder="John Smith"
                                                                                    value={
                                                                                        userSignature
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) =>
                                                                                        this.setValue(
                                                                                            e,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        className="cursive signature bg-gray1 pl15 mt15 mb15"
                                                                        style={{
                                                                            minHeight: 45,
                                                                        }}
                                                                    >
                                                                        {
                                                                            userSignature
                                                                        }
                                                                    </p>
                                                                    <p className="text-small">
                                                                        By
                                                                        signing,
                                                                        I agree
                                                                        to pay
                                                                        the full
                                                                        amount
                                                                        of $
                                                                        {delimit(
                                                                            totalSale.toFixed(
                                                                                2,
                                                                            ),
                                                                        )}{' '}
                                                                        to
                                                                        Yarden
                                                                        Garden
                                                                        Inc for
                                                                        all work
                                                                        listed
                                                                        in
                                                                        section
                                                                        (1a)
                                                                        "Project
                                                                        Description"
                                                                        of this
                                                                        contract.
                                                                        I
                                                                        acknowledge
                                                                        that I
                                                                        will pay
                                                                        the
                                                                        first
                                                                        payment
                                                                        of $
                                                                        {delimit(
                                                                            firstPayment.toFixed(
                                                                                2,
                                                                            ),
                                                                        )}{' '}
                                                                        today{' '}
                                                                        {moment().format(
                                                                            'MM/DD/YYYY',
                                                                        )}
                                                                        , and a
                                                                        second
                                                                        payment
                                                                        of $
                                                                        {delimit(
                                                                            secondPayment.toFixed(
                                                                                2,
                                                                            ),
                                                                        )}{' '}
                                                                        once all
                                                                        work has
                                                                        been
                                                                        completed.
                                                                    </p>
                                                                    <div
                                                                        className={
                                                                            selectedBids
                                                                                ? 'mb15'
                                                                                : 'hidden'
                                                                        }
                                                                    >
                                                                        <label>
                                                                            (1a)
                                                                            Projects
                                                                            Description
                                                                        </label>
                                                                        {selectedBids &&
                                                                            selectedBids.map(
                                                                                (
                                                                                    bid,
                                                                                    index,
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        className="mb15"
                                                                                    >
                                                                                        <p>
                                                                                            {
                                                                                                bid.description
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                ),
                                                                            )}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            selectedBid
                                                                                ? 'mb15'
                                                                                : 'hidden'
                                                                        }
                                                                    >
                                                                        <label>
                                                                            (1a)
                                                                            Project
                                                                            Description
                                                                        </label>
                                                                        <div className="mb15">
                                                                            <p>
                                                                                {selectedBid &&
                                                                                    selectedBid.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            selectedChangeOrder
                                                                                ? 'mb15'
                                                                                : 'hidden'
                                                                        }
                                                                    >
                                                                        <label>
                                                                            (1a)
                                                                            Change
                                                                            Order
                                                                            Description
                                                                        </label>
                                                                        <div className="mb15">
                                                                            <p>
                                                                                <label>
                                                                                    Description
                                                                                </label>
                                                                                <br />
                                                                                {selectedChangeOrder &&
                                                                                    selectedChangeOrder.description}
                                                                            </p>
                                                                            <p>
                                                                                <label>
                                                                                    Change
                                                                                    For
                                                                                </label>
                                                                                <br />
                                                                                {selectedChangeOrder &&
                                                                                    selectedChangeOrder
                                                                                        .order
                                                                                        .description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb25 mt25">
                                                            <Divider />
                                                        </div>
                                                        <div className="mb15">
                                                            <h5 className="mb15">
                                                                Payment Method
                                                            </h5>
                                                            {!user.data
                                                                .payment_info && (
                                                                <div className="mt15">
                                                                    <Alert
                                                                        variant="outlined"
                                                                        severity="info"
                                                                    >
                                                                        Please
                                                                        add a
                                                                        payment
                                                                        method
                                                                    </Alert>
                                                                </div>
                                                            )}
                                                            <div>
                                                                {user.data
                                                                    .payment_info && (
                                                                    <div className="flex flex-center-y">
                                                                        <CreditCardOutlinedIcon />
                                                                        <p className="ml5 mb0 text-small">
                                                                            {
                                                                                user
                                                                                    .data
                                                                                    .payment_info
                                                                                    .card_brand
                                                                            }{' '}
                                                                            ending
                                                                            in{' '}
                                                                            {
                                                                                user
                                                                                    .data
                                                                                    .payment_info
                                                                                    .card_last4
                                                                            }{' '}
                                                                            (Exp{' '}
                                                                            {
                                                                                user
                                                                                    .data
                                                                                    .payment_info
                                                                                    .card_exp_month
                                                                            }
                                                                            /
                                                                            {
                                                                                user
                                                                                    .data
                                                                                    .payment_info
                                                                                    .card_exp_year
                                                                            }
                                                                            )
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="float-right mt15">
                                                                <button
                                                                    className="btn2 small"
                                                                    onClick={() => {
                                                                        if (
                                                                            !user
                                                                                .data
                                                                                .payment_info
                                                                        ) {
                                                                            this.editSettings(
                                                                                'add card',
                                                                            );
                                                                        } else {
                                                                            this.editSettings(
                                                                                'edit card',
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="flex flex-center-y">
                                                                        {!user
                                                                            .data
                                                                            .payment_info
                                                                            ? 'Add'
                                                                            : 'Change'}{' '}
                                                                        Card{' '}
                                                                        <AddIcon />
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="pb25">
                                            <div className="flex flex-center-y flex-space-between mt25">
                                                <button
                                                    className={
                                                        step > 1
                                                            ? 'btn3 small flex flex-center-y btn2'
                                                            : 'hidden'
                                                    }
                                                    onClick={() => this.back()}
                                                >
                                                    <Icon className="btn2">
                                                        arrow_back
                                                    </Icon>{' '}
                                                    <span className="ml10">
                                                        Back
                                                    </span>
                                                </button>
                                                <div
                                                    className={
                                                        step === 1
                                                            ? null
                                                            : 'hidden'
                                                    }
                                                ></div>
                                                <button
                                                    className={
                                                        step === 3
                                                            ? 'hidden'
                                                            : 'btn3 small flex flex-center-y'
                                                    }
                                                    onClick={() => this.next()}
                                                >
                                                    <span className="mr10">
                                                        Next
                                                    </span>{' '}
                                                    <Icon>arrow_forward</Icon>
                                                </button>
                                                <button
                                                    disabled={loading.isLoading}
                                                    className={
                                                        step === 3
                                                            ? 'purple small'
                                                            : 'hidden'
                                                    }
                                                    onClick={() =>
                                                        this.approve()
                                                    }
                                                >
                                                    <div className="flex">
                                                        <Icon>done</Icon>{' '}
                                                        <span
                                                            style={{
                                                                marginLeft: 8,
                                                            }}
                                                        >
                                                            Finish
                                                        </span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={2} sm={2} xs={1}></Grid>
                            </Grid>
                        </div>
                    </div>
                )}
                {/* approval */}

                {/* quote approved */}
                {selectedBid && selectedBid.status === APPROVED && (
                    <div className="text-center mt50">
                        <h2>Quote approved!</h2>
                        <p>
                            Your quote has been approved. Next, Yarden will
                            schedule your service and send you a notification
                            with the date/time.
                        </p>
                        <p>
                            If you have any questions, please contact the Yarden
                            team at{' '}
                            <a href="mailto:info@yardengarden.com">
                                info@yardengarden.com
                            </a>{' '}
                            for assistance.
                        </p>
                    </div>
                )}

                {/* change order approved */}
                {selectedChangeOrder &&
                    selectedChangeOrder.status === APPROVED && (
                        <div className="text-center mt50">
                            <h2>Change order approved!</h2>
                            <p>Your change order has been approved.</p>
                            <p>
                                If you have any questions, please contact the
                                Yarden team at{' '}
                                <a href="mailto:info@yardengarden.com">
                                    info@yardengarden.com
                                </a>{' '}
                                for assistance.
                            </p>
                        </div>
                    )}

                {/* quote cancelled */}
                {selectedBid && selectedBid.status === CANCELLED && (
                    <div className="text-center mt50">
                        <h2>Quote cancelled</h2>
                        <p>
                            This quote has been cancelled and is no longer
                            available for approval.
                        </p>
                        <p>
                            If you have any questions, please contact the Yarden
                            team at{' '}
                            <a href="mailto:info@yardengarden.com">
                                info@yardengarden.com
                            </a>{' '}
                            for assistance.
                        </p>
                    </div>
                )}

                {/* change order cancelled */}
                {selectedChangeOrder &&
                    selectedChangeOrder.status === CANCELLED && (
                        <div className="text-center mt50">
                            <h2>Change order cancelled</h2>
                            <p>
                                This change order has been cancelled and is no
                                longer available for approval.
                            </p>
                            <p>
                                If you have any questions, please contact the
                                Yarden team at{' '}
                                <a href="mailto:info@yardengarden.com">
                                    info@yardengarden.com
                                </a>{' '}
                                for assistance.
                            </p>
                        </div>
                    )}

                {/* no quote found */}
                {!loading.isLoading &&
                    !selectedBid &&
                    !selectedBids &&
                    !selectedChangeOrder && (
                        <div className="text-center mt50">
                            <h2>No quote found</h2>
                            <p>
                                We could not find your quote, please contact the
                                Yarden team at{' '}
                                <a href="mailto:info@yardengarden.com">
                                    info@yardengarden.com
                                </a>{' '}
                                for assistance.
                            </p>
                        </div>
                    )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        plantSelection: state.plantSelection,
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBid,
            getBids,
            chargeCard,
            createOrder,
            updateBid,
            sendEmail,
            sendSms,
            sendAlert,
            getChangeOrder,
            updateChangeOrder,
            getImage,
            updateImage,
            getIP,
            createApproval,
            getReferral,
            updateReferral,
            toggleLoading,
            createSpecialRequest,
            getPlantSelection,
            updateUser,
        },
        dispatch,
    );
}

Approve = connect(mapStateToProps, mapDispatchToProps)(Approve);

export default Approve;
