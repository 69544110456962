// libs
import React from 'react';

// UI components
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';

const StepButtons = ({ next, back, finish, isLastStep }) => {
    return (
        <div className="flex flex-center-y flex-space-between">
            <button className="small btn3" onClick={() => back()}>
                <div className="flex flex-center-y">
                    <ArrowBack />
                    <span style={{ marginLeft: 8 }}>Back</span>
                </div>
            </button>
            {isLastStep ? (
                <button className="small purple" onClick={() => finish()}>
                    <div className="flex flex-center-y">
                        <CheckOutlined />
                        <span style={{ marginLeft: 8 }}>Finish</span>
                    </div>
                </button>
            ) : (
                <button className="small btn3" onClick={() => next()}>
                    <div className="flex flex-center-y">
                        <span style={{ marginRight: 8 }}>Next</span>
                        <ArrowForward />
                    </div>
                </button>
            )}
        </div>
    );
};

export default StepButtons;
