import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createPlantList(plantList) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/plant-lists`, plantList, { headers: { authorization: getAuthToken() } });
            return response;
        }

        catch (error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}

export function getPlantList(query) {
    return async function(dispatch) {
        const q = (query) ? `?${query}` : '';
        try {
            const response = await axios.get(`/api/v1/plant-lists${q}`, {headers: {authorization: getAuthToken()}});
            return response;
        }

        catch(error) {
            dispatch(throwError(error, 'Something went wrong. We are working on a fix now!'));
        }
    }
}
