import axios from 'axios';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';
import { UPDATE_ANSWERS } from './types';

export function createAnswer(answer) {
    return async function (dispatch) {
        try {
            const response = await axios.post(`/api/v1/answers`, answer, {
                headers: { authorization: getAuthToken() },
            });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getAnswers(query) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/answers${q}`, {
                headers: { authorization: getAuthToken() },
            });
            dispatch({ type: UPDATE_ANSWERS, payload: response.data });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function updateAnswers(answers) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_ANSWERS,
            payload: answers,
        });
    };
}
