// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// actions
import { validateUser, initiatePasswordReset } from '../actions/user/index';
import { toggleLoading } from '../actions/loading/index';

// UI components
import Grid from '@material-ui/core/Grid';
import LoaderModal from '../components/LoaderModal';
import Alert from '../components/Alert';
import GeneralMessage from '../components/GeneralMessage';

// helpers
import logInfo from '../helpers/logInfo';

// styling
import '../css/reset-password.css';

class ResetPassword extends Component {
    state = {
        userEmail: '',
    };

    componentDidMount() {
        logInfo('Reset Password page loaded');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    async validate() {
        if (!this.state.userEmail) {
            return window.alert('Please complete all required fields');
        }

        const validationResult = await this.props.validateUser(
            this.state.userEmail,
        );

        if (validationResult.data.isValid) {
            await this.props.initiatePasswordReset(this.state.userEmail);

            this.setState({ displayMessage: true });
        } else {
            return window.alert('Account does not exist');
        }
    }

    render() {
        const { alertIsOpen, displayMessage, userEmail } = this.state;

        let { loading, error } = this.props;

        return (
            <span>
                {/* modals */}
                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />
                <LoaderModal isOpen={loading.isLoading} />

                {/* reset form */}
                <div className={displayMessage ? 'hidden' : null}>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <div className="text-center mt25">
                                <h3 className="mt25 mb25">Reset Password</h3>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={3} xs={1}></Grid>
                        <Grid item md={4} sm={6} xs={10}>
                            <div>
                                <label>Account Email*</label>
                                <input
                                    type="email"
                                    name="userEmail"
                                    placeholder="john@yardengarden.com"
                                    value={userEmail}
                                    onChange={(e) =>
                                        this.setState({
                                            userEmail: e.target.value,
                                        })
                                    }
                                />
                                <div className="mt25 float-right">
                                    <button
                                        className="small btn2"
                                        disabled={loading.isLoading}
                                        onClick={() => this.validate()}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={3} xs={1}></Grid>
                    </Grid>
                </div>

                {/* confirmation message */}
                <div className={displayMessage ? 'text-center' : 'hidden'}>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <GeneralMessage
                                message="Almost there! Check your email for a reset link"
                                buttonText="Go Home"
                                buttonAction={() =>
                                    (window.location.href =
                                        window.location.origin)
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            validateUser,
            initiatePasswordReset,
            toggleLoading,
        },
        dispatch,
    );
}

ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default ResetPassword;
