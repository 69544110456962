import { 
    PENDING_TASKS,
    COMPLETE_TASKS
} from './../actions/status/types';

let initialState = {
    type: 'pending'
}

export default function( state = initialState, action){
    switch(action.type){
        case PENDING_TASKS:
            return {
                type: 'pending'
            }
        case COMPLETE_TASKS:
            return {
                type: 'complete'
            }
        default:
            return state;
    }
}