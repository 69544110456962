import axios from 'axios';
import { GET_APPLICATIONS } from './types';
import { throwError } from '../../actions/error/index';
import getAuthToken from '../../formatting/getAuthToken';

export function createApplication(application) {
    return async function (dispatch) {
        try {
            const response = await axios.post(
                `/api/v1/applications`,
                application,
            );
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getApplications(query) {
    return async function (dispatch) {
        try {
            const q = query ? `?${query}` : '';
            const response = await axios.get(`/api/v1/applications${q}`, {
                headers: { authorization: getAuthToken() },
            });
            dispatch({ type: GET_APPLICATIONS, payload: response });
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function getApplication(id) {
    return async function (dispatch) {
        try {
            const response = await axios.get(`/api/v1/applications/${id}`);
            return response;
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}

export function updateApplication(id, application) {
    return async function (dispatch) {
        try {
            await axios.put(`/api/v1/applications/${id}`, application);
        } catch (error) {
            dispatch(
                throwError(
                    error,
                    'Something went wrong. We are working on a fix now!',
                ),
            );
        }
    };
}
