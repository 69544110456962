// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import Alert from '../components/Alert.js';
import LoginModal from '../components/LoginModal.js';

// actions
import { authenticateUser } from '../actions/user/index';
import { updateOrder, getOrder } from '../actions/order/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';

// helpers
import getAuthToken from '../formatting/getAuthToken';

//css
import '../css/reschedule.css';

//image
const harvest =
    'https://yarden-garden.s3.us-west-1.amazonaws.com/products_%26_services_page_photos/harvest.png';

class Reschedule extends Component {
    state = {
        date: moment().add(3, 'days').startOf('day'),
        time: '',
    };

    async componentDidMount() {
        // revoke access if user is not authenticated
        const authToken = getAuthToken();
        if (!authToken) return this.setState({ loginModalIsOpen: true });
        await this.props.authenticateUser();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setState({
                alertIsOpen: true,
            });
        }
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async reschedule() {
        if (!this.state.time)
            return window.alert('Please complete all required fields');
        const urlParams = Object.fromEntries(
            new URLSearchParams(window.location.search),
        );
        const orderId = urlParams.order;
        const orderDetails = {
            date: this.state.date,
            time: this.state.time,
        };

        // update order time & date
        await this.props.updateOrder(orderId, orderDetails);
        const order = await this.props.getOrder(orderId);

        const email = {
            email: order.data.customer.email,
            subject: `Yarden - Your upcoming service has been rescheduled`,
            label: 'Scheduling Update',
            body:
                '<p>Hello <b>' +
                order.data.customer.first_name +
                '</b>,</p>' +
                '<p>Your upcoming service with Yarden has been rescheduled for another date, see the details below. If you have any questions, just let us know!</p>' +
                '<table style="margin: 0 auto;" width="600px" cellspacing="0" cellpadding="0" border="0">' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Date</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                moment(order.data.date).format('MM-DD-YYYY') +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Time</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                (order.data.type !== 'full plan' &&
                order.data.type !== 'assisted plan'
                    ? moment(order.data.time, `HH:mm:ss`).format(`h:mm A`)
                    : '9:00 AM - 5:00 PM') +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Type</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                order.data.type +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Full Name</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                order.data.customer.first_name +
                ' ' +
                order.data.customer.last_name +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Email</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                order.data.customer.email +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Phone Number</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                order.data.customer.phone_number +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><b>Address</b></p>' +
                '</td>' +
                '<td>' +
                '<p style="margin-bottom: 0px"><em>' +
                order.data.customer.address +
                (order.data.customer.unit
                    ? ' ' + order.data.customer.unit
                    : '') +
                ' ' +
                order.data.customer.city +
                ', ' +
                order.data.customer.state +
                ' ' +
                order.data.customer.zip_code +
                '</em></p>' +
                '</td>' +
                '</tr>' +
                '</table>',
        };

        // send confirmation email to customer
        await this.props.sendEmail(email);

        if (order.data.vendor) {
            if (
                order.data.vendor.notifications &&
                order.data.vendor.notifications === 'email'
            ) {
                const notification = {
                    email: 'info@yardengarden.com',
                    subject: `Yarden - Your work order has been rescheduled`,
                    label: 'Order Reschedule',
                    body: `<p>Greetings from Yarden! Your order for ${
                        order.data.customer.address
                    } was rescheduled for ${moment(order.data.date).format(
                        'MM-DD-YYYY',
                    )}. The details are in your dashboard at ${
                        window.location.origin
                    }/dashboard</p>`,
                };

                // send email notification to vendor
                await this.props.sendEmail(notification);
            } else {
                const sms = {
                    from: '8888289287',
                    to: order.data.vendor.phone_number.replace(/\D/g, ''),
                    body: `Greetings from Yarden! Your order for ${
                        order.data.customer.address
                    } was rescheduled for ${moment(order.data.date).format(
                        'MM-DD-YYYY',
                    )}. The details are in your dashboard at ${
                        window.location.origin
                    }/dashboard`,
                };

                // send sms notification to vendor
                await this.props.sendSms(sms);
            }
        }

        this.setState({ displaySuccessMessage: true });
    }

    render() {
        const {
            alertIsOpen,
            loginModalIsOpen,
            date,
            time,
            displaySuccessMessage,
        } = this.state;

        const { error, loading } = this.props;

        return (
            <section id="reschedule">
                {/* modals */}
                <LoginModal
                    isOpen={loginModalIsOpen}
                    onClose={() => this.setState({ loginModalIsOpen: false })}
                />

                <Alert
                    isOpen={alertIsOpen}
                    message={error.message}
                    onClose={() => this.setState({ alertIsOpen: false })}
                />

                <div>
                    {/* reschedule form */}
                    {!displaySuccessMessage && (
                        <div>
                            <h2>Reschedule Appointment</h2>
                            <p className="mt4">
                                Reschedule your appointment by selecting a new
                                time and date below.
                            </p>
                            <div className="mb15">
                                <label>New Date*</label>

                                <div className="date-container">
                                    <input
                                        type="date"
                                        name="estimatedStartDate"
                                        value={moment(date).format(
                                            'YYYY-MM-DD',
                                        )}
                                        min={moment()
                                            .add(3, 'days')
                                            .format('YYYY-MM-DD')}
                                        onChange={(e) =>
                                            this.setState({
                                                date: moment(
                                                    e.target.value,
                                                ).startOf('day'),
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex-column">
                                <label>New Time*</label>
                                <select
                                    name="time"
                                    disabled={loading.isLoading}
                                    value={time}
                                    onChange={(e) => this.setValue(e)}
                                >
                                    <option value={'09'}>9:00am</option>
                                    <option value={'0930'}>9:30am</option>
                                    <option value={'10'}>10:00am</option>
                                    <option value={'1030'}>10:30am</option>
                                    <option value={'11'}>11:00am</option>
                                    <option value={'1130'}>11:30am</option>
                                    <option value={'12'}>12:00pm</option>
                                    <option value={'1230'}>12:30pm</option>
                                    <option value={'13'}>1:00pm</option>
                                    <option value={'1330'}>1:30pm</option>
                                    <option value={'14'}>2:00pm</option>
                                    <option value={'1430'}>2:30pm</option>
                                    <option value={'15'}>3:00pm</option>
                                    <option value={'1530'}>3:30pm</option>
                                    <option value={'16'}>4:00pm</option>
                                    <option value={'1630'}>4:30pm</option>
                                    <option value={'17'}>5:00pm</option>
                                </select>
                            </div>
                            <div className="reschedule-button-container flex-end mt4">
                                <img
                                    src={harvest}
                                    alt="harvest"
                                    className="harvest-img"
                                />
                                <button
                                    className="purple"
                                    disabled={loading.isLoading}
                                    onClick={() => this.reschedule()}
                                >
                                    Reschedule
                                </button>
                            </div>
                        </div>
                    )}

                    {/* confirmation message */}
                    {displaySuccessMessage && (
                        <div>
                            <h1 className="text-center">Done!</h1>
                            <p>
                                Your appointment has been rescheduled for{' '}
                                <b>
                                    {moment(this.state.date).format(
                                        'MM/DD/YYYY',
                                    )}{' '}
                                    @{' '}
                                    {moment(this.state.time, `HH:mm:ss`).format(
                                        `h:mm A`,
                                    )}
                                </b>
                                , we look forward to seeing you then!
                            </p>
                            <div className="mt4 text-center">
                                <a href="/">Home Page</a>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        loading: state.loading,
        error: state.error,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateOrder,
            sendEmail,
            sendSms,
            getOrder,
            authenticateUser,
        },
        dispatch,
    );
}

Reschedule = connect(mapStateToProps, mapDispatchToProps)(Reschedule);

export default Reschedule;
