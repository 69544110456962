// libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import Icon from '@material-ui/core/Icon';
import { Divider } from '@material-ui/core';
import LoaderModal from '../components/LoaderModal.js';

// actions
import { getUsers } from '../actions/users/index';
import { getUser } from '../actions/user/index';
import {
    createMessage,
    getMessages,
    updateMessage,
} from '../actions/message/index';
import {
    createConversation,
    getConversations,
} from '../actions/conversation/index';
import { getImage, updateImage } from '../actions/image/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { toggleLoading } from '../actions/loading/index';
import { getOrders } from '../actions/order/index';

// helpers
import { truncate } from '../formatting/truncate';
import { compress } from '../formatting/compress';

// styling
import '../css/messenger.css';

class Messenger extends Component {
    state = {
        tab: 'inbox',
        message: '',
        conversations: [],
        selectedConversation: [],
        status: 'unread',
        uploadPreview: [],
        processedImages: [],
    };

    componentDidMount() {
        this.setState({ loadingModalIsOpen: true }, async () => {
            const urlParams = Object.fromEntries(
                new URLSearchParams(window.location.search),
            );
            await this.setConversations();
            if (urlParams.sender) {
                const user = await this.props.getUser(urlParams.sender);
                this.setConversation(user.data);
                if (window.history.pushState) {
                    var newurl =
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname;

                    window.history.pushState({ path: newurl }, '', newurl);
                }
            }

            this.setState({ loadingModalIsOpen: false });
        });
    }

    async setConversations() {
        const user = this.props.user.data;
        let conversations = [];
        await this.props.getConversations(`users=${this.props.user.data._id}`);

        const setInbox = new Promise(async (resolve) => {
            if (this.props.conversations.data.length < 1) {
                this.setState({ conversations });
                return resolve();
            }

            for (let i = 0; i < this.props.conversations.data.length; i++) {
                await this.props.getMessages(
                    `conversation_id=${this.props.conversations.data[i]._id}`,
                );
                conversations.push(this.props.messages.inbox.data);
                if (i === this.props.conversations.data.length - 1) {
                    const sortedConversations = conversations.sort(
                        (a, b) =>
                            new Date(b[b.length - 1].dt_created) -
                            new Date(a[a.length - 1].dt_created),
                    );
                    this.setState({ conversations: sortedConversations });
                    resolve();
                }
            }
        });

        setInbox.then(async () => {
            if (user.type === 'admin') {
                await this.props.getUsers();
                this.setState({
                    users: this.props.users.data,
                });
            } else {
                await this.setUsers();
            }

            this.setState({
                loadingModalIsOpen: false,
            });
        });
    }

    setValue(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async setConversation(user) {
        if (this.props.conversations.data.length < 1) {
            return await this.setState({
                selectedConversation: [],
                selectedUser: user,
                loadingModalIsOpen: false,
                uploadPreview: [],
            });
        }

        this.props.conversations.data.forEach(async (conversation, i) => {
            const match = conversation.users.find((u) => u._id === user._id);

            let matches = 0;

            if (match) {
                matches += 1;

                await this.props.getMessages(
                    `conversation_id=${conversation._id}`,
                );

                const lastIndex = this.props.messages.inbox.data.length - 1;

                const messages = this.props.messages.inbox.data;

                const setMessages = new Promise((resolve) => {
                    this.props.messages.inbox.data.forEach(
                        async (message, index) => {
                            if (
                                message.receiver._id ===
                                    this.props.user.data._id &&
                                !message.opened
                            ) {
                                await this.props.updateMessage(message._id, {
                                    opened: true,
                                });
                            }

                            if (index === lastIndex) resolve(messages);
                        },
                    );
                });

                setMessages.then(async (messages) => {
                    this.setState({
                        selectedConversation: messages,
                        conversationId: messages[0].conversation_id,
                        selectedUser: user,
                        uploadPreview: [],
                    });

                    await this.setConversations();
                    await this.props.onConversationOpen(conversation._id);
                    return this.scrollToBottom();
                });
            } else {
                if (
                    matches < 1 &&
                    i === this.props.conversations.data.length - 1
                ) {
                    this.setState({
                        selectedConversation: [],
                        uploadPreview: [],
                    });
                }
            }
        });

        this.setState({
            loadingModalIsOpen: false,
        });
    }

    async setUsers() {
        let users = [];
        const user = this.props.user.data;
        this.props.conversations.data.forEach((conversation) => {
            conversation.users.forEach((user) => {
                if (user._id !== this.props.user.data._id) users.push(user);
            });
        });

        if (user.type === 'customer') {
            const orders = await this.props.getOrders(`status=pending`, true);

            const maintenanceOrders =
                orders && orders.data && orders.data.list
                    ? orders.data.list.filter(
                          (order) =>
                              order.type === 'full plan' ||
                              order.type === 'assisted plan',
                      )
                    : [];

            const mostRecentOrder =
                maintenanceOrders[maintenanceOrders.length - 1];

            if (
                mostRecentOrder &&
                mostRecentOrder.vendor &&
                mostRecentOrder.vendor !== 'none'
            ) {
                this.setState({ gardener: mostRecentOrder.vendor });
            }
        }

        this.setState({ users });
    }

    async sendMessage() {
        this.setState({ loadingModalIsOpen: true }, async () => {
            await this.props.getConversations(
                `users=${this.props.user.data._id},${this.state.selectedUser._id}`,
            );
            if (this.props.conversations.data.length < 1) {
                await this.props.getConversations(
                    `users=${this.state.selectedUser._id},${this.props.user.data._id}`,
                );
            }

            if (this.state.uploadPreview.length > 0) {
                return this.compressImages();
            }

            this.finish();
        });
    }

    async finish() {
        let conversation;
        if (this.props.conversations.data.length < 1) {
            const newConversation = await this.props.createConversation({
                users: [this.state.selectedUser._id, this.props.user.data._id],
            });
            conversation = newConversation.data;
        } else {
            this.props.conversations.data.forEach((c) => {
                const match = c.users.find(
                    (user) => user._id === this.state.selectedUser._id,
                );
                if (match) conversation = c;
            });
        }

        const message = {
            sender: this.props.user.data._id,
            receiver: this.state.selectedUser._id,
            text: this.state.message,
            conversation_id: conversation._id,
            attachments:
                this.state.processedImages.length > 0
                    ? this.state.processedImages
                    : [],
        };

        await this.props.createMessage(message);
        let attachments = '';
        this.state.processedImages.forEach((image) => {
            attachments +=
                '<div><img src="' +
                image.url +
                '" width="200" height="100" /></div>';
        });

        attachments =
            this.state.processedImages.length > 0 ? attachments : '<p>None</p>';

        const emailMessage =
            this.state.selectedUser.type === 'customer'
                ? `Someone sent you a new message, please log in to your Yarden App to view the details.`
                : `Someone sent you a new message, see the details below or view the message in your <a href="https://www.yardengarden.com/dashboard?sender=${this.props.user.data._id}">' + Dashboard + '</a>.`;

        const notification = {
            email: this.state.selectedUser.email,
            subject: `Yarden - New Message`,
            label: 'New Message',
            body:
                '<p>Greetings from Yarden!</p>' +
                `<p>${emailMessage}</p>` +
                '<p style="margin-top: 15px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>FROM</b></p>' +
                '<p>' +
                this.props.user.data.first_name +
                ' ' +
                this.props.user.data.last_name +
                '</p>' +
                '<p style="margin-top: 5px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>MESSAGE</b></p>' +
                '<p>' +
                this.state.message +
                '</p>' +
                '<p style="margin-top: 5px; padding-top: 15px; border-top: 1px solid #DDDDDD;"><b>ATTACHMENTS</b></p>' +
                attachments,
        };

        await this.props.sendEmail(notification);

        const textMessage =
            this.state.selectedUser.type === 'customer'
                ? `Someone sent you a new message, please log in to your Yarden App to view the details.`
                : `Greeting from Yarden! You have a new message: https://www.yardengarden.com/dashboard?sender=${this.props.user.data._id}`;

        const sms = {
            from: '8888289287',
            to: this.state.selectedUser.phone_number.replace(/\D/g, ''),
            body: textMessage,
        };

        await this.props.sendSms(sms);

        await this.props.getMessages(`conversation_id=${conversation._id}`);

        const updateInbox = new Promise(async (resolve) => {
            let conversations = [];
            await this.props.getConversations(
                `users=${this.props.user.data._id}`,
            );
            for (let i = 0; i < this.props.conversations.data.length; i++) {
                await this.props.getMessages(
                    `conversation_id=${this.props.conversations.data[i]._id}`,
                );
                conversations.push(this.props.messages.inbox.data);
                if (i === this.props.conversations.data.length - 1) {
                    await this.setState({ conversations: conversations });
                    resolve(conversations);
                }
            }
        });

        updateInbox.then((conversations) => {
            let current = this.state.selectedConversation;
            conversations.forEach((conversation) => {
                const match = conversation.find(
                    (c) => c.conversation_id === this.state.conversationId,
                );
                if (match) current = conversation;
            });

            this.setState({
                selectedConversation: current,
                loadingModalIsOpen: false,
                message: '',
                uploadPreview: [],
                processedImages: [],
            });

            this.scrollToBottom();
        });
    }

    compressImages() {
        this.state.uploadPreview.forEach(async (file) => {
            if (typeof file === 'object')
                await compress(file, this.addToUploadQueue.bind(this));
        });
    }

    async addToUploadQueue(image) {
        const hostedImg = await this.props.getImage(image.name, image.type);
        const returnData = hostedImg.data.data.returnData;
        const signedRequest = returnData.signedRequest;
        const options = {
            headers: {
                'Content-Type': image.type,
            },
        };

        await this.props.updateImage(signedRequest, image, options);

        let processedImages = this.state.processedImages;
        processedImages.push({
            filename: image.name,
            mimetype: image.type,
            size: image.size,
            url: hostedImg.data.data.returnData.url,
        });

        this.setState(
            {
                processedImages: processedImages,
            },
            () => {
                if (
                    this.state.processedImages.length ===
                    this.state.uploadPreview.length
                ) {
                    this.finish();
                }
            },
        );
    }

    scrollToBottom() {
        let chat = document.getElementById('chat');
        chat.scrollTop = chat.scrollHeight;
    }

    addToUploadPreview() {
        let uploadPreview = this.state.uploadPreview;
        for (let file in this.selectedFiles.files) {
            if (typeof this.selectedFiles.files[file] === 'object')
                uploadPreview.push(this.selectedFiles.files[file]);
        }

        this.setState({ uploadPreview: uploadPreview });
    }

    removeFromUploadPreview(file) {
        const fileIndex = this.state.uploadPreview.indexOf(file);
        let uploadPreview = this.state.uploadPreview;
        uploadPreview.splice(fileIndex, 1);
        this.setState({ uploadPreview: uploadPreview });
    }

    renderMessenger() {
        const { user } = this.props;

        const {
            users,
            message,
            selectedConversation,
            uploadPreview,
            selectedUser,
            gardener,
        } = this.state;

        return (
            <div>
                {gardener && user.data.type === 'customer' && (
                    <div>
                        <button
                            className="btn2 mb15 flex flex-center full-on-mobile"
                            onClick={() => {
                                this.setState({
                                    loadingModalIsOpen: true,
                                    selectedUser: gardener,
                                });
                                setTimeout(async () => {
                                    await this.setConversation(gardener);
                                    this.scrollToBottom();
                                }, 2000);
                            }}
                        >
                            <span style={{ marginRight: 8 }}>
                                Message My Gardener
                            </span>
                            <Icon>chat_bubble_outline</Icon>
                        </button>
                    </div>
                )}
                <div className="messenger-container">
                    <label>To</label>
                    <div className="mt5">
                        <select
                            name="status"
                            className="fit-content"
                            value={selectedUser ? selectedUser._id : ''}
                            onChange={async (e) => {
                                const selectedOption = users.find(
                                    (user) => user._id === e.target.value,
                                );
                                if (selectedOption) {
                                    this.setState({
                                        loadingModalIsOpen: true,
                                        selectedUser: selectedOption,
                                    });
                                    setTimeout(async () => {
                                        await this.setConversation(
                                            selectedOption,
                                        );
                                        this.scrollToBottom();
                                    }, 2000);
                                }
                            }}
                        >
                            <option>Recipient</option>
                            {users &&
                                users.map((user, index) => (
                                    <option
                                        value={user._id}
                                        key={index}
                                    >{`${user.first_name} ${user.last_name}`}</option>
                                ))}
                        </select>
                    </div>
                    <div
                        className={
                            !gardener && user.data.type === 'customer'
                                ? null
                                : 'hidden'
                        }
                    >
                        <p>
                            Your gardener will appear in your contacts list
                            after your garden maintenance membership is
                            activated.
                        </p>
                    </div>
                    <div>
                        <div
                            className="chat-container p15 curved mt15 mb10"
                            id="chat"
                        >
                            {selectedConversation.map((message, index) => (
                                <div key={index}>
                                    <div className="flex flex-space-between">
                                        <div
                                            className={
                                                message.sender._id !==
                                                user.data._id
                                                    ? null
                                                    : 'hidden'
                                            }
                                        ></div>
                                        <div
                                            className={
                                                message.sender._id !==
                                                user.data._id
                                                    ? 'sender-chat-bubble p15 curved'
                                                    : 'receiver-chat-bubble p15 curved'
                                            }
                                        >
                                            {message.text}
                                            {message.attachments.length > 0 &&
                                                message.attachments.map(
                                                    (attachment, i) => (
                                                        <div
                                                            key={i}
                                                            className="relative"
                                                        >
                                                            <img
                                                                src={
                                                                    attachment.url
                                                                }
                                                                className="relative mt15 curved full"
                                                                alt="message attachment"
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                        </div>
                                        <div
                                            className={
                                                message.sender._id !==
                                                user.data._id
                                                    ? 'hidden'
                                                    : null
                                            }
                                        ></div>
                                    </div>
                                    <div className="flex flex-space-between">
                                        <div
                                            className={
                                                message.sender._id !==
                                                user.data._id
                                                    ? null
                                                    : 'hidden'
                                            }
                                        ></div>
                                        <div>
                                            <p className="chat-date">
                                                {moment(
                                                    message.dt_created,
                                                ).format('MM/DD/YYYY')}{' '}
                                                <span
                                                    className={
                                                        message.receiver._id !==
                                                        user.data._id
                                                            ? null
                                                            : 'hidden'
                                                    }
                                                >
                                                    ·{' '}
                                                    {message.opened
                                                        ? 'Read'
                                                        : 'Sent'}
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                message.sender._id !==
                                                user.data._id
                                                    ? 'hidden'
                                                    : null
                                            }
                                        ></div>
                                    </div>
                                </div>
                            ))}
                            {selectedConversation.length < 1 && (
                                <p className="text-gray3">
                                    To start a conversation, enter a chat
                                    message below
                                </p>
                            )}
                        </div>
                        <label>Message</label>
                        <textarea
                            type="text"
                            name="message"
                            placeholder="Hello there!"
                            value={message}
                            onChange={(e) => this.setValue(e)}
                        />
                        <div>
                            <label>Attachments (optional)</label>
                            <input
                                multiple
                                type="file"
                                className="filepicker"
                                ref={(inp) => (this.selectedFiles = inp)}
                                onChange={() => this.addToUploadPreview()}
                            />
                            <div className="mt15">
                                {uploadPreview.map((file, index) => {
                                    let fileName = file.name.split('.');
                                    let fileType =
                                        fileName[fileName.length - 1];
                                    return (
                                        <div key={index}>
                                            <div className="text-small pt10 pb10 flex flex-center-y flex-space-between">
                                                {truncate(
                                                    file.name,
                                                    20,
                                                    fileType,
                                                )}
                                                <div
                                                    className="pointer"
                                                    onClick={() =>
                                                        this.removeFromUploadPreview(
                                                            file,
                                                        )
                                                    }
                                                >
                                                    <Icon>clear</Icon>
                                                </div>
                                            </div>
                                            <Divider />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mt15 float-right">
                            <button
                                className="btn3 small flex flex-center-y"
                                disabled={
                                    !this.state.message ||
                                    !this.state.selectedUser
                                }
                                onClick={() => this.sendMessage()}
                            >
                                <span style={{ marginRight: 8 }}>Send</span>{' '}
                                <Icon>send</Icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderInbox() {
        const { conversations, loadingModalIsOpen, status } = this.state;
        let allOpened = true;

        return (
            <div>
                <div>
                    <div className="mb15">
                        <label>Status</label>
                        <div className="mt5">
                            <select
                                name="status"
                                className="fit-content"
                                value={status}
                                onChange={(e) => this.setValue(e)}
                            >
                                <option value="unread">Unread</option>
                                <option value="all">All Messages</option>
                            </select>
                        </div>
                    </div>
                    {conversations &&
                        conversations.map((conversation, index) => (
                            <div key={index}>
                                {/* eslint-disable-next-line */}
                                {conversation.map((message, i) => {
                                    if (i === conversation.length - 1) {
                                        const receiverUser =
                                            this.props.user.data._id ===
                                            message.receiver._id
                                                ? message.sender
                                                : message.receiver;
                                        if (status === 'unread') {
                                            if (
                                                !message.opened &&
                                                message.receiver._id ===
                                                    this.props.user.data._id
                                            ) {
                                                allOpened = false;
                                                return (
                                                    <div
                                                        key={i}
                                                        className="conversation-container-unread p15 mb15 curved pointer"
                                                        onClick={() => {
                                                            this.setState(
                                                                {
                                                                    loadingModalIsOpen: true,
                                                                    tab: 'messenger',
                                                                },
                                                                async () => {
                                                                    await this.setConversation(
                                                                        receiverUser,
                                                                    );
                                                                    this.scrollToBottom();
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <div className="flex flex-space-between flex-center-y">
                                                            <p className="text-gray3 m0">
                                                                <b>
                                                                    {message
                                                                        .receiver
                                                                        ._id !==
                                                                    this.props
                                                                        .user
                                                                        .data
                                                                        ._id
                                                                        ? `${message.receiver.first_name} ${message.receiver.last_name}`
                                                                        : `${message.sender.first_name} ${message.sender.last_name}`}
                                                                </b>
                                                            </p>
                                                            <div className="new-message bg-green2 text-white text-small curved">
                                                                New Message
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-space-between">
                                                            <p className="text-small mb0">
                                                                {truncate(
                                                                    message.text,
                                                                    20,
                                                                )}
                                                            </p>
                                                            <p className="text-small mb0">
                                                                {moment(
                                                                    message.dt_created,
                                                                ).format(
                                                                    'MM/DD/YYYY',
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                if (
                                                    index ===
                                                    conversations.length - 1
                                                ) {
                                                    if (allOpened) {
                                                        return (
                                                            <p
                                                                key={i}
                                                                className={
                                                                    loadingModalIsOpen
                                                                        ? 'hidden'
                                                                        : 'text-center mt15'
                                                                }
                                                            >
                                                                You have no
                                                                unread messages
                                                            </p>
                                                        );
                                                    }
                                                }
                                            }
                                        }

                                        if (status === 'all') {
                                            return (
                                                <div
                                                    key={i}
                                                    className={
                                                        conversation.find(
                                                            (message) =>
                                                                !message.opened &&
                                                                message.receiver
                                                                    ._id ===
                                                                    this.props
                                                                        .user
                                                                        .data
                                                                        ._id,
                                                        )
                                                            ? 'conversation-container-unread p15 mb15 curved pointer'
                                                            : 'conversation-container p15 mb15 curved pointer'
                                                    }
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                loadingModalIsOpen: true,
                                                                tab: 'messenger',
                                                            },
                                                            async () => {
                                                                await this.setConversation(
                                                                    receiverUser,
                                                                );
                                                                this.scrollToBottom();
                                                            },
                                                        );
                                                    }}
                                                >
                                                    <div className="flex flex-space-between flex-center-y">
                                                        <p className="text-gray3 m0">
                                                            <b>
                                                                {message
                                                                    .receiver
                                                                    ._id !==
                                                                this.props.user
                                                                    .data._id
                                                                    ? `${message.receiver.first_name} ${message.receiver.last_name}`
                                                                    : `${message.sender.first_name} ${message.sender.last_name}`}
                                                            </b>
                                                        </p>
                                                        <div
                                                            className={
                                                                conversation.find(
                                                                    (message) =>
                                                                        !message.opened &&
                                                                        message
                                                                            .receiver
                                                                            ._id ===
                                                                            this
                                                                                .props
                                                                                .user
                                                                                .data
                                                                                ._id,
                                                                )
                                                                    ? 'new-message bg-green2 text-white text-small curved'
                                                                    : 'hidden'
                                                            }
                                                        >
                                                            New Message
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-space-between">
                                                        <p className="text-small mb0">
                                                            {truncate(
                                                                message.text,
                                                                20,
                                                            )}
                                                        </p>
                                                        <p className="text-small mb0">
                                                            {moment(
                                                                message.dt_created,
                                                            ).format(
                                                                'MM/DD/YYYY',
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }
                                })}
                            </div>
                        ))}
                    {conversations.length < 1 && (
                        <div>
                            <p
                                className={
                                    loadingModalIsOpen
                                        ? 'hidden'
                                        : 'text-center mt25 mb25'
                                }
                            >
                                Your inbox is empty
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTab() {
        switch (this.state.tab) {
            case 'messenger':
                return this.renderMessenger();
            case 'inbox':
                return this.renderInbox();
            default:
                return <></>;
        }
    }

    render() {
        const { loadingModalIsOpen, tab } = this.state;

        return (
            <div>
                {/* modals */}
                <LoaderModal isOpen={loadingModalIsOpen} />

                {/* tabs */}
                <div className="flex">
                    <div
                        className={`flex flex-center-y ${
                            tab === 'inbox' ? 'tab-active' : 'tab'
                        }`}
                        onClick={() => this.setState({ tab: 'inbox' })}
                    >
                        <Icon>inbox_outlined</Icon>
                        <p style={{ marginLeft: 8, marginBottom: 0 }}>Inbox</p>
                    </div>
                    <div
                        className={`flex flex-center-y ${
                            tab === 'messenger' ? 'tab-active' : 'tab'
                        }`}
                        onClick={() => this.setState({ tab: 'messenger' })}
                    >
                        <Icon>chat_bubble_outline</Icon>
                        <p style={{ marginLeft: 8, marginBottom: 0 }}>
                            Messenger
                        </p>
                    </div>
                </div>

                {/* tab content */}
                {this.renderTab()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        messages: state.message,
        conversations: state.conversation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUsers,
            createMessage,
            getMessages,
            updateMessage,
            createConversation,
            getConversations,
            updateImage,
            getImage,
            sendEmail,
            sendSms,
            toggleLoading,
            getOrders,
            getUser,
        },
        dispatch,
    );
}

Messenger = connect(mapStateToProps, mapDispatchToProps)(Messenger);

export default Messenger;
