// config
import {
    DEVELOPMENT_URL,
    STAGING_URL,
    PRODUCTION_URL,
    DEVELOPMENT,
    STAGING,
    PRODUCTION,
} from '../config';

export default function getEnv() {
    switch (window.location.origin) {
        case DEVELOPMENT_URL:
            return DEVELOPMENT;
        case STAGING_URL:
            return STAGING;
        case PRODUCTION_URL:
            return PRODUCTION;
        default:
            return DEVELOPMENT;
    }
}
