import React from 'react';
import MaskedInput from 'react-text-mask';

const styles = {
    maskedField: {
        border: 'none',
        borderRadius: '0px',
        fontSize: '17px'
    }
}

// Visa, Mastercard, Discover
export function CreditCard(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            className="form-control"
            guide={false}
            style={styles.maskedField}
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}

export function CreditCardDate(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            className="form-control"
            guide={false}
            style={styles.maskedField}
            mask={[/\d/, /\d/, '/', /\d/, /\d/]}
            showMask
        />
    );
}

export function CreditCardCode(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            className="form-control"
            guide={false}
            style={styles.maskedField}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}