// libs
import React, { useState, useContext, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

// UI components
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LineItems from './LineItems';
import LoaderModal from './LoaderModal.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// actions
import { updateBid } from '../actions/bid/index';
import { sendEmail } from '../actions/email/index';
import { sendSms } from '../actions/sms/index';
import { getAccount } from '../actions/account/index';

// context
import { LineItemsContext } from '../context/LineItemsContext';

// helpers
import { setQty } from '../formatting/setQty';
import delimit from '../formatting/delimit';
import capitalize from '../formatting/capitalize';
import validateBeds from '../helpers/validateBeds';

// vars
import {
    PHONE_NUMBER,
    SALES_TAX,
    PAYMENT_PROCESSING_FEE,
    MISC_ORDER_PLATFORM_FEE,
} from '../vars/constants';
import {
    INSTALLATION,
    REVIVE,
    MISC,
    BID_REQUESTED,
    BID_PENDING_APPROVAL,
} from '../vars/types';

let BidDetails = (props) => {
    const { user, templates, bids, selectedBid, shapes, onClose } = props;
    let {
        hours,
        rate,
        materialsRequired,
        materials,
        deliveryRequired,
        deliveryItems,
        deliveryFee,
        rentalsRequired,
        rentalItems,
        rentalFee,
        disposalRequired,
        disposalItems,
        disposalFee,
        setHours,
        setRate,
        setMaterialsRequired,
        setMaterials,
        setDeliveryRequired,
        setDeliveryItems,
        setDeliveryFee,
        setRentalsRequired,
        setRentalItems,
        setRentalFee,
        setDisposalRequired,
        setDisposalItems,
        setDisposalFee,
    } = useContext(LineItemsContext);
    const defaultShape = shapes.data.find((shape) => shape.name === 'rectangle');
    const [activeView, setActiveView] = useState('edit');
    const [estimatedStartDate, setEstimatedStartDate] = useState(new Date());
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('none');
    const [beds, setBeds] = useState([
        { width: 0, length: 0, height: 0, qty: 0, shape: defaultShape._id  },
    ]);
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
    const [bid, setBid] = useState(selectedBid);
    const [bidType, setBidType] = useState(selectedBid.type);
    const [totals, setTotals] = useState();
    const [sendNotificationToCustomer, setSendNotificationToCustomer] =
        useState(false);

    useEffect(() => {
        if (bid.estimated_start_dt) {
            setEstimatedStartDate(bid.estimated_start_dt);
        }

        if (bid.title) {
            setTitle(bid.title);
        }

        if (bid.description) {
            setDescription(bid.description);
        }

        if (bid.line_items) {
            if (bid.line_items.labor) {
                setHours(bid.line_items.labor.qty);
                setRate(bid.line_items.labor.price);
            }

            if (bid.line_items.materials) {
                setMaterials(bid.line_items.materials);
                setMaterialsRequired(true);
            }

            if (bid.line_items.delivery) {
                setDeliveryItems(bid.line_items.delivery.name);
                setDeliveryFee(bid.line_items.delivery.price);
                setDeliveryRequired(true);
            }

            if (bid.line_items.rentals) {
                setRentalItems(bid.line_items.rentals.name);
                setRentalFee(bid.line_items.rentals.price);
                setRentalsRequired(true);
            }

            if (bid.line_items.disposal) {
                setDisposalItems(bid.line_items.disposal.name);
                setDisposalFee(bid.line_items.disposal.price);
                setDisposalRequired(true);
            }

            if (bid.line_items.beds) {
                const gardenBeds = bid.line_items.beds.map((bed) => {
                    return {
                        qty: bed.qty,
                        width: bed.width,
                        length: bed.length,
                        height: bed.height,
                        shape: bed.shape._id,
                    };
                });

                setBeds(gardenBeds);
            }
        }
    }, [bid]);

    const addNewBedRow = () => {
        let currentBeds = beds.map((b) => b);
        currentBeds.unshift({ width: 0, length: 0, height: 0, qty: 0, shape: defaultShape._id });
        setBeds(currentBeds);
    };

    const removeBedRow = () => {
        let currentBeds = beds.map((b) => b);
        let index = currentBeds.length - 1;
        currentBeds.splice(index, 1);
        setBeds(currentBeds);
    };

    const updateBeds = (info) => {
        if (info.value < 0) return;
        if (isNaN(info.value)) info.value = '';
        let bedsToSet = beds.map((b) => b);
        bedsToSet[info.index][info.name] = info.value;
        setBeds(bedsToSet);
    };

    const calculateMaterials = async (materials) => {
        let materialsTotal = 0;
        if (materials) {
            materials.forEach((item) => {
                materialsTotal += item.price * item.qty;
            });
        }

        return materialsTotal;
    };

    const viewBid = async () => {
        let materialsTotal = 0;
        if (materialsRequired) {
            materialsTotal = await calculateMaterials(materials);
            if (!materials || materialsTotal < 1 || isNaN(materialsTotal)) {
                return window.alert('Please add materials');
            }

            let missingMaterialsInfo = false;
            materials.forEach((item) => {
                if (!item.name || !item.url) {
                    missingMaterialsInfo = true;
                }
            });

            if (missingMaterialsInfo) {
                return window.alert('Please complete all required fields');
            }
        }

        const laborTotal = hours * rate;
        if (laborTotal < 1) {
            return window.alert('Please enter a labor fee');
        }

        let deliveryTotal = 0;
        if (deliveryRequired) {
            deliveryTotal = parseFloat(deliveryFee);
            if (!deliveryItems || deliveryTotal < 1 || isNaN(deliveryTotal)) {
                return window.alert(
                    'Please enter a delivery description and price',
                );
            }
        }

        let rentalTotal = 0;
        if (rentalsRequired) {
            rentalTotal = parseFloat(rentalFee);
            if (!rentalItems || rentalTotal < 1 || isNaN(rentalTotal)) {
                return window.alert(
                    'Please enter a rental description and price',
                );
            }
        }

        let disposalTotal = 0;
        if (disposalRequired) {
            disposalTotal = parseFloat(disposalFee);
            if (!disposalItems || disposalTotal < 1 || isNaN(disposalTotal)) {
                return window.alert(
                    'Please enter a disposal description and price',
                );
            }
        }

        if (
            moment(estimatedStartDate).format('MM/DD/YYYY') ===
            moment().format('MM/DD/YYYY')
        ) {
            return window.alert('Estimated start date cannot be today');
        }

        setTotals({
            materialsTotal,
            laborTotal,
            deliveryTotal,
            rentalTotal,
            disposalTotal,
        });

        setActiveView('view');
    };

    const saveBid = async () => {
        const { updateBid, sendEmail, sendSms, getAccount } = props;
        setLoadingModalIsOpen(true);

        const newStatus =
            bid.status === BID_REQUESTED ? BID_PENDING_APPROVAL : bid.status;
        let updatedBid = {
            type: bidType,
            status: newStatus,
            estimated_start_dt: estimatedStartDate,
            title: title,
            description: description,
            line_items: {
                labor: { name: 'hourly rate', price: rate, qty: hours },
            },
        };

        if (materialsRequired) updatedBid.line_items.materials = materials;
        if (deliveryRequired)
            updatedBid.line_items.delivery = {
                name: deliveryItems,
                price: parseFloat(deliveryFee),
            };
        if (rentalsRequired)
            updatedBid.line_items.rentals = {
                name: rentalItems,
                price: parseFloat(rentalFee),
            };
        if (disposalRequired)
            updatedBid.line_items.disposal = {
                name: disposalItems,
                price: parseFloat(disposalFee),
            };
        if (bidType === INSTALLATION || bidType === REVIVE) {
            if (beds) {
                updatedBid.line_items.beds = beds;
            }

            if (bid.line_items.vegetables) {
                updatedBid.line_items.vegetables = bid.line_items.vegetables;
            }

            if (bid.line_items.herbs) {
                updatedBid.line_items.herbs = bid.line_items.herbs;
            }

            if (bid.line_items.fruit) {
                updatedBid.line_items.fruit = bid.line_items.fruit;
            }
        }

        await updateBid(bid._id, updatedBid);

        const quoteUrl = bid.job
            ? `${window.location.origin}/approve?job=${bid.job}`
            : `${window.location.origin}/approve?bid=${bid._id}`;

        if (bid.status === BID_REQUESTED) {
            let sendCommunication = true;
            if (bid.job) {
                const otherBidsRequestedForSameJob = bids.data.list.filter(
                    (b) => b.job === bid.job && b._id !== bid._id,
                );

                if (otherBidsRequestedForSameJob.length > 0) {
                    sendCommunication = false;
                }
            }

            if (sendCommunication) {
                const account = await getAccount(bid.customer._id);
                const accountPending =
                    account.data && account.data.status === 'pending';
                const accountActivationUrl = `${
                    window.location.origin
                }/set-password?${bid.job ? 'job' : 'bid'}=${
                    bid.job ? bid.job : bid._id
                }&user=${bid.customer._id}`;

                const emailNotification = {
                    email: bid.customer.email,
                    subject: `Yarden - (ACTION REQUIRED) New Quote`,
                    label: 'New Quote',
                    body:
                        `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                        `<p style="margin-bottom: 15px">You received a new quote, please click the button below to view the details. If you have questions, please contact us at <a href="mailto:info@yardengarden.com">info@yardengarden.com</a>.</p>` +
                        '<div style="border-top: 1px solid #DDDDDD;">' +
                        '<div style="margin-top: 25px; text-align: center;">' +
                        `<a href="${
                            accountPending ? accountActivationUrl : quoteUrl
                        }" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>View Quote</b></a>` +
                        '</div>' +
                        '</div>',
                };

                const smsNotification = {
                    from: PHONE_NUMBER,
                    to: bid.customer.phone_number.replace(/\D/g, ''),
                    body: `Greetings from Yarden! You received a new quote: ${
                        accountPending ? accountActivationUrl : quoteUrl
                    }`,
                };

                await sendEmail(emailNotification);
                await sendSms(smsNotification);
            }
        } else {
            if (sendNotificationToCustomer) {
                const emailNotification = {
                    email: bid.customer.email,
                    subject: `Yarden - Your quote has been updated`,
                    label: 'Updated Quote',
                    body:
                        `<p style="margin-bottom: 15px">Greetings from Yarden!</p>` +
                        `<p style="margin-bottom: 15px">Your quote "${bid.title}" has been updated:</p>` +
                        '<div style="border-top: 1px solid #DDDDDD;">' +
                        '<div style="margin-top: 25px; text-align: center;">' +
                        `<a href="${quoteUrl}" style="display: block; padding: 15px 30px; background-color: #330099; color: #FFFFFF; border-radius: 10px;"><b>View Update</b></a>` +
                        '</div>' +
                        '</div>',
                };

                const smsNotification = {
                    from: PHONE_NUMBER,
                    to: bid.customer.phone_number.replace(/\D/g, ''),
                    body: `Greetings from Yarden! Your quote "${bid.title}" has been updated: ${quoteUrl}`,
                };

                await sendEmail(emailNotification);
                await sendSms(smsNotification);
            }
        }

        setActiveView('confirm');
        setLoadingModalIsOpen(false);
    };

    const renderView = () => {
        switch (activeView) {
            case 'edit':
                return renderEdit();
            case 'view':
                return renderViewBid();
            case 'confirm':
                return renderConfirmation();
            default:
                return <></>;
        }
    };

    const renderEdit = () => {
        return (
            <div>
                {/* quote type */}
                <div className="mt15">
                    <h6>Quote Type*</h6>
                    <div className="mt15">
                        <select
                            margin="dense"
                            name="bidType"
                            value={bidType}
                            onChange={(e) => setBidType(e.target.value)}
                        >
                            <option value={INSTALLATION}>Installation</option>
                            <option value={REVIVE}>Revive</option>
                            <option value={MISC}>Misc</option>
                        </select>
                    </div>
                </div>
                <hr />

                {/* template type */}
                <div className="mt15">
                    <h6>Materials Template (optional)</h6>
                    <div className="mt15">
                        <select
                            className="fit-content"
                            name="selectedTemplate"
                            value={selectedTemplate._id || 'none'}
                            onChange={(e) => {
                                const matchingTemplate = templates.data.find(
                                    (template) =>
                                        template._id === e.target.value,
                                );
                                const t = matchingTemplate
                                    ? matchingTemplate
                                    : 'none';
                                setBid({
                                    ...bid,
                                    ...{ line_items: t.line_items },
                                });
                                setSelectedTemplate(t);
                            }}
                        >
                            <option value={'none'}>No Template</option>
                            {templates.data &&
                                templates.data.length > 0 &&
                                templates.data.map((template, index) => (
                                    <option
                                        key={index}
                                        value={template._id}
                                        className={
                                            template.type === 'system' &&
                                            user.data.type !== 'admin'
                                                ? 'hidden'
                                                : null
                                        }
                                    >
                                        {template.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <hr />

                {/* estimated start date */}
                <div className="mt15">
                    <h6>Estimated Start Date*</h6>
                    <div className="mt15 date-container curved fit-content">
                        <input
                            type="date"
                            name="estimatedStartDate"
                            value={moment(estimatedStartDate).format(
                                'YYYY-MM-DD',
                            )}
                            min={moment().add(3, 'days').format('YYYY-MM-DD')}
                            onChange={(e) =>
                                setEstimatedStartDate(
                                    moment(e.target.value).startOf('day'),
                                )
                            }
                        />
                    </div>
                </div>
                <hr />

                {/* title */}
                <div className="mt15">
                    <h6>Title*</h6>
                    <div className="mt15">
                        <input
                            className="full-width"
                            type="text"
                            placeholder="Retaining Wall"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <hr />

                {/* description */}
                <div className="mt15">
                    <h6>Description*</h6>
                    <div className="mt15">
                        <textarea
                            className="full-width"
                            margin="dense"
                            type="text"
                            placeholder="10 ft tall, 50 ft wide retaining wall..."
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <hr />

                {/* garden beds */}
                <div className={bidType === MISC ? 'hidden' : 'mt15'}>
                    <div className="flex flex-center-y flex-space-between">
                        <h6 className="ml5">Garden Beds *</h6>
                        <div className="flex flex-center-y flex-space-between mt10 text-small">
                            <div className="flex flex-center-y">
                                <button
                                    className="small btn3 mr10"
                                    onClick={() => addNewBedRow()}
                                >
                                    <AddIcon />
                                </button>
                                <button
                                    disabled={beds.length < 2}
                                    className="small btn3"
                                    onClick={() => removeBedRow(0)}
                                >
                                    <RemoveIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt15 mb25">
                        {beds.map((bed, index) => (
                            <div key={index}>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={3} xs={4}>
                                        <label>Width (inches)</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={bed.width}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    bed.width,
                                                    e.target.value,
                                                );
                                                updateBeds({
                                                    name: 'width',
                                                    value: qty,
                                                    index: index,
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={4}>
                                        <label>Length (inches)</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={bed.length}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    bed.length,
                                                    e.target.value,
                                                );
                                                updateBeds({
                                                    name: 'length',
                                                    value: qty,
                                                    index: index,
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={4}>
                                        <label>Height (inches)</label>
                                        <div>
                                            <select
                                                className="full-width"
                                                value={bed.height}
                                                onChange={async (e) => {
                                                    const qty = await setQty(
                                                        bed.height,
                                                        e.target.value,
                                                    );
                                                    updateBeds({
                                                        name: 'height',
                                                        value: qty,
                                                        index: index,
                                                    });
                                                }}
                                            >
                                                <option value={0}>0</option>
                                                <option value={12}>12</option>
                                                <option value={18}>18</option>
                                                <option value={24}>24</option>
                                            </select>
                                        </div>
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={4}>
                                        <label>Qty</label>
                                        <input
                                            type="number"
                                            placeholder="0"
                                            value={bed.qty}
                                            onChange={async (e) => {
                                                const qty = await setQty(
                                                    bed.qty,
                                                    e.target.value,
                                                );
                                                updateBeds({
                                                    name: 'qty',
                                                    value: qty,
                                                    index: index,
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}
                    </div>
                    <hr />
                </div>

                {/* line items */}
                <div className="mt15">
                    <LineItems data={bid} />
                </div>

                <div className="mt15 mb15">
                    <Divider />
                </div>
                <div className="mt15 pb25 float-right">
                    <button
                        className="small btn3"
                        onClick={() => {
                            if (!selectedTemplate) {
                                viewBid();
                                return;
                            }

                            const { name } = selectedTemplate;
                            const templateName = name
                                ? name.toLowerCase()
                                : null;

                            if (
                                (templateName === INSTALLATION ||
                                    templateName === REVIVE) &&
                                bidType === MISC
                            ) {
                                const confirmMessage = `You selected "${capitalize(
                                    templateName,
                                )}" materials template, but the quote is "Misc" type. Continue?`;
                                if (!window.confirm(confirmMessage)) {
                                    return;
                                }
                            }

                            if (
                                bidType === INSTALLATION ||
                                bidType === REVIVE
                            ) {
                                const bedsValidation = validateBeds(beds);
                                if (!bedsValidation.valid) {
                                    return window.alert(bedsValidation.message);
                                }
                            }

                            viewBid();
                        }}
                    >
                        <div className="flex flex-center-y">
                            <span style={{ marginRight: 8 }}>Next</span>
                            <Icon>arrow_forward</Icon>
                        </div>
                    </button>
                </div>
            </div>
        );
    };

    const renderViewBid = () => {
        const {
            materialsTotal,
            laborTotal,
            deliveryTotal,
            rentalTotal,
            disposalTotal,
        } = totals;

        const salesTaxRate = SALES_TAX;
        const preTaxTotal =
            laborTotal +
            materialsTotal +
            deliveryTotal +
            rentalTotal +
            disposalTotal;

        const salesTax = preTaxTotal * salesTaxRate;
        const paymentProcessingFee =
            (preTaxTotal + salesTax) * PAYMENT_PROCESSING_FEE;

        const totalSale = preTaxTotal + salesTax + paymentProcessingFee;

        return (
            <div>
                <h5 className="mt50">Review & Save</h5>
                <div className="mt15">
                    <label>Title</label>
                    <p className="m0">{title}</p>
                </div>
                <div>
                    <label>Description</label>
                    <p className="m0">{description}</p>
                </div>
                <div className="mb15">
                    <label>Estimated Start Date</label>
                    <p className="m0">
                        {moment(estimatedStartDate).format('MM/DD/YYYY')}
                    </p>
                </div>
                <div className="mt25">
                    {materialsRequired && (
                        <div>
                            <h5 className="mt15">
                                Materials ${delimit(materialsTotal.toFixed(2))}
                            </h5>
                            <Grid
                                container
                                spacing={2}
                                className="overflow-x-scroll overflow-y-scroll table-container"
                            >
                                <Grid item md={12} sm={12} xs={12}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="table-cell">
                                                    Item
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    Price
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    Qty
                                                </TableCell>
                                                <TableCell className="table-cell">
                                                    Subtotal
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {materials.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <a
                                                            href={item.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {item.name}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell>
                                                        $
                                                        {delimit(
                                                            item.price.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.qty}
                                                    </TableCell>
                                                    <TableCell>
                                                        $
                                                        {delimit(
                                                            (
                                                                item.qty *
                                                                item.price
                                                            ).toFixed(2),
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </div>
                    )}

                    <h5 className="mt15">
                        Labor ${delimit(laborTotal.toFixed(2))}
                    </h5>
                    <Grid container spacing={2} className="overflow-x-scroll">
                        <Grid item md={12} sm={12} xs={12}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-cell">
                                            Item
                                        </TableCell>
                                        <TableCell className="table-cell">
                                            Price
                                        </TableCell>
                                        <TableCell className="table-cell">
                                            Qty
                                        </TableCell>
                                        <TableCell className="table-cell">
                                            Subtotal
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <label>Hourly Rate</label>
                                        </TableCell>
                                        <TableCell>
                                            ${delimit(rate.toFixed(2))}
                                        </TableCell>
                                        <TableCell>{hours}</TableCell>
                                        <TableCell>
                                            $
                                            {delimit((rate * hours).toFixed(2))}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <div className={deliveryRequired ? null : 'hidden'}>
                        <h5 className="mt15">
                            Delivery ${delimit(deliveryTotal.toFixed(2))}
                        </h5>
                        <Grid
                            container
                            spacing={2}
                            className="overflow-x-scroll"
                        >
                            <Grid item md={12} sm={12} xs={12}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-cell">
                                                Item
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Price
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Qty
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Subtotal
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <label>{deliveryItems}</label>
                                            </TableCell>
                                            <TableCell>
                                                $
                                                {delimit(
                                                    deliveryTotal.toFixed(2),
                                                )}
                                            </TableCell>
                                            <TableCell>{1}</TableCell>
                                            <TableCell>
                                                $
                                                {delimit(
                                                    deliveryTotal.toFixed(2),
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={rentalsRequired ? null : 'hidden'}>
                        <h5 className="mt15">
                            Rentals ${delimit(rentalTotal.toFixed(2))}
                        </h5>
                        <Grid
                            container
                            spacing={2}
                            className="overflow-x-scroll"
                        >
                            <Grid item md={12} sm={12} xs={12}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-cell">
                                                Item
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Price
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Qty
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Subtotal
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <label>{rentalItems}</label>
                                            </TableCell>
                                            <TableCell>
                                                $
                                                {delimit(
                                                    rentalTotal.toFixed(2),
                                                )}
                                            </TableCell>
                                            <TableCell>{1}</TableCell>
                                            <TableCell>
                                                $
                                                {delimit(
                                                    rentalTotal.toFixed(2),
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={disposalRequired ? null : 'hidden'}>
                        <h5 className="mt15">
                            Disposal ${delimit(disposalTotal.toFixed(2))}
                        </h5>
                        <Grid
                            container
                            spacing={2}
                            className="overflow-x-scroll"
                        >
                            <Grid item md={12} sm={12} xs={12}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-cell">
                                                Item
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Price
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Qty
                                            </TableCell>
                                            <TableCell className="table-cell">
                                                Subtotal
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <label>{disposalItems}</label>
                                            </TableCell>
                                            <TableCell>
                                                $
                                                {delimit(
                                                    disposalTotal.toFixed(2),
                                                )}
                                            </TableCell>
                                            <TableCell>{1}</TableCell>
                                            <TableCell>
                                                $
                                                {delimit(
                                                    disposalTotal.toFixed(2),
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                            <div className="p15">
                                <h5>Quote Summary</h5>
                                {materialsRequired && (
                                    <div className="flex flex-space-between mt15">
                                        <p className="m0">Materials</p>
                                        <p className="m0">
                                            $
                                            {delimit(materialsTotal.toFixed(2))}
                                        </p>
                                    </div>
                                )}
                                <div className="flex flex-space-between">
                                    <p className="m0">
                                        Labor
                                        {deliveryTotal > 0 ? ', Delivery' : ''}
                                        {rentalTotal > 0 ? ', Rentals' : ''}
                                        {disposalTotal > 0 ? ', Disposal' : ''}
                                    </p>
                                    <p className="m0">
                                        $
                                        {delimit(
                                            (
                                                laborTotal +
                                                deliveryTotal +
                                                rentalTotal +
                                                disposalTotal
                                            ).toFixed(2),
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-space-between">
                                    <p className="m0">Processing Fee</p>
                                    <p className="m0">
                                        $
                                        {delimit(
                                            paymentProcessingFee.toFixed(2),
                                        )}
                                    </p>
                                </div>
                                <div className="flex flex-space-between">
                                    <p className="m0">Taxes</p>
                                    <p className="m0">
                                        ${delimit(salesTax.toFixed(2))}
                                    </p>
                                </div>
                                <div className="mt10 mb10">
                                    <Divider />
                                </div>
                                <div className="flex flex-space-between">
                                    <p className="m0">Quote Total</p>
                                    <p className="m0">
                                        ${delimit(totalSale.toFixed(2))}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className="payments-separator"
                        >
                            <div className="p15">
                                <h5>Payout Information</h5>
                                <div className="flex flex-space-between mt15">
                                    <p className="m0">Labor</p>
                                    <p className="m0">
                                        ${delimit(laborTotal.toFixed(2))}
                                    </p>
                                </div>
                                <div className="flex flex-space-between">
                                    <p className="m0">
                                        Yarden Fee (
                                        {bidType === MISC ? '10%' : 'None'})
                                    </p>
                                    {bidType === MISC ? (
                                        <p className="m0">
                                            - $
                                            {delimit(
                                                (
                                                    laborTotal *
                                                    MISC_ORDER_PLATFORM_FEE
                                                ).toFixed(2),
                                            )}
                                        </p>
                                    ) : (
                                        <p className="m0">---</p>
                                    )}
                                </div>
                                <div className="mt10 mb10">
                                    <Divider />
                                </div>
                                <div className="flex flex-space-between flex-center-y">
                                    <p className="m0">Profit</p>
                                    <p className="m0">
                                        $
                                        {bidType === MISC
                                            ? delimit(
                                                  (
                                                      laborTotal -
                                                      laborTotal *
                                                          MISC_ORDER_PLATFORM_FEE
                                                  ).toFixed(2),
                                              )
                                            : delimit(laborTotal.toFixed(2))}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-small text-gray3">
                                        Payout Information is only visible to
                                        you, not the customer
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="mt15">
                    <Divider />
                </div>
                {bid.status === BID_PENDING_APPROVAL && (
                    <div>
                        <p className="mt25 mb5 text-small">
                            * Send notification about this update?
                        </p>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendNotificationToCustomer}
                                    />
                                }
                                label="Yes, send customer notification"
                                onClick={() =>
                                    setSendNotificationToCustomer(
                                        !sendNotificationToCustomer,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
                {bid.status === BID_REQUESTED && (
                    <div>
                        <p className="mt25 mb5 text-small">
                            * The customer will be sent a link to this quote
                            upon clicking "Finish"
                        </p>
                    </div>
                )}
                <div className="mt15 flex flex-space-between">
                    <button
                        className="btn3 small mr15 flex flex-center-y"
                        onClick={async () => setActiveView('edit')}
                    >
                        <Icon>arrow_back</Icon>{' '}
                        <span style={{ marginLeft: 8 }}>Back</span>
                    </button>
                    <button
                        className="purple small flex flex-center-y"
                        onClick={() => saveBid()}
                    >
                        <Icon>done</Icon>{' '}
                        <span style={{ marginLeft: 8 }}>Finish</span>
                    </button>
                </div>
            </div>
        );
    };

    const renderConfirmation = () => {
        return (
            <div className="mt50 mb50">
                <div>
                    <p>Done! Your changes were successfully saved.</p>
                </div>
                <button className="btn3 small" onClick={() => onClose()}>
                    <Icon>clear</Icon>
                    <span style={{ marginLeft: 8 }}>Close</span>
                </button>
            </div>
        );
    };

    return (
        <div className="mt15">
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* active view */}
            {renderView()}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        users: state.users,
        templates: state.template,
        bids: state.bid,
        shapes: state.shape,
    };
}

export function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateBid,
            sendEmail,
            sendSms,
            getAccount,
        },
        dispatch,
    );
}

BidDetails = connect(mapStateToProps, mapDispatchToProps)(BidDetails);

export default BidDetails;
