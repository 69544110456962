// libs
import React from 'react';

// vars
import {
    VISA,
    MASTERCARD,
    AMERICAN_EXPRESS,
    DISCOVER,
} from '../vars/constants';

const CardNumberInput = ({ type, number, onChange }) => {
    const handleCardNumberChange = (e) => {
        const input = e.target.value;
        let formattedInput = input.replace(/\D/g, ''); // Remove non-numeric characters

        if (type === VISA || type === MASTERCARD || type === DISCOVER) {
            formattedInput = formattedInput.replace(/(\d{4})(?=\d)/g, '$1-');
            formattedInput = formattedInput.slice(0, 19); // Limit card number length for Visa, Mastercard, and Discover
        } else if (type === AMERICAN_EXPRESS) {
            formattedInput = formattedInput
                .replace(/(\d{4})(\d{6})(\d{5})?/, '$1-$2-$3')
                .trim('-');
            formattedInput = formattedInput.slice(0, 17); // Limit card number length for American Express
        }

        onChange(formattedInput);
    };

    return (
        <input
            type="text"
            placeholder={
                type === AMERICAN_EXPRESS
                    ? 'xxxx-xxxxxx-xxxxx'
                    : 'xxxx-xxxx-xxxx-xxxx'
            }
            value={number}
            onChange={handleCardNumberChange}
            maxLength={type === AMERICAN_EXPRESS ? 17 : 19} // Set max length based on card brand
        />
    );
};

export default CardNumberInput;
