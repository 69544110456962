export default function getDeviceType() {
    const isMobileDevice =
        /android/i.test(navigator.userAgent) ||
        /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isMobileDevice) {
        return 'mobile';
    } else {
        return 'desktop';
    }
}
