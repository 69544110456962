// vars
import { PAYMENT_PROCESSING_FEE, SALES_TAX } from '../vars/constants';

export default function getQuotePricing(quote) {
    const {
        laborTotal,
        materialsTotal,
        deliveryTotal,
        rentalTotal,
        disposalTotal,
    } = calculateLineItemCosts(quote);
    const { firstPayment, secondPayment } = calculateQuotePricing(
        laborTotal,
        materialsTotal,
        deliveryTotal,
        rentalTotal,
        disposalTotal,
    );

    return {
        laborTotal,
        materialsTotal,
        deliveryTotal,
        rentalTotal,
        disposalTotal,
        firstPayment,
        secondPayment,
    };
}

function calculateLineItemCosts(quote) {
    let materialsTotal = 0;
    let laborTotal = 0;
    let deliveryTotal = 0;
    let rentalTotal = 0;
    let disposalTotal = 0;

    if (quote.line_items) {
        laborTotal = quote.line_items.labor
            ? quote.line_items.labor.qty * quote.line_items.labor.price
            : 0;
        materialsTotal = quote.line_items.materials
            ? calculateMaterials(quote)
            : 0;
        deliveryTotal = quote.line_items.delivery
            ? quote.line_items.delivery.price
            : 0;
        rentalTotal = quote.line_items.rentals
            ? quote.line_items.rentals.price
            : 0;
        disposalTotal = quote.line_items.disposal
            ? quote.line_items.disposal.price
            : 0;
    }

    return {
        materialsTotal,
        laborTotal,
        deliveryTotal,
        rentalTotal,
        disposalTotal,
    };
}

function calculateMaterials(quote) {
    let materialsTotal = 0;
    if (quote.line_items.materials) {
        quote.line_items.materials.forEach((item) => {
            materialsTotal += item.price * item.qty;
        });
    }

    return materialsTotal;
}

function calculateQuotePricing(
    laborTotal,
    materialsTotal,
    deliveryTotal,
    rentalTotal,
    disposalTotal,
) {
    const salesTaxRate = SALES_TAX;
    const preTaxFirstPaymentTotal =
        materialsTotal + deliveryTotal + rentalTotal + disposalTotal;
    const firstPaymentSalesTax = preTaxFirstPaymentTotal * salesTaxRate;
    const firstPaymentProcessingFee =
        (preTaxFirstPaymentTotal + firstPaymentSalesTax) *
        PAYMENT_PROCESSING_FEE;

    const firstPaymentTotalWithTaxAndFee =
        preTaxFirstPaymentTotal +
        firstPaymentSalesTax +
        firstPaymentProcessingFee;

    const secondPaymentSalesTax = laborTotal * salesTaxRate;
    const secondPaymentProcessingFee =
        (laborTotal + secondPaymentSalesTax) * PAYMENT_PROCESSING_FEE;

    const secondPaymentTotalWithTaxAndFee =
        laborTotal + secondPaymentSalesTax + secondPaymentProcessingFee;

    return {
        firstPayment: {
            preTaxTotal: preTaxFirstPaymentTotal,
            salesTax: firstPaymentSalesTax,
            paymentProcessingFee: firstPaymentProcessingFee,
            total: firstPaymentTotalWithTaxAndFee,
        },
        secondPayment: {
            preTaxTotal: laborTotal,
            salesTax: secondPaymentSalesTax,
            paymentProcessingFee: secondPaymentProcessingFee,
            total: secondPaymentTotalWithTaxAndFee,
        },
    };
}
