// libs
import React, { useState, useContext, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// UI components
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import LineItems from './LineItems';
import LoaderModal from './LoaderModal';

// actions
import {
    createTemplate,
    updateTemplate,
    deleteTemplate,
} from '../actions/template/index';

// context
import { LineItemsContext } from '../context/LineItemsContext';

let TemplateDetails = (props) => {
    const { selectedTemplate, user, createTemplate, updateTemplate } = props;

    const [templateName, setTemplateName] = useState(
        selectedTemplate ? selectedTemplate.name : '',
    );
    const [templateType, setTemplateType] = useState(
        selectedTemplate ? selectedTemplate.type : 'custom',
    );
    const [bidType, setBidType] = useState(
        selectedTemplate ? selectedTemplate.bid_type : 'misc',
    );
    const [successMessage, setSuccessMessage] = useState('');
    const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);

    const {
        hours,
        rate,
        materials,
        deliveryRequired,
        deliveryItems,
        deliveryFee,
        rentalsRequired,
        rentalItems,
        rentalFee,
        disposalRequired,
        disposalItems,
        disposalFee,
        setHours,
        setRate,
        setMaterialsRequired,
        setMaterials,
        setDeliveryRequired,
        setDeliveryItems,
        setDeliveryFee,
        setRentalsRequired,
        setRentalItems,
        setRentalFee,
        setDisposalRequired,
        setDisposalItems,
        setDisposalFee,
    } = useContext(LineItemsContext);

    useEffect(() => {
        if (selectedTemplate) {
            setTemplateName(selectedTemplate.name);
            setTemplateType(selectedTemplate.type);
            setBidType(selectedTemplate.bid_type);

            if (selectedTemplate.line_items) {
                if (selectedTemplate.line_items.labor) {
                    setHours(selectedTemplate.line_items.labor.qty);
                    setRate(selectedTemplate.line_items.labor.price);
                }

                if (selectedTemplate.line_items.materials) {
                    setMaterials(selectedTemplate.line_items.materials);
                    setMaterialsRequired(true);
                }

                if (selectedTemplate.line_items.delivery) {
                    setDeliveryItems(selectedTemplate.line_items.delivery.name);
                    setDeliveryFee(selectedTemplate.line_items.delivery.price);
                    setDeliveryRequired(true);
                }

                if (selectedTemplate.line_items.rentals) {
                    setRentalItems(selectedTemplate.line_items.rentals.name);
                    setRentalFee(selectedTemplate.line_items.rentals.price);
                    setRentalsRequired(true);
                }

                if (selectedTemplate.line_items.disposal) {
                    setDisposalItems(selectedTemplate.line_items.disposal.name);
                    setDisposalFee(selectedTemplate.line_items.disposal.price);
                    setDisposalRequired(true);
                }
            }
        }
    }, [selectedTemplate]);

    const calculateMaterials = (materials) => {
        let materialsTotal = 0;
        if (materials) {
            materials.forEach((item) => {
                materialsTotal += item.price * item.qty;
            });
        }

        return materialsTotal;
    };

    const saveTemplate = () => {
        setLoadingModalIsOpen(true);
        setTimeout(async () => {
            const template = {
                name: templateName,
                type: templateType,
                bid_type: bidType,
                line_items: {
                    labor: { name: 'hourly rate', price: rate, qty: hours },
                },
            };

            if (calculateMaterials(materials) > 0)
                template.line_items.materials = materials;
            if (deliveryRequired)
                template.line_items.delivery = {
                    name: deliveryItems,
                    price: deliveryFee,
                };
            if (rentalsRequired)
                template.line_items.rentals = {
                    name: rentalItems,
                    price: rentalFee,
                };
            if (disposalRequired)
                template.line_items.disposal = {
                    name: disposalItems,
                    price: disposalFee,
                };
            if (selectedTemplate) {
                try {
                    await updateTemplate(selectedTemplate._id, template);
                    setSuccessMessage('Template successfully updated');
                } catch (err) {
                    window.alert('Error updating template');
                } finally {
                    setLoadingModalIsOpen(false);
                }
            } else {
                template.owner = user.data._id;
                try {
                    await createTemplate(template);
                    setSuccessMessage('Template successfully created');
                } catch (err) {
                    window.alert('Error creating template');
                } finally {
                    setLoadingModalIsOpen(false);
                }
            }
        }, 1000);
    };

    return (
        <div className="mt25">
            {/* modals */}
            <LoaderModal isOpen={loadingModalIsOpen} />

            {/* template info */}
            <div>
                <label>Template Name*</label>
                <input
                    type="text"
                    placeholder="Retaining Wall"
                    name="templateName"
                    value={templateName}
                    onChange={(e) => {
                        setTemplateName(e.target.value);
                    }}
                />
            </div>
            <div className="mt15">
                <label>Template Type*</label>
                <div>
                    <select
                        style={{ width: 'fit-content' }}
                        name="templateType"
                        value={templateType}
                        onChange={(e) => setTemplateType(e.target.value)}
                    >
                        <option value={'custom'}>
                            Custom - template for common quotes
                        </option>
                        <option
                            value={'system'}
                            className={
                                user.data.type === 'admin' ? null : 'hidden'
                            }
                        >
                            System - template for quote automation
                        </option>
                    </select>
                </div>
            </div>
            {templateType === 'system' && (
                <div className="mt15">
                    <div>
                        <label>Quote Type*</label>
                    </div>
                    <div>
                        <select
                            style={{ width: 'fit-content' }}
                            margin="dense"
                            name="bidType"
                            value={bidType}
                            onChange={(e) => setBidType(e.target.value)}
                        >
                            <option value={'installation'}>Installation</option>
                            <option value={'revive'}>Revive</option>
                            <option value={'misc'}>Misc</option>
                        </select>
                    </div>
                </div>
            )}
            <div className="mt15 mb15">
                <Divider />
            </div>

            {/* line items */}
            <LineItems data={selectedTemplate} />

            {/* save button */}
            <div className="float-right pb25">
                <div className="flex flex-center-y">
                    {successMessage && (
                        <p style={{ color: 'green' }} className="mb0 mr15">
                            {successMessage}
                        </p>
                    )}
                    <button
                        className="purple small flex flex-center-y"
                        disabled={!templateName || !templateType || !bidType}
                        onClick={() => saveTemplate()}
                    >
                        <Icon>done</Icon>{' '}
                        <span style={{ marginLeft: 8 }}>Finish</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createTemplate,
            updateTemplate,
            deleteTemplate,
        },
        dispatch,
    );
}

TemplateDetails = connect(mapStateToProps, mapDispatchToProps)(TemplateDetails);

export default TemplateDetails;
